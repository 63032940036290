import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;


const getForm = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/form/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const getForms = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/form/?organization=${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const createForm = async (data, accessToken, idToken) => {
  const request = axios.post(`${baseUrl}/form`, data, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const deleteForm = async (id, accessToken, idToken) => {
  const request = axios.delete(`${baseUrl}/form/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const updateForm = async (data, accessToken, idToken) => {
  const request = axios.put(`${baseUrl}/form/${data.id}`, data, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const formServices = { getForm, getForms, createForm, deleteForm, updateForm };
export default formServices;