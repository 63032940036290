import React from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Typography, Paper, Chip, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TranslationContent from "../../Translations/TranslationContent";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Error from "../GeneralComponents/Error";
import Loading from "../GeneralComponents/Loading";
import SiteTable from "./SiteTable";
import { useNavigate } from "react-router-dom";
import { Tokens } from "../../services/Tokens";
import siteService from "../../services/sites";
import { SessionContext } from "../../Context/SessionProvider";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { logger } from "../../services/logger";


const Site = () => {
  const [sites, setSites] = React.useState([]);
  const [siteStreetAddress, setSiteStreetAddress] = React.useState("");
  const [creationMode, setCreationMode] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const session = React.useContext(SessionContext);
  const { addAlert } = React.useContext(SnackBarContext);

  const navigate = useNavigate();

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    if(fetchData.current && session.roles.length > 0) {
      fetchData.current = false;
      fetchSites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchSites = () => {
    Tokens().then((tokens) => {
      siteService
      .getSites(session.activeRole.organizationId, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedSites = [...sites];
        updatedSites = [...response];
        setSites(updatedSites);
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarFetchingSitesFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const retryClick = () => {
    setLoading(true);
    setError(false);
    fetchSites();
  }

  const updateSelectedSite = (siteStreetAddress, isNew) => {
    if(isNew) {
      setCreationMode(true);
    }
    else {
      setCreationMode(false);
      setSiteStreetAddress(siteStreetAddress)
    }
  }

  const updateSites = (newSites) => {
    let updatedSites = [...sites];
    updatedSites = [...newSites];
    setSites(updatedSites);
  }


  const navigateClick = () => {
    setCreationMode(false);
    setSiteStreetAddress("");
    navigate("/site");
  }

  if(error && !loading) return <Error section="siteManagement" retryClick={retryClick}/>
  if(loading && !error) return <Loading section="siteManagement" />

  if(!error && !loading) {
    return(
      <MainLayout>
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              {siteStreetAddress !== "" ?
                <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigateClick()} ><TranslationContent contentID="siteManagement" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />{siteStreetAddress}</Typography>
              :
                (creationMode ?
                  <Stack direction="row">
                    <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigateClick()} >
                      <TranslationContent contentID="siteManagement" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
                    </Typography>
                    <Chip color="primary" size="small" label={<b style={{color: "yellow"}}><TranslationContent contentID="new" /></b>} />
                  </Stack>
                :
                  <Typography color="primary" variant="body2"><b><TranslationContent contentID="siteManagement" /></b></Typography>
                )
              }
            </Grid>
          </Grid>
        </Paper>
        <SiteTable 
          updateSelectedSite={updateSelectedSite} 
          updateSites={updateSites} 
          siteStreetAddress={siteStreetAddress} 
          sites={sites} 
          creationMode={creationMode} 
        />
      </MainLayout>
    )
  }
}

export default Site;