import React from "react";
import { Typography, Divider, Box, Stack, ListItem, ListItemText } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TranslationContent from "../../../Translations/TranslationContent";
import { useViewport } from "../../../Context/ViewportProvider";
import EventIcon from '@mui/icons-material/Event';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import DomainIcon from '@mui/icons-material/Domain';
import StatusChip from "./StatusChip";
import ActionButtons from "./ActionButtons";


const RegistrationListItem = ({ registration, openUserPlacementDialog }) => {
  const [actionStatus, setActionStatus] = React.useState("pending");
  const { width } = useViewport();

  const handleStatusChange = (newStatus) => {
    setActionStatus(newStatus);
  }

  return(
    <>
      <ListItem 
        sx={{paddingLeft: width > 600 ? "16px" : 0}} 
        secondaryAction={
          <ActionButtons 
            status={actionStatus} 
            handleStatusChange={handleStatusChange} 
            registration={registration} 
            openUserPlacementDialog={openUserPlacementDialog}
          />
        }
      >
        <ListItemText 
          primary={
            <Grid container>
              <Grid xs={6}>
                <Typography>
                  <b>{registration.name}</b>
                </Typography>
              </Grid>
              {width > 600 && 
                <Grid xs={6} >
                  <StatusChip status={actionStatus} />
                </Grid>
              }
            </Grid>
          } 
        />
      </ListItem>
      <Box ml={width > 600 ? "25px" : 0}>
        <Stack direction="row" spacing={0.5}>
          <AlternateEmailIcon fontSize="small" color="primary"/><Typography variant="caption">{registration.email}</Typography>
        </Stack>
        <Stack direction="row" spacing={0.5} mt="10px">
          <PhoneIcon fontSize="small" color="primary"/>
          {registration.phone.length > 0 ? <Typography variant="caption">{registration.phone}</Typography> : <Typography variant="caption" sx={{opacity: 0.5}}><i><TranslationContent contentID="noPhoneNumber" /></i></Typography>}
        </Stack>
        <Stack direction="row" spacing={0.5} mt="10px">
          <DomainIcon fontSize="small" color="primary"/>
          <Typography variant="caption">{registration.requestedOrganization}</Typography>
        </Stack>
        <Stack direction="row" spacing={0.5} mt="10px">
          <SpeakerNotesIcon fontSize="small" color="primary"/>
          {registration.additionalInformation.length > 0 ? <Typography variant="caption">{registration.additionalInformation}</Typography> : <Typography variant="caption" sx={{opacity: 0.5}}><i><TranslationContent contentID="noExtraInfo" /></i></Typography>}
        </Stack>
        <Stack direction="row" spacing={0.5} mt="10px">
          <EventIcon fontSize="small" color="primary"/><Typography variant="caption">{new Date(registration._ts * 1000).toLocaleString()}</Typography>
        </Stack>
      </Box>
      <br />
      <Divider />
    </>
  )
}

export default RegistrationListItem;