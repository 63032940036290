import React from "react";
import SiteCreator from "./SiteCreator";
import SiteEditor from "./SiteEditor";
import SiteTableContent from "./SiteTableContent";


const SiteTable = ({ updateSelectedSite, sites, updateSites, siteStreetAddress, creationMode }) => {
  const [selectedSite, setSelectedSite] = React.useState({});
    
  const rowClick = (site, isNew) => {
    if(isNew) {
      updateSelectedSite("", isNew);
    }
    else {
      updateSelectedSite(site.streetAddress, isNew);
      setSelectedSite({...selectedSite, ...site})
    }
  }

  const validateSiteInputs = (site) => {
    let validStatuses = [false, false, false, false, false, false, false, false];
    validStatuses[0] = site.propertyName.length > 5 && site.propertyName.length < 35;
    validStatuses[1] = site.propertyType.length > 5 && site.propertyType.length < 35;
    validStatuses[2] = site.customerName.length > 5 && site.customerName.length < 35;
    validStatuses[3] = site.customerEmail.length > 5 && site.customerEmail.length < 35;
    validStatuses[4] = site.customerPhone.length > 6 && site.customerPhone.length < 17;
    validStatuses[5] = site.streetAddress.length > 5 && site.streetAddress.length < 35;
    validStatuses[6] = site.postalCode.length === 5;
    validStatuses[7] = site.city.length > 1 && site.city.length < 20;
    return validStatuses.every(status => status === true);
  }

  return (
    <>
      <SiteTableContent 
        show={siteStreetAddress === "" && !creationMode} 
        sites={sites} 
        rowClick={rowClick}
      />
      <SiteCreator 
        show={creationMode} 
        validateSiteInputs={validateSiteInputs} 
        sites={sites} 
        updateSites={updateSites} 
        updateSelectedSite={updateSelectedSite}
      />
      <SiteEditor 
        show={siteStreetAddress !== "" && !creationMode} 
        validateSiteInputs={validateSiteInputs} 
        sites={sites} 
        updateSites={updateSites} 
        updateSelectedSite={updateSelectedSite} 
        selectedSite={selectedSite}
      />
    </>
  )   
}

export default SiteTable;