import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const downloadInspection = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/archive/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey }, responseType: 'arraybuffer', });

  return request.then(response => response.data);
}

const getArchivedInspections = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/archive/?organization=${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const getSiteArchivedInspections = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/archive/?site=${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const createDownloadLink = async (id, accessToken, idToken) => {
  const request = axios.post(`${baseUrl}/archive`, id, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const deleteArchivedInspection = async (id, accessToken, idToken) => {
  const request = axios.delete(`${baseUrl}/archive/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const archiveServices =  
  { 
    downloadInspection,
    getArchivedInspections,
    getSiteArchivedInspections,
    createDownloadLink,
    deleteArchivedInspection
  };
export default archiveServices;