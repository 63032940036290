import React from "react";
import { List, Typography } from "@mui/material";
import Section from "./Section";
import TranslationContent from "../../../Translations/TranslationContent";


const SectionList = ({ sections, updateSection, permissionType, updateSelectedSection, deleting }) => {

  if(sections.length > 0) {
    return(
      <List sx={{ mb: 2 }}>
        {sections.map((section, index) => 
          <Section 
            key={section.id} 
            section={section} 
            index={index} 
            updateSection={updateSection} 
            overallSections={sections.length} 
            permissionType={permissionType} 
            updateSelectedSection={updateSelectedSection}
            deleting={deleting}
          />
        )}
      </List>
    )
  }
  else return <Typography sx={{opacity: 0.5, mb: 3, mt: 3}}><TranslationContent contentID="noSections" /></Typography>;
}

export default SectionList;