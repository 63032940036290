import React from "react";
import TranslationContent from "../../Translations/TranslationContent";
import { Dialog, DialogActions, DialogTitle, DialogContent, Button, Divider, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from "@mui/material";


const FilterDialog = ({ open, handleClose, handleFilter }) => {
  const [userType, setUserType] = React.useState("all");

  const clearFilters = () => {
    setUserType("all");
    handleFilter("roleType", "all");
    handleClose();
  }

  const applyFilters = () => {
    handleFilter("roleType", userType);
    handleClose();
  }

  return(
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="filter-user-dialog-title"
      aria-describedby="filter-users-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle><TranslationContent contentID="filters" /></DialogTitle>
      <Divider />
      <DialogContent>
        <FormControl>
          <FormLabel id="role-type-radio-buttons-group-label"><TranslationContent contentID="userType" /></FormLabel>
          <RadioGroup
            aria-labelledby="role-type-radio-buttons-group-label"
            name="radio-buttons-group-role-type"
            row
            value={userType}
            onChange={event => setUserType(event.target.value)}
          >
            <FormControlLabel value="internal" control={<Radio />} label={<TranslationContent contentID="internal" />} />
            <FormControlLabel value="external" control={<Radio />} label={<TranslationContent contentID="external" />}  />
            <FormControlLabel value="all" control={<Radio />} label={<TranslationContent contentID="all" />}  />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => clearFilters()}><TranslationContent contentID="clearFilters" /></Button>
        <Button onClick={() => applyFilters()} variant="contained"><TranslationContent contentID="apply" /></Button>
      </DialogActions>
    </Dialog>
  )
}

export default FilterDialog;