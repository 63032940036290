import React from "react";
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, IconButton, Box, TextField, Stack } from "@mui/material";
import { useViewport } from "../../../Context/ViewportProvider";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import TranslationContent from "../../../Translations/TranslationContent";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterDialog from "../../Dialogs/FilterDialog";
import UserRow from "./UserRow";


const UserTableContent = ({ rowClick, users, show }) => {
  const { width } = useViewport();
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [searchWord, setSearchWord] = React.useState("");
  const [filterRoleType, setFilterRoleType] = React.useState("all");
  const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const filterUsers = (type, event) => {
  
    if(type === "searchInput") {
      setSearchWord(event.target.value);
      let updatedFilteredUsers = [...users];
      if(event.target.value === "") {
        updatedFilteredUsers = [...users];
      }
      else {
        updatedFilteredUsers = updatedFilteredUsers.filter(user => user.azureEmail.toLowerCase().includes(event.target.value.toLowerCase()) || user.name.toLowerCase().includes(event.target.value.toLowerCase()))
      }
      if(filterRoleType !== "all") {
        updatedFilteredUsers = updatedFilteredUsers.filter(user => user.roles.some(role => role.type === filterRoleType))
      }
      setFilteredUsers(updatedFilteredUsers);
    }

    if(type === "roleType") {
      let updatedFilteredUsers = [...users];
      if(event === "all") {
        if(searchWord !== "") {
          updatedFilteredUsers = updatedFilteredUsers.filter(user => user.azureEmail.toLowerCase().includes(searchWord.toLowerCase()) || user.name.toLowerCase().includes(searchWord.toLowerCase()))
        }
      }
      else {
        if(searchWord !== "") {
          updatedFilteredUsers = updatedFilteredUsers.filter(user => user.azureEmail.toLowerCase().includes(searchWord.toLowerCase()) || user.name.toLowerCase().includes(searchWord.toLowerCase()))
        }
        if(event === "internal") {
          updatedFilteredUsers = updatedFilteredUsers.filter(user => user.roles.some(role => role.type === "internal"))
        }
        if(event === "external") {
          updatedFilteredUsers = updatedFilteredUsers.filter(user => user.roles.every(role => role.type === "external"))
        }
      }
      setFilteredUsers(updatedFilteredUsers);
      setFilterRoleType(event);
    }
  }

  if(show) {
    return(
      <Box>
        <FilterDialog open={filterDialogOpen} handleClose={() => setFilterDialogOpen(false)} handleFilter={filterUsers}/>
        <Stack direction="row">
          <IconButton color="primary" onClick={() => setFilterDialogOpen(true)}><FilterListIcon /></IconButton>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: 3, width: "100%", marginLeft: width > 600 ? "50%" : 5, marginBottom: "15px", }}>
            <PersonSearchIcon sx={{  mr: 1, my: 0.5 }} color="primary" />
            <TextField label={<TranslationContent contentID="whoAreULooking" />} variant="standard" fullWidth autoComplete="off" onChange={event => filterUsers("searchInput", event)} value={searchWord} />
          </Box>
        </Stack>
        <TableContainer sx={{width: "99%", height: "110%"}}>
          <Table aria-label="collapsible table" size="small" sx={{width: "99%",}}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell><b><TranslationContent contentID="name" /></b></TableCell>
                <TableCell align="right"><b><TranslationContent contentID="email" /></b></TableCell>
                {width > 600 && 
                  <TableCell align="right"><b><TranslationContent contentID="type" /></b></TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {searchWord !== "" || filterRoleType !== "all" ? 
                filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => 
                  <UserRow key={user.id} user={user} rowClick={() => rowClick(user)}/>
                )
                : 
                users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                  <UserRow key={user.id} user={user} rowClick={() => rowClick(user)}/>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={searchWord !== "" || filterRoleType !== "all" ? filteredUsers.length : users.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={<TranslationContent contentID="rowsPerPage" />}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return ` ${from}–${to} / ${count}`
            }
          }
        />
      </Box>
    )
  }
}


export default UserTableContent;