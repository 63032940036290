import React from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import DomainIcon from '@mui/icons-material/Domain';
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useLocation } from "react-router-dom";


const MenuIconHandler = ({ navigationItem}) => {
  const location = useLocation();

  if(navigationItem.section === "home") {
    if(location.pathname === navigationItem.path) {
      return <HomeIcon color="primary"/>
    }
    else {
      return <HomeOutlinedIcon color="primary"/>
    }
  }

  if(navigationItem.section === "account") {
    if(location.pathname === navigationItem.path) {
      return <ManageAccountsIcon color="primary" />
    }
    else {
      return <ManageAccountsOutlinedIcon color="primary" />
    }
  }

  if(navigationItem.section === "newInspection") {
    if(location.pathname.includes("/inspect/new")) {
      return <AddCircleIcon color="primary" />
    }
    else {
      return <AddCircleOutlineIcon color="primary" />
    }
  }

  if(navigationItem.section === "inspectionArchive") {
    if(location.pathname === navigationItem.path) {
      return <ArchiveIcon color="primary" />
    }
    else {
      return <ArchiveOutlinedIcon color="primary" />
    }
  }

  if(navigationItem.section === "continueInspection") {
    if(location.pathname.includes("/inspect/active")) {
      return <UnarchiveIcon color="primary" />
    }
    else {
      return <UnarchiveOutlinedIcon color="primary" />
    }
  }

  if(navigationItem.section === "userManagement") {
    if(location.pathname.includes("users")) {
      return <PeopleAltIcon color="primary" />
    }
    else {
      return <PeopleOutlineOutlinedIcon color="primary" />
    }
  }

  if(navigationItem.section === "organizationManagement") {
    if(location.pathname === navigationItem.path) {
      return <DomainTwoToneIcon color="primary" />
    }
    else {
      return <DomainIcon color="primary" />
    }
  }
  
  if(navigationItem.section === "siteManagement") {
    if(location.pathname === navigationItem.path) {
      return <HolidayVillageIcon color="primary" />
    }
    else {
      return <HolidayVillageOutlinedIcon color="primary" />
    }
  }
  
  if(navigationItem.section === "inspectionTemplate") {
    if(location.pathname.includes("form")) {
      return <ReceiptLongIcon color="primary" />
    }
    else {
      return <ReceiptLongOutlinedIcon color="primary" />
    }
  }

  if(navigationItem.section === "reportTemplate") {
    if(location.pathname === navigationItem.path) {
      return <AssignmentTurnedInIcon color="primary" />
    }
    else {
      return <InventoryOutlinedIcon color="primary" />
    }
  }

}

export default MenuIconHandler;