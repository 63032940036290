import React from "react";
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  DialogContentText, 
  Button, 
  CircularProgress, 
  Stack, 
  Divider, 
  TextField, 
  Box,
  Autocomplete, 
  Radio, 
  RadioGroup, 
  FormControl, 
  FormControlLabel, 
  FormLabel, 
  Checkbox, 
  FormGroup 
} from "@mui/material";
import LoadingSpinner from "../Loading/LoadingSpinner";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TranslationContent from "../../Translations/TranslationContent";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { useViewport } from "../../Context/ViewportProvider";
import { Tokens } from "../../services/Tokens";
import roleService from "../../services/roles";
import { SessionContext, SessionDispatchContext } from "../../Context/SessionProvider";
import { logger } from "../../services/logger";


const UserPlacementDialog = ({ open, handleClose, newUser, organizations, fetchOrganizations }) => {
  const [dialogState, setDialogState] = React.useState("initial");

  const updateState = (newState) => {
    setDialogState(newState);
  }

  return(
    <Dialog
      open={open}
      aria-labelledby="user-role-creation-dialog-title"
      aria-describedby="user-role-creation-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogContentManager 
        state={dialogState} 
        handleClose={handleClose} 
        newUser={newUser} 
        updateState={updateState} 
        organizations={organizations} 
        fetchOrganizations={fetchOrganizations}
      />
    </Dialog>
  )
}

export default UserPlacementDialog;


const DialogContentManager = ({ state, handleClose, newUser, updateState, organizations, fetchOrganizations }) => {
  const { width } = useViewport();
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const [external, setExternal] = React.useState(false);
  const [internal, setInternal] = React.useState(false);
  const [newRole, setNewRole] = React.useState({
    oid: newUser.id,
    userName: newUser.name,
    brandId: "",
    organization: {
      name: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      phone: "",
      email: "",
    },
    internalRights: {
      userManagement: false,
      organizationManagement: false,
      siteManagement: false,
      inspectionFormManagement: false,
      reportManagement: false,
      inspect: false,
    },
    type: "",
    role: "",
    organizationId: "",
  })

  const handleInternalRightsToggle = (type) => {
    let updatedNewRole = {...newRole};
    if(type === "userManagement") {
      updatedNewRole.internalRights.userManagement = !updatedNewRole.internalRights.userManagement;
    }
    if(type === "organizationManagement") {
      updatedNewRole.internalRights.organizationManagement = !updatedNewRole.internalRights.organizationManagement;
    }
    if(type === "siteManagement") {
      updatedNewRole.internalRights.siteManagement = !updatedNewRole.internalRights.siteManagement;
    }
    if(type === "inspectionFormManagement") {
      updatedNewRole.internalRights.inspectionFormManagement = !updatedNewRole.internalRights.inspectionFormManagement;
    }
    if(type === "reportManagement") {
      updatedNewRole.internalRights.reportManagement = !updatedNewRole.internalRights.reportManagement;
    }
    if(type === "inspect") {
      updatedNewRole.internalRights.inspect = !updatedNewRole.internalRights.inspect;
    }
    setNewRole(updatedNewRole);
  }

  const internalClick = () => {
    let updatedNewRole = {...newRole};
    updatedNewRole.type = "internal";
    updatedNewRole.role = "admin";
    updatedNewRole.brandId = session.activeRole.brandId;
    updatedNewRole.organizationId = session.activeRole.organizationId;
    updatedNewRole.organization.name = session.activeRole.organization.name;
    updatedNewRole.organization.streetAddress = session.activeRole.organization.streetAddress;
    updatedNewRole.organization.postalCode = session.activeRole.organization.postalCode;
    updatedNewRole.organization.city = session.activeRole.organization.city;
    updatedNewRole.organization.phone = session.activeRole.organization.phone;
    updatedNewRole.organization.email = session.activeRole.organization.email;
    setNewRole(updatedNewRole);
    setInternal(true);
  }

  const externalClick = () => {
    let updatedNewRole = {...newRole};
    updatedNewRole.type = "external";
    updatedNewRole.role = "inspector";
    setNewRole(updatedNewRole);
    setExternal(true)
  }

  const returnClick = () => {
    let updatedNewRole = {...newRole};
    updatedNewRole.type = "";
    updatedNewRole.role = "";
    updatedNewRole.brandId = "";
    updatedNewRole.organizationId = "";
    updatedNewRole.organization.name = "";
    updatedNewRole.organization.streetAddress = "";
    updatedNewRole.organization.postalCode = "";
    updatedNewRole.organization.city = "";
    updatedNewRole.organization.phone = "";
    updatedNewRole.organization.email = "";
    setNewRole(updatedNewRole);
    setInternal(false);
    setExternal(false);
  }

  const submitRole = (updatedNewRole) => {
    logger(updatedNewRole)
    updateState("loading");
    Tokens().then((tokens) => {
      roleService
      .createRole(updatedNewRole, tokens.accessToken, tokens.idToken)
      .then(response => { 
        logger(response);
        let updatedSession = {...session};
        updatedSession.userCount = updatedSession.userCount + 1;
        updateSession({type: "UPDATE_SESSION", value: updatedSession})
        addAlert({message: "successAcceptApplication", type: "success"});
        handleClose();
      })
      .catch(error => {
        logger(error);
        updateState("error");
        addAlert({message: "errorAcceptApplication", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      updateState("error");
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }
  

  const handleExternalRoleChange = (event) => {
    let updatedNewRole = {...newRole};
    updatedNewRole.role = event.target.value;
    setNewRole(updatedNewRole);
  }

  const addExternalOrganizationRole = (organization) => {
    let updatedNewRole = {...newRole};
    if(organization) {
      updatedNewRole.brandId = organization.brandId;
      updatedNewRole.organizationId = organization.id;
      updatedNewRole.organization.name = organization.name;
      updatedNewRole.organization.streetAddress = organization.streetAddress;
      updatedNewRole.organization.postalCode = organization.postalCode;
      updatedNewRole.organization.city = organization.city;
      updatedNewRole.organization.phone = organization.phone;
      updatedNewRole.organization.email = organization.email;
    }
    else {
      updatedNewRole.brandId = "";
      updatedNewRole.organizationId = "";
      updatedNewRole.organization.name = "";
      updatedNewRole.organization.streetAddress = "";
      updatedNewRole.organization.postalCode = "";
      updatedNewRole.organization.city = "";
      updatedNewRole.organization.phone = "";
      updatedNewRole.organization.email = "";
    }
    setNewRole(updatedNewRole);
  }

  if(state === "loading") {
    return(
      <DialogContent sx={{textAlign: "center"}}>
        <LoadingSpinner />
      </DialogContent>
    )
  }

  if(state === "error") {
    return(
      <>
        <DialogTitle id="alert-dialog-title" textAlign={"center"}>
          <ErrorIcon fontSize="large" color="error" />
          <br />
          <b><TranslationContent contentID="errorOccured" /></b>
        </DialogTitle>
        <DialogContent sx={{textAlign: "center"}}>
          <DialogContentText id="alert-dialog-description" textAlign={"center"}>
            <TranslationContent contentID="somethingWrong" />
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}><TranslationContent contentID="close" /></Button>
          <Button onClick={() => submitRole()} autoFocus variant="contained" color="primary">
            <TranslationContent contentID="retry" />
          </Button>
        </DialogActions>
      </>
    )
  }

  if(state === "initial") {
    return(
      <>
        <DialogTitle id="alert-dialog-title" textAlign={"center"}>
          <CheckCircleIcon fontSize="large" color="success" />
          <br />
          <b><TranslationContent contentID="applicationAccepted" /></b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" textAlign={"center"}>
            <TranslationContent contentID="postSuccessDesc1" /> <b>{newUser.name}</b> <TranslationContent contentID="postSuccessDesc2" /> 
          </DialogContentText>

          {!internal && !external && 
            <Stack spacing={3} mt={3}>
              <Button variant="contained" onClick={() => internalClick()}><TranslationContent contentID="internalUser" /></Button>
              <Button variant="contained" onClick={() => externalClick()}><TranslationContent contentID="externalUser" /></Button>
              <Button onClick={handleClose} variant="outlined"><TranslationContent contentID="createRoleLater" /></Button>
            </Stack>
          }

          {internal && 
            <Box spacing={3} mt={5} sx={{textAlign: "center", justifyContent: "center"}}>
              <FormGroup sx={{textAlign: "center", justifyContent: "left"}}>
                <FormLabel component={"legend"} sx={{textAlign: width > 700 ? "center" : "left", marginBottom: "10px"}}><TranslationContent contentID="selectAccessRights" /></FormLabel>
                <FormControlLabel label={<TranslationContent contentID="userManagementCheck" />} control={<Checkbox checked={newRole.userManagement} onChange={() => handleInternalRightsToggle("userManagement")} />} sx={{textAlign: "left", marginLeft: width > 700 ? "35%" : 0, marginRight: "auto"}}/>
                <FormControlLabel label={<TranslationContent contentID="organizationManagementCheck" />} control={<Checkbox checked={newRole.organizationManagement} onChange={() => handleInternalRightsToggle("organizationManagement")} />} sx={{textAlign: "left", marginLeft: width > 700 ? "35%" : 0, marginRight: "auto"}}/>
                <FormControlLabel label={<TranslationContent contentID="siteManagementCheck" />} control={<Checkbox checked={newRole.siteManagement} onChange={() => handleInternalRightsToggle("siteManagement")} />} sx={{textAlign: "left", marginLeft: width > 700 ? "35%" : 0, marginRight: "auto"}}/>
                <FormControlLabel label={<TranslationContent contentID="inspectionFormManagementCheck" />} control={<Checkbox checked={newRole.inspectionFormManagement} onChange={() => handleInternalRightsToggle("inspectionFormManagement")} />} sx={{textAlign: "left", marginLeft: width > 700 ? "35%" : 0, marginRight: "auto"}}/>
                <FormControlLabel label={<TranslationContent contentID="reportManagementCheck" />} control={<Checkbox checked={newRole.reportManagement} onChange={() => handleInternalRightsToggle("reportManagement")} />} sx={{textAlign: "left", marginLeft: width > 700 ? "35%" : 0, marginRight: "auto"}}/>
                <FormControlLabel label={<TranslationContent contentID="inspectCheck" />} control={<Checkbox checked={newRole.inspect} onChange={() => handleInternalRightsToggle("inspect")} />} sx={{textAlign: "left", marginLeft: width > 700 ? "35%" : 0, marginRight: "auto"}}/>
              </FormGroup>
              <div style={{height: "15px"}} />
              <Button onClick={() => returnClick()}><TranslationContent contentID="cancel" /></Button>
            </Box>
          }

          {external && 
            <Stack spacing={3} mt={5} textAlign={"center"}>
              <OrganizationSelector addExternalOrganizationRole={addExternalOrganizationRole} organizations={organizations} fetchOrganizations={fetchOrganizations} />
              <FormControl sx={{textAlign: "center"}}>
                <FormLabel id="radio-buttons-for-controlled-external-role"><TranslationContent contentID="role" /></FormLabel>
                <RadioGroup
                  aria-labelledby="radio-buttons-for-controlled-external-role"
                  name="radio-buttons-for-controlled-external-role"
                  sx={{textAlign: "center", justifyContent: "center"}}
                  value={newRole.role}
                  onChange={event => handleExternalRoleChange(event)}
                >
                  <FormControlLabel value="inspector" control={<Radio disabled={newRole.organizationId === ""} />} label={<TranslationContent contentID="inspector" />} sx={{textAlign: "center", justifyContent: "center"}} />
                  <FormControlLabel value="manager" control={<Radio disabled={newRole.organizationId === ""} />} label={<TranslationContent contentID="manager" />} sx={{textAlign: "center", justifyContent: "center"}} />
                </RadioGroup>
              </FormControl>
              <Button onClick={() => returnClick()}><TranslationContent contentID="cancel" /></Button>
            </Stack>
          }

        </DialogContent>
        <Divider />
        {(external || internal) && 
          <DialogActions>
            <Button onClick={() => submitRole(newRole)} fullWidth variant="contained" color="primary" disabled={newRole.organizationId === ""}>
              <TranslationContent contentID="save" />
            </Button>
          </DialogActions>
        }
      </>
    )
  }
}


const OrganizationSelector = ({ addExternalOrganizationRole, organizations, fetchOrganizations }) => {
  const [selectedOrganization, setSelectedOrganization] = React.useState(null);
  const [organizationInput, setOrganizationInput] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const loading = open && organizations.length === 0;

  const handleOrganizationChange = (newValue) => {
    setOpen(false);
    let newSelectedOrganization = {...selectedOrganization};
    newSelectedOrganization = newValue;
    setSelectedOrganization(newSelectedOrganization);
    addExternalOrganizationRole(newValue);
  }

  React.useEffect(() => {
    if(open && organizations.length === 0) {
      fetchOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])
  
  return(
    <Autocomplete
      disablePortal
      value={selectedOrganization || null}
      onChange={(event, newValue) => handleOrganizationChange(newValue)}
      inputValue={organizationInput}
      onInputChange={(event, newInputValue) => setOrganizationInput(newInputValue)}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={organizations}
      filterOptions={(option) => organizations.filter(option => option.name !== "Piristeel Oy" && option.name !== "Ruukki" && option.name.toLowerCase().includes(organizationInput.toLowerCase()))}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      noOptionsText={<TranslationContent contentID="noOrganizationsAvailable" />}
      fullWidth
      loading={loading}
      loadingText={<TranslationContent contentID="fetchingOrgs" />}
      renderInput={(params) => 
        <TextField {...params} label={<TranslationContent contentID="organization" />}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      }
    />
  )
}