import React from "react";
import { Chip } from "@mui/material";
import TranslationContent from "../../../Translations/TranslationContent";


const StatusChip = ({ status }) => {
  if(status === "pending") {
    return(<Chip label={<TranslationContent contentID="pending"/>} />)
  }
  if(status === "accepting") {
    return(<Chip label={<TranslationContent contentID="accepting"/>} color="success"/>)
  }
  if(status === "rejecting") {
    return(<Chip label={<TranslationContent contentID="rejecting"/>} color="error"/>)
  }
  if(status === "accepted") {
    return(<Chip label={<TranslationContent contentID="accepted"/>} color="success"/>)
  }
  if(status === "rejected") {
    return(<Chip label={<TranslationContent contentID="rejected"/>} color="error"/>)
  }
  if(status === "loading") {
    return(<Chip label={<TranslationContent contentID="sending"/>}/>)
  }
}

export default StatusChip;