import React from "react";
import { useTheme } from '@mui/material/styles';
import { Drawer, List, Typography, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge } from '@mui/material'
import MenuIconHandler from "./MenuIconHandler";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import TranslationContent from "../../Translations/TranslationContent";
import CompanyLogo from "../Utils/GlobalComponents/CompanyLogo";
import { useLocation } from "react-router-dom";
import { SessionContext } from "../../Context/SessionProvider";
import { DrawerHeader } from "./MainHeader";


const MobileDrawer = ({ mobileOpen, navigationList, handleLogout, handleNavigation, handleDrawerClose }) => {
  const theme = useTheme();
  const location = useLocation();
  const session = React.useContext(SessionContext);

  return(
    <Drawer open={mobileOpen} variant="temporary" sx={{zIndex: 5000}} onClose={handleDrawerClose}>
      <DrawerHeader>
        <CompanyLogo width={100} showAppLogo={false}/>
        <IconButton onClick={handleDrawerClose} color="primary">
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {navigationList.basic.map((navigationItem, index) => (
          <ListItem key={navigationItem.section + index} disablePadding sx={{ display: 'block', }} color="primary">
            <ListItemButton
              onClick={() => handleNavigation(navigationItem.path)}
              sx={{
                minHeight: 48,
                justifyContent: mobileOpen ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                color="primary"
                sx={{
                  minWidth: 0,
                  mr: mobileOpen ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <MenuIconHandler navigationItem={navigationItem}/>
              </ListItemIcon>
              <ListItemText primary={<TranslationContent contentID={navigationItem.section} />} sx={{ opacity: mobileOpen ? 1 : 0, color: location.pathname === navigationItem.path ? window.location.hostname.includes("ruukki") ? "#fc2516" : "#009fe3" : "" }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {navigationList.inspections.some(entry => entry !== null) && 
        <>
          <Divider />
          <Typography variant="caption" sx={{padding: "5px 0 0 20px"}}><b><TranslationContent contentID="inspections" /></b></Typography>
          <List>
            {navigationList.inspections.map((navigationItem, index) => ( navigationItem !== null &&
              <ListItem key={navigationItem.section + index} disablePadding sx={{ display: 'block', }} color="primary">
                <ListItemButton
                  onClick={() => handleNavigation(navigationItem.path)}
                    sx={{
                      minHeight: 48,
                      justifyContent: mobileOpen ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      color="primary"
                      sx={{
                        minWidth: 0,
                        mr: mobileOpen ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <MenuIconHandler navigationItem={navigationItem}/>
                    </ListItemIcon>
                    {navigationItem.section === "newInspection" ?
                      <ListItemText 
                        primary={
                          <Badge badgeContent={session.newInspections} variant="standard" color="primary" /*sx={{position: "relative", top: 13, right: -3}}*/ >
                            <TranslationContent contentID={navigationItem.section} />
                          </Badge>} 
                        sx={{ opacity: mobileOpen ? 1 : 0, color: location.pathname === navigationItem.path || 
                          (location.pathname.includes("/inspect/new/create") && navigationItem.path.includes("inspect/new")) || 
                          (location.pathname.includes("/inspect/new/select") && navigationItem.path.includes("inspect/new")) ?
                          window.location.hostname.includes("ruukki") ? "#fc2516" : "#009fe3" : "" }} />
                    :
                      navigationItem.section === "continueInspection" ?
                        <ListItemText primary={<Badge badgeContent={session.unfinishedInspections} variant="standard" color="primary" /*sx={{position: "relative", top: 13, right: -3}}*/ ><TranslationContent contentID={navigationItem.section} /></Badge>} sx={{ opacity: mobileOpen ? 1 : 0, color: location.pathname === navigationItem.path || (location.pathname.includes("inspect/active") && navigationItem.path.includes("inspect/active")) ? window.location.hostname.includes("ruukki") ? "#fc2516" : "#009fe3" : "" }} />
                      :
                        <ListItemText primary={<TranslationContent contentID={navigationItem.section} />} sx={{ opacity: mobileOpen ? 1 : 0, color: location.pathname === navigationItem.path ? window.location.hostname.includes("ruukki") ? "#fc2516" : "#009fe3" : "" }} />
                    }
                  </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      }
      {navigationList.management.some(entry => entry !== null) && 
        <>
          <Divider />
          <Typography variant="caption" sx={{padding: "5px 0 0 20px"}}><b><TranslationContent contentID="management" /></b></Typography>
          <List>
            {navigationList.management.map((navigationItem, index) => ( navigationItem !== null &&
              <ListItem key={navigationItem.section + index} disablePadding sx={{ display: 'block', }} color="primary">
                <ListItemButton
                  onClick={() => handleNavigation(navigationItem.path)}
                  sx={{
                    minHeight: 48,
                    justifyContent: mobileOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    color="primary"
                    sx={{
                      minWidth: 0,
                      mr: mobileOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <MenuIconHandler navigationItem={navigationItem}/>
                  </ListItemIcon>
                  {navigationItem.section === "userManagement" ?
                    <ListItemText primary={<Badge badgeContent={session.applications} variant="standard" color="primary" ><TranslationContent contentID={navigationItem.section} /></Badge>} sx={{ opacity: mobileOpen ? 1 : 0, color: location.pathname === navigationItem.path ? window.location.hostname.includes("ruukki") ? "#fc2516" : "#009fe3" : "" }} />
                  :
                    <ListItemText primary={<TranslationContent contentID={navigationItem.section} />} sx={{ opacity: mobileOpen ? 1 : 0, color: location.pathname === navigationItem.path ? window.location.hostname.includes("ruukki") ? "#fc2516" : "#009fe3" : "" }} />
                  }
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      }
      <Divider />
      <List>
        <ListItem key="logout-button-mobile" disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            color="primary"
            onClick={handleLogout}
            sx={{
              minHeight: 48,
              justifyContent: mobileOpen ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              color="primary"
              sx={{
                minWidth: 0,
                mr: mobileOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <LogoutIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={<TranslationContent contentID="logout" />} sx={{ opacity: mobileOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem> 
      </List>
    </Drawer>
  )
}

export default MobileDrawer;