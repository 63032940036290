import React from "react";
import loaderStylesRuukki from "./loaderStylesRuukki.css";
import loaderStylesPisko from "./loaderStylesPisko.css";


const LoadingSpinner = () => {
  let domain = window.location.hostname;

  if(domain.includes("ruukki")) {
  return(
    <div className="lds-roller" style={loaderStylesRuukki}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  )
  }
  else {
    return(
      <div className="lds-roller" style={loaderStylesPisko}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    )
  }
}

export default LoadingSpinner;