import React from "react";
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Collapse, Box, Tooltip, CircularProgress, Stack } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { LanguageContext } from "../../../Translations/LanguageProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from '@mui/icons-material/Share';
import DownloadIcon from '@mui/icons-material/Download';
import { useViewport } from "../../../Context/ViewportProvider";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ShareLinkDialog from "../../Dialogs/ShareLinkDialog";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { Tokens } from "../../../services/Tokens";
import archiveService from "../../../services/archives";
import TranslationContent from "../../../Translations/TranslationContent";
import { logger } from "../../../services/logger";
import StartIcon from '@mui/icons-material/Start';
import SportsScoreIcon from '@mui/icons-material/SportsScore';


const ArchiveTable = ({ inspections }) => {
  const { width } = useViewport();

  return(
    <TableContainer sx={{width: "99%", height: "110%"}}>
      <Table aria-label="collapsible table" size="small" sx={{width: "99%",}}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell><b><TranslationContent contentID="inspection" /></b></TableCell>
            <TableCell align="right"><b><TranslationContent contentID="streetAddress" /></b></TableCell>
            {width > 600 && 
              <>
                <TableCell align="right"><b><TranslationContent contentID="inspector" /></b></TableCell>
                <TableCell align="right"><b><TranslationContent contentID="completed" /></b></TableCell>
              </>
            }
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inspections.map((document) => (
            <ArchiveRow key={document.id} document={document} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ArchiveTable;


const ArchiveRow = ({ document }) => {
  const { width } = useViewport();
  const lang = React.useContext(LanguageContext);
  const [open, setOpen] = React.useState(false);
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [buttons, setButtons] = React.useState("initial");
  const [downloading, setDownloading] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const { addAlert } = React.useContext(SnackBarContext);

  const handleDialogClose = () => {
    setShareDialogOpen(false);
  }

  const openShareDialog = () => {
    setShareDialogOpen(true);
  }

  const downloadInpsection = () => {
    setDownloading(true);
    Tokens().then((tokens) => {
      archiveService
      .downloadInspection(document.id, tokens.accessToken, tokens.idToken)
      .then(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const newTab = window.open(url, '_blank');
  
        if (newTab) {
          newTab.focus();
        } else {
          alert('Please allow pop-ups to open the file.');
        }
        setDownloading(false);
      })
      .catch(error => {
        logger(error);
        setDownloading(false);
        addAlert({message: "snackbarDownloadFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setDownloading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const confirmDelete = () => {
    setShowConfirmDialog(false);
    deleteFromDB();
  }

  const deleteFromDB = () => {
    setDeleting(true);
    Tokens().then((tokens) => {
      archiveService
      .deleteArchivedInspection(document.id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        setDeleting(false);
        setButtons("deleted");
        addAlert({message: "snackbarDeleteInspectionSuccess", type: "success"});
      })
      .catch(error => {
        logger(error);
        setDeleting(false);
        addAlert({message: "snackbarDeleteInspectionFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setDeleting(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const renderButtons = () => {
    if(buttons === "initial") {
      return(
        <TableCell align="right">
          {downloading ? 
            <CircularProgress style={{height: "30px", width: "30px", marginBottom: "-10px"}} color="success"/>
          :
            <Tooltip title={<TranslationContent contentID="downloadPDFReport" />} arrow>
              <IconButton color="success" onClick={() => downloadInpsection(document.id)}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          }
          <Tooltip title={<TranslationContent contentID="createOneTimeDownloadLink" />} arrow>
            <IconButton color="primary" onClick={() => openShareDialog()}>
              <ShareIcon />
            </IconButton>
          </Tooltip>
          {deleting ? 
            <CircularProgress style={{height: "30px", width: "30px", marginBottom: "-10px"}} color="error" />
          :
            <Tooltip title={<TranslationContent contentID="deleteReport" />} arrow>
              <IconButton color="error" onClick={() => setShowConfirmDialog(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          }
        </TableCell>
      )
    }
    if(buttons === "loading") {
      return(
        <TableCell align="right">
          <CircularProgress />
        </TableCell>
      )
    }
    if(buttons === "deleted") {
      return(
        <TableCell align="right">
          <Typography sx={{opacity: 0.5}} variant="caption"><TranslationContent contentID="deleted" /></Typography>
        </TableCell>)
    }
  }

  return(
    <>
      <ShareLinkDialog open={shareDialogOpen} handleClose={handleDialogClose} id={document.id} />
      <ConfirmDialog title="deleteReportTitle" text="deleteReportDesc" open={showConfirmDialog} handleClose={() => setShowConfirmDialog(false)} confirmClick={() => confirmDelete()} />
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={{opacity: buttons === "deleted" ? 0.5 : ""}}>
          {document.formName[lang.lang]}
        </TableCell>
        <TableCell align="right" sx={{opacity: buttons === "deleted" ? 0.5 : ""}}>{document.site.streetAddress}</TableCell>
          {width > 600 &&
            <>
              <TableCell align="right" sx={{opacity: buttons === "deleted" ? 0.5 : ""}}>{document.inspector.name}</TableCell>
              <TableCell align="right" sx={{opacity: buttons === "deleted" ? 0.5 : ""}}>{new Date(document.completed).toLocaleString(undefined, {dateStyle: "short", timeStyle: "short"} )}</TableCell>
            </>
          }
        {renderButtons()}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {document.name[lang.lang]}
              </Typography>              
            </Box>
            <Grid container p={1} spacing={3}>
              <Grid xs={12} sm={6} lg={4} xl={3}>
                <Typography mb={width > 600 ? 1 : 0}><b><TranslationContent contentID="schedule" /></b></Typography>
                <Stack direction="row" spacing={1}>
                <StartIcon fontSize="small" color="primary" />
                <Typography variant="body2">{new Date(document.started).toLocaleString(undefined, {dateStyle: "short", timeStyle: "short"} )}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                <SportsScoreIcon fontSize="small" color="primary" />
                <Typography variant="body2">{new Date(document.completed).toLocaleString(undefined, {dateStyle: "short", timeStyle: "short"} )}</Typography>
                </Stack>
              </Grid>
              <Grid xs={12} sm={6} lg={4} xl={3}>
                <Typography mb={width > 600 ? 1 : 0}><b><TranslationContent contentID="address" /></b></Typography>
                <Typography variant="body2">{document.site.streetAddress}</Typography>
                <Typography variant="body2">{document.site.postalCode}, {document.site.city}</Typography>
                <Typography variant="body2"></Typography>
              </Grid>
              <Grid xs={12} sm={6} lg={4} xl={3}>
                <Typography mb={width > 600 ? 1 : 0}><b><TranslationContent contentID="inspector" /></b></Typography>
                <Typography variant="body2">{document.inspector.name} ({document.organization.name})</Typography>
                <Typography variant="body2">{document.inspector.phone}</Typography>
                <Typography variant="body2">{document.inspector.email}</Typography>
              </Grid>
              <Grid xs={12} sm={6} lg={4} xl={3}>
                <Typography mb={width > 600 ? 1 : 0}><b><TranslationContent contentID="customer" /></b></Typography>
                <Typography variant="body2">{document.site.customerName}</Typography>
                <Typography variant="body2">{document.site.customerPhone}</Typography>
                <Typography variant="body2">{document.site.customerEmail}</Typography>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}