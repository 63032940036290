import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const createOrganization = async (data, accessToken, idToken) => {
  const request = axios.post(`${baseUrl}/organization`, data, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const getOrganization = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/organization/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const getOrganizations = async (accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/organization`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const updateOrganization = async (data, accessToken, idToken) => {
  const request = axios.put(`${baseUrl}/organization/${data.organization.id}`, data, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const deleteOrganization = async (id, accessToken, idToken) => {
  const request = axios.delete(`${baseUrl}/organization/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const organizationServices = { createOrganization, getOrganization, getOrganizations, updateOrganization, deleteOrganization };
export default organizationServices;
