import React from "react";
import Error from "../../GeneralComponents/Error";
import Loading from "../../GeneralComponents/Loading";
import MainLayout from "../../../Layouts/MainLayout";
import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TranslationContent from "../../../Translations/TranslationContent";
import { useNavigate } from "react-router-dom";
import UserTable from "./UserTable";
import { Tokens } from "../../../services/Tokens";
import userService from "../../../services/users";
import organizationService from "../../../services/organizations";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { SessionContext, SessionDispatchContext } from "../../../Context/SessionProvider";
import { logger } from "../../../services/logger";


const UserList = () => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const { addAlert } = React.useContext(SnackBarContext);
  const [organizations, setOrganizations] = React.useState([]);
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const navigate = useNavigate();

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    if(fetchData.current && session.roles.length > 0) {
      fetchData.current = false;
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchUsers = () => {
    Tokens().then((tokens) => {
      userService
      .getUsers(tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedUsers = [...users];
        updatedUsers = [...response];
        setUsers(updatedUsers);
        let updatedSession = {...session};
        updatedSession.userCount = updatedUsers.length;
        updateSession({type: "UPDATE_SESSION", value: updatedSession})
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarFetchingUsersFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const fetchOrganizations = () => {
    Tokens().then((tokens) => {
      organizationService
      .getOrganizations(tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedOrganizations = [...organizations];
        updatedOrganizations = [...response];
        setOrganizations(updatedOrganizations);
      })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarOrganizatrionFetchFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const updateUserList = (updatedUser) => {
    let updatedUsers = users.map(user => 
      user.id === updatedUser.id ? {...user, ...updatedUser} : user)
    setUsers(updatedUsers);
  }

  const deleteUserFromList = (id) => {
    let updatedUsers = users.filter(user => user.id !== id);
    setUsers(updatedUsers);
  }

  const retryClick = () => {
    setLoading(true);
    setError(false);
    fetchUsers();
  }

  if(error && !loading) return <Error section="userManagement" retryClick={() => retryClick()}/>;
  if(loading && !error) return <Loading section="userManagement" />;

  if(!loading && !error) {
    return(
      <MainLayout>
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigate("/users")}><TranslationContent contentID="userManagement" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} /><TranslationContent contentID="userList" /></Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{padding: "7px", width: "100%",}}>
          <Grid container spacing={2} mt={0}>   
            <Grid xs={12} sx={{textAlign: "center", justifyContent: "center" }}>
              <UserTable 
                users={users} 
                fetchOrganizations={fetchOrganizations} 
                organizations={organizations} 
                updateUserList={updateUserList} 
                deleteUserFromList={deleteUserFromList}  
              />
            </Grid>
          </Grid>
        </Paper>
      </MainLayout>
    )
  }
}

export default UserList;