import React from "react";
import MainLayout from "../../../Layouts/MainLayout";
import { Paper, Typography, Collapse, Button, Divider, ButtonGroup, CircularProgress, Accordion, Zoom, AccordionSummary, AccordionDetails } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Error from "../../GeneralComponents/Error";
import Loading from "../../GeneralComponents/Loading";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SectionList from "./SectionList";
import Section from "./Section";
import { LanguageContext } from "../../../Translations/LanguageProvider";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import Observations from "./Observations";
import { Tokens } from "../../../services/Tokens";
import inspectionService from "../../../services/inspections";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { useNavigate, useParams } from "react-router-dom";
import { SessionContext, SessionDispatchContext } from "../../../Context/SessionProvider";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TranslationContent from "../../../Translations/TranslationContent";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { logger } from "../../../services/logger";
import SaveFabButton from "../../Utils/GlobalComponents/SaveFabButton";


const InspectActive = () => {
  const [loading, setLoading] = React.useState(true);
  const [deleting, setDeleting] = React.useState(false);
  const [finishing, setFinishing] = React.useState(false);
  const [showSaveButton, setShowSaveButton] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [activeContent, setActiveContent] = React.useState("list");
  const [activeSection, setActiveSection] = React.useState({sectionData: {}, index: 0});
  const currentLang = React.useContext(LanguageContext);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState({title: "", text: ""});
  const [inspection, setInspection] = React.useState({})
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const [images, setImages] = React.useState([]);
  const { id } = useParams();
  const navigate = useNavigate();


  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    if(fetchData.current && session.roles.length > 0) {
      fetchData.current = false;
      fetchInspection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchInspection = () => {
    Tokens().then((tokens) => {
      inspectionService
      .getInspection(id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedInspection = { ...inspection, ...response };
        setInspection(updatedInspection);
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        if(error.response.status === 403) {
          navigate("/");
        }
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
      if(error.response.status === 403) {
        navigate("/");
      }
    })
  }

  const handleActiveContentChange = (clickType, data, index) => {
    if(clickType === "list") {
      setActiveContent("list");
    }
    if(clickType === "toObservations") {
      setActiveSection({...activeSection, index: inspection.sections.length})
      setActiveContent("otherObservations");
    }
    if(clickType === "previous") {
      if(activeSection.index > 0) {
        let updatedActiveSection = {...activeSection};
        updatedActiveSection.data = inspection.sections[activeSection.index - 1];
        updatedActiveSection.index = updatedActiveSection.index - 1;
        setActiveSection(updatedActiveSection);
        setActiveContent("section");
      }
    }
    if(clickType === "next") {
      if(activeSection.index <= (inspection.sections.length - 1)) {
        let updatedActiveSection = {...activeSection};
        updatedActiveSection.data = inspection.sections[activeSection.index + 1];
        updatedActiveSection.index = updatedActiveSection.index + 1;
        setActiveSection(updatedActiveSection);
        setActiveContent("section");
      }
      if(activeSection.index === inspection.sections.length - 1) {
        let updatedActiveSection = {...activeSection};
        setActiveContent("otherObservations");
        updatedActiveSection.index = updatedActiveSection.index + 1;
      }
    }
    if(clickType === "toSection") {
      let updatedActiveSection = {...activeSection};
      updatedActiveSection.data= data;
      updatedActiveSection.index = index;
      setActiveSection(updatedActiveSection);
      setActiveContent("section");
    }
  }


  const finishInspection = () => {
    setFinishing(true);
    setConfirmDialogOpen(false);
    Tokens().then((tokens) => {
      inspectionService
      .finishInspection(id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        // Old way but removed the return of pdf file as requested by client. If nothing has happened, remove after year 2024
        /*
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const newTab = window.open(url, '_blank');

        if (newTab) {
          newTab.focus();
        } else {
          alert('Please allow pop-ups to open the file.');
        }
        */
        const updatedSession = {...session};
        updatedSession.unfinishedInspections = updatedSession.unfinishedInspections - 1;
        updateSession({type: "UPDATE_SESSION", value: updatedSession});
        addAlert({message: "snackbarInspectionFinishSuccess", type: "success"});
        navigate("/inspect/active");
      })
      .catch(error => {
        logger(error);
        setFinishing(false);
        addAlert({message: "snackbarInspectionFinishFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setFinishing(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const updateInspection = (updatedInspection, type) => {
    let newInspectionData = {...inspection, ...updatedInspection};
    setInspection(newInspectionData);
    if(type === "imageUpdate") {
      return;
    }
    else {
      setShowSaveButton(true);
    }
  }

  const retryClick = () => {
    setLoading(true);
    setError(false);
    fetchInspection();
  }

  const confirmClick = () => {
    if(dialogContent.title === "deleteInspectionTitle") {
      deleteInspection(id);
    }
    if(dialogContent.title === "finishInspectionTitle") {
      finishInspection(id);
    }
  }

  const deleteInspection = (id) => {
    setDeleting(true);
    Tokens().then((tokens) => {
      inspectionService
      .deleteInspection(id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedSession = {...session};
        updatedSession.unfinishedInspections = updatedSession.unfinishedInspections - 1;
        updateSession({type: "UPDATE_SESSION", value: updatedSession});
        addAlert({message: "snackbarDeleteInspectionSuccess", type: "success"});
        navigate("/inspect/active")
      })
      .catch(error => {
        logger(error);
        setDeleting(false);
        addAlert({message: "snackbarDeleteInspectionFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setDeleting(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const saveInspection = () => {
    setSaving(true);
    Tokens().then((tokens) => {
      inspectionService
      .updateInspection(inspection, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        addAlert({message: "snackbarInspectionSaveSuccess", type: "success"});
        setSaving(false);
        setShowSaveButton(false);
      })
      .catch(error => {
        logger(error);
        setSaving(false);
        addAlert({message: "snackbarInspectionSaveFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setSaving(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const handleDialogOpen = (type) => {
    if(type === "delete") {
      const updatedDialogContent = {...dialogContent};
      updatedDialogContent.title = "deleteInspectionTitle";
      updatedDialogContent.text = "deleteInspectionDesc";
      setDialogContent(updatedDialogContent);
      setConfirmDialogOpen(true);
    }
    if(type === "finish") {
      const updatedDialogContent = {...dialogContent};
      updatedDialogContent.title = "finishInspectionTitle";
      updatedDialogContent.text = "finishInspectionDesc";
      setDialogContent(updatedDialogContent);
      setConfirmDialogOpen(true);
    }
  }

  const updateImages = (newImage, type) => {
    if (type === "add") {
      if (images.length < 21) {
        setImages(prevImages => [...prevImages, newImage]);
      } else {
        setImages(prevImages => prevImages.slice(0, -1).concat(newImage));
      }
    } else if (type === "remove") {
      setImages(images.filter(image => image.id !== newImage));
    }
  }

  if(error && !loading) return <Error section="continueInspection" retryClick={retryClick}/>
  if(loading && !error) return <Loading section="continueInspection" />

  if(!loading && !error) {
    return(
      <MainLayout>
        <SaveFabButton show={showSaveButton} loading={saving} onClick={() => saveInspection()} disabled={finishing || deleting || saving} />
        <ConfirmDialog open={confirmDialogOpen} text={dialogContent.text} title={dialogContent.title} handleClose={() => setConfirmDialogOpen(false)} confirmClick={confirmClick} />
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12} >
              <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigate("/inspect/active")}><TranslationContent contentID="continueInspection" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />{inspection.site.propertyName}</Typography>
            </Grid>
          </Grid>
        </Paper>

        <ButtonGroup variant="text" sx={{width:"100%"}}>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} onClick={() => navigate("/inspect/active")} disabled={finishing || deleting || saving}><KeyboardReturnIcon /><Typography variant="caption" ><TranslationContent contentID="return" /></Typography></Button>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} color="error" onClick={() => handleDialogOpen("delete")} disabled={finishing || deleting || saving}>{deleting ? <CircularProgress color="error" /> :<><DeleteForeverIcon /><Typography variant="caption" ><TranslationContent contentID="delete" /></Typography></>}</Button>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} color="success" onClick={() => handleDialogOpen("finish")} disabled={finishing || deleting || saving}>{finishing ? <CircularProgress color="success" /> :<><AssignmentTurnedInIcon /><Typography variant="caption" ><TranslationContent contentID="complete" /></Typography></>}</Button>
          <Zoom in={showSaveButton}><Button onClick={() => saveInspection()} size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} disabled={finishing || deleting || saving}>{saving ? <CircularProgress color="primary"/> : <><SaveIcon /><Typography variant="caption" ><TranslationContent contentID="save" /></Typography></>}</Button></Zoom>
        </ButtonGroup>
        <Divider sx={{mb:2}} />

        <Accordion mt={5} TransitionProps={{ transition: { unmountOnExit: true } }}>
          <AccordionSummary 
            expandIcon={<ExpandMoreIcon color="primary"/>}
          >
            <Typography variant="body1"><b><TranslationContent contentID="siteAndInspectionInfo" /></b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} mt={0}>
              <Grid xs={12} mt={-3}>
                <Divider />
              </Grid>
                <Grid xs={6} sm={4} md={3} xl={2.4}>
                <Typography><b><TranslationContent contentID="inspectionName" /></b></Typography>
                <Typography variant="body2">{inspection.name[currentLang.lang]}</Typography>
              </Grid>

              <Grid xs={6} sm={4} md={3} xl={2.4}>
                <Typography><b><TranslationContent contentID="inspection" /></b></Typography>
                <Typography variant="body2">{inspection.formName[currentLang.lang]}</Typography>
              </Grid>

              <Grid xs={6} sm={4} md={3} xl={2.4}>
                <Typography ><b><TranslationContent contentID="started" /></b></Typography>
                <Typography variant="body2">{new Date(inspection.started).toLocaleString(undefined, {dateStyle: "short", timeStyle: "short"})}</Typography>
              </Grid>

              <Grid xs={6} sm={4} md={3} xl={2.4}>
                <Typography ><b><TranslationContent contentID="lastEdit" /></b></Typography>
                <Typography variant="body2">{new Date(inspection.saved).toLocaleString(undefined, {dateStyle: "short", timeStyle: "short"})}</Typography>
              </Grid>

              <Grid xs={6} sm={4} md={3} xl={2.4}>
                <Typography><b><TranslationContent contentID="address" /></b></Typography>
                <Typography variant="body2">{inspection.site.streetAddress} ({inspection.site.propertyType})</Typography>
                <Typography variant="body2">{inspection.site.postalCode}, {inspection.site.city}</Typography>
              </Grid>

              <Grid xs={6} sm={4} md={3} xl={2.4}>
                <Typography><b><TranslationContent contentID="customer" /></b></Typography>
                <Typography variant="body2">{inspection.site.customerName}</Typography>
                <Typography variant="body2">{inspection.site.customerEmail}</Typography>
                <Typography variant="body2">{inspection.site.customerPhone}</Typography>
              </Grid>

              <Grid xs={6} sm={4} md={3} xl={2.4}>
                <Typography><b><TranslationContent contentID="inspector" /></b></Typography>
                <Typography variant="body2">{inspection.inspector.name} ({inspection.organization.name})</Typography>
                <Typography variant="body2">{inspection.inspector.email}</Typography>
                <Typography variant="body2">{inspection.inspector.phone}</Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Paper sx={{padding: "7px", paddingLeft: "15px", marginTop: "15px", width: "100%",}}>
          <Grid container spacing={2} mt={0}>
            <Grid xs={12} sx={{textAlign: "center", justifyContent: "center"}}>
              <Collapse in={activeContent !== "list"}>
                <ButtonGroup size="small" sx={{width: "100%"}}>
                  <Button disabled={activeSection.index === 0} sx={{ width: "40%" }} onClick={() => handleActiveContentChange("previous")}><ArrowBackIcon />&nbsp;<Typography variant="caption" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{activeSection.index > 0 ? inspection.sections[activeSection.index-1].name[currentLang.lang] : ""}</Typography></Button>
                  <Button sx={{ width: "20%" }} onClick={() => handleActiveContentChange("list")}><TranslationContent  contentID="selectSection" /></Button>
                  <Button disabled={activeSection.index === inspection.sections.length} sx={{ width: "40%" }} onClick={() => handleActiveContentChange("next")}><Typography variant="caption" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{activeSection.index + 1 < inspection.sections.length ? inspection.sections[activeSection.index+1].name[currentLang.lang] : activeSection.index === inspection.sections.length ? "" : <TranslationContent contentID="otherObservations" />}</Typography>&nbsp;<ArrowForwardIcon /></Button>
                </ButtonGroup>
              </Collapse>
              <InspectionContent activeContent={activeContent}  handleActiveContentChange={handleActiveContentChange} activeSection={activeSection} inspection={inspection} updateInspection={updateInspection} images={images} updateImages={updateImages} />
              <Collapse in={activeContent !== "list"}>
                <ButtonGroup size="small" sx={{width: "100%"}}>
                  <Button disabled={activeSection.index === 0} sx={{width: "40%"}} onClick={() => handleActiveContentChange("previous")}><ArrowBackIcon />&nbsp;<Typography variant="caption" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{activeSection.index > 0 ? inspection.sections[activeSection.index-1].name[currentLang.lang] : ""}</Typography></Button>
                  <Button sx={{ width: "20%" }} onClick={() => handleActiveContentChange("list")}><TranslationContent  contentID="selectSection" /></Button>
                  <Button disabled={activeSection.index === inspection.sections.length} sx={{width: "40%",}} onClick={() => handleActiveContentChange("next")}><Typography variant="caption" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{activeSection.index + 1 < inspection.sections.length ? inspection.sections[activeSection.index+1].name[currentLang.lang] : activeSection.index === inspection.sections.length ? "" : <TranslationContent contentID="otherObservations" />}</Typography>&nbsp;<ArrowForwardIcon /></Button>
                </ButtonGroup>
              </Collapse>
            </Grid>
          </Grid>
        </Paper>
      </MainLayout>
    )
  }
}

export default InspectActive;


const InspectionContent = ({ activeContent, activeSection, handleActiveContentChange, inspection, updateInspection, images, updateImages }) => {
  if(activeContent === "list") {
    return <SectionList handleActiveContentChange={handleActiveContentChange} inspection={inspection} />
  }
  if(activeContent === "section") {
    return <Section activeSection={activeSection} inspectionForm={inspection} updateInspection={updateInspection} images={images} updateImages={updateImages} />
  }
  if(activeContent === "otherObservations") {
    return <Observations activeSection={activeSection} inspection={inspection} updateInspection={updateInspection} images={images} updateImages={updateImages} />
  }
}