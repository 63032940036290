import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Paper, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TranslationContent from "../../Translations/TranslationContent";


const SaveChangesBanner = ({ show }) => {

  if(show) {
    return(
      <Grid container>
        <Grid xs={12} mb={1}>
          <Paper sx={{display:"inline-flex", width: "100%", borderLeft: "3px solid #009fe3", p: 1, alignItems: "center"}} elevation={24}>
            <InfoIcon color="primary" fontSize="small" />
            &nbsp;&nbsp;
            <Typography variant="body2">
              <b>
                <TranslationContent contentID="unsavedChanges" />
              </b>
            </Typography>
            &nbsp;&nbsp;
            <Typography sx={{opacity: 0.7}} variant="body2">
              <TranslationContent contentID="unsavedChangesDesc" /> 
            </Typography>
            <Button size="small" sx={{alignSelf: "right", display: "flex", mr: 0, ml: "auto"}} onClick={() => window.location.reload()}><TranslationContent contentID="cancelChanges" /></Button>
          </Paper>
        </Grid>
      </Grid>
    )
  }
  else return null;
}

export default SaveChangesBanner;