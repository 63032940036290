import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const getUsers = async (accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/user`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const updateUser = async (data, accessToken, idToken) => {
  const request = axios.put(`${baseUrl}/user`, data, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const deleteUser = async (id, accessToken, idToken) => {
  const request = axios.delete(`${baseUrl}/user/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const userServices = { getUsers, updateUser, deleteUser };
export default userServices;