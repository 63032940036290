import React from "react";
import { TextField, Button, Zoom, CircularProgress, ButtonGroup, Typography, Divider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import SaveIcon from "@mui/icons-material/Save";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OrganizationUsers from "./OrganizationUsers";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import UserSelectionDialog from "../Dialogs/UserSelectionDialog";
import { Tokens } from "../../services/Tokens";
import organizationService from "../../services/organizations";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { SessionContext } from "../../Context/SessionProvider";
import OrganizationLogo from "./OrganizationLogo";
import { logger } from "../../services/logger";
import SaveFabButton from "../Utils/GlobalComponents/SaveFabButton";
import TranslationContent from "../../Translations/TranslationContent";


const OrganizationCreator = ({ show, backButtonClick, organizations, allUsers, fetchAllUsers, fetchingAllUsers, updateSelectedOrganizationName, updateOrganizations, validateOrganizationInput }) => {
  const session = React.useContext(SessionContext);
  const [openUserSelection, setOpenUserSelection] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showSaveButton, setShowSaveButton] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [organizationUsers, setOrganizationUsers] = React.useState([]);
  const { addAlert } = React.useContext(SnackBarContext);
  const [newOrganization, setNewOrganization] = React.useState(
    {
      name: "",
      brandId: session.activeRole.brandId,
      streetAddress: "",
      postalCode: "",
      city: "",
      email: "",
      phone: "",
      logo: "",
      type: "",
    }
  );

  React.useEffect(() => {
    setNewOrganization({
      name: "",
      brandId: session.activeRole.brandId,
      streetAddress: "",
      postalCode: "",
      city: "",
      email: "",
      phone: "",
      logo: "",
      type: "",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[show]);

  const submitNewOrganization = () => {
    setLoading(true);
    let roleData = organizationUsers.map(role => {
      delete role.id;
      role.brandId = session.activeRole.brandId;
      role.organization.name = newOrganization.name;
      role.organization.city = newOrganization.city;
      role.organization.streetAddress = newOrganization.streetAddress;
      role.organization.postalCode = newOrganization.postalCode;
      role.organization.email = newOrganization.email;
      role.organization.phone = newOrganization.phone;
      return role;
    })
    const requestBody = {
      organization: {...newOrganization},
      roles: [...roleData]
    };
    requestBody.organization.brandId = session.activeRole.brandId;
    requestBody.organization.type = "external";
    Tokens().then((tokens) => {
      organizationService
      .createOrganization(requestBody, tokens.accessToken, tokens.idToken)
      .then(response => {
        let updatedOrganizations = [...organizations, response];
        updateOrganizations(updatedOrganizations);
        setNewOrganization({
          name: "",
          streetAddress: "",
          postalCode: "",
          city: "",
          email: "",
          phone: "",
          logo: "",
        })
        updateSelectedOrganizationName("", false);
        setShowSaveButton(false);
        addAlert({message: "snackbarSubmitNewOrgSuccess", type: "success"});
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarSubmitNewOrgFailed", type: "error"});
        setLoading(false);
      })
    })
    .catch(error => {
      logger(error);
      addAlert({message: "snackbarSessionExpired", type: "error"});
      setLoading(false);
    })

  }

  const  clearNewOrganization = () => {
    setOrganizationUsers([]);
    setNewOrganization({
      name: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      email: "",
      phone: "",
      logo: "",
    });
    setShowDeleteDialog(false);
    setShowSaveButton(false);
  }
  
  const returnClick = () => {
    setNewOrganization({
      name: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      email: "",
      phone: "",
      logo: "",
    });
    backButtonClick();
  }

  const handleTextFieldChange = (event, type) => {
    let newValue = event.target.value;
    let updatedNewOrganization = {};
    if(type.includes("new")) {
      updatedNewOrganization = {...newOrganization};
      if(type === "newName") {
        updatedNewOrganization.name = newValue;
      }
      if(type === "newAddress") {
        updatedNewOrganization.streetAddress = newValue;
      }
      if(type === "newPostalCode") {
        updatedNewOrganization.postalCode = newValue;
      }
      if(type === "newCity") {
        updatedNewOrganization.city = newValue;
      }
      if(type === "newEmail") {
        updatedNewOrganization.email = newValue;
      }
      if(type === "newPhone") {
        updatedNewOrganization.phone = newValue;
      }
      setNewOrganization(updatedNewOrganization);
    }
    let validOrganizationData = validateOrganizationInput(updatedNewOrganization);
    setShowSaveButton(validOrganizationData);
  }

  const deleteUser = (oid) => {
    let updatedOrganizationUsers = organizationUsers.filter(user => user.oid !== oid);
    setOrganizationUsers(updatedOrganizationUsers);
    setShowSaveButton(true);
  }

  const updateLogo = (image) => {
      let updatedNewOrganization = {...newOrganization};
      updatedNewOrganization.logo = image;
      setNewOrganization(updatedNewOrganization);
    
  }

  const addUsers = (newUsers) => {
    let updatedOrganizationUsers = [...organizationUsers];
    
    newUsers.forEach(user => {
      let newRole = {
        brandId: "",
        id: "",
        internalRights: {
          userManagement: false,
          siteManagement: false,
          reportManagement: false,
          organizationManagement: false,
          inspectionFormManagement: false,
          inspect: false,
        },
        oid: user.id,
        organization: {
          name: newOrganization.name,
          city: newOrganization.city,
          streetAddress: newOrganization.streetAddress,
          postalCode: newOrganization.postalCode,
          email: newOrganization.email,
          phone: newOrganization.phone
        },
        organizationId: newOrganization.id,
        role: "",
        type: "",
        userName: user.name,
      }
      if(show) {
        newRole.role = "inspector"
        newRole.type = "external"
        newRole.brandId = session.activeRole.brandId
      }
      updatedOrganizationUsers = [...updatedOrganizationUsers, newRole]
    })
    setOrganizationUsers(updatedOrganizationUsers);
    setShowSaveButton(true);
  }

  const toggleInternalRights = (type, oid) => {
    let updatedOrganizationUsers = organizationUsers.map(user => {
      if(user.oid === oid) {
        switch(type) {
          case "userManagement":
            user.internalRights.userManagement = !user.internalRights.userManagement
            break;
          case "organizationManagement":
            user.internalRights.organizationManagement = !user.internalRights.organizationManagement
            break;
          case "siteManagement":
            user.internalRights.siteManagement = !user.internalRights.siteManagement
            break;
          case "inspectionFormManagement":
            user.internalRights.inspectionFormManagement = !user.internalRights.inspectionFormManagement
            break;
          case "reportManagement":
            user.internalRights.reportManagement = !user.internalRights.reportManagement
            break;
          case "inspect":
            user.internalRights.inspect = !user.internalRights.inspect
            break;
          default:
            console.log("Bad type!");
            break;
        }
      }
      return user;
    })
    setOrganizationUsers(updatedOrganizationUsers);
    setShowSaveButton(true);
  }

  const toggleExternalUserRole = (event, oid) => {
    let updatedOrganizationUsers = organizationUsers.map(user => {
      if(user.oid === oid) {
        user.role = event.target.value;
      }
      return user;
    })
    setOrganizationUsers(updatedOrganizationUsers);
    setShowSaveButton(true);
  }

  if(show) {
    return(
      <>
        <UserSelectionDialog open={openUserSelection} handleClose={() => setOpenUserSelection(false)} allUsers={allUsers} fetchAllUsers={fetchAllUsers} fetchingAllUsers={fetchingAllUsers} organizationUsers={organizationUsers} addUsers={addUsers} />
        <ConfirmDialog open={showDeleteDialog} confirmClick={() => clearNewOrganization()} title="resetTitle" text="resetDesc" handleClose={() => setShowDeleteDialog(false)} />
        <SaveFabButton show={showSaveButton && organizationUsers.some(user => user.role === "manager" || user.role === "admin")} loading={loading} onClick={() => submitNewOrganization()} disabled={loading} />
      
        <ButtonGroup variant="text" sx={{width:"100%"}}>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} onClick={() => returnClick()} disabled={loading}><KeyboardReturnIcon /><Typography variant="caption" ><TranslationContent contentID="return" /></Typography></Button>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} color="error" onClick={() => setShowDeleteDialog(true)} disabled={loading}><DeleteForeverIcon /><Typography variant="caption" ><TranslationContent contentID="reset" /></Typography></Button>
          <Zoom in={showSaveButton}><Button size="small" onClick={() => submitNewOrganization()} sx={{width: "33%", display: "grid", justifyItems: "center"}} disabled={loading}>{loading ? <CircularProgress color="primary"/> : <><SaveIcon /><Typography variant="caption" ><TranslationContent contentID="save" /></Typography></>}</Button></Zoom>
        </ButtonGroup>

        <Grid container spacing={2} mt={0} margin={0}>

          <Grid xs={12}>
            <Divider />
          </Grid>

          <Grid xs={12} mt={1} >
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent contentID="organizationInfo" /></b> <i style={{opacity: 0.7, fontSize: "smaller"}}>(*<TranslationContent contentID="required" />)</i></Typography></Divider>
          </Grid>

          <OrganizationLogo selectedOrganization={newOrganization} updateLogo={updateLogo} />
          <Grid xs={12} md={6} m="auto"><TextField label={<TranslationContent contentID="name" />} required fullWidth value={newOrganization.name} onChange={(event) => handleTextFieldChange(event, "newName")} autoComplete="off" /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="email" />} required fullWidth value={newOrganization.email} onChange={(event) => handleTextFieldChange(event, "newEmail")} autoComplete="off" /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="phoneNumber" />} required fullWidth value={newOrganization.phone} onChange={(event) => handleTextFieldChange(event, "newPhone")} autoComplete="off" /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="streetAddress" />} required fullWidth value={newOrganization.streetAddress} onChange={(event) => handleTextFieldChange(event, "newAddress")} autoComplete="off" /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="postalCode" />} required fullWidth value={newOrganization.postalCode} onChange={(event) => handleTextFieldChange(event, "newPostalCode")} autoComplete="off" /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="city" />} required fullWidth value={newOrganization.city} onChange={(event) => handleTextFieldChange(event, "newCity")} autoComplete="off" /></Grid>

          <Grid xs={12} mt={1} >
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent contentID="users" /></b></Typography></Divider>
          </Grid>

          <Grid xs={12}>
            <OrganizationUsers 
              newOrganization={true}
              toggleInternalRights={toggleInternalRights} 
              deleteUser={deleteUser} 
              organization={{}}
              organizationUsers={organizationUsers} 
              allUsers={allUsers} 
              fetchingUsers={false} 
              toggleExternalUserRole={toggleExternalUserRole} 
            />
            <Zoom in={true}>
              <Button variant="outlined" size="small" sx={{ mt: 5, minWidth: "80%" }} fullWidth onClick={() => setOpenUserSelection(true)}><PersonAddAlt1Icon />&nbsp;&nbsp;<TranslationContent contentID="addUser" /></Button>
            </Zoom>
          </Grid>
    
        </Grid>
      </>
    )
  }
}

export default OrganizationCreator;