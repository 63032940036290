import React from "react";
import MainLayout from "../../Layouts/MainLayout";
import 
  { 
    Button, 
    Zoom, 
    Fab, 
    CircularProgress, 
    Divider, 
    Paper, 
    Typography, 
    Stack, 
    Avatar, 
    TextField, 
    FormGroup, 
    FormControlLabel, 
    Checkbox, 
    List, 
    ListItem, 
    ListItemAvatar, 
    ListItemText, 
  } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useViewport } from "../../Context/ViewportProvider";
import TranslationContent from "../../Translations/TranslationContent";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import FactoryIcon from '@mui/icons-material/Factory';
import SaveIcon from "@mui/icons-material/Save";
import { SessionContext, SessionDispatchContext } from "../../Context/SessionProvider";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { Tokens } from "../../services/Tokens";
import accountService from "../../services/accounts";
import ButtonHandler from "./ButtonHandler";
import ImagePreviewDialog from "../Dialogs/ImagePreviewDialog";
import ImageResizer from 'react-image-file-resizer';
import { useNavigate } from "react-router-dom";
import { logger } from "../../services/logger";
import SaveChangesBanner from "../Home/SaveChangesBanner";



const Account = () => {
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const [showLargeImage, setShowLargeImage] = React.useState(false);
  const [showSave, setShowSave] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { width } = useViewport();
  const { addAlert } = React.useContext(SnackBarContext);

  const navigate = useNavigate();

  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  const deleteProfilePicture = () => {
    let updatedSession = {...session};
    updatedSession.profilePicture = "";
    updateSession({type: "UPDATE_SESSION", value: updatedSession});
    setShowSave(true);
  }

  const handleTextFieldChange = (event, type) => {
    let updatedSession = {...session};
    if(type === "email") {
      updatedSession.workEmail = event.target.value;
    }
    if(type === "phone") {
      updatedSession.workPhone = event.target.value;
    }
    updateSession({type: "UPDATE_SESSION", value: updatedSession});
    setShowSave(true);
  }

  const changeBoolean = (event, type) => {
    let updatedSession = {...session};
    // Implement more logic when new preferences occur
    updatedSession.preferences[0].value = event.target.checked;
    updateSession({type: "UPDATE_SESSION", value: updatedSession});
    setShowSave(true);
  }
  
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    let updatedSession = {...session};
    // Resize and compress the image before setting it in context
    ImageResizer.imageFileResizer(
      file,
      300, // New width (in pixels)
      300, // New height (in pixels)
      'JPEG', // Output format (JPEG or PNG)
      90, // Quality (1 to 100)
      0, // Rotation (0, 90, 180, or 270)
      (resizedImage) => {
        updatedSession.profilePicture = resizedImage;
        setLoading(false);
        updateSession({type: "UPDATE_SESSION", value: updatedSession});
        setShowSave(true);
      },
      'base64', // Output type (base64 or blob)
      100, // Max file size (in kilobytes)
      350, // Max width (in pixels)
      350 // Max height (in pixels)
    );
  };


  const saveAccount = () => {
    setLoading(true);
    let data = {
      workEmail: session.workEmail,
      workPhone: session.workPhone,
      profilePicture: session.profilePicture,
      preferences: [...session.preferences],
      preferredRoleId: session.preferredRoleId
    }
    Tokens().then((tokens) => {
      accountService
      .updateAccount(data, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        setLoading(false);
        addAlert({message: "snackbarAccountUpdateSuccess", type: "success"});
        setShowSave(false);
      })
      .catch(error => {
        logger(error.response.status);
        setLoading(false);
        if(error.response.status === 403) {
          addAlert({message: "snackbarSessionExpired", type: "error"});
          navigate("/")
        }
        else {
        addAlert({message: "snackbarAccountUpdateFailed", type: "error"});
        }
      })
    })
    .catch(error => {
      logger(error);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const switchPreferredRole = (role) => {
    let updatedSession = {...session};
    if(updatedSession.preferredRoleId === role.id) {
      updatedSession.preferredRoleId = "";
    }
    else {
      updatedSession.preferredRoleId = role.id;
    }
    updateSession({type: "UPDATE_SESSION", value: updatedSession});
    setShowSave(true);
  }


  return(
    <MainLayout>
      <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography color="primary" variant="body2"><b><TranslationContent contentID="account" /></b></Typography>
          </Grid>
        </Grid>
      </Paper>
      
      <ImagePreviewDialog open={showLargeImage} close={() => setShowLargeImage(false)} img={session.profilePicture} />
      <SaveChangesBanner show={showSave} />
      <Paper sx={{padding: "7px", width: "100%",}}>
        <Grid container spacing={2} mt={0}>
          <Grid xs={12}>
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent contentID="personalInfo" /></b></Typography></Divider>
          </Grid>

          <Grid xs={12} md={6} m="auto" sx={{textAlign:"center"}}>
            <Stack direction="row" spacing={2} sx={{textAlign: width > 600 ? "" : "center", marginLeft: width > 600 ? "10%" : "", justifyContent: width > 600 ? "" : "center"}}>
              {loading ? 
                <Avatar sx={{width:"100px", height:"100px"}}><CircularProgress sx={{color: "white"}} /></Avatar>
                :
                <Avatar alt="Profile picture" src={session.profilePicture} sx={{width:"100px", height:"100px", cursor: session.profilePicture !== "" ? "zoom-in" : ""}} onClick={session.profilePicture !== "" ? () => setShowLargeImage(true) : console.log()}/>
              }
              <Stack direction="column" spacing={2}>
                <input accept="image/*" type="file" id={session.id + "Account profile picture"} style={{display: "none"}} onChange={event => handleImageChange(event)} />
                <label htmlFor={session.id + "Account profile picture"}>
                  <Button variant="contained" size="small" sx={{minWidth: "150px"}} component="span"><TranslationContent contentID="uploadNewImage" /></Button>
                </label>
                <Button variant="outlined" size="small" sx={{minWidth: "150px"}} disabled={session.profilePicture === ""} onClick={() => deleteProfilePicture()}><TranslationContent contentID="removeImage" /></Button>
              </Stack>
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            <TextField id="account-work-phone" size="small" label={<TranslationContent contentID="workNumber" />} variant="outlined" value={session.workPhone} onChange={event => handleTextFieldChange(event, "phone")} fullWidth/>
            <br />
            <br />
            <TextField id="account-work-email" size="small" label={<TranslationContent contentID="workEmail" />} value={session.workEmail} onChange={event => handleTextFieldChange(event, "email")} variant="outlined" fullWidth/>
          </Grid>

          <Grid xs={12} mt="2vh">
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent contentID="preferences" /></b></Typography></Divider>
          </Grid>

          <Grid xs={12} ml="5%">
            <FormGroup>
              {session.preferences.map((preference, index) => 
              <FormControlLabel key={preference.label+index} control={<Checkbox checked={preference.value} onChange={(event) => changeBoolean(event, preference.label)} />} label={<TranslationContent contentID={preference.label} />} />
              )}
            </FormGroup>
          </Grid>

          <Grid xs={12} mt="2vh">
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent contentID="roles" /></b></Typography></Divider>
          </Grid>

          <Grid xs={12}>
            <List sx={{marginLeft: width > 600 ? "3%" : "", mt: -3}}>
              {session.roles.map(role => 
                <div key={role.id}>
                  <ListItem 
                    sx={{padding: "15px"}}
                    secondaryAction={<ButtonHandler role={role} switchPreferredRole={switchPreferredRole} />}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {role.organization.name === "Ruukki" || role.organization.name === "Piristeel Oy" ? 
                          <FactoryIcon />
                          :
                          <HomeWorkIcon />
                        }
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      sx={{mr: "30%"}} 
                      primary={
                        <Stack direction="row">
                          <Typography color="primary"><b>{role.organization.name}</b></Typography>
                        </Stack>
                      }
                      secondary={<TranslationContent contentID={role.role} />} 
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </div>
              )}
            </List>
          </Grid>          

        </Grid>
      </Paper>

      <Zoom in={showSave}>
        <Fab sx={{position: "fixed", bottom: 16, right: 16}} aria-label="Save unsaved changes" color="primary" onClick={() => saveAccount()} disabled={loading}>
          {!loading ? 
            <SaveIcon/>
            :
            <CircularProgress color="primary"/>
          }
        </Fab>
      </Zoom>

    </MainLayout>
  )
}

export default Account;