import React from "react";
import { TableCell, TableRow, Avatar } from "@mui/material";
import { useViewport } from "../../../Context/ViewportProvider";
import { BrightnessThemeStateContext } from "../../../Theme/BrightnessThemeProvider";
import TranslationContent from "../../../Translations/TranslationContent"

const UserRow = ({ user, rowClick }) => {
  const { width } = useViewport();
  const currentTheme = React.useContext(BrightnessThemeStateContext);

  return(
    <TableRow 
      sx={{
        cursor: "pointer", 
        '&:hover': {
          backgroundColor: currentTheme.theme === "light" ? "rgba(0, 0, 0, 0.04)" : "#121212",
        },
      }} 
      onClick={() => rowClick()} 
    >
      <TableCell>
        <Avatar src={user.profilePicture} />
      </TableCell>
      <TableCell component="th" scope="row">
        {user.name}
      </TableCell>
      <TableCell align="right">
        {user.azureEmail}
      </TableCell>
      {width > 600 &&
        <TableCell align="right">{user.roles.some(role => role.type === "internal") ? <TranslationContent contentID="internal" /> : <TranslationContent contentID="external" />}</TableCell>
      }
    </TableRow>
  )
}

export default UserRow;