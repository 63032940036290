import React from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Paper, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ErrorIcon from '@mui/icons-material/Error';
import ReplayIcon from '@mui/icons-material/Replay';
import TranslationContent from "../../Translations/TranslationContent";


const Error = ({ section, retryClick }) => {
  return(
    <MainLayout>  
      <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography color="primary" variant="body2"><b><TranslationContent contentID={section} /></b></Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{padding: "7px", width: "100%",}}>
        <Grid container spacing={2} mt={0}> 
          <Grid xs={12} sx={{textAlign: "center", justifyContent: "center", padding: "50px"}}>
            <ErrorIcon sx={{width: 40, height: 40}} color="error" />
            <Typography><b><TranslationContent contentID="somethingWrong" /></b></Typography>
            <div style={{height: "25px"}} />
            <Button onClick={() => retryClick()} size="small" variant="contained"><TranslationContent contentID="retry" />&nbsp;<ReplayIcon fontSize="small"/></Button>
          </Grid>
        </Grid>
      </Paper>
    </MainLayout>
  )
}

export default Error;