import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const getApplications = async (accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/application`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const createApplication = async (data, accessToken, idToken) => {
  const request = axios.post(`${baseUrl}/application`, data, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const denyApplication = async (id, accessToken, idToken) => {
  const request = axios.delete(`${baseUrl}/application/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const acceptApplication = async (id, accessToken, idToken) => {
  const request = axios.put(`${baseUrl}/application`, {id}, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const applicationsServices = { getApplications, createApplication, denyApplication, acceptApplication };
export default  applicationsServices;