import React from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Button, Card, CardActionArea, CardActions, CardContent, Divider, Paper, Typography, Stack, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import DifferenceIcon from '@mui/icons-material/Difference';
import { SessionContext, SessionDispatchContext } from "../../Context/SessionProvider";
import TranslationContent from "../../Translations/TranslationContent";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { Tokens } from "../../services/Tokens";
import sessionService from "../../services/sessions";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import NotificationBanner from "./NotificationBanner";
import { logger } from "../../services/logger";


const Home = () => {
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const [loading, setLoading] = React.useState(true);
  const { addAlert } = React.useContext(SnackBarContext);
  const navigate = useNavigate();

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    if(fetchData.current && session.roles.length > 0) {
      fetchData.current = false;
      fetchActiveSessionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchActiveSessionData = () => {
    Tokens().then((tokens) => {
      sessionService
      .getActiveSession(session.activeRole.id, tokens.accessToken, tokens.idToken)
      .then(response => {
        let updatedSession = { ...session, ...response };
        updateSession({type: "UPDATE_SESSION", value: updatedSession})
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setLoading(false);
        addAlert({message: "snackbarFailedToFetchData", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  if(loading) {
    return (
      <MainLayout>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Paper sx={{padding: "7px"}} elevation={5}>
              <Typography color="primary" variant="body2"><b><TranslationContent contentID="home" /></b></Typography>
            </Paper>
          </Grid>
          <Box sx={{margin: "auto", mt: 5, textAlign: "center"}}>
            <LoadingSpinner />
          </Box>
        </Grid>
      </MainLayout>
    )
  }
  
  if(!loading) {
    return(
      <MainLayout>

        <Grid container spacing={2}>
          <Grid xs={12}>
            <Paper sx={{padding: "7px"}} elevation={5}>
              <Typography color="primary" variant="body2"><b><TranslationContent contentID="home" /></b></Typography>
            </Paper>
          </Grid>

          <NotificationBanner />

          <Grid xs={12} md={6}>
            <Card sx={{height: "275px"}}>
              <CardActionArea sx={{padding: 1}} disabled>
                <CardContent>
                  <Typography fontSize={"14px"} sx={{opacity: 0.8}} gutterBottom>
                    <TranslationContent contentID="activeOrganization" />
                  </Typography>
                  <Typography variant="h5" component="div" >
                    {session.activeRole.organization?.name}
                  </Typography>
                  <Typography fontSize={"14px"} sx={{opacity: 0.8}}>
                    {session.activeRole.userName} (<TranslationContent contentID={session.activeRole.role} />)
                  </Typography>
                  <br />
                  <Typography variant="body2">
                    {session.activeRole.organization?.streetAddress}
                  </Typography>
                  <Typography variant="body2">
                    {session.activeRole.organization?.city} {session.activeRole.organization?.postalCode}
                  </Typography>
                  <Typography variant="body2">
                    {session.activeRole.organization?.phone}
                  </Typography>
                  <Typography variant="body2">
                    {session.activeRole.organization?.email}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <Divider />
              <CardActions>
                <Button disabled={session.roles.length < 2} onClick={() => navigate("/account")}><TranslationContent contentID="switchButton" /></Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid xs={12} md={6}>

            <Card sx={{height: "135px", marginBottom: "2.5px"}}>
              <CardActionArea sx={{padding: 2, width: "100%", height: "100%" }} onClick={() => navigate("/inspect/active")}>
                <CardContent sx={{display: "flex"}}>
                  <Box sx={{width: "80%"}}>
                    <Stack>
                      <Typography variant="body2">
                        <TranslationContent contentID="unfinishedInspections" />
                      </Typography>
                      <Typography variant="h4">
                        {session.unfinishedInspections}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box sx={{width: "20%"}}>
                    <UnarchiveIcon sx={{height: "60px", width: "60px"}} color="error" opacity={0.3} mr={0} ml="auto"/>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card sx={{height: "135px", marginTop: "2.5px"}}>
              <CardActionArea sx={{padding: 2, width: "100%", height: "100%"}} onClick={() => navigate("/inspect/new/select")}>
                <CardContent sx={{display: "flex"}}>
                  <Box sx={{width: "80%"}}>
                    <Stack>
                      <Typography variant="body2">
                        <TranslationContent contentID="newInspections" />
                      </Typography>
                      <Typography variant="h4">
                        {session.newInspections}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box sx={{width: "20%"}}>
                    <DifferenceIcon sx={{height: "60px", width: "60px"}} color="primary" opacity={0.3} mr={0} ml="auto"/>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>

          </Grid>

        </Grid>

      </MainLayout>
    )
  }
}

export default Home;