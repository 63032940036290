import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from "@mui/material";
import TranslationContent from "../../Translations/TranslationContent";


const ConfirmDialog = ({ title, text, open, handleClose, confirmClick }) => {
  return(
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">
        <TranslationContent contentID={title} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          <TranslationContent contentID={text} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}><TranslationContent contentID="cancel" /></Button>
        <Button onClick={confirmClick} autoFocus variant="contained" color="primary">
          <TranslationContent contentID="confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog;