import React from "react";
import { 
  Typography,  
  IconButton, 
  Collapse, 
  Box, 
  Radio, 
  ButtonGroup, 
  CircularProgress, 
  Avatar, 
  TextField, 
  Button, 
  FormLabel, 
  Stack, 
  Divider, 
  FormGroup, 
  FormControlLabel, 
  Checkbox, 
  List, 
  ListItem,
  ListItemAvatar, 
  ListItemText, 
  Zoom, 
  FormControl, 
  RadioGroup, 
  Tooltip 
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useViewport } from "../../../Context/ViewportProvider";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import TranslationContent from "../../../Translations/TranslationContent";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import FactoryIcon from "@mui/icons-material/Factory";
import SaveIcon from '@mui/icons-material/Save';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { SessionContext, SessionDispatchContext } from "../../../Context/SessionProvider";
import { Tokens } from "../../../services/Tokens";
import userService from "../../../services/users";
import OrganizationSelector from "./OrganizationSelector";
import { logger } from "../../../services/logger";
import SaveFabButton from "../../Utils/GlobalComponents/SaveFabButton";
import ImageResizer from 'react-image-file-resizer';



const UserSelected = ({ selectedUser, show, organizations, returnToUserList, updateUserList, deleteUserFromList, fetchOrganizations, updateSelectedUser, updateUserSelected }) => {
  const { width } = useViewport();
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const [loading, setLoading] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [showSaveButton, setShowSaveButton] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showOrganizationSelector, setShowOrganizationSelector] = React.useState(false);
  const { addAlert } = React.useContext(SnackBarContext);

  const changeInternalUserRights = (userRight, roleIndex) => {
    let updatedSelectedUser = {...selectedUser};
    if(userRight === "userManagement") {
      updatedSelectedUser.roles[roleIndex].internalRights.userManagement = !updatedSelectedUser.roles[roleIndex].internalRights.userManagement;
    }
    if(userRight === "organizationManagement") {
      updatedSelectedUser.roles[roleIndex].internalRights.organizationManagement = !updatedSelectedUser.roles[roleIndex].internalRights.organizationManagement;
    }
    if(userRight === "siteManagement") {
      updatedSelectedUser.roles[roleIndex].internalRights.siteManagement = !updatedSelectedUser.roles[roleIndex].internalRights.siteManagement;
    }
    if(userRight === "inspectionFormManagement") {
      updatedSelectedUser.roles[roleIndex].internalRights.inspectionFormManagement = !updatedSelectedUser.roles[roleIndex].internalRights.inspectionFormManagement;
    }
    if(userRight === "reportManagement") {
      updatedSelectedUser.roles[roleIndex].internalRights.reportManagement = !updatedSelectedUser.roles[roleIndex].internalRights.reportManagement;
    }
    if(userRight === "inspect") {
      updatedSelectedUser.roles[roleIndex].internalRights.inspect = !updatedSelectedUser.roles[roleIndex].internalRights.inspect;
    }
    updateSelectedUser(updatedSelectedUser);
    setShowSaveButton(true);
  }

  const deleteUser = () => {
    setShowDeleteDialog(false);
    setDeleting(true);
    Tokens().then((tokens) => {
      userService
      .deleteUser(selectedUser.id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedSession = {...session};
        updatedSession.userCount = updatedSession.userCount - 1;
        updateSession({type: "UPDATE_SESSION", value: updatedSession});
        deleteUserFromList(selectedUser.id);
        updateUserSelected();
        updateSelectedUser({});
        setDeleting(false);
        addAlert({message: "snackbarUserDeleteSuccessful", type: "success"});
      })
      .catch(error => {
        logger(error);
        setDeleting(false);
        addAlert({message: "snackbarUserDeleteFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setDeleting(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const saveUser = () => {
    setLoading(true);
    let userData = {...selectedUser};

    Tokens().then((tokens) => {
      userService
      .updateUser(userData, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response)
        let updatedSelectedUser = {...selectedUser};
        updatedSelectedUser.roles = [...response]
        updateUserList(updatedSelectedUser);
        setLoading(false);
        setShowSaveButton(false);
        addAlert({message: "snackbarUserUpdateSuccessful", type: "success"});
      })
      .catch(error => {
        logger(error)
        setLoading(false);
        addAlert({message: "snackbarUserUpdateFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const changeUserPreferences = (preferenceIndex) => {
    let updatedSelectedUser = {...selectedUser};
    updatedSelectedUser.preferences[preferenceIndex].value = !updatedSelectedUser.preferences[preferenceIndex].value;
    updateSelectedUser(updatedSelectedUser);
    setShowSaveButton(true);
  }

  const addRole = (role) => {
    let updatedSelectedUser = {...selectedUser};
    updatedSelectedUser.roles = [...updatedSelectedUser.roles, role];
    updateSelectedUser(updatedSelectedUser);
    setShowSaveButton(true);
  }

  const deleteRole = (roleId) => {
    let updatedSelectedUser = {...selectedUser};
    updatedSelectedUser.roles = updatedSelectedUser.roles.filter(role => role.id !== roleId);
    updateSelectedUser(updatedSelectedUser);
    setShowSaveButton(true);
  }

  const showRoleSelector = () => {
    setShowOrganizationSelector(!showOrganizationSelector)
  }

  const changeRoleType = (event, roleIndex) => {
    let updatedSelectedUser = {...selectedUser};
    updatedSelectedUser.roles[roleIndex].role = event.target.value;
    updateSelectedUser(updatedSelectedUser);
    setShowSaveButton(true);
  }

  const handleNumberEmailChanges = (event, type) => {
    let updatedSelectedUser = {...selectedUser};
    if(type === "email") {
      updatedSelectedUser.workEmail = event.target.value;
    }
    if(type === "phone") {
      updatedSelectedUser.workPhone = event.target.value;
    }
    updateSelectedUser(updatedSelectedUser);
    setShowSaveButton(true);
  }

  const deleteProfilePicture = () => {
    let updatedSelectedUser = {...selectedUser};
    updatedSelectedUser.profilePicture = "";
    updateSelectedUser(updatedSelectedUser);
    setShowSaveButton(true);
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    let updatedSelectedUser = {...selectedUser};
    // Resize and compress the image before setting it in context
    ImageResizer.imageFileResizer(
      file,
      300, // New width (in pixels)
      300, // New height (in pixels)
      'JPEG', // Output format (JPEG or PNG)
      90, // Quality (1 to 100)
      0, // Rotation (0, 90, 180, or 270)
      (resizedImage) => {
        updatedSelectedUser.profilePicture = resizedImage;
        setLoading(false);
        updateSelectedUser(updatedSelectedUser);
        setShowSaveButton(true);
      },
      'base64', // Output type (base64 or blob)
      100, // Max file size (in kilobytes)
      350, // Max width (in pixels)
      350 // Max height (in pixels)
    );
  }

  const returnClick = () => {
    setShowOrganizationSelector(false);
    setShowSaveButton(false);
    returnToUserList();
  }

  if(show) {
    return(
      <>
        <ConfirmDialog open={showDeleteDialog} title="userDeleteTitle" text="userDeleteDesc" handleClose={() => setShowDeleteDialog(false)} confirmClick={() => deleteUser(selectedUser.id)} />
        <SaveFabButton show={showSaveButton} disabled={loading || deleting} loading={loading} onClick={saveUser}/>

        <Grid container spacing={2} mt={0} margin={0}>
          <ButtonGroup variant="text" sx={{width:"100%"}}>
            <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} onClick={() => returnClick()} disabled={loading || deleting}><KeyboardReturnIcon /><Typography variant="caption" ><TranslationContent contentID="return" /></Typography></Button>
            <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} color="error" onClick={() => setShowDeleteDialog(true)} disabled={loading || deleting}>{deleting ? <CircularProgress color="error"/> : <PersonRemoveIcon />}<Typography variant="caption" ><TranslationContent contentID="deleteUser" /></Typography></Button>
            <Zoom in={showSaveButton}><Button onClick={() => saveUser()} size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} disabled={loading}>{loading ? <CircularProgress color="primary"/> : <><SaveIcon /><Typography variant="caption" ><TranslationContent contentID="save" /></Typography></>}</Button></Zoom>
          </ButtonGroup>

          <Grid xs={12} mt={1} >
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent contentID="personalInfo" /></b></Typography></Divider>
          </Grid>

          <Grid xs={12} md={6}>
            <TextField id="account-work-phone" size="small" label={<TranslationContent contentID="name" />} variant="outlined" fullWidth value={selectedUser.name} disabled inputProps={{readOnly: true}}/>
          </Grid>

          <Grid xs={12} md={6}>
            <TextField id="account-work-phone" size="small" label={<TranslationContent contentID="userName" />} variant="outlined" fullWidth value={selectedUser.azureEmail} disabled inputProps={{readOnly: true}}/>
          </Grid>

          <Grid xs={12} md={6} m="auto" sx={{textAlign:"center"}}>
            <Stack direction="row" spacing={2} sx={{textAlign: width > 600 ? "" : "center", marginLeft: width > 600 ? "10%" : "", justifyContent: width > 600 ? "" : "center", alignItems: "center"}}>
              <Avatar alt="Profile picture" src={selectedUser.profilePicture} sx={{width:"100px", height:"100px"}}/>
              <Stack direction="column" sx={{alignSelf: "normal"}} spacing={2}>
                <input accept="image/*" type="file" id={selectedUser.id + "Account profile picture"} style={{display: "none"}} onChange={event => handleImageChange(event)} />
                <label htmlFor={selectedUser.id + "Account profile picture"}>
                  <Button variant="contained" size="small" disabled={showOrganizationSelector || deleting} component="span"><TranslationContent contentID="uploadNewImage" /></Button>
                </label>
                <Button variant="outlined" size="small" onClick={() => deleteProfilePicture()} disabled={selectedUser.profilePicture === "" || showOrganizationSelector  || deleting}><TranslationContent contentID="delete" /></Button>
              </Stack>
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            <TextField id="account-work-phone" size="small" label={<TranslationContent contentID="workNumber" />} disabled={showOrganizationSelector || deleting} value={selectedUser.workPhone} onChange={event => handleNumberEmailChanges(event, "phone")} variant="outlined" fullWidth/>
            <br />
            <br />
            <TextField id="account-work-email" size="small" label={<TranslationContent contentID="workEmail" />} disabled={showOrganizationSelector || deleting} value={selectedUser.workEmail} onChange={event => handleNumberEmailChanges(event, "email")} variant="outlined" fullWidth/>
          </Grid>

          <Grid xs={12} mt={4} mb={-2}>
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent contentID="preferences" /></b></Typography></Divider>
          </Grid>

          <Grid xs={12} ml="5%">
            <FormGroup>
              {selectedUser.preferences.map((preference, index) => 
                <FormControlLabel key={index+preference.label} control={<Checkbox disabled={showOrganizationSelector || deleting} checked={preference.value} onChange={() => changeUserPreferences(index)}/>} label={<TranslationContent contentID={preference.label} />} />
              )}
            </FormGroup>
          </Grid>

          <Grid xs={12} mt={4} mb={-3}>
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent contentID="roles" /></b></Typography></Divider>
          </Grid>
          <Grid xs={12}>
            <List sx={{marginLeft: width > 600 ? "3%" : "", }}>
              {selectedUser.roles.map((role, index) => 
                role.type === "external" ?
                  <div key={role.organizationId}>
                    <ListItem 
                      secondaryAction={
                        <Tooltip title={<TranslationContent contentID="deleteRole" />} arrow>
                          <span>
                            <IconButton disabled={showOrganizationSelector  || deleting} onClick={() => deleteRole(role.id)} color="error">
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <HomeWorkIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText 
                        primary={
                          <Stack direction="row">
                            <Typography color="primary"><b>{role.organization.name}</b></Typography>
                          </Stack>
                        }
                        disableTypography
                        secondary={
                          <FormControl sx={{minWidth: "200px"}} size="small">
                            <RadioGroup row value={role.role} onChange={(event) => changeRoleType(event, index)} >
                              <FormControlLabel value="inspector" control={<Radio />} label={<TranslationContent contentID="inspector" />} disabled={showOrganizationSelector  || deleting}/>
                              <FormControlLabel value="manager" control={<Radio />} label={<TranslationContent contentID="manager" />} disabled={showOrganizationSelector || deleting} />
                            </RadioGroup>
                          </FormControl>
                        } 
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </div>
                : 
                  <div key={role.organizationId}>
                  <ListItem 
                    secondaryAction={
                      <Tooltip title={<TranslationContent contentID="deleteRole" />} arrow>
                        <span>
                          <IconButton disabled={showOrganizationSelector} onClick={() => deleteRole(role.id)} color="error" ><DeleteIcon /></IconButton>
                        </span>
                      </Tooltip>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <FactoryIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary={
                        <Stack direction="row">
                          <Typography color="primary"><b>{role.organization.name}</b></Typography>
                        </Stack>
                      }
                      disableTypography
                      secondary={<Stack direction="row" sx={{alignItems: "center", margin: "auto"}}><AdminPanelSettingsIcon fontSize="small" color="primary" />&nbsp;&nbsp;<Typography sx={{opacity: 0.5}}><TranslationContent contentID="admin" /></Typography></Stack>}
                    />
                  </ListItem>
                  <Collapse in={role.type === "internal"}> 
                    <Box ml={"72px"}>
                      <FormGroup >
                        <FormLabel component={"legend"} sx={{textAlign:"left", mb: 1, mt: 1}}><TranslationContent contentID="accessRights" /></FormLabel>
                        <Grid container>
                          <Grid xs={12} sm={6} md={4} xl={3} sx={{textAlign:"left"}}>
                            <FormControlLabel label={<TranslationContent contentID="userManagementCheck" />} control={<Checkbox disabled={showOrganizationSelector || deleting} checked={role.internalRights.userManagement} onChange={() => changeInternalUserRights("userManagement", index) }/>} />
                          </Grid>
                          <Grid xs={12} sm={6} md={4} xl={3} sx={{textAlign:"left"}}>
                            <FormControlLabel label={<TranslationContent contentID="organizationManagementCheck" />} control={<Checkbox disabled={showOrganizationSelector || deleting} checked={role.internalRights.organizationManagement} onChange={() => changeInternalUserRights("organizationManagement", index) }/>} />
                          </Grid>
                          <Grid xs={12} sm={6} md={4} xl={3} sx={{textAlign:"left"}}>
                            <FormControlLabel label={<TranslationContent contentID="siteManagementCheck" />} control={<Checkbox disabled={showOrganizationSelector || deleting} checked={role.internalRights.siteManagement} onChange={() => changeInternalUserRights("siteManagement", index) }/>} />
                          </Grid>
                          <Grid xs={12} sm={6} md={4} xl={3} sx={{textAlign:"left"}}>
                            <FormControlLabel label={<TranslationContent contentID="inspectionFormManagementCheck" />} control={<Checkbox disabled={showOrganizationSelector || deleting} checked={role.internalRights.inspectionFormManagement} onChange={() => changeInternalUserRights("inspectionFormManagement", index) }/>} />
                          </Grid>
                          <Grid xs={12} sm={6} md={4} xl={3} sx={{textAlign:"left"}}>
                            <FormControlLabel label={<TranslationContent contentID="reportManagementCheck" />} control={<Checkbox disabled={showOrganizationSelector || deleting} checked={role.internalRights.reportManagement} onChange={() => changeInternalUserRights("reportManagement", index) }/>} />
                          </Grid>
                          <Grid xs={12} sm={6} md={4} xl={3} sx={{textAlign:"left"}}>
                            <FormControlLabel label={<TranslationContent contentID="inspectCheck" />} control={<Checkbox disabled={showOrganizationSelector || deleting} checked={role.internalRights.inspect} onChange={() => changeInternalUserRights("inspect", index) }/>} />
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </Box>
                  </Collapse>
                  <Divider variant="inset" component="li" />
                </div>
              )}
              {showOrganizationSelector && 
                <OrganizationSelector selectedUser={selectedUser} showRoleSelector={() => showRoleSelector()} deleting={deleting} organizations={organizations} fetchOrganizations={fetchOrganizations} addRole={addRole} />
              }
              <Zoom in={!showOrganizationSelector}>
                <Button onClick={() => showRoleSelector()} disabled={deleting} sx={{ mt: 2 }} fullWidth variant="outlined"><AddHomeWorkIcon />&nbsp;&nbsp;<TranslationContent contentID="addRole" /></Button>
              </Zoom>
            </List>
          </Grid>
        </Grid>
      </>
    )
  }
}


export default UserSelected;