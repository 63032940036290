import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Zoom, Button, IconButton, Stack, Divider, TextField, Checkbox, FormControlLabel, Chip, Box, MenuItem, Select, InputLabel, FormHelperText, Collapse, Tooltip, FormControl, FormLabel, RadioGroup, CircularProgress } from "@mui/material";
import TranslationContent from "../../../Translations/TranslationContent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { LanguageContext } from "../../../Translations/LanguageProvider";
import { useViewport } from "../../../Context/ViewportProvider";
import ImagePreviewDialog from "../../Dialogs/ImagePreviewDialog";
import WriteTextField from "./WriteTextField";
import FormImage from "../../Images/FormImage";


const SectionInspection = ({ 
  inspection, 
  section, 
  index, 
  inspections, 
  permissionType, 
  decreaseInspectionIndex, 
  increaseInspectionIndex, 
  deleteInspection, 
  deleting,
  handleInspectionImageChange, 
  handleInspectionTextFieldChange, 
  handleInspectionToggle,
  imageTransactionActive,
  images,
  updateImages,
  form,
  updateSectionChanges
}) => {
  const currentLang = React.useContext(LanguageContext);
  const { width } = useViewport();
  const [showLargeImage, setShowLargeImage] = React.useState(false);
  const [selectedInspectionImage, setSelectedInspectionImage] = React.useState("");

  const openLargeImage = (image) => {
    setSelectedInspectionImage(image);
    setShowLargeImage(true);
  }

  return(
    <div key={inspection.id}>
      <ImagePreviewDialog open={showLargeImage} close={() => setShowLargeImage(false)} img={selectedInspectionImage} />
        <Stack display={"-webkit-box"} mt={2}>
          <Box  sx={{textAlign: "center",width: "15%", minHeight: "100%"}}>
            <IconButton onClick={() => decreaseInspectionIndex(index)} color="primary" disabled={inspections.findIndex(i => i.id === inspection.id) === 0 || permissionType === "read"}><KeyboardArrowUpIcon /></IconButton>
            <Divider orientation="vertical" sx={{mb: 0, mt: 0, minHeight: "99%", height: "99%"}}><Chip label={inspections.findIndex(i => i.id === inspection.id) + 1} /></Divider>
            <IconButton onClick={() => increaseInspectionIndex(index)} disabled={section.inspections.length - (inspections.findIndex(i => i.id === inspection.id)) === 1 || permissionType === "read"} color="primary"><KeyboardArrowDownIcon /></IconButton>
          </Box>
          <Box sx={{width: "75%"}}>
          <Grid container spacing={3} /*ml={"5%"}*/>
            <Grid xs={12} sx={{ textAlign: "right"}}>{deleting === inspection.id ? <CircularProgress color="error" sx={{maxWidth: 25, maxHeight: 25}} /> : <Button color="error" disabled={permissionType === "read"} onClick={() => deleteInspection(inspection.id)}><TranslationContent contentID="deleteInspection" /></Button>}</Grid>
            <Grid xs={12} md={6}>
              <WriteTextField label="nameEN" maxStringLength={150} multiline={true} readOnly={permissionType === "read"} value={inspection.name.en} onChange={event => handleInspectionTextFieldChange(event, inspection.id, "nameEN")} />
            </Grid>
            <Grid xs={12} md={6}>
              <WriteTextField label="nameFI" maxStringLength={150} multiline={true} readOnly={permissionType === "read"} value={inspection.name.fi} onChange={event => handleInspectionTextFieldChange(event, inspection.id, "nameFI")} />
            </Grid>
            <Grid xs={12} md={6}>
              <WriteTextField label="descEN" maxStringLength={500} multiline={true} readOnly={permissionType === "read"} minRows={2} value={inspection.desc.en} onChange={event => handleInspectionTextFieldChange(event, inspection.id, "descEN")} />
            </Grid>
            <Grid xs={12} md={6}>
              <WriteTextField label="descFI" maxStringLength={500} multiline={true} readOnly={permissionType === "read"} minRows={2} value={inspection.desc.fi} onChange={event => handleInspectionTextFieldChange(event, inspection.id, "descFI")} />
            </Grid>
            <Grid xs={12} md={6}>
            <Collapse in={section.categories.length > 0}>
              <FormControl fullWidth size="small">
                <InputLabel id={`category-select-label-${inspection.id}`}><TranslationContent contentID="category" /></InputLabel>
                <Select
                  labelId={`category-select-label-${inspection.id}`}
                  id={`category-select-${inspection.id}`}
                  value={inspection.linkedCategoryId}
                  label={<TranslationContent contentID="category" />}
                  readOnly={permissionType === "read"}
                  onChange={event => handleInspectionTextFieldChange(event, inspection.id, "linkedCategory")}
                >
                  {section.categories.map(category => 
                    <MenuItem key={category.id} value={category.id}>{category.name[currentLang.lang]}</MenuItem>
                  )}
                  <MenuItem value="" key="emptyCategory" sx={{opacity: 0.5, justifyContent: "right"}}><TranslationContent contentID="empty" /></MenuItem>
                </Select>
                <FormHelperText><TranslationContent contentID="ifEmptyAskAlwaysDesc" /></FormHelperText>
              </FormControl>
            </Collapse>
          </Grid>
          <Grid xs={12} md={6}>
            <Collapse in={section.categories.length > 0}>
              <FormControl fullWidth size="small">
                <InputLabel id={`option-select-label-${inspection.id}`}><TranslationContent contentID="option" /></InputLabel>
                  <Select
                    disabled={inspection.linkedCategoryId === ""}
                    readOnly={permissionType === "read"}
                    labelId={`option-select-label-${inspection.id}`}
                    id={`option-select-${inspection.id}`}
                    value={inspection.linkedOptionId}
                    label={<TranslationContent contentID="option" />}
                    onChange={event => handleInspectionTextFieldChange(event, inspection.id, "linkedOption")}
                  >
                    {inspection.linkedCategoryId !== "" && (section.categories.find(category => category.id === inspection.linkedCategoryId).options.map(option => 
                      <MenuItem key={option.id} value={option.id}>{option.name[currentLang.lang]}</MenuItem>
                    ))}
                    <MenuItem value="" key="emptyOption" sx={{opacity: 0.5, justifyContent: "right"}}><TranslationContent contentID="empty" /></MenuItem>
                  </Select>
                  {inspection.linkedCategoryId === "" && 
                    <FormHelperText><TranslationContent contentID="selectCategoryToSeeOptions" /></FormHelperText>
                  }
                </FormControl>
              </Collapse>
            </Grid>
            <Grid xs={12} md={6} textAlign={"left"}>
              <FormControlLabel 
                control={<Checkbox disabled={permissionType === "read"} checked={inspection.forceComplete} onChange={() => handleInspectionToggle(inspection.id, "forceComplete")} />} 
                label={<Stack direction="row" spacing={1}><Typography><TranslationContent contentID="forcedInspection" /></Typography><Tooltip arrow title={<TranslationContent contentID="forcedInspectionDesc" />}><InfoOutlinedIcon color="primary" onClick={event => event.preventDefault()}/></Tooltip></Stack>} 
              />
            </Grid>
            <Grid xs={12} md={6} textAlign={"left"}>
              <FormControlLabel control={<Checkbox  disabled={permissionType === "read"} checked={inspection.enableImage} onChange={() => handleInspectionToggle(inspection.id, "enableImage")} />} 
                label={<Stack direction="row" spacing={1}><Typography><TranslationContent contentID="allowImages" /></Typography><Tooltip arrow title={<TranslationContent contentID="allowInspectorToTakeImages" />}><InfoOutlinedIcon color="primary" onClick={event => event.preventDefault()}/></Tooltip></Stack>} 
              />
            </Grid>
            {imageTransactionActive === inspection.id && 
              <Grid xs={12} md={6} textAlign={"left"} mt={2} sx={{margin: "auto", textAlign: "center"}}>
                <CircularProgress />
              </Grid>
            }
            {imageTransactionActive !== inspection.id && 
            <Grid xs={12} md={6} textAlign={"left"} mt={2} m="auto">
              <input accept="image/*" type="file" id={inspection.id + " - Inspection guide image"} style={{display: "none"}} onChange={event => handleInspectionImageChange(event, inspection.id)} />
                  <label htmlFor={inspection.id + " - Inspection guide image"}>
                  <Button variant="outlined" disabled={permissionType === "read"} component="span"><AddPhotoAlternateIcon />&nbsp;<TranslationContent contentID="guideImage" /></Button>
              </label>
            </Grid>
            }
            <Grid xs={12} md={6} textAlign={"left"} mt={2} sx={{margin: "auto"}}>
              <FormImage 
                form={form} 
                imageId={inspection.guideImage} 
                images={images} 
                updateImages={updateImages} 
                openLargeImage={openLargeImage} 
                section={section} 
                inspectionId={inspection.id} 
                updateSectionChanges={updateSectionChanges}
              />
            </Grid>
            <Grid xs={12} md={6} textAlign={"left"} mt={2}>
              <FormControl>
                <FormLabel><TranslationContent contentID="answerType" /></FormLabel>
                <RadioGroup row >
                  <FormControlLabel control={<Checkbox  disabled={permissionType === "read"} checked={inspection.isBoolean} onChange={() => handleInspectionToggle(inspection.id, "answerType")} />} 
                    label={<TranslationContent contentID="yesno" />}
                  />
                  <FormControlLabel label={<TranslationContent contentID="numerical" />} control={<Checkbox disabled={permissionType === "read"} checked={inspection.isNumber} onChange={() => handleInspectionToggle(inspection.id, "answerType")} />} />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Zoom in={inspection.isNumber}>
              <Grid xs={12} md={6} m="auto">
                <Stack direction={width > 1400 ? "row" : "column"} spacing={2}>
                  <Stack direction={"row"}>
                    <TextField 
                      label={<TranslationContent contentID="smallestAcceptableValue" />}
                      autoComplete="off"
                      size="small" 
                      fullWidth        
                      inputProps={{
                        pattern: '[0-9]*', // Use the pattern attribute to enforce numeric input
                        inputMode: 'numeric', // Use inputMode to provide a numeric keyboard on mobile devices
                        readOnly: permissionType === "read",
                      }}            
                      value={inspection.minNumberValue} 
                      onChange={event => handleInspectionTextFieldChange(event, inspection.id, "minNumberValue")}
                    />
                  </Stack>
                  <Stack direction={"row"}>
                    <TextField 
                      label={<TranslationContent contentID="greatestAcceptableValue" />}
                      autoComplete="off"
                      size="small" 
                      fullWidth         
                      inputProps={{
                        pattern: '[0-9]*', // Use the pattern attribute to enforce numeric input
                        inputMode: 'numeric', // Use inputMode to provide a numeric keyboard on mobile devices
                        readOnly: permissionType === "read"
                      }}           
                      value={inspection.maxNumberValue} 
                      onChange={event => handleInspectionTextFieldChange(event, inspection.id, "maxNumberValue")}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Zoom>
          </Grid>
        </Box>
      </Stack>
      <Divider sx={{mt: 10}} />
    </div>
  )
}

export default SectionInspection;