import React from "react";
import MainLayout from "../../../Layouts/MainLayout";
import { Paper, Typography, Box, Card, CardActionArea, CardContent, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TranslationContent from "../../../Translations/TranslationContent";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../../Context/SessionProvider";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PendingActionsIcon from '@mui/icons-material/PendingActions';


const InspectNew = () => {
  const session = React.useContext(SessionContext);
  const navigate = useNavigate();

  const navigateClick = (destination) => {
    let path = "/inspect/new/" + destination;
    navigate(path);
  };

  return (
    <MainLayout>
      <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography color="primary" variant="body2"><b><TranslationContent contentID="newInspection" /></b></Typography>
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          <Card sx={{height: "20vh"}}>
            <CardActionArea sx={{padding: 2, width: "100%", height: "100%" }} onClick={() => navigateClick("create")}>
              <CardContent sx={{display: "flex"}}>
                <Box sx={{width: "80%"}}>
                  <Stack>
                    <Typography variant="h4">
                      <TranslationContent contentID="createNew" />
                    </Typography>
                    <Typography variant="body2" sx={{opacity: 0.6}}>
                      <TranslationContent contentID="chooseTargetAndInspection" />
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={{width: "20%"}}>
                  <NoteAddIcon sx={{height: "60px", width: "60px"}} color="success" opacity={0.3} mr={0} ml="auto"/>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid xs={12} md={6}>
          <Card sx={{height: "20vh"}}>
            <CardActionArea sx={{padding: 2, width: "100%", height: "100%"}} onClick={() => navigateClick("select")}>
              <CardContent sx={{display: "flex"}}>
                <Box sx={{width: "80%"}}>
                  <Stack>
                    <Typography variant="h4" >
                      <TranslationContent contentID="chooseNew" />
                    </Typography>
                    <Typography variant="body2" sx={{opacity: 0.6}}>
                      <TranslationContent contentID="newInspections" /> <b>{session.newInspections}</b>
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={{width: "20%"}}>
                  <PendingActionsIcon sx={{height: "60px", width: "60px"}} color="primary" opacity={0.3} mr={0} ml="auto"/>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  )
}

export default InspectNew;