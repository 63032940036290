import React from "react";
import { Box, Typography, Paper, List, ListItemButton, ListItemAvatar, Avatar, ListItemText } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import TranslationContent from "../../Translations/TranslationContent";


const AccountSelector = ({ accounts, activeAccountSelected, updateActiveAccount, error }) => {
  if(accounts.length > 1 && !activeAccountSelected && !error) {
    return(
      <Box sx={{textAlign: "center", justifyContent: "center", width: "100%"}}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div" textAlign={"center"}>
          <TranslationContent contentID="selectAccount" />
        </Typography>
        <Paper sx={{maxWidth: "400px", textAlign: "center", margin: "auto"}}>
          <List dense={true} sx={{textAlign: "center"}}>
            {accounts.map((account) => 
              <ListItemButton key={account.idTokenClaims.email} onClick={() => updateActiveAccount(account)}>
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={account.name}
                  secondary={account.idTokenClaims.email}
                />
              </ListItemButton>
            )}
          </List>
        </Paper>
      </Box>
    )
  }
}

export default AccountSelector;