import React from "react";
import UserSelected from "./SelectedUser";
import UserTableContent from "./UserTableContent";


const UserTable = ({ users, fetchOrganizations, organizations, updateUserList, deleteUserFromList }) => {
  const [userSelected, setUserSelected] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState({});

  const rowClick = (user) => {
    let updatedSelectedUser = {...selectedUser};
    updatedSelectedUser = user;
    setSelectedUser(updatedSelectedUser);
    setUserSelected(true);
  }

  const updateSelectedUser = (updatedSelectedUser) => {
    setSelectedUser(updatedSelectedUser);
  }

  const updateUserSelected = () => {
    setUserSelected(!userSelected);
  }
  
  return(
    <>
      <UserTableContent rowClick={rowClick} users={users} show={!userSelected}/>
      <UserSelected 
        selectedUser={selectedUser} 
        show={userSelected} 
        organizations={organizations} 
        returnToUserList={() => setUserSelected(false)} 
        updateUserList={updateUserList} 
        deleteUserFromList={deleteUserFromList} 
        fetchOrganizations={fetchOrganizations} 
        updateSelectedUser={updateSelectedUser} 
        updateUserSelected={updateUserSelected}
      />
    </>
  )
}

export default UserTable;