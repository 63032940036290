import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const getSites = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/site/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const createSite = async (data, accessToken, idToken) => {
  const request = axios.post(`${baseUrl}/site`, data, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const deleteSite = async (id, accessToken, idToken) => {
  const request = axios.delete(`${baseUrl}/site/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const updateSite = async (data, accessToken, idToken) => {
  const request = axios.put(`${baseUrl}/site/${data.id}`, data, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const siteServices = { getSites, createSite, deleteSite, updateSite };
export default siteServices;