import React from "react";
import { List, ListItem, ListItemAvatar, ListItemText, IconButton, Typography, Avatar, Stack, RadioGroup, Radio, FormControlLabel, Divider, FormControl, Collapse, Box, FormGroup, FormLabel, Checkbox, Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useViewport } from "../../Context/ViewportProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingSpinner from "../Loading/LoadingSpinner";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import TranslationContent from "../../Translations/TranslationContent";
import InfoIcon from '@mui/icons-material/Info';


const OrganizationUsers = ({ newOrganization, toggleExternalUserRole, organizationUsers, fetchingUsers, deleteUser, toggleInternalRights }) => {
  const { width } = useViewport();

  if(fetchingUsers && !newOrganization) {
    return (
      <>
        <br />
        <LoadingSpinner />
        <br />
      </>
    )
  }

  if(organizationUsers?.length === 0) {
    return(
      <Box sx={{justifyContent: "center"}}>
        {!organizationUsers.some(user => user.role === "manager" || user.role === "admin") && <Paper elevation={24} sx={{justifyContent: "center", maxWidth: "900px", display: "inline-flex", padding: "10px"}}><InfoIcon color="primary" /><Typography ml={1}><TranslationContent contentID="toSaveHaveAtLeastOneManagerDesc" /></Typography></Paper>}
        <Typography sx={{opacity: 0.7, mt: 2}}><TranslationContent contentID="orgHasNoUsers" /></Typography>
      </Box>
    )
  }

  if(organizationUsers?.length > 0) {
    return(
      <>
        {!organizationUsers.some(user => user.role === "manager" || user.role === "admin") && <Paper elevation={24} sx={{justifyContent: "center", maxWidth: "900px", display: "inline-flex", padding: "10px"}}><InfoIcon color="primary" /><Typography ml={1}><TranslationContent contentID="toSaveHaveAtLeastOneManagerDesc" /></Typography></Paper>}
        <List sx={{marginLeft: width > 600 ? "3%" : "", mt: -3, mb: -3 }}>
          {organizationUsers.map(role => 
            <div key={role.oid}>
              <ListItem 
                secondaryAction={
                  <IconButton onClick={() => deleteUser(role.oid)} color="error"><DeleteIcon /></IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar />
                </ListItemAvatar>
                <ListItemText 
                  primary={
                    <Stack direction="row"><Typography color="primary"><b>{role.userName}</b></Typography></Stack>
                  }
                  disableTypography
                  secondary={
                    <>
                      {role.type === "external" ? 
                        <FormControl sx={{minWidth: "200px", mt: 0}} size="small">
                          <RadioGroup row value={role.role} onChange={event => toggleExternalUserRole(event, role.oid)} >
                            <FormControlLabel value="inspector" control={<Radio />} label={<TranslationContent contentID="inspector" />} />
                            <FormControlLabel value="manager" control={<Radio />} label={<TranslationContent contentID="organizationAdmin" />} />
                          </RadioGroup>
                        </FormControl>
                      :
                        <Stack direction="row" spacing={1}><AdminPanelSettingsIcon fontSize="small" color="primary"/><Typography variant="caption" sx={{opacity: 0.5}}><TranslationContent contentID={role.role} /></Typography></Stack>
                      }
                    </>
                  } 
                />
              </ListItem>
              <Collapse in={role.type === "internal"}>
                <Box ml={"72px"} mb="15px">
                  <FormGroup >
                    <FormLabel component={"legend"} sx={{textAlign:"left", mb: 1, mt: 1}}><TranslationContent contentID="accessRights" /></FormLabel>
                    <Grid container>
                      <Grid xs={12} sm={6} md={4} xl={3} sx={{textAlign:"left"}}>
                        <FormControlLabel label={<TranslationContent contentID="userManagementCheck" />} control={<Checkbox size="small" checked={role.internalRights?.userManagement} onChange={() => toggleInternalRights("userManagement", role.oid) }/>} />
                      </Grid>

                      <Grid xs={12} sm={6} md={4} xl={3} sx={{textAlign:"left"}}>
                        <FormControlLabel label={<TranslationContent contentID="organizationManagementCheck" />} control={<Checkbox size="small" checked={role.internalRights?.organizationManagement} onChange={() => toggleInternalRights("organizationManagement", role.oid) }/>} />
                      </Grid>

                      <Grid xs={12} sm={6} md={4} xl={3} sx={{textAlign:"left"}}>
                        <FormControlLabel label={<TranslationContent contentID="siteManagementCheck" />} control={<Checkbox size="small" checked={role.internalRights?.siteManagement} onChange={() => toggleInternalRights("siteManagement", role.oid) }/>} />
                      </Grid>

                      <Grid xs={12} sm={6} md={4} xl={3} sx={{textAlign:"left"}}>
                        <FormControlLabel label={<TranslationContent contentID="inspectionFormManagementCheck" />} control={<Checkbox size="small" checked={role.internalRights?.inspectionFormManagement} onChange={() => toggleInternalRights("inspectionFormManagement", role.oid) }/>} />
                      </Grid>

                      <Grid xs={12} sm={6} md={4} xl={3} sx={{textAlign:"left"}}>
                        <FormControlLabel label={<TranslationContent contentID="reportManagementCheck" />} control={<Checkbox size="small" checked={role.internalRights?.reportManagement} onChange={() => toggleInternalRights("reportManagement", role.oid) }/>} />
                      </Grid>

                      <Grid xs={12} sm={6} md={4} xl={3} sx={{textAlign:"left"}}>
                        <FormControlLabel label={<TranslationContent contentID="inspectCheck" />} control={<Checkbox size="small" checked={role.internalRights?.inspect} onChange={() => toggleInternalRights("inspect", role.oid) }/>} />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Box>
              </Collapse>
              <Divider variant="inset" component="li"/>
            </div>
          )}
        </List>
      </>
    )
  }
}

export default OrganizationUsers;