import React from "react";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Divider, ListSubheader, ListItemButton } from "@mui/material";
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import PostAddIcon from '@mui/icons-material/PostAdd';
import TranslationContent from "../../../Translations/TranslationContent";
import { LanguageContext } from "../../../Translations/LanguageProvider";


const SectionList = ({ handleActiveContentChange, inspection, }) => {
  const currentLang = React.useContext(LanguageContext);

  const createSecondaryText = (section) => {
    let secondaryText = "";

    if(section.categories.length === 0) {
      let count = 0;
      section.inspections.forEach(inspection => {
        if(inspection.isBoolean && inspection.booleanValue !== "") {
          count = count + 1;
        }
        if(inspection.isNumber && inspection.numberValue !== "") {
          count = count + 1;
        }
      })
      secondaryText = `${count}/${section.inspections.length}`
    }

    if(section.categories.length > 0) {
      let validSelections = section.categories.map((category, index) => {
        if(category.selectedOption !== "") {
          return true;
        }
        else {
          return false;
        }
      })
      let allCategoriesSelected = validSelections.every(selection => selection === true);
      if(!allCategoriesSelected) {
        //secondaryText = "Osa valinnoista tekemättä";
        secondaryText = <TranslationContent contentID="someSelectionsUndone" />
      }
      if(allCategoriesSelected) {
        let count = 0;
        let maxCount = 0;
        section.inspections.forEach(inspection => {
          let linkedCategory = section.categories.find(category => category.id === inspection.linkedCategoryId);
          
          if(inspection.isBoolean && inspection.booleanValue !== "" && (linkedCategory?.selectedOption === inspection.selectedOptionId || inspection.selectedOptionId !== "")) {
            count = count + 1;
          }
          if(inspection.isNumber && inspection.numberValue !== "" && (linkedCategory?.selectedOption === inspection.selectedOptionId || inspection.selectedOptionId !== "")) {
            count = count + 1;
          }
          if(linkedCategory?.selectedOption === inspection.linkedOptionId || inspection.linkedOptionId === "") {
            maxCount = maxCount + 1;
          }
        })
        secondaryText = <>{count}/{maxCount} <TranslationContent contentID="completedInspectionsSuffix" /></>;
      }
    }
    return secondaryText;
  }

  return(
    <List 
      dense 
      sx={{maxWidth: 600, textAlign: "center", margin: "auto"}} 
      subheader={
        <ListSubheader sx={{background: "none"}}><TranslationContent contentID="selectSection" /></ListSubheader>
      }
    >
      {inspection.sections.map((section, index) => 
        <div key={section.id}>
          <ListItem
            disablePadding
            alignItems="flex-start"
            key={section.id}
          >
            <ListItemButton onClick={() => handleActiveContentChange("toSection", section, index)}>
              <ListItemAvatar>
                <AvatarSelector section={section} />
              </ListItemAvatar>
              <ListItemText
                primary={section.name[currentLang.lang]}
                secondary={<>{createSecondaryText(section)}</>}
              />
            </ListItemButton>
          </ListItem>
          <Divider variant="inset" component="li" />
        </div>
      )}
      {inspection.otherObservations.available ? 
      <ListItem
        disablePadding
        alignItems="flex-start"
      >
          <ListItemButton onClick={() => handleActiveContentChange("toObservations")}>
          <ListItemAvatar>
            <Avatar><PostAddIcon /></Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<TranslationContent contentID="otherObservations" />}
            secondary={<>{inspection.otherObservations.observations.length} <TranslationContent contentID="observations" /></>}
          />
        </ListItemButton>
      </ListItem>
      :null}
      <Divider variant="inset" component="li" />      
    </List>
  )
}

export default SectionList;


const AvatarSelector = ({ section }) => {
  const [status, setStatus] = React.useState("pending");
  
  const checkStatus = () => {
    let values = [];

    if(section.categories.length === 0) {
      let count = 0;
      section.inspections.forEach(inspection => {
        if(inspection.isBoolean && inspection.booleanValue !== "") {
          count = count + 1;
          values = [...values, inspection.booleanValue]
        }
        if(inspection.isNumber && inspection.numberValue !== "") {
          count = count + 1;
          if(Number(inspection.numberValue) <= Number(inspection.maxNumberValue) && Number(inspection.numberValue) >= Number(inspection.minNumberValue)) {
            values = [...values, true];
          }
          else {
            values = [...values, false];
          }
        }
      })
      if(count === section.inspections.length) {
        if(values.every(value => value === true)) {
          setStatus("success")
        }
        else {
          setStatus("failed")
        }
      }
    }

    if(section.categories.length > 0) {
      let validSelections = section.categories.map((category, index) => {
        if(category.selectedOption !== "") {
          return true;
        }
        else {
          return false;
        }
      })

      let allCategoriesSelected = validSelections.every(selection => selection === true);

      if(allCategoriesSelected) {
        let count = 0;
        let maxCount = 0;
        section.inspections.forEach(inspection => {
          let linkedCategory = section.categories.find(category => category.id === inspection.linkedCategoryId);
          
          if(inspection.isBoolean && inspection.booleanValue !== "" && (linkedCategory?.selectedOption === inspection.selectedOptionId || inspection.selectedOptionId !== "")) {
            count = count + 1;
            values = [...values, inspection.booleanValue]
          }
          if(inspection.isNumber && inspection.numberValue !== "" && (linkedCategory?.selectedOption === inspection.selectedOptionId || inspection.selectedOptionId !== "")) {
            count = count + 1;
            if(Number(inspection.numberValue) <= Number(inspection.maxNumberValue) && Number(inspection.numberValue) >= Number(inspection.minNumberValue)) {
              values = [...values, true];
            }
            else {
              values = [...values, false];
            }
          }
          if(linkedCategory?.selectedOption === inspection.linkedOptionId || inspection.linkedOptionId === "") {
            maxCount = maxCount + 1;
          }
        })
        if(count === maxCount) {
          if(values.every(value => value === true)) {
            setStatus("success")
          }
          else {
            setStatus("failed")
          }
        }
      }
    }
  }

  React.useEffect(() => {
    checkStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if(status === "pending") {
    return(
      <Avatar sx={{background: "grey"}}>
        <PendingIcon />
      </Avatar>
    )
  }
  if(status === "failed") {
    return(
      <Avatar sx={{background: "red"}}>
        <DoDisturbOnIcon />
      </Avatar>
    )
  }
  if(status === "success") {
    return(
      <Avatar sx={{background: "green"}}>
        <CheckCircleOutlineIcon />
      </Avatar>
    )
  }
}