import React from "react";
import MainLayout from "../../../Layouts/MainLayout";
import { Typography, Paper, Box, Stack, Card, CardActionArea, CardContent } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TranslationContent from "../../../Translations/TranslationContent";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../../Context/SessionProvider";


const Users = () => {
  const session = React.useContext(SessionContext);
  const navigate = useNavigate();

  const navigateClick = (destination) => {
    let path = "";
    if(destination === "registrations") {
      path = "registrations";
    }
    if(destination === "users") {
      path = "list";
    }
    navigate(path);
  }

  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return(
    <MainLayout>
      
      <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography color="primary" variant="body2"><b><TranslationContent contentID="userManagement" /></b></Typography>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={2}>

        <Grid xs={12} md={6}>
          <Card>
            <CardActionArea sx={{padding: 2, width: "100%", height: "100%" }} onClick={() => navigateClick("registrations")} >
              <CardContent sx={{display: "flex"}}>
                <Box sx={{width: "80%"}}>
                  <Stack>
                    <Typography variant="body2">
                      <TranslationContent contentID="applications" />
                    </Typography>
                    <Typography variant="h4">
                      {session.applications}
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={{width: "20%"}}>
                  <GroupAddIcon sx={{height: "60px", width: "60px"}} color="success" opacity={0.3} mr={0} ml="auto"/>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid xs={12} md={6}>
          <Card>
            <CardActionArea sx={{padding: 2, width: "100%", height: "100%"}} onClick={() => navigateClick("users")} >
              <CardContent sx={{display: "flex"}}>
                <Box sx={{width: "80%"}}>
                  <Stack>
                    <Typography variant="body2">
                      <TranslationContent contentID="users" />
                    </Typography>
                    <Typography variant="h4">
                      {session.userCount}
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={{width: "20%"}}>
                  <GroupIcon sx={{height: "60px", width: "60px"}} color="primary" opacity={0.3} mr={0} ml="auto"/>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

      </Grid>
    </MainLayout>
  )
}

export default Users;

