import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, ListItemButton, ListItemText, Box, Button, Typography, TextField, List } from "@mui/material";
import { useViewport } from "../../Context/ViewportProvider";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { LanguageContext } from "../../Translations/LanguageProvider";
import TranslationContent from "../../Translations/TranslationContent";


const FormSelectDialog = ({ open, handleClose, forms, confirmTemplateSelection }) => {
  const [searchWord, setSearchWord] = React.useState("");
  const [formSelection, setFormSelection] = React.useState({id: ""});
  const [filteredForms, setFilteredForms] = React.useState([])
  const lang = React.useContext(LanguageContext);
  const { width } = useViewport();
  
  const formClick = (selectedForm) => {
    let updatedSelectedForm = {...formSelection};
    updatedSelectedForm = {...selectedForm};
    setFormSelection(updatedSelectedForm);
  }

  const declineSelectedForm = () => {
    let updatedSelectedForm = {...formSelection};
    updatedSelectedForm = {id: ""};
    setFormSelection(updatedSelectedForm);
  }

  const handleFilterWordChange = (event) => {
    setSearchWord(event.target.value);
    let updatedFilteredForms = [...forms];
    if(event.target.value === "") {
      updatedFilteredForms = [...forms];
    }
    else {
      updatedFilteredForms = updatedFilteredForms.filter(form => form.name[lang.lang].toLowerCase().includes(event.target.value.toLowerCase()) || form.owner.toLowerCase().includes(event.target.value.toLowerCase()) || form.creator.name.toLowerCase().includes(event.target.value.toLowerCase()))
    }
    setFilteredForms(updatedFilteredForms);
  }

  return(
    <Dialog
      sx={{ '& .MuiDialog-paper': {  width: width < 600 ? '99%' : "400px", height: "80%" } }}
      maxWidth="xl"
      fullWidth
      open={open}
    >
      <DialogTitle>
        {formSelection.id === "" ? <TranslationContent contentID="selectForm" /> : <TranslationContent contentID="confirmSelection" />}
      </DialogTitle>
      <DialogContent dividers m={0} p={0}>
        {formSelection.id === "" ?
          <List>
            {searchWord === "" ?
              forms.map(form => (
                <ListItemButton key={form.id} onClick={() => formClick(form)}>
                  <ListItemText primary={form.name[lang.lang]} secondary={`${form.creator.name} (${form.owner})`}/>
                </ListItemButton>
              ))
            :
              filteredForms.map(form => (
                <ListItemButton key={form.id} onClick={() => formClick(form)}>
                  <ListItemText primary={form.name[lang.lang]} secondary={`${form.creator.name} (${form.owner})`}/>
                </ListItemButton>
              ))
            }
          </List>      
        :
          <Box p={2} textAlign={"center"}>
            <Typography variant="body1" mb={8}>
              <TranslationContent contentID="useFormDesc1" /> <b>{formSelection.name[lang.lang]}</b> <TranslationContent contentID="useFormDesc2" />
            </Typography>
            <Button onClick={() => declineSelectedForm()}><TranslationContent contentID="cancel" /></Button>&nbsp;&nbsp;
            <Button onClick={() => confirmTemplateSelection(formSelection.id)} variant="contained"><TranslationContent contentID="confirm" /></Button>
          </Box>
        }
      </DialogContent>
      <DialogActions sx={{ display: 'block', width: "100%",}}>
        <Box sx={{ alignItems: 'flex-end', display: "flex", mb: 3 }}>
          <PersonSearchIcon sx={{  mr: 1, my: 0.5 }} />
          <TextField label={<TranslationContent contentID="whatFormRULooking4" />} disabled={formSelection.id !== ""} variant="standard" value={searchWord} onChange={event => handleFilterWordChange(event)} fullWidth  />
        </Box>
      </DialogActions>
      <DialogActions>
        <Button autoFocus onClick={handleClose} >
          <TranslationContent contentID="cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default FormSelectDialog;