import React from "react";
import MainLayout from "../../../Layouts/MainLayout";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Loading from "../../GeneralComponents/Loading";
import Error from "../../GeneralComponents/Error";
import { Typography, Paper, Zoom, Button, ButtonGroup, Divider, CircularProgress, Checkbox, FormControlLabel, Box } from "@mui/material";
import TranslationContent from "../../../Translations/TranslationContent";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import { useNavigate, useParams } from "react-router-dom";
import { LanguageContext } from "../../../Translations/LanguageProvider";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import { v4 as uuid } from "uuid";
import InfoIcon from '@mui/icons-material/Info';
import { Tokens } from "../../../services/Tokens";
import formService from "../../../services/forms";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { SessionContext } from "../../../Context/SessionProvider";
import SectionList from "./SectionList";
import SaveFabButton from "../../Utils/GlobalComponents/SaveFabButton";
import OtherObservations from "./OtherObservations";
import SectionContent from "./SectionContent";
import ReadTextField from "./ReadTextField";
import WriteTextField from "./WriteTextField";
import { logger } from "../../../services/logger";
import imageService from "../../../services/images";

const FormEditor = () => {
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [adding, setAdding] = React.useState(false);
  const [deleting, setDeleting] = React.useState("");
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showSaveButton, setShowSaveButton] = React.useState(false);
  const [sectionEditMode, setSectionEditMode] = React.useState(false);
  const [selectedSection, setSelectedSection] = React.useState({});
  const [images, setImages] = React.useState([]);
  const [form, setForm] = React.useState({})
  const lang = React.useContext(LanguageContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const session = React.useContext(SessionContext);

  const navigate = useNavigate();
  const { id } = useParams();

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    if(fetchData.current && session.roles.length > 0) {
      fetchData.current = false;
      fetchForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchForm = () => {
    setLoading(true);
    Tokens().then((tokens) => {
      formService
      .getForm(id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedForm = {...form, ...response};
        setForm(updatedForm);
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarFetchingInspectionFormsFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const saveForm = async () => {
    return new Promise((resolve, reject) => {
      setSaving(true);
      Tokens().then((tokens) => {
        formService
        .updateForm(form, tokens.accessToken, tokens.idToken)
        .then(response => {
          logger(response);
          addAlert({message: "snackbarFormSaveSuccess", type: "success"});
          setSaving(false);
          setShowSaveButton(false);
          resolve(true);
        })
        .catch(error => {
          logger(error);
          setSaving(false);
          addAlert({message: "snackbarFormSaveFailed", type: "error"});
          reject(false);
        })
      })
      .catch(error => {
        logger(error);
        setSaving(false);
        addAlert({message: "snackbarSessionExpired", type: "error"});
        reject(false);
      })
    });
  }

  const deleteForm = () => {
    setLoading(true);
    Tokens().then((tokens) => {
      formService
      .deleteForm(form.id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        addAlert({message: "snacbarFormDeleteSuccess", type: "success"});
        navigate("/form");
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setLoading(false);
        addAlert({message: "snackbarFormDeleteFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const handleTextFieldChange = (event, type) => {
    let updatedForm = {...form};
    if(type === "nameFI") {
      updatedForm.name.fi = event.target.value.length < 51 ? event.target.value : updatedForm.name.fi;
    }
    if(type === "nameEN") {
      updatedForm.name.en = event.target.value.length < 51 ? event.target.value : updatedForm.name.en;
    }
    if(type === "maxObservationCount") {
      const inputValue = event.target.value.replace(/[^0-9]/g, '');
      updatedForm.otherObservations.maxCount = Number(inputValue);
    }
    setForm(updatedForm);
    setShowSaveButton(true);
  }

  const handleToggleChange = (type) => {
    let updatedForm = {...form};
    if(type === "available") {
      updatedForm.available = !updatedForm.available;
    }
    if(type === "globallyAvailable") {
      updatedForm.globallyAvailable = !updatedForm.globallyAvailable;
    }
    if(type === "observationsAvailable") {
      updatedForm.otherObservations.available = !updatedForm.otherObservations.available;
    }
    if(type === "imagesEnabled") {
      updatedForm.otherObservations.imagesEnabled = !updatedForm.otherObservations.imagesEnabled;
    }
    if(type === "restrictMax") {
      updatedForm.otherObservations.restrictMax = !updatedForm.otherObservations.restrictMax;
    }
    if(type === "decreaseMaxCount") {
      updatedForm.otherObservations.maxCount = updatedForm.otherObservations.maxCount > 0 ? Number(updatedForm.otherObservations.maxCount) - 1 : updatedForm.otherObservations.maxCount;
    }
    if(type === "increaseMaxCount") {
      updatedForm.otherObservations.maxCount = Number(updatedForm.otherObservations.maxCount) + 1;
    }
    setForm(updatedForm);
    setShowSaveButton(true);
  }

  const addSection = async () => {
    setAdding(true);
    let updatedForm = {...form};
    let newSection = {
      id: uuid(),
      name: {
        fi: "",
        en: "",
      },
      forceComplete: false,
      generalComment: "",
      selectedCategory: "",
      categories: [],
      inspections: [],
    };
    updatedForm.sections = [...updatedForm.sections, newSection];
    Tokens().then((tokens) => {
      formService
      .updateForm(updatedForm, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        setForm(updatedForm);
        addAlert({message: "snackbarSectionCreateSuccess", type: "success"});
        setAdding(false);
      })
      .catch(error => {
        logger(error);
        setSaving(false);
        addAlert({message: "snackbarSectionCreateFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setSaving(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const updateSection = async (updatedSection, type, index) => {
    let updatedForm = {...form};
    if(type === "delete") {
      setDeleting(updatedSection.id);
      let guideImageIds = [];
      // eslint-disable-next-line array-callback-return
      guideImageIds = updatedSection.inspections.map(inspection => {
        if(inspection.guideImage !== "") {
           return{imageId: inspection.guideImage, inspectionId: inspection.id};
        }
      })
      let imageRemoval = [];
      await Promise.all(
        imageRemoval = guideImageIds.map(async (image) => {
          Tokens().then((tokens) => {
            imageService
            .deleteFormImage(form.id, image.imageId, updatedSection.id, image.inspectionId, tokens.accessToken, tokens.idToken)
            .then(response => {
              logger(response);
              return true;
            })
            .catch(error => {
              logger(error);
              return false;
            })
          })
          .catch(error => {
            logger(error);
            return false;
          })
      }))
      if(imageRemoval.length === 0 || imageRemoval.every(removed => removed)) {
        updatedForm.sections = updatedForm.sections.filter(section => section.id !== updatedSection.id);
        Tokens().then((tokens) => {
          formService
          .updateForm(updatedForm, tokens.accessToken, tokens.idToken)
          .then(response => {
            logger(response);
            setForm(updatedForm);
            addAlert({message: "snackbarSectionDeleteSuccess", type: "success"});
            setDeleting("");
          })
          .catch(error => {
            logger(error);
            setDeleting("");
            addAlert({message: "snackbarSectionDeleteFailed", type: "error"});
          })
        })
        .catch(error => {
          logger(error);
          setDeleting("");
          addAlert({message: "snackbarSessionExpired", type: "error"});
        })
      }
      //updatedForm.sections = updatedForm.sections.filter(section => section.id !== updatedSection.id);
      // make a list of all id's of the section's guide images
      // delete all images with a loop
      // put request to save updated form
      //setForm(updatedForm);
      //setShowSaveButton(true);
    }
    if(type === "increaseSectionIndex") {
      if(form.sections.length > 1 && index + 1 < form.sections.length) {
        let updatedSections = [...form.sections];
        let itemToMove = updatedForm.sections[index];
        updatedSections.splice(index, 1);
        updatedSections.splice(index + 1, 0, itemToMove);
        updatedForm.sections = updatedSections;
      }
      setForm(updatedForm);
      setShowSaveButton(true);
    }
    if(type === "decreaseSectionIndex") {
      if(form.sections.length > 1 && index > 0) {
        let updatedSections = [...form.sections];
        let itemToMove = updatedForm.sections[index];
        updatedSections.splice(index, 1);
        updatedSections.splice(index - 1, 0, itemToMove);
        updatedForm.sections = updatedSections;
      }
      setForm(updatedForm);
      setShowSaveButton(true);
    }
    if(type === "update") {
      updatedForm.sections = updatedForm.sections.map(section => {
        if(section.id === updatedSection.id) {
          return {...section, ...updatedSection};
        }
        else {
          return section;
        }
      })
      setForm(updatedForm);
      setShowSaveButton(true);
    }
    if(type === "updateAndSave") {
      updatedForm.sections = updatedForm.sections.map(section => {
        if(section.id === updatedSection.id) {
          let newSection = {...section};
          newSection = {...updatedSection}
          newSection.inspections = [...updatedSection.inspections];
          return newSection;
        }
        else {
          return section;
        }
      })
      const saveUpdatedForm = async () => {
        return new Promise((resolve, reject) => {
          setSaving(true);
          Tokens().then((tokens) => {
            formService
            .updateForm(updatedForm, tokens.accessToken, tokens.idToken)
            .then(response => {
              logger(response);
              setForm(updatedForm);
              addAlert({message: "snackbarFormSaveSuccess", type: "success"});
              setSaving(false);
              setShowSaveButton(false);
              resolve(true);
            })
            .catch(error => {
              logger(error);
              setSaving(false);
              addAlert({message: "snackbarFormSaveFailed", type: "error"});
              reject(false);
            })
          })
          .catch(error => {
            logger(error);
            setSaving(false);
            addAlert({message: "snackbarSessionExpired", type: "error"});
            reject(false);
          })
        });
      }
      let success = await saveUpdatedForm();
      return success;
      //setForm(updatedForm);
      //let success = saveForm();
      //setShowSaveButton(false);
      //return success;
    }
    if(type === "imageUpdate") {
      updatedForm.sections = updatedForm.sections.map(section => {
        if(section.id === updatedSection.id) {
          return {...section, ...updatedSection};
        }
        else {
          return section;
        }
      })
      setForm(updatedForm);
    }
  }

  const retryClick = () => {
    fetchForm();
  }

  const updateSelectedSection = (section) => {
    if(sectionEditMode) {
      setSelectedSection({});
      setSectionEditMode(false);
    }
    else {
      let updatedSection = {...selectedSection};
      updatedSection = {...section}
      setSelectedSection(updatedSection);
      setSectionEditMode(true);
    }
  }

  const updateImages = (newImage, type) => {
    if (type === "add") {
      if (images.length < 21) {
        setImages(prevImages => [...prevImages, newImage]);
      } else {
        setImages(prevImages => prevImages.slice(0, -1).concat(newImage));
      }
    } else if (type === "remove") {
      setImages(images.filter(image => image.id !== newImage));
    }
  }

 
  if(error && !loading) return <Error section="inspectionTemplate" retryClick={retryClick} />;
  if(loading && !error) return <Loading section="inspectionTemplate" />;
  if(!error && !loading && sectionEditMode) return <SectionContent selectedSection={selectedSection} updateSection={updateSelectedSection} updateSectionChanges={updateSection} form={form} saving={saving} showSaveButton={showSaveButton} images={images} updateImages={updateImages} />;

  if(!error && !loading && !sectionEditMode) {
    return(
      <MainLayout>
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12}>
            {form.name[lang.lang] !== "" ?
              <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigate("/form")} ><TranslationContent contentID="inspectionTemplate" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />{form.name[lang.lang]}</Typography>
            :
              <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigate("/form")} ><TranslationContent contentID="inspectionTemplate" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} /><i style={{opacity: 0.5}}><TranslationContent contentID="noName" /></i></Typography> 
            }
            </Grid>
          </Grid>
        </Paper>
        <ConfirmDialog open={showDeleteDialog} confirmClick={() => deleteForm()} title="deleteFormTitle" text="deleteFormDesc" handleClose={() => setShowDeleteDialog(false)} />
        <SaveFabButton show={showSaveButton} loading={saving} disabled={saving || loading || form.permissionType === "read" } onClick={() => saveForm()}/>
        <ButtonGroup variant="text" sx={{width:"100%"}}>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} onClick={() => navigate("/form")} disabled={loading}><KeyboardReturnIcon /><Typography variant="caption" ><TranslationContent contentID="return" /></Typography></Button>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} disabled={loading || form.permissionType === "read"} color="error" onClick={() => setShowDeleteDialog(true)} ><DeleteForeverIcon /><Typography variant="caption" ><TranslationContent contentID="deleteForm" /></Typography></Button>
          <Zoom in={showSaveButton}><Button size="small" onClick={() => saveForm()} sx={{width: "33%", display: "grid", justifyItems: "center"}} disabled={loading || saving || form.permissionType === "read"}>{saving ? <CircularProgress color="primary"/> : <><SaveIcon /><Typography variant="caption" ><TranslationContent contentID="save" /></Typography></>}</Button></Zoom>
        </ButtonGroup>
        <Grid container spacing={2}>
          <Grid xs={12}><Divider /></Grid>
          {form.permissionType === "read" &&
            <Grid xs={12} mt={1}><Paper sx={{display:"inline-flex", width: "100%", borderLeft: "3px solid #009fe3", p: 1}}><InfoIcon color="primary" fontSize="small" sx={{alignItems: "center"}} />&nbsp;&nbsp;<Typography variant="body2"><b><TranslationContent contentID="readOnly" /></b></Typography>&nbsp;&nbsp;<Typography sx={{opacity: 0.7}} variant="body2"><TranslationContent contentID="readOnlyDesc" /></Typography></Paper></Grid>
          }
          <Grid xs={12} mt={1} >
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent contentID="basicInfo" /></b></Typography></Divider>
          </Grid>
          <Grid xs={12} md={6}><WriteTextField label="nameEN" maxStringLength={50} readOnly={form.permissionType === "read" } value={form.name.en} onChange={(event) => handleTextFieldChange(event, "nameEN")} /></Grid>
          <Grid xs={12} md={6}><WriteTextField label="nameFI"  maxStringLength={50} readOnly={form.permissionType === "read" } value={form.name.fi} onChange={(event) => handleTextFieldChange(event, "nameFI")} /></Grid>
          <Grid xs={12} md={6}>
            <FormControlLabel control={<Checkbox disabled={form.permissionType === "read"} checked={form.available} onChange={() => handleToggleChange("available")}  />} label={<TranslationContent contentID="formAvailable" />} />
          </Grid>
          <Grid xs={12} md={6}>
            <FormControlLabel control={<Checkbox disabled={form.permissionType === "read"} checked={form.globallyAvailable} onChange={() => handleToggleChange("globallyAvailable")} />} label={<TranslationContent contentID="formAvailable2All" />} />
          </Grid>
          <Grid xs={12} md={6}><ReadTextField label="creator" value={form.creator.name} /></Grid>
          <Grid xs={12} md={6}><ReadTextField label="owner" value={form.owner} /></Grid>
          <Grid xs={12} md={6}><ReadTextField label="created" value={`${new Date(form.created).toLocaleDateString()} ${new Date(form.created).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`} /></Grid>
          <Grid xs={12} md={6}><ReadTextField label="lastEdit" value={`${new Date(form.saved).toLocaleDateString()} ${new Date(form.saved).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`} /></Grid>
          <Grid xs={12} mt={4} textAlign={"center"}>
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent contentID="sections" /></b></Typography></Divider>
          </Grid>
          <Grid xs={12} sx={{textAlign: "center"}} mt={-3}>
            <SectionList sections={form.sections} updateSection={updateSection} permissionType={form.permissionType} selectedSection={{...selectedSection}} updateSelectedSection={updateSelectedSection} deleting={deleting} />
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button
                variant="contained"
                fullWidth
                sx={{/*width: 200,*/ marginTop: "25px", marginBottom: "10px"}} 
                size="small"
                disabled={adding}
                onClick={() => addSection()}
              >
                <PlaylistAddCircleIcon />
                &nbsp;&nbsp;
                <TranslationContent contentID="addSection" />
              </Button>
              {adding && (
                <CircularProgress
                  size={24}
                  sx={{
                    //color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-5px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Grid>
          <OtherObservations form={form} handleTextFieldChange={handleToggleChange} handleToggleChange={handleToggleChange} />
        </Grid>
      </MainLayout>
    )
  }
}

export default FormEditor;