import React from "react";
import { TextField, Button, Zoom, CircularProgress, ButtonGroup, Typography, Divider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import SaveIcon from "@mui/icons-material/Save";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import OrganizationUsers from "./OrganizationUsers";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import UserSelectionDialog from "../Dialogs/UserSelectionDialog";
import { Tokens } from "../../services/Tokens";
import organizationService from "../../services/organizations";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import OrganizationLogo from "./OrganizationLogo";
import { logger } from "../../services/logger";
import SaveFabButton from "../Utils/GlobalComponents/SaveFabButton";
import TranslationContent from "../../Translations/TranslationContent";


const OrganizationEditor = ({ show, backButtonClick, organizations, allUsers, fetchAllUsers, organization, fetchingAllUsers, updateSelectedOrganizationName, updateOrganizations, validateOrganizationInput }) => {
  const [openUserSelection, setOpenUserSelection] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showSaveButton, setShowSaveButton] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [organizationUsers, setOrganizationUsers] = React.useState([]);
  const [fetchingUsers, setFetchingUsers] = React.useState(true);
  const [selectedOrganization, setSelectedOrganization] = React.useState({
    brandId: "",
    city: "",
    email: "",
    id: "",
    logo: "",
    name: "",
    phone: "",
    postalCode: "",
    streetAddress: "",
    type: "",
  });
  const { addAlert } = React.useContext(SnackBarContext);

  React.useEffect(() => {
    if(show) {
      setSelectedOrganization({...selectedOrganization, ...organization})
      fetchOrganizationUsers(organization.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[show])
  
  const fetchOrganizationUsers = (id) => {
    setFetchingUsers(true);
    Tokens().then((tokens) => {
      organizationService
      .getOrganization(id, tokens.accessToken, tokens.idToken)
      .then(response => {
        let updatedOrganizationUsers = [...organizationUsers];
        updatedOrganizationUsers = [...response.roles];
        setOrganizationUsers(updatedOrganizationUsers);
        setFetchingUsers(false);
      })
      .catch(error => {
        logger(error);
        setFetchingUsers(false);
        addAlert({message: "snackbarFetchingUsersFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setFetchingUsers(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const saveOrganization = () => {
    setLoading(true);
    let roleData = organizationUsers.map(role => {
      if(role.id === "") {
        delete role.id;
      }
      if(role.type === "external") {
        delete role.internalRights;
      }
      role.brandId = selectedOrganization.brandId;
      return role;
    })
    const requestBody = {
      organization: {...selectedOrganization},
      roles: [...roleData]
    };
    Tokens().then((tokens) => {
      organizationService
      .updateOrganization(requestBody, tokens.accessToken, tokens.idToken)
      .then(response => {
        let updatedOrganizations = organizations.map(organization => {
          if(organization.id === selectedOrganization.id) {
            organization = {...organization, ...selectedOrganization}
            return organization;
          }
          return organization;
        });
        updateOrganizations(updatedOrganizations);
        let updatedOrganizationUsers = [...organizationUsers];
        updatedOrganizationUsers = response;
        setOrganizationUsers(updatedOrganizationUsers)
        setShowSaveButton(false);
        addAlert({message: "snackbarOrganizationUpdateSuccess", type: "success"});
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarOrganizationUpdateFailed", type: "error"});
        setLoading(false)
      })
    })
    .catch(error => {
      logger(error);
      addAlert({message: "snackbarSessionExpired", type: "error"});
      setLoading(false)
    })    
  }

  const deleteOrganization = () => {
    setDeleting(true);
    setShowDeleteDialog(false);
    Tokens().then((tokens) => {
      organizationService
      .deleteOrganization(selectedOrganization.id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedOrganizations = organizations.filter(organization => organization.id !== selectedOrganization.id);
        updateOrganizations(updatedOrganizations);
        setSelectedOrganization({})
        updateSelectedOrganizationName("", false);
        addAlert({message: "snackbarOrganizationDeleteSuccess", type: "success"});
        setDeleting(false);
      })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarOrganizationDeleteFailed", type: "error"});
        setDeleting(false);
      })
    })
    .catch(error => {
      logger(error);
      addAlert({message: "snackbarSessionExpired", type: "error"});
      setLoading(false);
    })
  }
  
  const handleTextFieldChange = (event, type) => {
    let newValue = event.target.value;
    let updatedNewOrganization = {};
    updatedNewOrganization = {...selectedOrganization};
    if(type === "name") {
      updatedNewOrganization.name = newValue;
    }
    if(type === "address") {
      updatedNewOrganization.streetAddress = newValue;
    }
    if(type === "postalCode") {
      updatedNewOrganization.postalCode = newValue;
    }
    if(type === "city") {
      updatedNewOrganization.city = newValue;
    }
    if(type === "email") {
      updatedNewOrganization.email = newValue;
    }
    if(type === "phone") {
      updatedNewOrganization.phone = newValue;
    }
    setSelectedOrganization(updatedNewOrganization);
    let validOrganizationData = validateOrganizationInput(updatedNewOrganization);
    setShowSaveButton(validOrganizationData);
  }

  const toggleInternalRights = (type, oid) => {
    let updatedOrganizationUsers = organizationUsers.map(user => {
      if(user.oid === oid) {
        switch(type) {
          case "userManagement":
            user.internalRights.userManagement = !user.internalRights.userManagement
            break;
          case "organizationManagement":
            user.internalRights.organizationManagement = !user.internalRights.organizationManagement
            break;
          case "siteManagement":
            user.internalRights.siteManagement = !user.internalRights.siteManagement
            break;
          case "inspectionFormManagement":
            user.internalRights.inspectionFormManagement = !user.internalRights.inspectionFormManagement
            break;
          case "reportManagement":
            user.internalRights.reportManagement = !user.internalRights.reportManagement
            break;
          case "inspect":
            user.internalRights.inspect = !user.internalRights.inspect
            break;
          default:
            console.log("Bad type!");
            break;
        }
      }
      return user;
    })
    setOrganizationUsers(updatedOrganizationUsers);
    setShowSaveButton(true);
  }

  const toggleExternalUserRole = (event, oid) => {
    let updatedOrganizationUsers = organizationUsers.map(user => {
      if(user.oid === oid) {
        user.role = event.target.value;
      }
      return user;
    })
    setOrganizationUsers(updatedOrganizationUsers);
    setShowSaveButton(true);
  }

  const updateLogo = (image) => {
    let updatedSelectedOrganization = {...selectedOrganization};
    updatedSelectedOrganization.logo = image;
    setSelectedOrganization(updatedSelectedOrganization);
  }

  const deleteUser = (oid) => {
    let updatedOrganizationUsers = organizationUsers.filter(user => user.oid !== oid);
    setOrganizationUsers(updatedOrganizationUsers);
    setShowSaveButton(true);
  }

  const addUsers = (newUsers) => {
    let updatedOrganizationUsers = [...organizationUsers];
    
    newUsers.forEach(user => {
      let newRole = {
        brandId: "",
        id: "",
        internalRights: {
          userManagement: false,
          siteManagement: false,
          reportManagement: false,
          organizationManagement: false,
          inspectionFormManagement: false,
          inspect: false,
        },
        oid: user.id,
        organization: {
          name: selectedOrganization.name,
          city: selectedOrganization.city,
          streetAddress: selectedOrganization.streetAddress,
          postalCode: selectedOrganization.postalCode,
          email: selectedOrganization.email,
          phone: selectedOrganization.phone
        },
        organizationId: selectedOrganization.id,
        role: "",
        type: "",
        userName: user.name,
      }
      if(selectedOrganization.type === "external") {
        newRole.role = "inspector"
        newRole.type = "external"
      }
      if(selectedOrganization.type === "internal") {
        newRole.role = "admin"
        newRole.type = "internal"
      }
      updatedOrganizationUsers = [...updatedOrganizationUsers, newRole]
    })
    setOrganizationUsers(updatedOrganizationUsers);
    setShowSaveButton(true);
  }

  if(show) {
    return(
      <>
        <UserSelectionDialog open={openUserSelection} handleClose={() => setOpenUserSelection(false)} allUsers={allUsers} fetchAllUsers={fetchAllUsers} fetchingAllUsers={fetchingAllUsers} organizationUsers={organizationUsers} addUsers={addUsers}/>
        <ConfirmDialog open={showDeleteDialog} confirmClick={() => deleteOrganization()} title="deleteOrganizationTitle" text="deleteOrganizationDesc" handleClose={() => setShowDeleteDialog(false)} /> 
        <SaveFabButton show={showSaveButton && organizationUsers.some(user => user.role === "manager" || user.role === "admin")} loading={loading} onClick={() => saveOrganization()} disabled={loading} />

        <ButtonGroup variant="text" sx={{width:"100%"}}>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} onClick={() => backButtonClick()} disabled={loading}><KeyboardReturnIcon /><Typography variant="caption" ><TranslationContent contentID="return" /></Typography></Button>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} color="error" onClick={() => setShowDeleteDialog(true)} disabled={loading || deleting || selectedOrganization.type === "internal"}>{deleting? <CircularProgress color="error"/> : <DomainDisabledIcon />}<Typography variant="caption" ><TranslationContent contentID="delete" /></Typography></Button>
          <Zoom in={showSaveButton}><Button size="small" onClick={() => saveOrganization()} sx={{width: "33%", display: "grid", justifyItems: "center"}} disabled={loading}>{loading ? <CircularProgress color="primary"/> : <><SaveIcon /><Typography variant="caption" ><TranslationContent contentID="save" /></Typography></>}</Button></Zoom>
        </ButtonGroup>

        <Grid container spacing={2} mt={0} margin={0}>

          <Grid xs={12}><Divider /></Grid>
          <Grid xs={12} mt={1} >
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent contentID="organizationInfo" /></b> <i style={{opacity: 0.7, fontSize: "smaller"}}>(*<TranslationContent contentID="required" />)</i></Typography></Divider>
          </Grid>
          <OrganizationLogo selectedOrganization={selectedOrganization} updateLogo={updateLogo} />
          <Grid xs={12} md={6} m="auto"><TextField label={<TranslationContent contentID="name" />} required fullWidth value={selectedOrganization.name} onChange={(event) => handleTextFieldChange(event, "name")} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="email" />} required fullWidth value={selectedOrganization.email} onChange={(event) => handleTextFieldChange(event, "email")} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="phoneNumber" />} required fullWidth value={selectedOrganization.phone} onChange={(event) => handleTextFieldChange(event, "phone")} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="streetAddress" />} required fullWidth value={selectedOrganization.streetAddress} onChange={(event) => handleTextFieldChange(event, "address")} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="postalCode" />} required fullWidth value={selectedOrganization.postalCode} onChange={(event) => handleTextFieldChange(event, "postalCode")} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="city" />} required fullWidth value={selectedOrganization.city} onChange={(event) => handleTextFieldChange(event, "city")} /></Grid>
  
          <Grid xs={12} mt={1} >
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent contentID="users" /></b></Typography></Divider>
          </Grid>

          <Grid xs={12}>
            <OrganizationUsers 
              toggleInternalRights={toggleInternalRights} 
              deleteUser={deleteUser} 
              organization={selectedOrganization} 
              organizationUsers={organizationUsers} 
              allUsers={allUsers} 
              fetchingUsers={fetchingUsers} 
              toggleExternalUserRole={toggleExternalUserRole} 
            />
            <Zoom in={!fetchingUsers}>
              <Button variant="outlined" size="small" sx={{ mt: 5, minWidth: "80%" }} fullWidth onClick={() => setOpenUserSelection(true)}><PersonAddAlt1Icon />&nbsp;&nbsp;<TranslationContent contentID="addUser" /></Button>
            </Zoom>
          </Grid>

        </Grid>
      </>
    )
  }
}

export default OrganizationEditor;