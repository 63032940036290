import React from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Paper, Typography, Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, Divider, ListSubheader, IconButton, ListItemButton, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Loading from "../GeneralComponents/Loading";
import Error from "../GeneralComponents/Error";
import { useNavigate } from "react-router-dom";
import { Tokens } from "../../services/Tokens";
import inspectionService from "../../services/inspections";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { SessionContext, SessionDispatchContext } from "../../Context/SessionProvider";
import { LanguageContext } from "../../Translations/LanguageProvider";
import TranslationContent from "../../Translations/TranslationContent";
import { logger } from "../../services/logger";


const InspectContinue = () => {
  const [loading, setLoading] = React.useState(true);
  const [deleting, setDeleting] = React.useState("");
  const [error, setError] = React.useState(false);
  const [inspections, setInspections] = React.useState([]);
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const currentLang = React.useContext(LanguageContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const navigate = useNavigate();

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    if(fetchData.current && session.roles.length > 0) {
      fetchData.current = false;
      fetchInspections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchInspections = () => {
    Tokens().then((tokens) => {
      inspectionService
      .getUsersInspections(session.activeRole.organizationId, tokens.accessToken, tokens.idToken)
      .then(response => {
        let updatedInspections = [...inspections];
        updatedInspections = [...response];
        setInspections(updatedInspections);
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarFetchingInspectionsFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
      navigate("");
    })
  }

  const deleteInspection = (id) => {
    setDeleting(id);
    Tokens().then((tokens) => {
      inspectionService
      .deleteInspection(id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedInspections = [...inspections];
        updatedInspections = updatedInspections.filter(inspection => inspection.id !== id);
        setInspections(updatedInspections);
        const updatedSession = {...session};
        updatedSession.unfinishedInspections = updatedSession.unfinishedInspections - 1;
        updateSession({type: "UPDATE_SESSION", value: updatedSession});
        setDeleting("");
        addAlert({message: "snackbarDeleteInspectionSuccess", type: "success"});
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarDeleteInspectionFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const retryClick = () => {
    setLoading(true);
    setError(false);
    fetchInspections();
  }

  if(error && !loading) return <Error section="continueInspection" retryClick={retryClick} />;
  if(loading && !error) return <Loading section="continueInspection" />;

  if(inspections.length < 1 && !loading && !error) {
    return(
      <MainLayout>
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Typography color="primary" variant="body2"><b><TranslationContent contentID="continueInspection" /></b></Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{padding: "7px", width: "100%",}}>
          <Grid container spacing={2} mt={0}>
            <Grid xs={12} sx={{textAlign: "center", justifyContent: "center", padding: "50px"}}>
              <FactCheckIcon sx={{width: 40, height: 40}} color="success" />
              <Typography><i><TranslationContent contentID="zeroUnfinishedInspections" /></i></Typography>
                <div style={{height: "25px"}} />
              <Button size="small" variant="contained" onClick={() => navigate("/inspect/new")}><TranslationContent contentID="newInspection" /></Button>
            </Grid>
          </Grid>
        </Paper>
      </MainLayout>
    )
  }

  if(inspections.length > 0 && !loading && !error) {
    return(
      <MainLayout>
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Typography color="primary" variant="body2"><b><TranslationContent contentID="continueInspection" /></b></Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{padding: "7px", width: "100%",}}>
          <Grid container spacing={2} mt={0}>
            <Grid xs={12} sx={{textAlign: "center", justifyContent: "center"}}>
              <List dense sx={{maxWidth: 600, textAlign: "center", margin: "auto"}} subheader={<ListSubheader sx={{background: "none"}}><TranslationContent contentID="selectInspection" /></ListSubheader>}>
                {inspections.map(inspection => 
                  <div key={inspection.id}>
                    <ListItem
                      disablePadding
                      alignItems="flex-start"
                      key={inspection.id}
                      secondaryAction={
                        deleting === inspection.id ? 
                          <CircularProgress color="error" />
                        :
                          <IconButton edge="end" aria-label="delete" color="error" onClick={() => deleteInspection(inspection.id)}>
                            <DeleteIcon />
                          </IconButton>
                      }
                    >
                      <ListItemButton onClick={() => navigate(`/inspect/active/${inspection.id}`)}>
                        <ListItemAvatar>
                          <Avatar>
                            <HistoryEduIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${inspection.propertyName} (${inspection.streetAddress})`}
                          secondary={<>
                            {inspection.name[currentLang.lang]} ({inspection.formName[currentLang.lang]})<br /><i style={{fontSize:"smaller"}}><TranslationContent contentID="edited" /> {new Date(inspection.saved).toLocaleString(undefined, {dateStyle: "short", timeStyle: "short"} )}</i>
                            </>}
                        />
                      </ListItemButton>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </div>
                )}
              </List>
            </Grid>
          </Grid>
        </Paper>
      </MainLayout>
    )
  }
}

export default InspectContinue;