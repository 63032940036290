import React from "react";
import { Zoom, Fab, CircularProgress } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';


const SaveFabButton = ({ show, loading, onClick, disabled }) => {
  return(
    <Zoom in={show}>
      <Fab sx={{position: "fixed", bottom: 16, right: 16}} aria-label="Save unsaved changes" color="primary" onClick={() => onClick()} disabled={disabled}>
        {!loading ? 
          <SaveIcon/>
        :
          <CircularProgress color="primary"/>
        }
      </Fab>
    </Zoom>
  )
}

export default SaveFabButton;