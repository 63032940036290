import React from "react";
import { Button, CircularProgress, Stack, ToggleButton } from "@mui/material";
import { useViewport } from "../../Context/ViewportProvider";
import TranslationContent from "../../Translations/TranslationContent";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { SessionContext, SessionDispatchContext } from "../../Context/SessionProvider";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { Tokens } from "../../services/Tokens";
import sessionService from "../../services/sessions";


const ButtonHandler = ({ role, switchPreferredRole }) => {
  const { width } = useViewport();
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const [loading, setLoading] = React.useState(false);

  const doubleActiveClick = () => {
    addAlert({message: "snackbarAccountAlreadyActive", type: "info"});
  }

  const switchActiveRole = () => {
    setLoading(true);
      Tokens().then((tokens) => {
        sessionService
        .getActiveSession(role.id, tokens.accessToken, tokens.idToken)
        .then(response => {
          let updatedSession = {...session};
          updatedSession.activeRole = {...role};
          updatedSession = { ...updatedSession, ...response };
          updateSession({type: "UPDATE_SESSION", value: updatedSession})
          setLoading(false);
          addAlert({message: "snackbarActiveRoleSwitchSuccess", type: "success"});
        })
        .catch(error => {
          setLoading(false);
          addAlert({message: "snackbarActiveRoleSwitchFailed", type: "error"});
        })
      })
      .catch(error => {
        setLoading(false);
        addAlert({message: "snackbarSessionExpired", type: "error"});
      })
  }
  

  if(loading) {
    return(
      <Stack direction={width > 600 ? "row" : "column"} spacing={width > 600 ? 2 : 1}>
        <CircularProgress sx={{marginRight: "20px"}} />
        <ToggleButton disabled sx={{height: "32px"}} value={session.preferredRoleId} onClick={() => switchActiveRole(role)}>
          {session.preferredRoleId === role.id ? 
            <StarIcon color="primary" /> 
            : 
            <StarBorderIcon />
          }
          </ToggleButton>
      </Stack>
    )
  }

  if(session.activeRole.id === role.id) {
    return(
      <Stack direction={width > 600 ? "row" : "column"} spacing={width > 600 ? 2 : 1}>
        <Button edge="end" aria-label="delete" color="success" size="small" variant="outlined" onClick={() => doubleActiveClick()} >
          <TranslationContent contentID="active" />
        </Button>
        <ToggleButton sx={{height: "32px"}} value={session.preferredRoleId} onClick={() => switchPreferredRole(role)}>
          {session.preferredRoleId === role.id ? 
            <StarIcon color="primary" /> 
            : 
            <StarBorderIcon />
          }
        </ToggleButton>
      </Stack>
    )
  }

  if(!loading && session.activeRole.id !== role.id) {
    return(
      <Stack direction={width > 600 ? "row" : "column"} spacing={width > 600 ? 2 : 1} >
        <Button edge="end" aria-label="delete" color="primary" onClick={() => switchActiveRole()} >
          <TranslationContent contentID="activate" />
        </Button>
        <ToggleButton sx={{height: "32px"}} value={session.preferredRoleId} onClick={() => switchPreferredRole(role)}>
          {session.preferredRoleId === role.id ? 
            <StarIcon color="primary" /> 
            : 
            <StarBorderIcon />
          }
        </ToggleButton>
      </Stack>
    )
  }
}

export default ButtonHandler;