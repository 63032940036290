import React from "react";
import LandingLayout from "../../Layouts/LandingLayout";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { Box, Typography, Button } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useViewport } from "../../Context/ViewportProvider";
import TranslationContent from "../../Translations/TranslationContent";
import documentServices from "../../services/documents";
import { logger } from "../../services/logger";
import { useParams } from "react-router-dom";


const DownloadDocument = () => {
  const [state, setState] = React.useState("loading");
  const { width } = useViewport();
  //const [token, setToken] = React.useState("");
  //const [key, setKey] = React.useState("");
  const { id } = useParams();


  React.useEffect(() => {
    initializeToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
/*
  const initializeToken = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has("token") && urlParams.has("key")) {
      setToken(urlParams.get("token"));
      setKey(urlParams.get("key"))
      setState("initial");
    }
    else {
      setState("error");
    }
  };
  */

  const initializeToken = () => {
    if(id) {
      setState("initial");
    }
    else {
      setState("error");
    }
  }

  const openLink = () => {
    setState("loading");
    fetchDocument();
  };
  
  
  const fetchDocument = () => {
    //documentService
    documentServices(id)
        .then(response => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const newTab = window.open(url, '_blank');
          if (newTab) {
            newTab.focus();
          } else {
            alert('Please allow pop-ups to open the file.');
          }
          setState("opened");
        })
      .catch(error => {
        logger(error);
        setState("error");
      })
  };
  
  if(state === "loading") {
    return(
      <LandingLayout>
        <Box textAlign={"center"} mt={"10%"} mb={"10%"}>
          <LoadingSpinner />
        </Box>
      </LandingLayout>
    )
  };

  if(state === "initial") {
    return(
      <LandingLayout>
        <Box textAlign={"center"} mt={"10%"} mb={"10%"}>
          <ErrorIcon fontSize="large" color="primary" />
          <Typography variant="h6"><b><TranslationContent contentID="initialLinkTitle" /></b></Typography>
          <div style={{height: "15px"}} />
          <Typography><TranslationContent contentID="initialLinkDesc" /></Typography>
          <div style={{height: "25px"}} />
          <Button variant="contained" onClick={() => openLink()}><LockOpenIcon />&nbsp;&nbsp;<TranslationContent contentID="openLink" /></Button>
        </Box>
      </LandingLayout>
    )
  };

  if(state === "error") {
    return(
      <LandingLayout>
        <Box textAlign={"center"} mt={"10%"} mb={"10%"}>
          <ErrorIcon fontSize="large" color="error" />
          <Typography><b><TranslationContent contentID="errorTitle" /></b></Typography>
          <Typography><TranslationContent contentID="errorDesc" /></Typography>
        </Box>
      </LandingLayout>
    )
  };

  if(state === "opened") {
    return(
      <LandingLayout>
        <Box textAlign={"center"} mt={"10%"} mb={"10%"}>
          <Typography mr={width > 600 ? 15 : 2} ml={width > 600 ? 15 : 2} sx={{opacity: 0.5}}><TranslationContent contentID="linkDeprecated" /></Typography>
        </Box>
      </LandingLayout>
    )
  }
};

export default DownloadDocument;