import React from "react";
import { Tokens } from "../../services/Tokens";
import imageService from "../../services/images";
import { logger } from "../../services/logger";
import { Button, IconButton, Skeleton, Stack, Box } from "@mui/material";
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { SnackBarContext } from "../../Context/SnackbarProvider";
import TranslationContent from "../../Translations/TranslationContent";
import Delete from "@mui/icons-material/Delete";


const FormImage = ({ form, imageId, images, updateImages, openLargeImage, inspectionId, section, updateSectionChanges }) => {
  const [imageState, setImageState] = React.useState("loading");
  const [imageData, setImageData] = React.useState("");
  const { addAlert } = React.useContext(SnackBarContext);

  React.useEffect(() => {
    if(imageId === "") {
      setImageState("noContent");
    }

    if(imageId !== "") {
      let localImage = images.find(image => image.id === imageId)
      if(localImage) {
        setImageData(localImage.data);
        setImageState("fetched")
      }
      else {
        Tokens().then((tokens) => {
          imageService
          .getFormImage(form.id, imageId, tokens.accessToken, tokens.idToken)
          .then(response => {
            logger(response);
            updateImages(response, "add");
            setImageState("fetched")
            setImageData(response.data);
          })
          .catch(error => {
            logger(error);
            setImageState("error")
          })
        })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarSessionExpired", type: "error"});
        setImageState("error")
      })
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[imageId])

  const deleteImage = () => {
    setImageState("deleting");
    Tokens().then((tokens) => {
      imageService
      .deleteFormImage(form.id, imageId, section.id, inspectionId, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedSection = {...section};
        console.log(updatedSection)
        updatedSection.inspections = updatedSection.inspections.map(inspection => {
          if(inspection.id === inspectionId) {
            inspection.guideImage = ""
            return inspection;
          }
          else return inspection;
        })
        updateSectionChanges(updatedSection, "imageUpdate");
        updateImages(imageId, "remove");
        setImageState("noContent");
        addAlert({message: "snackbarImageDeleteSuccessful", type: "success"});
      })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarImageDeleteFailed", type: "error"});
        setImageState("fetched");
      })
    })
    .catch(error => {
      logger(error);
      addAlert({message: "snackbarSessionExpired", type: "error"});
      setImageState("fetched");
    })
  }
  

  switch(imageState) {
    case "loading":
      return (
        <Stack direction="row" spacing={2} sx={{ width: "100%", textAlign: "left", alignContent: "left", justifyContent: "left"}}>
          <Skeleton variant="retangular" width={125} height={125} />
        </Stack>
      ) 
    case "fetched":
      return (
      <Stack direction="row" spacing={2} sx={{ width: "100%", textAlign: "left", alignContent: "left", justifyContent: "left"}}>
        <img src={imageData} onClick={() => openLargeImage(imageData)} alt="Inspector's observation's visual content" key={`Observation image - ${imageId}`} height={125} width={125} style={{border: "1px solid", cursor: "zoom-in"}}/>
        <IconButton color="error" size="small" onClick={() => deleteImage()}><Delete /></IconButton>
      </Stack>
      )
    case "error":
      return <BrokenImageIcon color="error" sx={{width: 125, height: 125}} />;
    case "deleting":
      return (
        <Stack direction="row" spacing={2} sx={{ width: "100%", textAlign: "left", alignContent: "left", justifyContent: "left"}} >
          <Skeleton variant="retangular" width={125} height={125} sx={{ bgcolor: "darkred"}} />
          <Button color="error" size="small" disabled><Delete /></Button>
        </Stack>
      );
    case "noContent": 
        return <Box sx={{/*border: "1px solid",*/ height: "100%", alignContent: "center"}} ><i style={{opacity: 0.5}}><TranslationContent contentID="noGuideImage" /></i></Box>
    default: 
      return null;
  }
}

export default FormImage;