import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Button, IconButton, Stack, Divider, Box, FormLabel } from "@mui/material";
import TranslationContent from "../../../Translations/TranslationContent";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EmptyListText from "./EmptyListText";
import WriteTextField from "./WriteTextField";


const SectionCategory = ({ category, permissionType, deleteOption, addOption, handleOptionTextFieldChange, handleCategoryTextFieldChange, deleteCategory }) => {
  return(
    <Grid container spacing={3} key={category.id} sx={{width: "98%", textAlign: "center", margin: "auto"}}>
      <Grid xs={12} sx={{ textAlign: "right", mt: -3, mb: -2 }}><Button color="error" onClick={() => deleteCategory(category.id)}><TranslationContent contentID="deleteCategory" /></Button></Grid>
      <Grid xs={12} md={6}><WriteTextField maxStringLength={50} multiline variant="filled" readOnly={permissionType === "read"} label="categoryEN" value={category.name.en} onChange={(event) => handleCategoryTextFieldChange(event, category, "categoryNameEN")} /></Grid>
      <Grid xs={12} md={6}><WriteTextField maxStringLength={50} multiline  variant="filled" readOnly={permissionType === "read"} label="categoryFI" value={category.name.fi} onChange={(event) => handleCategoryTextFieldChange(event, category, "categoryNameFI")} /></Grid>
      <Grid xs={12} textAlign={"left"}>
        <FormLabel sx={{ml: 2}}><TranslationContent contentID="options" /></FormLabel>
      </Grid>
      <EmptyListText show={category.options.length < 1} text="noOptions" />
      {category.options.map(option => 
        <Grid xs={12} key={option.id}>
          <Stack direction="row" spacing={3}>
            <Box width={"95%"}>
              <WriteTextField label="optionEN" readOnly={permissionType === "read"} maxStringLength={50} value={option.name.en} onChange={event => handleOptionTextFieldChange(event, category.id, option.id, "optionNameEN")} />
              <div style={{height: "10px"}} />
              <WriteTextField label="optionFI" readOnly={permissionType === "read"} maxStringLength={50} value={option.name.fi} onChange={event => handleOptionTextFieldChange(event, category.id, option.id, "optionNameFI")} />
            </Box>
            <Box width={"5%"} sx={{alignSelf: "center"}}>
            <IconButton color="error" disabled={permissionType === "read"} onClick={() => deleteOption(category.id, option.id)}><DeleteIcon /></IconButton>
            </Box>
          </Stack>
        </Grid>
      )}
      <Grid xs={12} ><Button disabled={permissionType === "read"} onClick={() => addOption(category)}><AddIcon />&nbsp;&nbsp;<TranslationContent contentID="addOption" /></Button></Grid>
      <Grid xs={12}><Divider /></Grid>
    </Grid>
  )
}

export default SectionCategory;