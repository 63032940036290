import React from "react";
import { Box, Typography, Paper, List, ListItemButton, ListItemAvatar, Avatar, ListItemText } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import TranslationContent from "../../Translations/TranslationContent";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { logger } from "../../services/logger";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';


const RoleStatus = ({ activeAccount, activeAccountSelected, roles, error, updateActiveRole }) => {
  logger(roles);

  if(activeAccount?.idTokenClaims?.groups?.length > 0 && activeAccountSelected && roles?.length < 1 && !error.activeError) {
    return(
      <Box mt={5}>
        <Typography><TranslationContent contentID="fetchingRoles" /></Typography>
        <LoadingSpinner />
      </Box>
    )
  }

  if(roles.length > 0) {
    return(
      <Box sx={{textAlign: "center", justifyContent: "center", width: "100%"}}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div" textAlign={"center"}>
          <TranslationContent contentID="chooseRole" />
          </Typography>
          <Paper sx={{maxWidth: "400px", textAlign: "center", margin: "auto"}}>
            <List dense={true} sx={{textAlign: "center"}}>
              {roles.map((role) => 
                <ListItemButton key={role.id} onClick={() => updateActiveRole({...role})}>
                  <ListItemAvatar>
                    <Avatar>
                      <AvatarHandler role={role.role} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={role.organization.name}
                    secondary={<TranslationContent contentID={role.role} />}
                  />
                </ListItemButton>
              )}
            </List>
            </Paper>
        </Box>
    )
  }

  if(activeAccountSelected && error.code === "E404") {
    return(
      <Box mt={5}>
        <GppMaybeIcon color="error" fontSize="large"/>
        <Typography textAlign={"center"} mx={5}><TranslationContent contentID="noGroupsAttachedErrorDesc" /></Typography>
      </Box>
    )
  }
  
  if(roles.length > 0 || (activeAccountSelected && activeAccount?.idTokenClaims?.groups?.length < 1) || (activeAccount?.idTokenClaims?.groups?.length > 0 && activeAccountSelected)) {return null}
}

export default RoleStatus;


const AvatarHandler = ({ role }) => {
  if(role === "manager") {
    return <SupervisedUserCircleIcon />
  }
  if(role === "admin") {
    return <AdminPanelSettingsIcon />
  }
  if(role === "inspector") {
    return <PersonIcon />
  }
}