import React from "react";
import { Avatar, Button, CircularProgress, Stack } from "@mui/material";
import { useViewport } from "../../Context/ViewportProvider";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import TranslationContent from "../../Translations/TranslationContent";
import ImagePreviewDialog from "../Dialogs/ImagePreviewDialog";
import ImageResizer from 'react-image-file-resizer';


const OrganizationLogo = ({ selectedOrganization, updateLogo }) => {
  const { width } = useViewport();
  const [loading, setLoading] = React.useState(false);
  const [showLargeImage, setShowLargeImage] = React.useState(false);

  const handleImageChange = (event) => {
    setLoading(true);
    const file = event.target.files[0];
    ImageResizer.imageFileResizer(
      file,
      300, // New width (in pixels)
      300, // New height (in pixels)
      'JPEG', // Output format (JPEG or PNG)
      90, // Quality (1 to 100)
      0, // Rotation (0, 90, 180, or 270)
      (resizedImage) => {
        updateLogo(resizedImage);
        setLoading(false);
      },
      'base64', // Output type (base64 or blob)
      100, // Max file size (in kilobytes)
      350, // Max width (in pixels)
      350 // Max height (in pixels)
    );
  }

  const deleteImage = () => {
    updateLogo("");
  }

  return(
    <Grid xs={12} md={6} m="auto" sx={{textAlign:"center"}}>
      <ImagePreviewDialog open={showLargeImage} close={() => setShowLargeImage(false)} img={selectedOrganization.logo} />
        <Stack direction="row" spacing={2} sx={{textAlign: width > 600 ? "" : "center", marginLeft: width > 600 ? "10%" : "", justifyContent: width > 600 ? "" : "center"}}>
          {loading ? 
            <Avatar sx={{width:"100px", height:"100px"}}><CircularProgress sx={{color: "white"}} /></Avatar>
          :
            selectedOrganization.logo === "" ?
              <Avatar sx={{width:"100px", height:"100px"}}><AddPhotoAlternateIcon fontSize="large"/></Avatar>
            :
              <Avatar alt="Organization logo" src={selectedOrganization.logo} sx={{width:"100px", height:"100px", cursor: selectedOrganization.logo !== "" ? "zoom-in" : ""}} onClick={selectedOrganization.logo !== "" ? () => setShowLargeImage(true) : console.log()} />
          }
        <Stack direction="column" spacing={2}>
          <input accept=".jpg, .jpeg, .png" type="file" id={selectedOrganization.id + "Organization logo"} style={{display: "none"}} onChange={event => handleImageChange(event)} />
          <label htmlFor={selectedOrganization.id + "Organization logo"}>
            <Button variant="contained" size="small" component="span"><TranslationContent contentID="uploadNewImage" /></Button>
          </label>
          <Button variant="outlined" size="small" disabled={selectedOrganization.logo === ""} onClick={() => deleteImage()}><TranslationContent contentID="removeImage" /></Button>
        </Stack>
      </Stack>
    </Grid>
  )
}

export default OrganizationLogo;