import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, Stack, Divider, TextField, IconButton, Tooltip } from "@mui/material";
import LoadingSpinner from "../Loading/LoadingSpinner";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { Tokens } from "../../services/Tokens";
import archiveService from "../../services/archives";
import { useNavigate } from "react-router-dom";
import { logger } from "../../services/logger";
import TranslationContent from "../../Translations/TranslationContent";


const ShareLinkDialog = ({ open, handleClose, id }) => {
  const [dialogState, setDialogState] = React.useState("loading");
  const [link, setLink] = React.useState("");
  const navigate = useNavigate();
  
  const createLink = React.useRef(true);
  React.useEffect(() => {
    if(createLink.current && open) {
      createLink.current = false;
      linkCreator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])

  const linkCreator = () => {
    setDialogState("loading")
    Tokens().then((tokens) => {
      archiveService
      .createDownloadLink(id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        setLink(response);
        setDialogState("success");
      })
      .catch(error => {
        logger(error);
        setDialogState("error");
      })
    })
    .catch(error => {
      logger(error);
      setDialogState("error");
      navigate("");
    })
  }

  const closeDialog = () => {
    handleClose();
    setLink("");
    setDialogState("loading");
    createLink.current = true;
  }

  return(
    <Dialog
      open={open}
      aria-labelledby="create-a-link-to-share-document"
      aria-describedby="created link is one time only"
      maxWidth="md"
      fullWidth
    >
      <DialogContentManager state={dialogState} handleClose={closeDialog} link={link} linkCreator={linkCreator} />
    </Dialog>
  )
}

export default ShareLinkDialog;


const DialogContentManager = ({ state, handleClose, link, linkCreator }) => {
  const { addAlert } = React.useContext(SnackBarContext);
  
  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(link);
    addAlert({message: "linkCopiedToClipboard", type: "success"});
  }

  if(state === "loading") {
    return(
      <>
        <DialogTitle id="Creating share link..." textAlign={"center"}>
          <TranslationContent contentID="creatingLink" />
        </DialogTitle>
        <DialogContent sx={{textAlign: "center"}}>
          <LoadingSpinner />
        </DialogContent>
      </>
    )
  }

  if(state === "error") {
    return(
      <>
        <DialogTitle id="Error creating download link" textAlign={"center"}>
          <ErrorIcon fontSize="large" color="error" />
          <br />
          <b><TranslationContent contentID="errorOccured" /></b>
        </DialogTitle>
        <DialogContent sx={{textAlign: "center"}}>
        <DialogContentText id="Error occured when creatinh link" textAlign={"center"}>
            <TranslationContent contentID="somethingWrong" /> <TranslationContent contentID="shareLinkCreatonFailed" />
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}><TranslationContent contentID="cancel" /></Button>
          <Button onClick={() => linkCreator()} autoFocus variant="contained" color="primary">
            <TranslationContent contentID="retry" />
          </Button>
        </DialogActions>
      </>
    )
  }

  if(state === "success") {
    return(
      <>
        <DialogTitle id="link-created-title" textAlign={"center"}>
          <CheckCircleIcon fontSize="large" color="success" />
          <br />
          <b><TranslationContent contentID="linkCreatedTitle" /></b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="link-created-description" textAlign={"center"}>
            <TranslationContent contentID="linkCreatedDesc1" /> 
            <br />
            <TranslationContent contentID="linkCreatedDesc2" />
          </DialogContentText>
          <Stack direction="row" spacing={1} sx={{marginTop: "25px"}}>
            <TextField 
              label={<TranslationContent contentID="shareLink" />} 
              autoFocus
              InputProps={{
                readOnly: true,
              }} 
              fullWidth 
              value={link}
            />
            <Tooltip title={<TranslationContent contentID="copyLinkToClipboard" />} arrow >
              <IconButton color="primary" onClick={() => copyLinkToClipboard()}>
                <ContentCopyIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Stack>
        </DialogContent>
        <DialogActions >
          <Button onClick={handleClose} fullWidth variant="contained" color="primary">
            <TranslationContent contentID="ready" />
          </Button>
        </DialogActions>
      </>
    )
  }
}