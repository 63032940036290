import React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, Chip, Button, Stack, Divider } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import TranslationContent from "../../Translations/TranslationContent";
import ToggleThemeButton from "../../Theme/ToggleThemeButton";
import SwitchLanguageButton from "../../Translations/SwitchLanguageButton";

const environment = process.env.REACT_APP_ENVIRONMENT;
const phase = process.env.REACT_APP_PHASE;
const version = process.env.REACT_APP_VERSION;


const GlobalFooter = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
    sessionStorage.clear();
  }
    
  return(
    <Grid container spacing={2} sx={{textAlign: "center"}}>
      <Grid xs={12} mt="25px">
        <Divider />
      </Grid>
      <AuthenticatedTemplate>
        <Grid xs={12} 
          sx={{ 
            //eslint-disable-next-line
            textAlign:"center", textAlign: "-webkit-center"
          }}
        >
          <Stack spacing={2} sx={{maxWidth: "250px", marginTop: "25px"}}>
            <Button variant="outlined" size="small" onClick={() => handleLogout()}><LogoutIcon fontSize="small"/>&nbsp;&nbsp;<TranslationContent contentID="logOutButton"/></Button>
          </Stack>
        </Grid>
      </AuthenticatedTemplate>
      <Grid xs={12}>
        <ToggleThemeButton />
      </Grid>
      <Grid xs={3}></Grid>
      <Grid xs={12}>
        <SwitchLanguageButton />
      </Grid>  
      <Grid xs={12}>
        <Typography variant="caption" sx={{opacity: 0.2, margin: "auto"}}><TranslationContent contentID="version" />: {version} - <TranslationContent contentID="environment" />: {environment} <Chip size="small" label={phase}/></Typography>
      </Grid>
    </Grid>
  )
}

export default GlobalFooter;