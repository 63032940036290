import React from "react";
import { Tokens } from "../../services/Tokens";
import imageService from "../../services/images";
import { logger } from "../../services/logger";
import { Button, Skeleton, Stack } from "@mui/material";
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { SnackBarContext } from "../../Context/SnackbarProvider";
import TranslationContent from "../../Translations/TranslationContent";


const InspectionImage = ({ renderImage, inspectionForm, imageId, images, updateImages, openLargeImage, inspection, updateInspection, sectionId }) => {
  const [imageState, setImageState] = React.useState("loading");
  const [imageData, setImageData] = React.useState("");
  const { addAlert } = React.useContext(SnackBarContext);

  console.log(renderImage)

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(fetchData.current && renderImage) {
      fetchData.current = false;
    
    let localImage = images.find(image => image.id === imageId)
    if(localImage) {
      setImageData(localImage.data);
      setImageState("fetched")
    }
    else {
      Tokens().then((tokens) => {
        imageService
        .getInspectionImage(inspectionForm.id, imageId, tokens.accessToken, tokens.idToken)
        .then(response => {
          logger(response);
          updateImages(response, "add");
          setImageState("fetched")
          setImageData(response.data);
        })
        .catch(error => {
          logger(error);
          setImageState("error")
        })
      })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarSessionExpired", type: "error"});
        setImageState("error")
      })
    
  }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[renderImage])


  const deleteImage = () => {
    setImageState("deleting");
    Tokens().then((tokens) => {
      imageService
      .deleteInspectionImage(inspectionForm.id, imageId, sectionId, inspection.id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedForm = {...inspectionForm};
        updatedForm.sections = updatedForm.sections.map(section => {
          if(section.id === sectionId) {
            section.inspections = section.inspections.map(oldInspection => {
              if(oldInspection.id === inspection.id) {
                oldInspection.inspectionImages = oldInspection.inspectionImages.filter(image => image !== imageId)
                return oldInspection;
              }
              return oldInspection;
            })
            return section;
          }
          return section;
        })
        updateInspection(updatedForm, "imageUpdate")
        updateImages(imageId, "remove");
        setImageState("");
        addAlert({message: "snackbarImageDeleteSuccessful", type: "success"});
      })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarImageDeleteFailed", type: "error"});
        setImageState("fetched");
      })
    })
    .catch(error => {
      logger(error);
      addAlert({message: "snackbarSessionExpired", type: "error"});
      setImageState("fetched");
    })
  }

  switch(imageState) {
    case "loading":
      return (
        <Stack sx={{ width: "100%", textAlign: "center", alignContent: "center", justifyContent: "center", display: "inline-grid"}}>
          <Skeleton variant="retangular" width={125} height={125} />
        </Stack>
      ) 
    case "fetched":
      return (
      <Stack sx={{ width: "100%", textAlign: "center", alignContent: "center", justifyContent: "center", display: "inline-grid"}}>
        <img src={imageData} onClick={() => openLargeImage(imageData)} alt="Inspector's observation's visual content" key={`Observation image - ${imageId}`} height={125} width={125} style={{border: "1px solid", cursor: "zoom-in"}}/>
        <Button color="error" size="small" onClick={() => deleteImage()}><TranslationContent contentID="delete" /></Button>
      </Stack>
      )
    case "error":
      return <BrokenImageIcon color="error" sx={{width: 125, height: 125}} />;
    case "deleting":
      return (
        <Stack sx={{ width: "100%", textAlign: "center", alignContent: "center", justifyContent: "center", display: "inline-grid"}} >
          <Skeleton variant="retangular" width={125} height={125} sx={{ bgcolor: "darkred"}} />
          <Button color="error" size="small" disabled><TranslationContent contentID="delete" /></Button>
        </Stack>
      );
    default: 
      return null;
  }
}

export default InspectionImage;