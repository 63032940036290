import React from "react";
import MainLayout from "../../../Layouts/MainLayout";
import { Typography, Paper, List, Divider, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TranslationContent from "../../../Translations/TranslationContent";
import { useViewport } from "../../../Context/ViewportProvider";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import Error from "../../GeneralComponents/Error";
import Loading from "../../GeneralComponents/Loading";
import UserPlacementDialog from "../../Dialogs/UserPlacementDialog";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import { Tokens } from "../../../services/Tokens";
import applicationService from "../../../services/applications";
import organizationService from "../../../services/organizations";
import { SessionContext, SessionDispatchContext } from "../../../Context/SessionProvider";
import RegistrationListItem from "./RegistrationListItem";
import { logger } from "../../../services/logger";


const Registrations = () => {
  const { width } = useViewport();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [registrations, setRegistrations] = React.useState([]);
  const [organizations, setOrganizations] = React.useState([]);
  const [userPlacementDialogOpen, setUserPlacementDialogOpen] = React.useState(false);
  const [newUser, setNewUser] = React.useState(null)
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const navigate = useNavigate();

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    if(fetchData.current && session.roles.length > 0) {
      fetchData.current = false;
      fetchApplications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchApplications = () => {
    Tokens().then((tokens) => {
      applicationService
      .getApplications(tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedRegistrations = [...registrations];
        updatedRegistrations = response;
        setRegistrations(updatedRegistrations);
        let updatedSession = {...session};
        updatedSession.applications = updatedRegistrations.length;
        updateSession({type: "UPDATE_SESSION", value: updatedSession})
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
    })
  }

  const fetchOrganizations = () => {
    Tokens().then((tokens) => {
      organizationService
      .getOrganizations(tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedOrganizations = [...organizations];
        updatedOrganizations = [...response];
        setOrganizations(updatedOrganizations);
      })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarOrganizatrionFetchFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const openUserPlacementDialog = (createdUser) => {
    logger(createdUser)
    let updatedNewUser = {...createdUser};
    setNewUser(updatedNewUser);
    setUserPlacementDialogOpen(true);
  }
  

  if(error && !loading) return <Error section="userManagement" />;
  if(loading && !error) return <Loading section="userManagement" />;
  
  if(!loading && !error) {
    return(
      <>
        <UserPlacementDialog open={userPlacementDialogOpen} newUser={newUser} organizations={organizations} fetchOrganizations={fetchOrganizations} handleClose={() => setUserPlacementDialogOpen(false)}/>
        <MainLayout>

          <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigate("/users")}><TranslationContent contentID="userManagement" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} /><TranslationContent contentID="openApplicants" /></Typography>
              </Grid>
            </Grid>
          </Paper>
      
          <Paper sx={{padding: "7px", width: "100%",}}>
            <List 
              dense 
              subheader={
                <Typography>
                  <b><TranslationContent contentID="openApplicants" /></b>
                </Typography>
              }
            >
              <Divider />
              {registrations.map((registration, index) => 
                <RegistrationListItem key={index + registration.id} registration={registration} openUserPlacementDialog={openUserPlacementDialog} />
              )}
              {registrations.length === 0 && 
                <Box mt="25px" sx={{textAlign: width > 450 ? "center" : "left"}}>
                  <Typography><i><TranslationContent contentID="noOpenApplicants" /></i></Typography>
                </Box>
              }
            </List>
          </Paper>

        </MainLayout>
      </>
    )
  }
}

export default Registrations;