import React from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Typography, Paper, Chip, Stack, } from "@mui/material";
import Error from "../GeneralComponents/Error";
import Loading from "../GeneralComponents/Loading";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TranslationContent from "../../Translations/TranslationContent";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import OrganizationTable from "./OrganizationTable";
import { Tokens } from "../../services/Tokens";
import organizationService from "../../services/organizations";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { SessionContext } from "../../Context/SessionProvider";
import { logger } from "../../services/logger";


const Organization = () => {
  const [organizations, setOrganizations] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [selectedOrg, setSelectedOrg] = React.useState("");
  const [creationMode, setCreationMode] = React.useState(false);
  const { addAlert } = React.useContext(SnackBarContext);
  const session = React.useContext(SessionContext);

  const navigate = useNavigate();

  const updateOrganization = (organizationName, isNew) => {
    if(isNew) {
      setCreationMode(true);
    }
    else {
      setCreationMode(false);
      setSelectedOrg(organizationName);
    }
  }

  const updateOrganizations = (updatedOrganizationList) => {
    let updatedOrganizations = [...organizations];
    updatedOrganizations = updatedOrganizationList;
    setOrganizations(updatedOrganizations)
  }

  const navigateClick = () => {
    setCreationMode(false);
    setSelectedOrg("");
    navigate("/organization");
  }

  const fetchOrganizations = () => {
    Tokens().then((tokens) => {
      organizationService
      .getOrganizations(tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedOrganizations = [...organizations];
        updatedOrganizations = [...response];
        setOrganizations(updatedOrganizations);
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarOrganizatrionFetchFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const retryClick = () => {
    setLoading(true);
    setError(false);
    fetchOrganizations();
  }

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    if(fetchData.current && session.roles.length > 0) {
      fetchData.current = false;
      fetchOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  if(error && !loading) return <Error section="organizationManagement" retryClick={() => retryClick()}/>;
  if(loading && !error) return <Loading section="organizationManagement" />;
  
  if(!loading && !error) {
    return(
      <MainLayout>
        <Paper sx={{ padding: "7px", marginBottom: "15px" }} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              {selectedOrg !== "" ?
                <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigateClick()}><TranslationContent contentID="organizationManagement" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />{selectedOrg}</Typography>
              :
                (creationMode ?
                  <Stack direction="row">
                    <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigateClick()}>
                      <TranslationContent contentID="organizationManagement" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
                    </Typography>
                    <Chip color="primary" size="small" label={<b style={{color: "yellow"}}><TranslationContent contentID="new" /></b>} />
                  </Stack>
                :
                  <Typography color="primary" variant="body2"><b><TranslationContent contentID="organizationManagement" /></b></Typography>
                )
              }
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: "7px", width: "100%" }}>
          <Grid container spacing={2} mt={0}>
            <Grid xs={12} sx={{ textAlign: "center", justifyContent: "center" }}>
              <OrganizationTable 
                updateSelectedOrganizationName={updateOrganization} 
                updateOrganizations={updateOrganizations} 
                selectedOrganizationName={selectedOrg} 
                creationMode={creationMode} 
                organizations={organizations} 
              />
            </Grid>
          </Grid>
        </Paper>
    </MainLayout>
    )
  }
}

export default Organization;