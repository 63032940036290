import React from "react";
import { TableCell, TableRow, Avatar } from "@mui/material";
import { useViewport } from "../../Context/ViewportProvider";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { BrightnessThemeStateContext } from "../../Theme/BrightnessThemeProvider";
import { LanguageContext } from "../../Translations/LanguageProvider";


const InspectionFormRow = ({ form, rowClick }) => {
  const { width } = useViewport();
  const currentTheme = React.useContext(BrightnessThemeStateContext);
  const currentLang = React.useContext(LanguageContext);

  return(
    <TableRow 
      sx={{
        cursor: "pointer", 
        '&:hover': {
          backgroundColor: currentTheme.theme === "light" ? "rgba(0, 0, 0, 0.04)" : "#121212",
        },
      }} 
      onClick={() => rowClick()} 
    >
      <TableCell>
        <Avatar><ReceiptLongIcon /></Avatar>
      </TableCell>
      <TableCell component="th" scope="row" >
        {form.name[currentLang.lang]}
      </TableCell>
      <TableCell align="right" sx={{color: form.owner === "Piristeel Oy" ? "#009fe3" : "", fontWeight: form.owner === "Piristeel Oy" ? 800 : ""}}>
        {form.owner}
      </TableCell>
      {width > 700 && 
        <>
          <TableCell align="right" >{form.creator.name}</TableCell>
          <TableCell align="right" >{new Date(form.saved).toLocaleDateString()} {new Date(form.saved).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}</TableCell>
        </>
      }
    </TableRow>
  )
}

export default InspectionFormRow;