import React from "react";
import MainLayout from "../../../Layouts/MainLayout";
import { Divider, Paper, Typography, Chip, Avatar, ListSubheader, ListItemButton, List, ListItem, ListItemAvatar, ListItemText, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Error from "../../GeneralComponents/Error";
import Loading from "../../GeneralComponents/Loading";
import TranslationContent from "../../../Translations/TranslationContent";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { useNavigate } from "react-router-dom";
import { SessionContext, SessionDispatchContext } from "../../../Context/SessionProvider";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { Tokens } from "../../../services/Tokens";
import inspectionService from "../../../services/inspections";
import { LanguageContext } from "../../../Translations/LanguageProvider";
import { logger } from "../../../services/logger";


const InspectSelectNew = () => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [inspections, setInspections] = React.useState([]);
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const currentLang = React.useContext(LanguageContext);
  const { addAlert } = React.useContext(SnackBarContext);

  const navigate = useNavigate();

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    if(fetchData.current && session.roles.length > 0) {
      fetchData.current = false;
      fetchInspections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchInspections = () => {
    Tokens().then((tokens) => {
      inspectionService
      .getUsersNewInspections(session.activeRole.organizationId, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedInspections = [...inspections];
        updatedInspections = [...response];
        setInspections(updatedInspections);
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarFetchingInspectionsFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
      navigate("");
    })
  }

  const selectInspection = (id) => {
    setLoading(true);
    Tokens().then((tokens) => {
      inspectionService
      .startNewInspection(id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedSession = {...session};
        updatedSession.newInspections = updatedSession.newInspections - 1;
        updatedSession.unfinishedInspections = updatedSession.unfinishedInspections + 1;
        updateSession({ type: "UPDATE_SESSION", value: updatedSession });
        navigate(`/inspect/active/${id}`);
      })
      .catch(error => {
        logger(error);
        setLoading(false);
        addAlert({message: "snackbarInspectionCreationFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
      navigate("");
    })
  }

  const retryClick = () => {
    setLoading(true);
    setError(false);
    fetchInspections();
  }

  if(error && !loading) return <Error section="newInspection" retryClick={retryClick} />;
  if(loading && !error) return <Loading section="newInspection" />;

  if(!loading && !error) {
    return (
      <MainLayout>
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Stack direction="row">
                <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigate("/inspect/new")} >
                  <TranslationContent contentID="newInspection" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
                </Typography>
                <Chip color="primary" size="small" label={<b style={{color: "yellow"}}><TranslationContent contentID="chooseNew" /></b>} />
              </Stack>      
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{padding: "7px", width: "100%",}}>
          <Grid container spacing={2} mt={0}>
            <Grid xs={12} sx={{textAlign: "center", justifyContent: "center"}}>
              {inspections.length === 0 ? 
                <Typography sx={{opacity: 0.5}}><TranslationContent contentID="noInspections" /></Typography>
              :
                <List dense sx={{maxWidth: 600, textAlign: "center", margin: "auto"}} 
                  subheader={
                  <ListSubheader 
                    sx={{background: "none", lineHeight: 1.2, mb: 2}}
                  >
                    <b style={{mb: 0}}><TranslationContent contentID="selectInspection" /></b>
                    <br />
                    <i style={{mb: 5}}><TranslationContent contentID="selectInspectionDesc" /></i>
                    </ListSubheader>}>
                  {inspections.map(inspection => 
                    <div key={inspection.id}>
                      <ListItem
                        disablePadding
                        alignItems="flex-start"
                        key={inspection.id}
                      >
                        <ListItemButton onClick={() => selectInspection(inspection.id)}>
                          <ListItemAvatar>
                            <Avatar>
                              <HistoryEduIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${inspection.propertyName} (${inspection.streetAddress})`}
                            secondary={<>
                              {inspection.name[currentLang.lang]} ({inspection.formName[currentLang.lang]})<br />{inspection.timed !== "" && <i style={{fontSize:"smaller"}}><TranslationContent contentID="timed" /> {new Date(inspection.timed).toLocaleString(undefined, {dateStyle: "short"} )}</i>}</>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </div>
                  )}
                </List>
              }
            </Grid>
          </Grid>
        </Paper>
      </MainLayout>
    )
  }
}

export default InspectSelectNew;