import React from "react";
import { Typography, Zoom, ButtonGroup, Divider, CircularProgress, Button, TextField  } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SaveIcon from "@mui/icons-material/Save";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import { Tokens } from "../../services/Tokens";
import siteService from "../../services/sites";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { SessionContext } from "../../Context/SessionProvider";
import { logger } from "../../services/logger";
import SaveFabButton from "../Utils/GlobalComponents/SaveFabButton";
import TranslationContent from "../../Translations/TranslationContent";


const SiteCreator = ({ show, validateSiteInputs, sites, updateSites, updateSelectedSite }) => {
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showSaveButton, setShowSaveButton] = React.useState(false);
  const session = React.useContext(SessionContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const [newSite, setNewSite] = React.useState(
    {
      customerName: "",
      customerEmail: "",
      customerPhone: "",
      streetAddress: "",
      postalCode: "",
      propertyName: "",
      propertyType: "",
      city: ""
    }
  );

  const clearNewSiteData = () => {
    let updatedNewSite = {...newSite};
    updatedNewSite.customerName = "";
    updatedNewSite.customerEmail = "";
    updatedNewSite.customerPhone = "";
    updatedNewSite.streetAddress = "";
    updatedNewSite.postalCode = "";
    updatedNewSite.propertyName = "";
    updatedNewSite.propertyType = "";
    updatedNewSite.city = "";
    setNewSite(updatedNewSite);
    setShowDeleteDialog(false);
  }

  const submitNewSite = () => {
    setLoading(true);
    let requestBody = {...newSite};
    requestBody.organizationId = session.activeRole.organizationId;
    requestBody.brandId = session.activeRole.brandId;
    Tokens().then((tokens) => {
      siteService
      .createSite(requestBody, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedNewSite = {...newSite};
        updatedNewSite.id = response.id
        updatedNewSite.brandId = session.activeRole.brandId
        updatedNewSite.organizationId = session.activeRole.organizationId
        let updatedSites = [...sites, updatedNewSite];
        updateSites(updatedSites);
        setNewSite({
          customerName: "",
          customerEmail: "",
          customerPhone: "",
          streetAddress: "",
          postalCode: "",
          propertyName: "",
          propertyType: "",
          city: ""
        });
        setShowSaveButton(false);
        updateSelectedSite("", false);
        setLoading(false);
        addAlert({message: "snackbarSubmitNewSiteSuccess", type: "success"});
      })
      .catch(error => {
        logger(error);
        setLoading(false);
        addAlert({message: "snackbarSubmitNewSiteFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const handleTextFieldChange = (event, type) => {
    let validOrganizationData  = false;
    let updatedNewSite = {...newSite};
    if(type === "newPropertyName") {
      updatedNewSite.propertyName = event.target.value;
    }
    if(type === "newPropertyType") {
      updatedNewSite.propertyType = event.target.value;
    }
    if(type === "newCustomerName") {
      updatedNewSite.customerName = event.target.value;
    }
    if(type === "newCustomerEmail") {
      updatedNewSite.customerEmail = event.target.value;
    }
    if(type === "newCustomerPhone") {
      updatedNewSite.customerPhone = event.target.value;
    }
    if(type === "newAddress") {
      updatedNewSite.streetAddress = event.target.value;
    }
    if(type === "newPostalCode") {
      updatedNewSite.postalCode = event.target.value;
    }
    if(type === "newCity") {
      updatedNewSite.city = event.target.value;
    }
    setNewSite(updatedNewSite);
    validOrganizationData = validateSiteInputs(updatedNewSite);
    setShowSaveButton(validOrganizationData);
  }
  

  if(show) {
    return(
      <>
        <ConfirmDialog open={showDeleteDialog} title="resetTitle" confirmClick={() => clearNewSiteData()} text="resetDesc" handleClose={() => setShowDeleteDialog(false)} />
        <SaveFabButton show={showSaveButton} loading={loading} onClick={() => submitNewSite()} disabled={loading} />
        <ButtonGroup variant="text" sx={{width:"100%"}}>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} onClick={() => updateSelectedSite("", false)} disabled={loading}><KeyboardReturnIcon /><Typography variant="caption" ><TranslationContent contentID="return" /></Typography></Button>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} color="error" onClick={() => setShowDeleteDialog(true)} disabled={loading}><DeleteForeverIcon /><Typography variant="caption" ><TranslationContent contentID="reset" /></Typography></Button>
          <Zoom in={showSaveButton}><Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} onClick={() => submitNewSite()} disabled={loading}>{loading ? <CircularProgress color="primary"/> : <><SaveIcon /><Typography variant="caption" ><TranslationContent contentID="save" /></Typography></>}</Button></Zoom>
        </ButtonGroup>
        <Grid container spacing={2} mt={0} margin={0}>
          <Grid xs={12}><Divider /></Grid>
          <Grid xs={12} mt={1} >
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent  contentID="siteInfo" /></b> <i style={{opacity: 0.7, fontSize: "smaller"}}>(*<TranslationContent contentID="required" />)</i></Typography></Divider>
          </Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="propertyName" />} autoComplete="off" required disabled={loading} fullWidth value={newSite.propertyName} onChange={(event) => handleTextFieldChange(event, "newPropertyName")} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="propertyType" />} autoComplete="off" required disabled={loading} fullWidth value={newSite.propertyType} onChange={(event) => handleTextFieldChange(event, "newPropertyType")} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="propertyOwner" />} autoComplete="off" required disabled={loading} fullWidth value={newSite.customerName} onChange={(event) => handleTextFieldChange(event, "newCustomerName")} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="propertyEmail" />} autoComplete="off" required disabled={loading} fullWidth value={newSite.customerEmail} onChange={(event) => handleTextFieldChange(event, "newCustomerEmail")} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="propertyPhone" />} autoComplete="off" required disabled={loading} fullWidth value={newSite.customerPhone} onChange={(event) => handleTextFieldChange(event, "newCustomerPhone")} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="streetAddress" />} autoComplete="off" required disabled={loading} fullWidth value={newSite.streetAddress} onChange={(event) => handleTextFieldChange(event, "newAddress")} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="postalCode" />} autoComplete="off" required disabled={loading} fullWidth value={newSite.postalCode} onChange={(event) => handleTextFieldChange(event, "newPostalCode")} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="city" />} autoComplete="off" required disabled={loading} fullWidth value={newSite.city} onChange={(event) => handleTextFieldChange(event, "newCity")} /></Grid>
        </Grid>
      </>
    )
  }
}

export default SiteCreator;