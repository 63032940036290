import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography, Box, Paper, IconButton, Tooltip, CircularProgress } from "@mui/material";
import { LanguageContext } from "../../Translations/LanguageProvider";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LoadingSpinner from "../Loading/LoadingSpinner";
import { Tokens } from "../../services/Tokens";
import inspectionService from "../../services/inspections";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { logger } from "../../services/logger";
import TranslationContent from "../../Translations/TranslationContent";
import DeleteIcon from "@mui/icons-material/Delete";


const SiteInspectionsTable = ({ siteId, toggleUpdate, updateUnfinishedInspections }) => {
  const [unfinishedInspections, setUnfinishedInspections] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [deleting, setDeleting] = React.useState("");
  const currentLang = React.useContext(LanguageContext);
  const { addAlert } = React.useContext(SnackBarContext);

  React.useEffect(() => {
    if(updateUnfinishedInspections) {
      fetchInspections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[updateUnfinishedInspections])

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(fetchData.current) {
      fetchData.current = false;
      fetchInspections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchInspections = () => {
    Tokens().then((tokens) => {
      inspectionService
      .getSitesInspections(siteId, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedUnfinishedInspections = [...response];
        setUnfinishedInspections(updatedUnfinishedInspections);
        setLoading(false);
        if(updatedUnfinishedInspections) {
          toggleUpdate();
        }
      })
      .catch(error => {
        logger(error);
        setLoading(false);
        addAlert({message: "snackbarFetchingInspectionsFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const deleteInspection = (id) => {
    setDeleting(id);
    Tokens().then((tokens) => {
      inspectionService
      .deleteInspection(id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedUnfinishedInspections = [...unfinishedInspections];
        updatedUnfinishedInspections = updatedUnfinishedInspections.filter(inspection => inspection.id !== id);
        setUnfinishedInspections(updatedUnfinishedInspections);
        setDeleting("");
        addAlert({message: "snackbarDeleteInspectionSuccess", type: "success"});
      })
      .catch(error => {
        logger(error);
        setDeleting("");
        addAlert({message: "snackbarFetchingInspectionsFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setDeleting("");
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }


  if(loading) return <Box><LoadingSpinner /></Box>;
  if(unfinishedInspections.length === 0 && !loading) return <Typography sx={{opacity: 0.5, mb: 3}}><TranslationContent contentID="noUnfinishedInspections" /></Typography>;

  if(unfinishedInspections.length > 0 && !loading) {
    return(
      <>
        <TableContainer sx={{maxWidth:"90vw", mb: 1}}>
          <Table sx={{borderCollapse: "separate", borderSpacing: "0 1em", WebkitBorderRadius: 5}}>  
            <TableBody>
              {unfinishedInspections.map(inspection => 
                <TableRow component={Paper} square={false} elevation={24} key={inspection.id}>
                  <TableCell><><b>{inspection.name[currentLang.lang]}</b><Typography variant="caption" sx={{opacity:0.6}} > {inspection.inspector.name !== "" ? <i>({inspection.inspector.name})</i> :null}</Typography></></TableCell>
                  <TableCell>
                  <StatusBox inspection={inspection}/>
                  </TableCell>
                  <TimeCell inspection={inspection} />
                  <TableCell>
                    {deleting === inspection.id ? 
                    <CircularProgress color="error" />
                    :
                    <Tooltip title={<TranslationContent contentID="deleteInspection" />} arrow >
                    <IconButton color="error" onClick={() => deleteInspection(inspection.id)}><DeleteIcon /></IconButton>
                    </Tooltip>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }
}

export default SiteInspectionsTable;


const TimeCell = ({ inspection }) => {
  let timedTime = new Date();
  let timeNow = new Date();
  let saved = new Date();
  let created = new Date(inspection.created).getTime()
  
  if(inspection.timed !== "") {
    let getTimedTime = new Date(inspection.timed);

    timedTime = getTimedTime
    let getTimeNow = new Date().getTime();
    timeNow = getTimeNow;
  }
  if(inspection.saved !== "") {
    let getSavedTime = new Date(inspection.saved);
    saved = getSavedTime;
  }
  
  if(inspection.timed !== "" && !inspection.inspectionStarted && timeNow > timedTime) {
    return(
      <TableCell>
        <Typography variant="caption"><b>{ Math.round( ( ( timeNow - timedTime ) / ( 1000 * 3600 * 24 ) ) ) }</b> <TranslationContent contentID="daysLate" /></Typography> <Typography variant="caption" sx={{opacity:0.6}} >({timedTime.toLocaleDateString()})</Typography>
      </TableCell>
    )
  }
  if(!inspection.inspectionStarted && inspection.timed !== "") {
    return(
      <TableCell>
        <Typography variant="caption"><TranslationContent contentID="timed" /> <b>{ Math.round( ( ( timedTime - timeNow ) / ( 1000 * 3600 * 24 ) ) ) }</b> <TranslationContent contentID="toDays" /> </Typography><Typography variant="caption" sx={{opacity:0.6}} >({timedTime.toLocaleDateString()})</Typography>
      </TableCell>
    )
  }
  if(!inspection.inspectionStarted && inspection.timed === "") {
    return(
      <TableCell>
        <Typography variant="caption"><TranslationContent contentID="created" /> <b>{`${Math.round( ( ( timeNow - created  ) / ( 1000 * 3600 * 24 ) ) )} `}</b> <TranslationContent contentID="daysAgo" /></Typography><Typography variant="caption" sx={{opacity:0.6}} > ({new Date(created).toLocaleDateString()})</Typography>
      </TableCell>
    )
  }
  if(inspection.inspectionStarted) {
    return(
      <TableCell>
        <Typography variant="caption"><TranslationContent contentID="lastEdit" /> {timeNow - saved.getTime() > 0 ? <>{Math.round( ( ( timeNow - saved.getTime() ) / ( 1000 * 3600 * 24 ) ) )} {<TranslationContent contentID="daysAgo" />}</>  : <TranslationContent contentID="today" />}</Typography><Typography variant="caption" sx={{opacity:0.6}} > ({saved.toLocaleDateString()})</Typography>
      </TableCell>
    )
  }
}


const StatusBox = ({ inspection }) => {
  let timedTime;
  let timeNow;
  if(inspection.timed !== "") {
    let timedTime1 = new Date(inspection.timed).getTime();
    timedTime = timedTime1
    let timeNow1 = new Date().getTime();
    timeNow = timeNow1;
  }
  if(inspection.timed !== "" && !inspection.started && timeNow > timedTime) {
    return(
      <Box sx={{width: "100%", padding: "2px", textAlign: "center", borderRadius: "5px", color: "white", maxWidth: "150px", minWidth: "100px"}} bgcolor={"#f44336"}><b><TranslationContent contentID="late" /></b></Box>
    )
  }
  if(!inspection.started && inspection.timed !== "") {
    return(
      <Box sx={{width: "100%", padding: "2px", textAlign: "center", borderRadius: "5px", color: "white", maxWidth: "150px", minWidth: "100px"}}>
      <PendingActionsIcon color="primary" fontSize="large"/>
      </Box>
    )
  }
  if(!inspection.inspectionStarted && inspection.timed === "") {
    return(
      <Box sx={{width: "100%", padding: "2px", textAlign: "center", borderRadius: "5px", color: "white", maxWidth: "150px", minWidth: "100px"}} bgcolor={"grey"}><b><TranslationContent contentID="notStarted" /></b></Box>
    )
  }
  if(inspection.inspectionStarted) {
    return(
      <Box sx={{width: "100%", padding: "2px", textAlign: "center", borderRadius: "5px", color: "white", maxWidth: "150px", minWidth: "100px"}} bgcolor={"burlywood"}><b><TranslationContent contentID="inProgress" /></b></Box>
    )
  }
}