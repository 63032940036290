import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { SnackBarProvider } from './Context/SnackbarProvider';
import LanguageProvider from './Translations/LanguageProvider';
import BrightnessThemeProvider from './Theme/BrightnessThemeProvider';
import SessionProvider from "./Context/SessionProvider";
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import ViewportProvider from './Context/ViewportProvider';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { PublicClientApplication } from '@azure/msal-browser';

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <SessionProvider>
        <BrightnessThemeProvider>
          <LanguageProvider>
            <CssBaseline />
            <SnackBarProvider>
              <ViewportProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </ViewportProvider>
            </SnackBarProvider>
          </LanguageProvider>
        </BrightnessThemeProvider>
      </SessionProvider>
    </MsalProvider>
  </React.StrictMode>
);


