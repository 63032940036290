import React, { useEffect } from "react"
import { ThemeProvider, createTheme } from '@mui/material/styles';

export const BrightnessThemeStateContext = React.createContext();
export const BrightnessThemeDispatchContext = React.createContext();

const initialState = {
  theme: "light",
  innerTheme: "Pisko",
};

function switchThemeReducer(state, action) {
  switch(action.type) {
    case "SWITCH_THEME_LIGHT": {
      return {
        ...state,
        theme: "light"
      }
    }
    case "SWITCH_THEME_DARK": {
      return {
        ...state,
        theme: "dark"
      }
    }
    case "SWITCH_THEME_PISKO": {
      return {
        ...state,
        innerTheme: "Pisko"
      }
    }
    case "SWITCH_THEME_RUUKKI": {
      return {
        ...state,
        innerTheme: "Ruukki"
      }
    }
    default:
      throw new Error('Bad Action Type')
  }
}

 const checkLocalStorageTheme = (initialState)  => {
  const localTheme = localStorage.getItem("themeMode");

  const currentUrl = window.location.hostname;
  let innerTheme = "Pisko"
  
  if(currentUrl.includes("localhost")) {
    innerTheme = "Pisko";
  }
  if(currentUrl.includes("piristeel")) {
    innerTheme = "Pisko";
  }
  if(currentUrl.includes("ruukki")) {
    innerTheme = "Ruukki"
  }
 
  if(localTheme === "light" || localTheme === "dark") {
    return {theme: localTheme, innerTheme: innerTheme};
  }
  else if(localTheme !== "light" || localTheme !== "dark"){
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
      if(prefersDarkMode) {
        return {theme: "dark",  innerTheme: innerTheme}
    }
  }
  return initialState;
}

const BrightnessThemeProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(switchThemeReducer, initialState, checkLocalStorageTheme)
  
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: state.theme,
          /*
          primary: {
            main: '#fc2516',
          },
          error: {
            main: '#ff9800',
          },
    
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                //backgroundColor: theme.palette.background.default,
                backgroundColor: state.theme === "dark" ? "#121212" : "rgb(255, 255, 255)",
                borderBottom: "2px solid #fc2516",
              }
            }
          },
          MuiAvatar: {
            styleOverrides: {
              colorDefault: {
                background: "#fc2516",
                //borderBottom: "2px solid #fc2516",
              }
            }
          },*/
        }
      }),
    [state],
  );

  //console.log("theme is: " + state.theme)
  useEffect(() => {
    localStorage.setItem("themeMode", state.theme);
  }, [state]);

  const innerThemeStyles = state.innerTheme === "Pisko" ? {
    palette: {
      mode: state.theme,
      primary: {
        main: '#009fe3'
      },
      error: {
        main: "#f44336",
        disabled: "rgba(255, 255, 255, 0.3)",
      }
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: theme.palette.background.default,
            borderBottom: "3px solid #009fe3",
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            background: "#009fe3",
            //color: "#009fe3",
            //borderBottom: "2px solid #fc2516",
            
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              color: "rgba(255, 255, 255, 0.3)",
            }
          }
        }
      }
    }
  }
  :
  {
    palette: {
      mode: state.theme,
      primary: {
        main: '#fc2516',
      },
      error: {
        main: '#ff9800',
      },

    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: theme.palette.background.default,
            borderBottom: "2px solid #fc2516",
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            background: "#fc2516",
            //borderBottom: "2px solid #fc2516",
          }
        }
      },
    }
  }

  const innerTheme = 
    () =>
  createTheme({
    ...theme,
    ...innerThemeStyles,
  })

  return(
    <BrightnessThemeStateContext.Provider value={state}>
      <BrightnessThemeDispatchContext.Provider value={dispatch}>
      <ThemeProvider theme={theme}>
          <ThemeProvider theme={state.innerTheme !== "default" ? innerTheme : theme}>
            {children}
          </ThemeProvider>
         </ThemeProvider>
      </BrightnessThemeDispatchContext.Provider>
    </BrightnessThemeStateContext.Provider>
  )
}

export default BrightnessThemeProvider;