import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const createInspection = async (data, accessToken, idToken) => {
  const request = axios.post(`${baseUrl}/inspection`, data, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const getInspection = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/inspection/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const getUsersInspections = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/inspection/?organization=${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const getUsersNewInspections = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/inspection/?organization=${id}&filter=new`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const getSitesInspections = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/inspection/?site=${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const updateInspection = async (data, accessToken, idToken) => {
  const request = axios.put(`${baseUrl}/inspection/${data.id}`, data, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const startNewInspection = async (id, accessToken, idToken) => {
  const request = axios.put(`${baseUrl}/inspection/${id}?action=start`, {}, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

// Old way but removed the return of pdf file as requested by client. If nothing has happened, remove after year 2024
/*
const finishInspection = async (id, accessToken, idToken) => {
  const request = axios.put(`${baseUrl}/inspection/${id}?action=finish`, {}, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey }, responseType: 'arraybuffer', });

  return request.then(response => response.data);
}
*/

const finishInspection = async (id, accessToken, idToken) => {
  const request = axios.put(`${baseUrl}/inspection/${id}?action=finish`, {}, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const deleteInspection = async (id, accessToken, idToken) => {
  const request = axios.delete(`${baseUrl}/inspection/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const inspectionServices =
  { 
    createInspection, 
    getInspection, 
    getUsersInspections, 
    getUsersNewInspections, 
    getSitesInspections, 
    updateInspection, 
    startNewInspection, 
    finishInspection, 
    deleteInspection 
  };
export  default inspectionServices;