import React from "react";
import { TableRow, TableCell, Avatar } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useViewport } from "../../Context/ViewportProvider";
import { BrightnessThemeStateContext } from "../../Theme/BrightnessThemeProvider";


const SiteRow = ({ site, rowClick }) => {
  const { width } = useViewport();
  const currentTheme = React.useContext(BrightnessThemeStateContext);

  return(
    <TableRow 
      onClick={() => rowClick()} 
      sx={{
        cursor: "pointer", 
        '&:hover': {
          backgroundColor: currentTheme.theme === "light" ? "rgba(0, 0, 0, 0.04)" : "#121212",
        },
      }} 
    >
      <TableCell>
        <Avatar>
          <HomeIcon />
        </Avatar>
      </TableCell>
      <TableCell component="th" scope="row" >
        {site.propertyName}
      </TableCell>
      <TableCell component="th" scope="row" >
        {site.customerName}
      </TableCell>
      {width > 700 &&
        <>
          <TableCell align="right" >{site.streetAddress}</TableCell>
          <TableCell align="right" >{site.postalCode}</TableCell>
          <TableCell align="right" >{site.city}</TableCell>
        </>
      }
    </TableRow>
  )
}

export default SiteRow;