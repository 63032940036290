import React from "react";
import RuukkiLogo from "../../../images/ruukki-header.png";
import PiskoLogo from "../../../images/Piristeel-avainlippu-logo-300x104.png";

const CompanyLogo = ({ width, showAppLogo }) => {
  const domain = window.location.hostname;
  if(domain.includes("ruukki") && showAppLogo) {
    return(
      <img src={RuukkiLogo} alt="Ruukki's orange Logo with text Ruukki" width={width} height="auto" />
    )
  }
  if (domain.includes("piristeel") && showAppLogo){
    return(
      <img src={PiskoLogo} alt="Piristeel Company's blue logo with text Piristeel" width={width} height="auto" />
    )
  }
  if (domain.includes("orange") && showAppLogo){
    return(
      <img src={PiskoLogo} alt="Piristeel Company's blue logo with text Piristeel" width={width} height="auto" />
    )
  }
  if (domain.includes("localhost") && showAppLogo){
    return(
      <img src={PiskoLogo} alt="Piristeel Company's blue logo with text Piristeel" width={width} height="auto" />
    )
  }
  if(domain.includes("ruukki") && !showAppLogo) {
    return(
      <img src={RuukkiLogo} alt="Ruukki's orange Logo with text Ruukki" width={width} height="auto" style={{marginRight: "20px"}} />
    )
  }
  if (domain.includes("piristeel") && !showAppLogo){
    return(
      <img src={PiskoLogo} alt="Piristeel Company's blue logo with text Piristeel" width={width} height="auto" style={{marginRight: "20px"}} />
    )
  }
  if (domain.includes("orange") && !showAppLogo){
    return(
      <img src={PiskoLogo} alt="Piristeel Company's blue logo with text Piristeel" width={width} height="auto" style={{marginRight: "20px"}} />
    )
  }
  if (domain.includes("localhost") && !showAppLogo){
    return(
      <img src={PiskoLogo} alt="Piristeel Company's blue logo with text Piristeel" width={width} height="auto" style={{marginRight: "20px"}} />
    )
  }
}

export default CompanyLogo;