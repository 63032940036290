import React from "react";
import { Box, Button } from "@mui/material";
import CompanyLogo from "../Utils/GlobalComponents/CompanyLogo";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import LogoutIcon from "@mui/icons-material/Logout";
import TranslationContent from "../../Translations/TranslationContent";
import { msalInstance } from "../..";
import { Tokens } from "../../services/Tokens";
import sessionService from "../../services/sessions";
import { SessionContext, SessionDispatchContext } from "../../Context/SessionProvider";
import RoleStatus from "./RoleStatus";
import AccountSelector from "./AccountSelector";
import LoginStatus from "./LoginStatus";
import ErrorStatus from "./ErrorStatus";
import { logger } from "../../services/logger";


const Roles = () => {
  const { instance, accounts } = useMsal();
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const [activeAccount, setActiveAccount] = React.useState({});
  const [activeAccountSelected, setActiveAccountSelected] = React.useState(false);
  const [roles, setRoles] = React.useState([])
  const [tempSession, setTempSession] = React.useState({});
  const [stateError, setStateError] = React.useState({activeError: false, code: "", body: "",})
  
  const navigate = useNavigate();

  logger(accounts);

  const handleLogout = () => {
    instance.logoutRedirect({
        postLogoutRedirectUri: "/",
    });
    sessionStorage.clear();
  }

  const fetchRoles = (account) => {
    Tokens().then((tokens) => {
      sessionService
      .getSession(tokens.accessToken, tokens.idToken)
      .then(response => {
        if(response.roles.length > 0) {
          const updatedSession = {...session};
          updatedSession.name = response.name;
          updatedSession.workEmail = response.workEmail;
          updatedSession.workPhone = response.workPhone;
          updatedSession.profilePicture = response.profilePicture;
          updatedSession.preferredBrand = response.preferredBrand;
          updatedSession.preferredRoleId = response.preferredRoleId;
          updatedSession.preferences = [...response.preferences];
          updatedSession.newInspections = response.newInspections;
          updatedSession.unfinishedInspections = response.unfinishedInspections;
          updatedSession.applications = response?.applications;
          updatedSession.userCount = response?.userCount
          updatedSession.roles = [...response.roles];
          updatedSession.brands = [...response.brands];

          if(response.roles.length === 1) {
            updatedSession.activeRole = {...response.roles[0]};
            setActiveAccountSelected(true);
            updateSession({type: "UPDATE_SESSION", value: updatedSession})
            navigate("/home");
          }
          if(response.preferredRoleId !== "") {
            let preferredRole = response.roles.find(role => role.id === response.preferredRoleId)
            setActiveAccountSelected(true);
            updatedSession.activeRole = {...preferredRole};
            updateSession({type: "UPDATE_SESSION", value: updatedSession})
            navigate("/home");
          }
          if(response.roles.length > 1 && response.preferredRoleId === "") {
            setRoles([...response.roles]);
            setTempSession(updatedSession);
          }
        }
      })
      .catch(error => {
        logger(error);
        let newError = {...stateError};
        if(error.response.status === 404 && error.response.data === "E404A1") {
          logger("No user but application yes")
          newError.activeError = true;
          newError.code = error.response.status;
          newError.body = error.response.data;
        }
        if(error.response.status === 404 && error.response.data === "E404A2") {
          logger("No user or application");
          newError.activeError = true;
          newError.code = error.response.status;
          newError.body = error.response.data;
        }
        if(error.response.status === 404 && error.response.data === "E404A3") {
          logger("User without roles found!");
          newError.activeError = true;
          newError.code = error.response.status;
          newError.body = error.response.data;
        }
        if(error.response.status !== 404) {
          newError.activeError = true;
          newError.code = error.response.status;
          newError.body = error.response.statusText;
        }
        setStateError(newError);
      })
    })
    .catch(error => {
      logger(error);
      let newError = {...stateError};
      newError.activeError = true;
      newError.code = error.response.status;
      newError.body = error.response.statusText;
      setStateError(newError);
    })
  }

  const updateActiveAccount = (account) => {
    let updatedAccount = {...activeAccount};
    updatedAccount = account;
    setActiveAccount(updatedAccount);
    setActiveAccountSelected(true);
    msalInstance.setActiveAccount(account);
  }

  const updateActiveRole = (role) => {
    let updatedSession = {...session};
    updatedSession.activeRole = {...role};
    updatedSession.name = tempSession.name;
    updatedSession.workEmail = tempSession.workEmail;
    updatedSession.workPhone = tempSession.workPhone;
    updatedSession.profilePicture = tempSession.profilePicture;
    updatedSession.preferredBrand = tempSession.preferredBrand;
    updatedSession.preferredRoleId = tempSession.preferredRoleId;
    updatedSession.preferences = [...tempSession.preferences];
    updatedSession.newInspections = tempSession.newInspections;
    updatedSession.unfinishedInspections = tempSession.unfinishedInspections;
    updatedSession.applications = tempSession?.applications;
    updatedSession.userCount = tempSession?.userCount
    updatedSession.roles = [...tempSession.roles];
    updatedSession.brands = [...tempSession.brands];
    updateSession({type: "UPDATE_SESSION", value: updatedSession});
    navigate("/home");
  }

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(accounts.length === 1 && fetchData.current) {
      fetchData.current = false;
      msalInstance.setActiveAccount(accounts[0]);
      setActiveAccount(accounts[0])
      setActiveAccountSelected(true);
      fetchRoles(accounts[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if(accounts.length < 1) return <NotFound />
  
  if(accounts.length > 0) {
    return (
      <Box width={"100vw"} height={"100vh"} sx={{padding: "5% 5% 5% 5%", textAlign: "center"}}>
        <Box mb={"60px"}>
          <CompanyLogo showAppLogo={true} width={270} />
        </Box>
        <ErrorStatus error={stateError} activeAccount={activeAccount} />
        <LoginStatus accounts={accounts} activeAccount={activeAccount} activeAccountSelected={activeAccountSelected} error={stateError.activeError} />
        <RoleStatus activeAccount={activeAccount} activeAccountSelected={activeAccountSelected} roles={roles} error={stateError} updateActiveRole={updateActiveRole} />
        <AccountSelector accounts={accounts} activeAccountSelected={activeAccountSelected} updateActiveAccount={updateActiveAccount} error={stateError.activeError} />
        <br />
        <Button variant="outlined" size="small" sx={{marginTop: "25px"}} onClick={() => handleLogout()}><LogoutIcon fontSize="small"/>&nbsp;&nbsp;<TranslationContent contentID="logOutButton"/></Button>
      </Box>
    )
  }
}

export default Roles;