import React from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Typography, Paper, Chip, Stack } from "@mui/material";
import Error from "../GeneralComponents/Error";
import Loading from "../GeneralComponents/Loading";
import TranslationContent from "../../Translations/TranslationContent";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import InspectionFormTable from "./InspectionFormTable";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../Context/SessionProvider";
import { Tokens } from "../../services/Tokens";
import formService from "../../services/forms";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { logger } from "../../services/logger";


const InspectionForm = () => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [creationMode, setCreationMode] = React.useState(false);
  const [formName, setFormName] = React.useState("");
  const [forms, setForms] = React.useState([]);
  const session = React.useContext(SessionContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const navigate = useNavigate();
  
  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    if(fetchData.current && session.roles.length > 0) {
      fetchData.current = false;
      fetchForms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchForms = () => {
    Tokens().then((tokens) => {
      formService
      .getForms(session.activeRole.organizationId, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedForms = [...forms];
        updatedForms = [...response];
        setForms(updatedForms);
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarFetchingInspectionFormsFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const retryClick = () => {
    setLoading(true);
    setError(false);
    fetchForms();
  }

  const updateSelectedForm = (formName, isNew) => {
    if(isNew) {
      setFormName("");
      setCreationMode(true);
    }
    else {
      setCreationMode(false);
      setFormName(formName)
    }
  }

  const navigateClick = () => {
    setCreationMode(false);
    setFormName("");
    navigate("/form");
  }

  if(error && !loading) return <Error section="inspectionTemplate" retryClick={retryClick}/>;
  if(loading && !error) return <Loading section="inspectionTemplate" />;

  if(!error && !loading) {
    return(
      <MainLayout>
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12}>
            {formName !== "" ?
              <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigateClick()} ><TranslationContent contentID="inspectionTemplate" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />{formName}</Typography>
            :
              (creationMode ?
                <Stack direction="row">
                  <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigateClick()} >
                    <TranslationContent contentID="inspectionTemplate" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
                  </Typography>
                  <Chip color="primary" size="small" label={<b style={{color: "yellow"}}><TranslationContent contentID="new" /></b>} />
                </Stack>   
              :
                <Typography color="primary" variant="body2"><b><TranslationContent contentID="inspectionTemplate" /></b></Typography>
              )
            }
            </Grid>
          </Grid>
        </Paper>
        <InspectionFormTable updateSelectedForm={updateSelectedForm} creationMode={creationMode} forms={forms} />
      </MainLayout>
    )
  }
}

export default InspectionForm;