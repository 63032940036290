import RuukkiFavicon from "../src/images/faviconRuukki.ico";
import PiskoFavicon from "../src/images/faviconPisko.ico";

export function appInitialization() {
    const favicon = getFaviconEl();
    const faviconApple = geAppletFaviconEl();
    favicon.href = PiskoFavicon;
    faviconApple.href = PiskoFavicon;

    const domain = window.location.hostname;
    if(domain === "localhost") {
      favicon.href = PiskoFavicon;
      faviconApple.href = PiskoFavicon;
      console.log(`
######  ### ######  ###  #####  ####### ####### ####### #       
#     #  #  #     #  #  #     #    #    #       #       #       
#     #  #  #     #  #  #          #    #       #       #       
######   #  ######   #   #####     #    #####   #####   #       
#        #  #   #    #        #    #    #       #       #       
#        #  #    #   #  #     #    #    #       #       #       
#       ### #     # ###  #####     #    ####### ####### ####### `);
    }
    if(domain.includes("ruukki")) {
      favicon.href = RuukkiFavicon;
      faviconApple.href = RuukkiFavicon;
      console.log(`
######  #     # #     # #    # #    # ### 
#     # #     # #     # #   #  #   #   #  
#     # #     # #     # #  #   #  #    #  
######  #     # #     # ###    ###     #  
#   #   #     # #     # #  #   #  #    #  
#    #  #     # #     # #   #  #   #   #  
#     #  #####   #####  #    # #    # ### `);
    }
    if(domain.includes("piristeel")) {
      favicon.href = PiskoFavicon;
      faviconApple.href = PiskoFavicon;
      console.log(`
######  ### ######  ###  #####  ####### ####### ####### #       
#     #  #  #     #  #  #     #    #    #       #       #       
#     #  #  #     #  #  #          #    #       #       #       
######   #  ######   #   #####     #    #####   #####   #       
#        #  #   #    #        #    #    #       #       #       
#        #  #    #   #  #     #    #    #       #       #       
#       ### #     # ###  #####     #    ####### ####### ####### `);
    }
}

function getFaviconEl() {
  return document.getElementById("favicon");
}

function geAppletFaviconEl() {
  return document.getElementById("apple-favicon");
}