import React from "react";
import { Typography, Paper, Button, TextField, Box, Table, TableContainer, TableCell, TableBody, TableHead, TableRow, TablePagination } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import { useViewport } from "../../Context/ViewportProvider";
import AddIcon from '@mui/icons-material/Add';
import { LanguageContext } from "../../Translations/LanguageProvider";
import { useNavigate } from "react-router-dom";
import InspectionFormRow from "./InspectionFormRow";
import TranslationContent from "../../Translations/TranslationContent";


const InspectionFormTableContent = ({ show, forms, updateSelectedForm }) => {
  const [searchWord, setSearchWord] = React.useState("");
  const [filteredForms, setFilteredForms] = React.useState([])
  const currentLang = React.useContext(LanguageContext);
  const { width } = useViewport();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowClick = (id) => {
    navigate(`/form/${id}`)
  }

  const handleFilterWordChange = (event) => {
    setSearchWord(event.target.value);
    let updatedFilteredForms = [...forms];
    if(event.target.value === "") {
      updatedFilteredForms = [...forms];
    }
    else {
      updatedFilteredForms = updatedFilteredForms.filter(form => form.name[currentLang.lang].toLowerCase().includes(event.target.value.toLowerCase()) || form.owner.toLowerCase().includes(event.target.value.toLowerCase()) || form.creator.name.toLowerCase().includes(event.target.value.toLowerCase()))
    }
    setFilteredForms(updatedFilteredForms);
  }

  if(show) {
    return(
      <Paper sx={{padding: "7px", width: "100%", textAlign: "center",}}>
        <Grid container spacing={2} >
          <Grid xs={12} md={2} sx={{alignSelf: "center"}}>
            <Button variant="contained" fullWidth onClick={() => updateSelectedForm("", true)}><AddIcon />&nbsp;&nbsp;<TranslationContent contentID="add" /></Button>
          </Grid>
          <Grid xs={12} md={10}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: width > 750 ? 5 : 0, marginLeft: width > 750 ? "40%" : 0, marginBottom: "15px", }}>
              <SearchIcon sx={{  mr: 1, my: 0.5 }} />
              <TextField label={<TranslationContent contentID="whatFormRULooking4" />} value={searchWord} onChange={event => handleFilterWordChange(event)} variant="standard" fullWidth  autoComplete="off" />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box>
              <TableContainer sx={{width: "99%", height: "110%", mt: 2}}>
                <Table aria-label="collapsible table" size="small" sx={{width: "99%",}}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell><b><TranslationContent contentID="name" /></b></TableCell>
                      <TableCell align="right"><b><TranslationContent contentID="owner" /></b></TableCell>
                      {width > 700 &&
                        <>
                          <TableCell align="right"><b><TranslationContent contentID="creator" /></b></TableCell>
                          <TableCell align="right"><b><TranslationContent contentID="lastEdit" /></b></TableCell>
                        </>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchWord === "" ?
                      forms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((form) => (
                        <InspectionFormRow key={form.id} form={form} rowClick={() => rowClick(form.id)} />
                      ))
                    :
                      filteredForms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((form) => (
                        <InspectionFormRow key={form.id} form={form} rowClick={() => rowClick(form.id)} />
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={searchWord !== "" ? filteredForms.length : forms.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={<TranslationContent contentID="rowsPerPage" />}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton={width > 600 ? true : false}
                showLastButton={width > 600 ? true : false}
                labelDisplayedRows={
                  ({ from, to, count }) => {
                    return ` ${from}–${to} / ${count}`
                  }
                }
             />
            </Box>
          </Grid>
        </Grid>
        {forms.length < 1 &&
          <Typography sx={{opacity: 0.5, mb: 3, mt: 3}}><TranslationContent contentID="noInspectionFormsFound" /></Typography>
        }
      </Paper>
    )
  }
}

export default InspectionFormTableContent;