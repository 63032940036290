import React from "react";
import { TableRow, TableCell, Avatar } from "@mui/material";
import { useViewport } from "../../Context/ViewportProvider";
import { BrightnessThemeStateContext } from "../../Theme/BrightnessThemeProvider";
import DomainIcon from '@mui/icons-material/Domain';
import FactoryIcon from '@mui/icons-material/Factory';


const OrganizationRow = ({ organization, rowClick }) => {
  const { width } = useViewport();
  const currentTheme = React.useContext(BrightnessThemeStateContext);

  return(
    <TableRow 
      sx={{
        cursor: "pointer", 
        '&:hover': {
          backgroundColor: currentTheme.theme === "light" ? "rgba(0, 0, 0, 0.04)" : "#121212",
        },
      }} 
      onClick={() => rowClick()} 
    >
      <TableCell>
        {organization.logo !== "" ? 
          <Avatar src={organization.logo} />
        :
          <Avatar>{organization.type === "internal" ? <FactoryIcon /> : <DomainIcon />}</Avatar>
        }
      </TableCell>
      <TableCell component="th" scope="row">
        {organization.name}
      </TableCell>
      <TableCell align="right">{organization.streetAddress}</TableCell>
      {width > 600 && 
        <>
          <TableCell align="right">{organization.postalCode}</TableCell>
          <TableCell align="right">{organization.city}</TableCell>
        </>
      }
    </TableRow>
  )
}

export default OrganizationRow;