import React from "react";
import GlobalFooter from "../Components/Footers/GlobalFooter";
import MainHeader from "../Components/Headers/MainHeader";
import { Box } from "@mui/material";


const MainLayout = ({ children }) => {
  return(
    <MainHeader >
      {children}
      <Box sx={{textAlign: "center"}}>
        <GlobalFooter />
      </Box>
    </MainHeader>
  )
}

export default MainLayout;