import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import { Box } from "@mui/material";


const LoadingScreen = () => {
  return(
    <Box sx={{mt: 50, textAlign: "center"}}>
      <LoadingSpinner />
    </Box>
  )
}

export default LoadingScreen;