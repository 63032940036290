import React from "react";
import { Paper, Button, Box, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import AddHomeIcon from "@mui/icons-material/AddHome";
import { useViewport } from "../../Context/ViewportProvider";
import SiteRow from "./SiteRow";
import TranslationContent from "../../Translations/TranslationContent";


const SiteTableContent = ({ show, sites, rowClick }) => {
  const [filteredSites, setFilteredSites] = React.useState([]);
  const [searchWord, setSearchWord] = React.useState("");
  const { width } = useViewport();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  

  const filterSites = (event) => {
    setSearchWord(event.target.value);
    let updatedFilteredSites = [...sites];
    if(event.target.value === "") {
      updatedFilteredSites = [...sites];
    }
    else {
      updatedFilteredSites = updatedFilteredSites.filter(site => site.customerName.toLowerCase().includes(event.target.value.toLowerCase()) || site.streetAddress.toLowerCase().includes(event.target.value.toLowerCase()))
    }
    setFilteredSites(updatedFilteredSites);
  }

  if(show) {
    return(
      <Paper sx={{padding: "7px", width: "100%",}}>
        <Grid container spacing={2} >
            <Grid xs={12} md={2} sx={{alignSelf: "center"}}>
          <Button variant="contained" fullWidth onClick={() => rowClick({}, true)}><AddHomeIcon />&nbsp;&nbsp;<TranslationContent contentID="add" /></Button>
          </Grid>
          <Grid xs={12} md={10}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: 5, marginLeft: width > 750 ? "40%" : 5, marginBottom: "15px", }}>
          <SearchIcon sx={{  mr: 1, my: 0.5 }} />
          <TextField label={<TranslationContent contentID="whatSiteRULooking4" />} value={searchWord} onChange={event => filterSites(event)} variant="standard" fullWidth  />
          </Box>
          </Grid>
          </Grid>
          <Box>
            <TableContainer sx={{width: "99%", height: "110%", mt: 2}}>
              <Table aria-label="collapsible table" size="small" sx={{width: "99%",}}>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell><b><TranslationContent contentID="propertyName" /></b></TableCell>
                    <TableCell><b><TranslationContent contentID="customer" /></b></TableCell>
                    {width > 700 ? 
                    <>
                    <TableCell align="right"><b><TranslationContent contentID="streetAddress" /></b></TableCell>
                    <TableCell align="right"><b><TranslationContent contentID="postalCode" /></b></TableCell>
                    <TableCell align="right"><b><TranslationContent contentID="city" /></b></TableCell>
                    </>
                    :null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchWord !== "" ? 
                    filteredSites.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(site => 
                      <SiteRow key={site.id} site={site} rowClick={() => rowClick(site, false)} />
                    )
                  :
                    sites.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((site) => (
                      <SiteRow key={site.id} site={site} rowClick={() => rowClick(site, false)} />
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={searchWord !== "" ? filteredSites.length : sites.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={<TranslationContent contentID="rowsPerPage" />}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton={width > 600 ? true : false}
              showLastButton={width > 600 ? true : false}
              labelDisplayedRows={
                ({ from, to, count }) => {
                  return ` ${from}–${to} / ${count}`
                }
              }
            />
            </Box>
            {sites.length < 1 &&
              <Typography sx={{opacity: 0.5, mb: 3, mt: 3, textAlign: "center"}}><TranslationContent contentID="noSitesFound" /></Typography>
            }
        </Paper>
    )
  }
}

export default SiteTableContent;