import React from 'react';
import { Route, Routes } from 'react-router-dom'
import './App.css';
import Login from './Components/Login/Login';
import Home from './Components/Home/Home';
import Site from './Components/Site/Site';
import Users from './Components/Users/User/Users';
import Report from './Components/Report/Report';
import InspectionForm from './Components/Form/InspectionForm';
import NotFound from './Components/NotFound/NotFound';
import InspectNew from './Components/Inspect/New/InspectNew';
import InspectCreateNew from './Components/Inspect/New/InspectCreateNew';
import InspectSelectNew from './Components/Inspect/New/InspectSelectNew';
import InspectContinue from './Components/Inspect/InspectContinue';
import InspectActive from './Components/Inspect/Active/InspectActive';
import InspectArchive from './Components/Inspect/Archive/InspectArchive';
import Organization from './Components/Organization/Organization';
import Account from './Components/Account/Account';
import DownloadDocument from './Components/DownloadDocument/DownloadDocument';
import Registrations from './Components/Users/Registration/Registrations';
import UserList from './Components/Users/User/UserList';
import Roles from './Components/Roles/Roles';
import FormEditor from './Components/Form/Editor/FormEditor';
import { loginRequest } from './authConfig';
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { appInitialization } from './initializeApp';
import LoadingScreen from './Components/Loading/LoadingScreen';
  

function App() {

  appInitialization();

  return (
    <Routes>
      <Route 
        index
        element={<Login />}
      />
      <Route 
        path="/home" 
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <Home />
          </MsalAuthenticationTemplate>}
      />
      <Route 
        path="/account" 
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <Account />
          </MsalAuthenticationTemplate>}
      />
      <Route 
        path="/organization" 
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <Organization />
          </MsalAuthenticationTemplate>}
      />
      <Route 
        path="/site" 
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <Site />
          </MsalAuthenticationTemplate>
        }
      />
      <Route 
        path="/users" 
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <Users />
          </MsalAuthenticationTemplate>
        }
      />
      <Route 
        path="/users/registrations" 
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <Registrations />
          </MsalAuthenticationTemplate>
        }
      />
      <Route 
        path="/users/list" 
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <UserList />
          </MsalAuthenticationTemplate>
        }
      />
      <Route 
        path="/report" 
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <Report />
          </MsalAuthenticationTemplate>
        }
      />
      <Route 
        path="/form" 
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <InspectionForm />
          </MsalAuthenticationTemplate>
        }
      />
      <Route
        path="/form/:id"
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <FormEditor />
          </MsalAuthenticationTemplate>
        }
      />
      <Route
        path="/inspect/new"
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <InspectNew />
          </MsalAuthenticationTemplate>
        }
      />
      <Route
        path="/inspect/new/create"
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <InspectCreateNew />
          </MsalAuthenticationTemplate>
        }
      />
      <Route
        path="/inspect/new/select"
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <InspectSelectNew />
          </MsalAuthenticationTemplate>
        }
      />
      <Route
        path="/inspect/active"
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <InspectContinue />
          </MsalAuthenticationTemplate>
        }
      />
      <Route
        path="/inspect/active/:id"
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <InspectActive />
          </MsalAuthenticationTemplate>
        }
      />
      <Route
        path="/inspect/archive"
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <InspectArchive />
          </MsalAuthenticationTemplate>
        }
      />
      <Route
        path="*"
        element={<NotFound />} 
      />
      <Route
        path="/document/:id"
        element={<DownloadDocument />}
      />
       <Route
        path="/roles"
        element={
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest}
            loadingComponent={LoadingScreen}
          >
            <Roles />
          </MsalAuthenticationTemplate>
        }
      />
    </Routes>
  )
}

export default App;
