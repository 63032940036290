import React from "react";
import { TextField } from "@mui/material";
import TranslationContent from "../../../Translations/TranslationContent";


const WriteTextField = ({ label, value, variant, multiline, onChange, readOnly, maxStringLength, minRows }) => {
  return(
    <TextField 
      label={<TranslationContent contentID={label} />} 
      helperText={value.length > maxStringLength / 2 ? `${value.length}/${maxStringLength}` : ""}
      multiline={multiline}
      autoComplete="off"  
      size="small" 
      inputProps={{ readOnly: readOnly}} 
      value={value} 
      onChange={onChange}
      fullWidth
      minRows={minRows ? minRows : 1}
      variant={variant}
    />
  )
}


export default WriteTextField;