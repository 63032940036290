import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const documentApiKey = process.env.REACT_APP_DOCUMENT_API_KEY;


const getDocument = async (id) => {
  const request = axios.get(`${baseUrl}/document/${id}`, { headers: { "x-document-api-key": documentApiKey } , responseType: 'arraybuffer',});

  return request.then(response => response.data);
}


const documentServices = ( getDocument )
export default documentServices;