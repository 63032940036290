import React from "react";
import { FormLabel, FormControlLabel, ToggleButtonGroup, List, ListItem, Stack, Radio, Collapse, Box, ListItemText, Divider, ListSubheader, ListItemButton, RadioGroup, FormControl, ToggleButton, TextField, Typography, Button, Paper, Zoom, CircularProgress } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { useViewport } from "../../../Context/ViewportProvider";
import { LanguageContext } from "../../../Translations/LanguageProvider";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { SessionContext } from "../../../Context/SessionProvider";
import TranslationContent from "../../../Translations/TranslationContent";
import ImagePreviewDialog from "../../Dialogs/ImagePreviewDialog";
import InspectionImage from "../../Images/InspectionImage";
import { Tokens } from "../../../services/Tokens";
import imageService from "../../../services/images";
import { logger } from "../../../services/logger";
import ImageResizer from 'react-image-file-resizer';
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import GuideImage from "../../Images/GuideImage";



const Section = ({ activeSection, inspectionForm, updateInspection, images, updateImages }) => {
  const [manualCategorySelection, setManualCategorySelection] = React.useState(false);
  const lang = React.useContext(LanguageContext);

  const categorySelection = () => {
    let showSelection = false;
    if(activeSection.data.categories.length > 0 && manualCategorySelection) {
      showSelection = true;
    }
    if(activeSection.data.categories.length > 0 && !manualCategorySelection) {
      let validSelections = activeSection.data.categories.map((category, index) => {
        if(category.selectedOption !== "") {
          return true;
        }
        else {
          return false;
        }
      })
      showSelection = !validSelections.every(selection => selection === true);
    }
    if(activeSection.data.categories.length === 0) {
      showSelection = false;
    }
    return showSelection;
  }

  const toggleManualCategorySelection = () => {
    setManualCategorySelection(!manualCategorySelection);
  }

  const handleCategoryOptionChange = (event, categoryId) => {
    let updatedInspection = {...inspectionForm};
    
    updatedInspection.sections = updatedInspection.sections.map(section => {
      if(section.id === activeSection.data.id) {
        section.categories = section.categories.map(category => {
          if(category.id === categoryId) {
            category.selectedOption = event.target.value;
            return category;
          }
          return category;
        })
        return section;
      }
      return section;
    })
    updateInspection(updatedInspection);
    setManualCategorySelection(false);
  }

  const showCancel = () => {
    let showCancelButton = false;
    let validValues = 
    activeSection.data.categories.map(category => {
      if(category.selectedOption !== "") {
        return true;
      }
      else {
        return false;
      }
    })
    showCancelButton = validValues.every(value => value === true);
    return showCancelButton;
  }

  const handleAdditionalCommentChange = (event) => {
    let updatedInspection = {...inspectionForm};
    updatedInspection.sections = updatedInspection.sections.map(section => {
      if(section.id === activeSection.data.id) {
        section.generalComment = event.target.value;
        return section;
      }
      return section;
    })
    updateInspection(updatedInspection);
  }

  return(
    <List 
      dense 
      sx={{ textAlign: "center" }} 
      subheader={
        <ListSubheader sx={{background: "none", fontSize: "larger"}}>
          {activeSection.data.name[lang.lang]}
        </ListSubheader>}
      >
        <Collapse in={categorySelection()}>
          {activeSection?.data.categories.map((category, categoryIndex) => (
            <FormControl sx={{marginTop: "15px", marginBottom: "15px"}} key={category.id}>
              <FormLabel id="select-section-type">{category.name[lang.lang]}</FormLabel>
              <RadioGroup
                aria-labelledby="select-section-type"
                name="radio-buttons-group-4-section-type-selection"
                value={category.selectedOption}
              >
                {category.options.map((option, index) => 
                  <FormControlLabel value={option.id} control={<Radio />} label={option.name[lang.lang]} key={option.id} onChange={(event) => handleCategoryOptionChange(event, category.id)}/>
                )}
              </RadioGroup>
            </FormControl>
          ))}
            <br />
            {showCancel() ? 
              <Button size="small" sx={{marginBottom: "25px"}} variant="outlined" onClick={() => toggleManualCategorySelection()}><TranslationContent contentID="cancel" /></Button>
            :null}
          </Collapse>
        <Collapse in={!categorySelection() && activeSection.data.categories.length > 0}>
        <Paper sx={{/*border: "1px solid",*/ margin: "auto", display: "flex", marginBottom: "15px"}}>
          {activeSection.data.categories.map(category => 
          <Box width="50%" textAlign={"right"} m="auto" key={category.id}>
          <Typography variant="caption"><b>{category.name[lang.lang]}:</b> {category.selectedOption !== "" ? category.options.find(option => option.id === category.selectedOption).name[lang.lang] : ""}</Typography>
          <div />
          </Box>
          )}&nbsp; &nbsp; 
          <Box width="50%" textAlign={"left"} m="auto"><Button size="small" onClick={() => toggleManualCategorySelection()}><TranslationContent contentID="switchButton" /></Button></Box>
          </Paper>
          </Collapse>
          <Collapse in={!categorySelection()}>
          {activeSection?.data?.inspections?.map((inspection, index) => 
            <MeasurementItem 
              inspectionForm={inspectionForm} 
              inspection={inspection} 
              inspectionIndex={index} 
              activeSection={activeSection} 
              key={inspection.id} 
              updateInspectionForm={updateInspection} 
              images={images} 
              updateImages={updateImages}/>
          )}
          </Collapse>
        <Collapse in={!categorySelection()}>
          <TextField fullWidth sx={{marginTop: "50px", marginBottom: "30px"}} id="outlined-basic" label={<TranslationContent contentID="additionalComments" />} variant="outlined" multiline value={activeSection.data.generalComment} onChange={(event) => handleAdditionalCommentChange(event)}/>
        </Collapse>
    </List>        
  )
}

export default Section;


const MeasurementItem = ({ inspectionForm, inspection, inspectionIndex, activeSection, updateInspectionForm, images, updateImages }) => {
  const { width } = useViewport();
  const lang = React.useContext(LanguageContext)
  const [showDesc, setShowDesc] = React.useState(false);
  const [showLargeImage, setShowLargeImage] = React.useState(false);
  const [selectedLargeImage, setSelectedLargeImage] = React.useState("");
  const [imageTransactionActive, setImageTransactionActive] = React.useState(false);
  const session = React.useContext(SessionContext);
  const { addAlert } = React.useContext(SnackBarContext);


  React.useEffect(() => {
    if(session.preferences[0].value) {
      setShowDesc(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  
  const filterByCategory = () => {
    let validMeasurementByCategory = false;
    if(inspection.linkedCategoryId === "") {
      validMeasurementByCategory = true;
    }
    if(inspection.linkedCategoryId !== "") {
      let category = inspectionForm.sections[activeSection.index].categories.find(category => category.id === inspection.linkedCategoryId);
      if(category?.selectedOption === inspection?.linkedOptionId) {
        validMeasurementByCategory = true;
      }
    }
    return validMeasurementByCategory;
  }


  const handleCommentChange = (event) => {    
    let updatedForm = {...inspectionForm};
    updatedForm.sections = updatedForm.sections.map(section => {
      if(section.id === activeSection.data.id) {
        section.inspections = section.inspections.map(oldInspection => {
          if(oldInspection.id === inspection.id) {
            oldInspection.comment = event.target.value;
            return oldInspection;
          }
          return oldInspection;
        })
        return section;
      }
      return section;
    })
    updateInspectionForm(updatedForm);
  }

  const handleBooleanChange = (event, value) => {
    let updatedForm = {...inspectionForm};
    updatedForm.sections = updatedForm.sections.map(section => {
      if(section.id === activeSection.data.id) {
        section.inspections = section.inspections.map(oldInspection => {
          if(oldInspection.id === inspection.id) {
            if(value === null) {
              oldInspection.booleanValue = "";
            }
            else {
              oldInspection.booleanValue = value;
            }
            return oldInspection;
          }
          return oldInspection;
        })
        return section;
      }
      return section;
    })
    updateInspectionForm(updatedForm);
  }

  function handleDecimalsOnValue(value) {
    //const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    const regex = /([0-9]*[.|,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
  }

  const handleNumericalChange = (event) => {
    let updatedForm = {...inspectionForm};
    updatedForm.sections = updatedForm.sections.map(section => {
      if(section.id === activeSection.data.id) {
        section.inspections = section.inspections.map(oldInspection => {
          if(oldInspection.id === inspection.id) {
            let value = handleDecimalsOnValue(event.target.value);
            value = value.replaceAll(",", ".")
            oldInspection.numberValue = value;
            return oldInspection;
          }
          return oldInspection;
        })
        return section;
      }
      return section;
    })
    updateInspectionForm(updatedForm);
  }

  const handleImageChange = (event, id) => {
    setImageTransactionActive(true);

    let updatedForm = {...inspectionForm};

    let files = [...event.target.files]

    if (files.length > 0 && files.length < 5) {
      
      
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        ImageResizer.imageFileResizer(
          file,
          300, // New width (in pixels)
          300, // New height (in pixels)
          'PNG', // Output format (JPEG or PNG)
          90, // Quality (1 to 100)
          0, // Rotation (0, 90, 180, or 270)
          (resizedImage) => {
            Tokens().then((tokens) => {
              imageService
              .saveInspectionImage(inspectionForm.id, activeSection.data.id, inspection.id, resizedImage, tokens.accessToken, tokens.idToken)
              .then(response => {
                logger(response);
                updateImages({data: resizedImage, id: response}, "add")
                updatedForm.sections = updatedForm.sections.map(section => {
                  if(section.id === activeSection.data.id) {
                    section.inspections = section.inspections.map(oldInspection => {
                      if(oldInspection.id === inspection.id) {
                        oldInspection.inspectionImages = [...oldInspection.inspectionImages, response];
                        return oldInspection;
                      }
                      return oldInspection;
                    })
                    return section;
                  }
                  return section;
                })
                addAlert({message: "snackbarImageSaveSuccessful", type: "success"});
                setImageTransactionActive(false);
              })
              .catch(error => {
                logger(error);
                addAlert({message: "snackbarImageSaveFailed", type: "error"});
              })
            })
            .catch(error => {
              logger(error);
              addAlert({message: "snackbarImageSaveFailed", type: "error"});
            })
          },
          'base64', // Output type (base64 or blob)
          300, // Max file size (in kilobytes)
          350, // Max width (in pixels)
          350 // Max height (in pixels)
        );
      };
    }
    updateInspectionForm(updatedForm, "imageUpdate")

    //updateInspection(updatedInspection, "imageUpdate");
  }

  
  const openLargeImage = (image) => {
    setSelectedLargeImage(image);
    setShowLargeImage(true);
  }

  const closeLargeImage = () => {
    setShowLargeImage(false);
    setSelectedLargeImage("");
  }

  if(filterByCategory() && inspection.isBoolean) {
    return(
      <>
        <ImagePreviewDialog open={showLargeImage} close={() => closeLargeImage()} img={selectedLargeImage}/>
        <ListItem
          disablePadding
          alignItems="flex-start"
          key={inspection.name.fi + inspection.name.en + inspection}
          secondaryAction={
            <ToggleButtonGroup exclusive edge="end" size="small" value={inspection.booleanValue} onChange={(event, value) => handleBooleanChange(event, value)} aria-label="delete" color="primary" sx={{marginRight: "-12px",}}>
              <ToggleButton value={true}>
                <CheckCircleIcon  color={inspection.booleanValue ? "success" : ""}/>
              </ToggleButton>
              <ToggleButton value={false}>
                <DangerousIcon color={!inspection.booleanValue && inspection.booleanValue !== "" ? "error" : ""} />
              </ToggleButton>
            </ToggleButtonGroup>
          }
        >
          <ListItemButton onClick={() => setShowDesc(!showDesc)}>
            <KeyboardArrowUpIcon 
            color="primary"
            sx={{
              // This makes it feel animated:
              transition: "transform 200ms linear",
              // This rotates the element:
              transform: `rotateZ(${showDesc ? 0 : "180deg"})`,
              display: "inline-block",
              marginRight: width > 600 ? "1.4em" : 0,
              marginLeft: width > 600 ? 0 : "-12px"
            }}
            />
            <ListItemText
              primaryTypographyProps={{marginRight: "12%", fontWeight: 600}}
              primary={`${inspection.name[lang.lang]} ${inspection.forceComplete ? "*" : ""}`}
              secondaryTypographyProps={{marginRight: "15%"}}
              secondary={showDesc ? `${inspection.desc[lang.lang]}` : ""}
            />
            
          </ListItemButton>
        </ListItem>
        <Collapse in={showDesc}>
        <br />
        <Grid container mb={2} spacing={3} sx={{marginLeft: width > 600 ? "70px" : ""}}>
          {inspection.guideImage !== "" &&
          <Grid xs={12} md={6}>
            <GuideImage formId={inspectionForm.formId} imageId={inspection.guideImage} renderImage={showDesc} images={images} updateImages={updateImages} openLargeImage={openLargeImage} />
          </Grid>
          }
          {inspection.enableImage && !imageTransactionActive && inspection.inspectionImages.length < 4 ? 
          <Grid xs={12} md={6} m="auto" >
            <>
            <input accept="image/*" type="file" id={inspection.name.fi + inspection.name.en + inspectionIndex} style={{display: "none"}} onChange={event => handleImageChange(event)} multiple/>
            <label htmlFor={inspection.name.fi + inspection.name.en + inspectionIndex}>
            <Button sx={{margin:"auto", textAlign: "center"}} component="span"><Stack><AddAPhotoIcon sx={{alignSelf: "center"}} /><TranslationContent contentID="addImage" /></Stack></Button>
            </label>
            </>
          </Grid>
          :null}
          {inspection.inspectionImages.length > 3 && 
          <Grid xs={12}>
            <Typography mt={1} mb={1} sx={{opacity: 0.5}}><b><TranslationContent contentID="imageMaxLimitReached" /></b></Typography>
          </Grid>
          }
          {imageTransactionActive && 
            <Grid xs={12} md={6} m="auto">
              <CircularProgress />
            </Grid>
          }
          {inspection.inspectionImages.length > 0 && 
          <Grid container >
            {inspection.inspectionImages.map((image, index) => 
              <Grid xs={12} sm={4} md={3} xl={2} m="auto" key={image}>
                <InspectionImage 
                  renderImage={showDesc} 
                  inspectionForm={inspectionForm} 
                  imageId={image} 
                  images={images} 
                  updateImages={updateImages} 
                  updateInspection={updateInspectionForm}
                  openLargeImage={openLargeImage} 
                  inspection={inspection} 
                  sectionId={activeSection.data.id}
                />
              </Grid>
            )}
          </Grid>
          }
          
          <Grid xs={12} m="auto">
            <TextField multiline label={<TranslationContent contentID="comments" />} value={inspection.comment} size="small" fullWidth onChange={(event) => handleCommentChange(event)}/>
          </Grid>
        </Grid>
        </Collapse>
        <Divider variant={width > 600 ? "inset" : ""} sx={{marginBottom: "2px"}} component="li" />
      </>
    )
  }

  if(filterByCategory() && inspection.isNumber) {
    return(
      <>
        <ImagePreviewDialog open={showLargeImage} close={() => closeLargeImage()} img={selectedLargeImage}/>
        <ListItem
          disablePadding
          alignItems="flex-start"
          key={inspection.name.fi + inspection.name.en + inspectionIndex}
          secondaryAction={
            <Stack direction="row" sx={{marginRight: "-12px", maxWidth: "150px"}} spacing={1}>
              {Number(inspection.numberValue) >= inspection.minNumberValue && Number(inspection.numberValue) <= inspection.maxNumberValue ? 
              <Zoom in={inspection.numberValue > 0}>
                <CheckCircleOutlineIcon color="success" sx={{margin:"auto",}} />
              </Zoom> 
              :
              <Zoom in={inspection.numberValue > 0}>
                <DoDisturbOnIcon color="error" sx={{margin:"auto",}} />
              </Zoom>
              }
              <TextField size="small" sx={{maxWidth: "120px"}} label="Mittausarvo" value={inspection.numberValue} onChange={(event) => handleNumericalChange(event)}/>
            </Stack>
          }
        >
          <ListItemButton onClick={() => setShowDesc(!showDesc)}>
            <KeyboardArrowUpIcon 
            color="primary"
            sx={{
              // This makes it feel animated:
              transition: "transform 200ms linear",
              // This rotates the element:
              transform: `rotateZ(${showDesc ? 0 : "180deg"})`,
              display: "inline-block",
              marginRight: width > 600 ? "1.4em" : 0,
              marginLeft: width > 600 ? 0 : "-12px"
            }}
            />
            <ListItemText
              primaryTypographyProps={{marginRight: "100px", fontWeight: 600}}
              primary={`${inspection.name[lang.lang]} ${inspection.forceComplete ? "*" : ""}`}
              secondaryTypographyProps={{marginRight: "100px"}}
              secondary={showDesc ? `${inspection.desc[lang.lang]}` : ""}
            />
            
          </ListItemButton>
        </ListItem>
        <Collapse in={showDesc}>
        <br />
        <Grid container mb={2} spacing={3} sx={{marginLeft: width > 600 ? "70px" : ""}}>
          <Grid xs={12} md={6}>
          <GuideImage formId={inspectionForm.formId} imageId={inspection.guideImage} renderImage={showDesc} images={images} updateImages={updateImages} openLargeImage={openLargeImage} />
          </Grid >
          {inspection.enableImage && !imageTransactionActive && inspection.inspectionImages.length < 4 ? 
          <Grid xs={12} md={6} m="auto" >
            <>
            <input accept="image/*" type="file" id={inspection.name.fi + inspection.name.en + inspectionIndex} style={{display: "none"}} onChange={event => handleImageChange(event)} multiple/>
            <label htmlFor={inspection.name.fi + inspection.name.en + inspectionIndex}>
            <Button sx={{margin:"auto", textAlign: "center"}} component="span"><Stack><AddAPhotoIcon sx={{alignSelf: "center"}} /><TranslationContent contentID="addImage" /></Stack></Button>
            </label>
            </>
          </Grid>
          :null}
          {inspection.inspectionImages.length > 3 && 
          <Grid xs={12}>
            <Typography mt={1} mb={1} sx={{opacity: 0.5}}><b><TranslationContent contentID="imageMaxLimitReached" /></b></Typography>
          </Grid>
          }
          {imageTransactionActive && 
            <Grid xs={12} md={6} m="auto">
              <CircularProgress />
            </Grid>
          }
          {inspection.inspectionImages.length > 0 && 
          <Grid container >
            {inspection.inspectionImages.map((image, index) => 
              <Grid xs={12} sm={4} md={3} xl={2} m="auto" key={image}>
                <InspectionImage 
                  renderImage={showDesc} 
                  inspectionForm={inspectionForm}
                  imageId={image} 
                  images={images} 
                  updateImages={updateImages} 
                  updateInspection={updateInspectionForm}
                  openLargeImage={openLargeImage} 
                  inspection={inspection}
                  sectionId={activeSection.data.id} />
              </Grid>
            )}
          </Grid>
          }
          <Grid xs={12} m="auto">
            <TextField multiline label={<TranslationContent contentID="comments" />} value={inspection.comment} size="small" fullWidth onChange={(event) => handleCommentChange(event)}/>
          </Grid>
        </Grid>
        </Collapse>
        <Divider variant={width > 600 ? "inset" : ""} sx={{marginBottom: "2px"}} component="li" />
      </>
    )
  }
}