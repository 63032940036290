import React from "react";
import { Typography, Box, Button, Tooltip, IconButton, CircularProgress } from "@mui/material";
import TranslationContent from "../../../Translations/TranslationContent";
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { Tokens } from "../../../services/Tokens";
import applicationService from "../../../services/applications";
import { SessionContext, SessionDispatchContext } from "../../../Context/SessionProvider";
import { logger } from "../../../services/logger";


const ActionButtons = ({ status, handleStatusChange, registration, openUserPlacementDialog }) => {
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const [newUser, setNewUser] = React.useState({});


  const deleteRegistration = () => {
    handleStatusChange("loading");
    Tokens().then((tokens) => {
      applicationService
      .denyApplication(registration.id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedSession = {...session};
        updatedSession.applications = updatedSession.applications - 1;
        updateSession({type: "UPDATE_SESSION", value: updatedSession})
        handleStatusChange("rejected");
        addAlert({message: "successRejectApplication", type: "succes"});
      })
      .catch(error => {
        logger(error);
        handleStatusChange("pending");
        addAlert({message: "errorRejectApplication", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      handleStatusChange("pending");
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const acceptRegistration = () => {
    handleStatusChange("loading");
    Tokens().then((tokens) => {
      applicationService
      .acceptApplication(registration.id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedSession = {...session};
        updatedSession.applications = updatedSession.applications - 1;
        updateSession({type: "UPDATE_SESSION", value: updatedSession})
        let updatedNewUser = {...newUser};
        updatedNewUser.id = registration.id;
        updatedNewUser.name = registration.name;
        setNewUser(updatedNewUser);
        handleStatusChange("accepted");
        addAlert({message: "successAcceptApplication", type: "succes"});
        openUserPlacementDialog(updatedNewUser);
      })
      .catch(error => {
        logger(error);
        handleStatusChange("pending");
        addAlert({message: "errorAcceptApplication", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      handleStatusChange("pending");
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  if(status === "pending") {
    return(<><Tooltip title={<TranslationContent contentID="acceptApplication" />} arrow><IconButton color="success" onClick={() => handleStatusChange("accepting")}><HowToRegIcon /></IconButton></Tooltip><Tooltip title={<TranslationContent contentID="rejectApplication"/>}arrow><IconButton color="error" onClick={() => handleStatusChange("rejecting")}><NoAccountsIcon /></IconButton></Tooltip></>)
  }
  if(status === "accepting") {
    return(<Box mt={2} mr={"-16px"}><Typography><TranslationContent contentID="acceptApplication"/>?</Typography><Button onClick={() => acceptRegistration()} variant="contained" size="small" color="primary"><TranslationContent contentID="yes" /></Button>&nbsp;<Button variant="outlined" size="small" onClick={() => handleStatusChange("pending")}><TranslationContent contentID="no" /></Button></Box>)
  }
  if(status === "rejecting") {
    return(<Box mt={2} mr={"-16px"}><Typography><TranslationContent contentID="rejectApplication"/>?</Typography><Button onClick={() => deleteRegistration()}variant="contained" size="small" color="primary"><TranslationContent contentID="yes" /></Button>&nbsp;<Button size="small" variant="outlined" onClick={() => handleStatusChange("pending")}><TranslationContent contentID="no" /></Button></Box>)
  }
  if(status === "accepted" || status === "rejected") {
    return( 
      <Typography variant="caption" sx={{opacity: 0.5}}>
        <TranslationContent contentID="processedApplication"/>
      </Typography>
    )
  }
  if(status === "loading") return <CircularProgress />;
}

export default ActionButtons;