import React from "react";
import LandingHeader from "../Components/Headers/LandingHeader";
import GlobalFooter from "../Components/Footers/GlobalFooter";
import { Box, Container } from "@mui/material";

const LandingLayout = ({ children }) => {
  return(
    <>
      <LandingHeader />
      <Container /*maxWidth="sm"*/  /*disableGutters={true} /*sx={{minHeight: "100vh"}} /*sx={{textAlign: "center", alignContent: "center", border: "0.5px solid black", minHeight: "100vh"}} */>
        <Box>
          
          {children}
        </Box>
        <GlobalFooter />
      </Container>
    </>
  )
}

export default LandingLayout;