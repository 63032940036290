import React from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import LoadingSpinner from "../Loading/LoadingSpinner"
import TranslationContent from "../../Translations/TranslationContent";


const Loading = ({ section }) => {
  return(
    <MainLayout>
      <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography color="primary" variant="body2"><b><TranslationContent contentID={section} /></b></Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{padding: "7px", width: "100%",}}>
        <Grid container spacing={2} mt={0}>
          <Grid xs={12} sx={{textAlign: "center", justifyContent: "center", padding: "50px"}}>
            <LoadingSpinner />
          </Grid>
        </Grid>
      </Paper>
    </MainLayout>
  )
}

export default Loading;