import React from "react";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { Typography, Table, TableContainer, TableBody, TableCell, TableRow, Paper, IconButton, Tooltip, Box, CircularProgress } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ShareLinkDialog from "../Dialogs/ShareLinkDialog";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import { LanguageContext } from "../../Translations/LanguageProvider";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Tokens } from "../../services/Tokens";
import archiveService from "../../services/archives";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { logger } from "../../services/logger";
import TranslationContent from "../../Translations/TranslationContent";


const SiteArchiveTable = ({ siteId }) => {
  const [loading, setLoading] = React.useState(true);
  const [downloading, setDownloading] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState("");
  const [inspections, setInspections] = React.useState([]);
  const[shareDialogOpen, setShareDialogOpen] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const currentLang = React.useContext(LanguageContext);
  const { addAlert } = React.useContext(SnackBarContext);

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(fetchData.current) {
      fetchData.current = false;
      fetchInspections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const fetchInspections = () => {
    Tokens().then((tokens) => {
      archiveService
      .getSiteArchivedInspections(siteId, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedInspections = [...inspections];
        updatedInspections = [...response];
        setInspections(updatedInspections);
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setLoading(false);
        addAlert({message: "snackbarFetchingInspectionsFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const downloadInspection = (id) => {
    setDownloading(true);
    Tokens().then((tokens) => {
      archiveService
      .downloadInspection(id, tokens.accessToken, tokens.idToken)
      .then(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const newTab = window.open(url, '_blank');
  
        if (newTab) {
          newTab.focus();
        } else {
          alert('Please allow pop-ups to open the file.');
        }
        
        setDownloading(false);

      })
      .catch(error => {
        logger(error);
        setDownloading(false);
        addAlert({message: "snackbarDownloadFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setDownloading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const confirmDelete = () => {
    setConfirmDialogOpen(false);
    setDeleting(true);
    Tokens().then((tokens) => {
      archiveService
      .deleteArchivedInspection(selectedId, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedInspections = inspections.filter(inspection => inspection.id !== selectedId);
        setInspections(updatedInspections);
        setSelectedId("");
        setDeleting(false);
        addAlert({message: "snackbarDeleteInspectionSuccess", type: "success"});
      })
      .catch(error => {
        logger(error);
        setSelectedId("");
        setDeleting(false);
        addAlert({message: "snackbarDeleteInspectionFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setSelectedId("");
      setDeleting(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }
  const openConfirmDialogForDelete = (id) => {
    setSelectedId(id);
    setConfirmDialogOpen(true);
  }
  const openShareDialog = (id) => {
    setSelectedId(id)
    setShareDialogOpen(true);
  }


  if(loading) return <Grid xs={12} textAlign={"center"}><LoadingSpinner/></Grid>;
  if(inspections.length === 0) return <Grid xs={12} textAlign="center" mt={1}><Typography sx={{opacity: 0.5, mb: 3}}><TranslationContent contentID="noCompletedInspections" /></Typography></Grid>;

  if(inspections.length > 0) {
    return(
      <Grid xs={12} mt={0}>
        <ShareLinkDialog open={shareDialogOpen} handleClose={() => setShareDialogOpen(false)} id={selectedId} />
        <ConfirmDialog title="deleteReportTitle" text="deleteReportDesc" open={confirmDialogOpen} handleClose={() => setConfirmDialogOpen(false)} confirmClick={confirmDelete} />
        <TableContainer sx={{maxWidth:"90vw", mb: 3}}>
          <Table sx={{borderCollapse: "separate", borderSpacing: "0 1em", WebkitBorderRadius: 5}}>
            <TableBody>
              {inspections.map(inspection => 
                <TableRow component={Paper} square={false} elevation={24} key={inspection.id}>
                  <TableCell><b>{inspection.formName[currentLang.lang]}</b><Typography variant="caption" sx={{opacity:0.6}} > {inspection.inspector.name !== "" ? <i>({inspection.inspector.name})</i> :null}</Typography></TableCell>
                  <TableCell>
                    <Box sx={{width: "100%", padding: "2px", textAlign: "center", borderRadius: "5px", color: "white", maxWidth: "150px", minWidth: "100px"}} bgcolor={"#388e3c"}><b><TranslationContent contentID="done" /></b></Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption"><TranslationContent contentID="completed" /> <b>{ Math.round( ( ( new Date().getTime() - inspection.completed ) / ( 1000 * 3600 * 24 ) ) ) }</b> <TranslationContent contentID="daysAgo" /> </Typography><Typography variant="caption" sx={{opacity:0.6}} >({new Date(inspection.completed).toLocaleDateString()})</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {downloading ? 
                      <CircularProgress style={{height: "30px", width: "30px", marginBottom: "-10px"}} color="success"/>
                    :
                      <Tooltip title={<TranslationContent contentID="downloadPDFReport" />} arrow>
                        <IconButton color="success" onClick={() => downloadInspection(inspection.id)}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    <Tooltip title={<TranslationContent contentID="createOneTimeDownloadLink" />} arrow>
                      <IconButton color="primary" onClick={() => openShareDialog(inspection.id)}>
                        <ShareIcon />
                      </IconButton>
                    </Tooltip>
                    {deleting && selectedId === inspection.id ? 
                      <CircularProgress style={{height: "30px", width: "30px", marginBottom: "-10px"}} color="error"/>
                    :
                      <Tooltip title={<TranslationContent contentID="deleteReport" />} arrow>
                        <IconButton color="error" onClick={() => openConfirmDialogForDelete(inspection.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    )
  }
}

export default SiteArchiveTable;