import React from "react";
import { Zoom, Button, Box, TextField, Collapse, MenuItem, Select, InputLabel, FormControl, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SendIcon from '@mui/icons-material/Send';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fi';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { fiFI, DEFAULT_LOCALE } from '@mui/x-date-pickers/locales';
import { LanguageContext } from "../../Translations/LanguageProvider";
import LoadingSpinner from "../Loading/LoadingSpinner";
import LockPersonIcon from '@mui/icons-material/LockPerson';
import TranslationContent from "../../Translations/TranslationContent";


const AddInspection = ({loading, deleting, fetching, inspectors, inspectionForms, fetchNewInspectionData, addNewTimedInspection }) => {
  const [show, setShow] = React.useState(false);
  const currentLang = React.useContext(LanguageContext);
  const [selectedForm, setSelectedForm] = React.useState("");
  const [selectedInspector, setSelectedInspector] = React.useState("");
  const [nameEN, setNameEN] = React.useState("");
  const [nameFI, setNameFI] = React.useState("");
  const [time, setTime] = React.useState(null);

  React.useEffect(() => {
    if(inspectors.length === 0 && inspectionForms.length === 0 && show) {
      fetchNewInspectionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[show])
  
  const closeSelection = () => {
    setSelectedForm("");
    setSelectedInspector("");
    setNameEN("");
    setNameFI("");
    setTime(null)
    setShow(false);
  }


  const submitInspection = async () => {
    let requestData = {
      formId: selectedForm,
      siteId: "",
      oid: selectedInspector,
      timed: time !== null && time !== "" ? new Date(time).getTime() : "",
      nameEN: nameEN,
      nameFI: nameFI,
      siteCreation: true
    }
    await addNewTimedInspection(requestData);
    setSelectedForm("");
    setSelectedInspector("");
    setNameEN("");
    setNameFI("");
    setTime(null)
    setShow(false);
  }


  if(fetching) return <Box textAlign={"center"} width="100%"><LoadingSpinner /></Box>;

  if(!show && !fetching) {
    return(
      <Grid xs={12} textAlign={"center"} mt={0}>
        <Zoom in={!show}>
          <Button variant="outlined" disabled={loading || deleting} onClick={() => setShow(true)} ><NoteAddIcon />&nbsp;&nbsp;<TranslationContent contentID="addInspection" /></Button>
        </Zoom>
      </Grid>
    )
  }

  if(show && !fetching) {
    return(
      <Grid xs={12} mt={0}>
        <Collapse in={show}>
          <Grid container spacing={2} height="maxContent">
            <Grid xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="inspection-select-label" required><TranslationContent contentID="inspection" /></InputLabel>
                <Select
                  labelId="inspection-select-label"
                  id="inspection-select"
                  value={selectedForm}
                  label={<TranslationContent contentID="inspection" />}
                  required
                  onChange={event => setSelectedForm(event.target.value)}
                >
                  {inspectionForms.filter(form => form.available || form.globallyAvailable).map(form => 
                    <MenuItem value={form.id} key={form.id}>{form.name[currentLang.lang]} ({form.creator.name})</MenuItem>
                  )}
                  <MenuItem value={""}><i style={{opacity: 0.5}}><TranslationContent contentID="empty" /></i></MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="inspector-select-label"><TranslationContent contentID="inspector" /></InputLabel>
                <Select
                  labelId="inspector-select-label"
                  id="inspector-select"
                  value={selectedInspector}
                  label={<TranslationContent contentID="inspector" />}
                  onChange={event => setSelectedInspector(event.target.value)}
                >
                  <MenuItem value={""} key=""><i><TranslationContent contentID="all" /></i></MenuItem>
                  {inspectors.map(inspector => 
                    <MenuItem value={inspector.oid} disabled={inspector.type === "internal" && !inspector?.internalRights?.inspect} key={inspector.id}>{inspector.userName} {inspector.type === "internal" && !inspector?.internalRights?.inspect ? <>&nbsp; <LockPersonIcon fontSize="small"/></> :null}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} md={6}>
              <TextField fullWidth required label={<><TranslationContent contentID="nameEN" />*</>} autoComplete="off" value={nameEN} onChange={event => setNameEN(event.target.value)} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField fullWidth required label={<><TranslationContent contentID="nameFI" />*</>} autoComplete="off" value={nameFI} onChange={event => setNameFI(event.target.value)} />
            </Grid>
            <Grid xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLang.lang === "fi" ? "fi" : "en"} localeText={currentLang.lang === "fi" ? fiFI.components.MuiLocalizationProvider.defaultProps.localeText : DEFAULT_LOCALE}>
                <DatePicker 
                  localeText={currentLang.lang === "fi" ? fiFI.components.MuiLocalizationProvider.defaultProps.localeText : DEFAULT_LOCALE}
                  label={<TranslationContent contentID="scheduleInspection" />} 
                  sx={{width: "100%"}} 
                  disablePast
                  value={time}
                  onChange={(newValue) => setTime(newValue)}
                  // Uncomment if will be required in future
                  /*
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  */
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={6} md={3} sx={{alignSelf: "center"}} >
              <Button fullWidth onClick={() => closeSelection()}><TranslationContent contentID="cancel" /></Button>
            </Grid>
            <Grid xs={6} md={3} sx={{alignSelf: "center"}} >
              <Button onClick={() => submitInspection()} fullWidth variant="contained" disabled={selectedForm === "" || (nameEN.length <5 && nameFI.length < 5 )}><TranslationContent contentID="add" />&nbsp;&nbsp;<SendIcon /></Button>
            </Grid>
            <Grid>
              <Typography variant="caption" sx={{opacity: 0.5}}>* <TranslationContent contentID="required" /></Typography><br />
              <Typography variant="caption" sx={{opacity: 0.5}}>** <TranslationContent contentID="atLeastOneNameHelperText" /></Typography>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    )
  }
}


export default AddInspection;