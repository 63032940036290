import React from "react";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Toolbar, CssBaseline, Typography, IconButton, Stack } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { useMsal } from "@azure/msal-react";
import TranslationContent from "../../Translations/TranslationContent";
import { useViewport } from "../../Context/ViewportProvider";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../Context/SessionProvider";
import MobileDrawer from "./MobileDrawer";
import DesktopDrawer from "./DesktopDrawer";

const drawerWidth = 240;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, mobileOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...((open || mobileOpen )&& {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));



const MainHeader = ({ children }) => {
  const { width } = useViewport();
  const [open, setOpen] = React.useState(width > 850 ? true : false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const session = React.useContext(SessionContext);

  let navigationList = {
    basic: [
      {
        section: "home",
        path: "/home"
      },
      {
        section: "account",
        path: "/account"
      }
    ],
    inspections: [
      session.activeRole?.internalRights?.inspect || session.activeRole.type === "external" ? 
      {
        section: "newInspection",
        path: "/inspect/new",
      } : null,

      session.activeRole?.internalRights?.inspect || session.activeRole.type === "external" ? 
      {
        section: "continueInspection",
        path: "/inspect/active",
      } : null,

      session.activeRole?.internalRights?.inspect || session.activeRole.type === "external" ? 
      {
        section: "inspectionArchive",
        path: "/inspect/archive",
      } : null,
    ],
    management: [
      session.activeRole?.internalRights?.userManagement ? 
      {
        section: "userManagement",
        path: "/users",
      } : null,

      session.activeRole?.internalRights?.organizationManagement ?
      {
        section: "organizationManagement",
        path: "/organization",
      } : null,

      session.activeRole?.internalRights?.siteManagement || session.activeRole.role === "manager" ?
      {
        section: "siteManagement",
        path: "/site",
      } : null,

      session.activeRole?.internalRights?.inspectionFormManagement || session.activeRole.role === "manager" ?
      {
        section: "inspectionTemplate",
        path: "/form",
      } : null,

      // Not in use right now. Maybe extra feature that will be added in the future
      /*
      session.activeRole?.internalRights?.reportManagement ?
      {
        section: "reportTemplate",
        path: "/report",
      } : null,
      */
    ],
  }

  React.useEffect(() => {
    if(width < 750) {
      setOpen(false);
    }
  },[width])

  const handleDrawerOpen = () => {
    if(width > 750) {
      setOpen(true);
    }
    else {
      setMobileOpen(true);
    }
  };

  const handleDrawerClose = () => {
    if(width > 750) {
      setOpen(false);
    }
    else {
      setMobileOpen(false);
    }
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
    sessionStorage.clear();
  }

  const handleNavigation = (path) => {
    if(path === "/site" && window.location.pathname.includes("/site")) {
      window.location.reload();
    }
    if(path === "/organization" && window.location.pathname.includes("/organization")) {
      window.location.reload();
    }
    if(!(path === "/site" && window.location.pathname.includes("/site")) && !(path === "/organization" && window.location.pathname.includes("/organization"))) {
      navigate(path);
    }
  }

  return(
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Stack spacing={0}>
            <Typography variant="h6" noWrap component="div" color="primary">
              <TranslationContent contentID="adminDashboard"/>PISKO PRO
            </Typography>
            <Typography variant="caption" component="div" color="primary">
              {session.activeRole.organization?.name} – {session.name} (<TranslationContent contentID={session.activeRole.role}/>)
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      {width > 750 ?
        <DesktopDrawer open={open} navigationList={navigationList} handleLogout={handleLogout} handleNavigation={handleNavigation} handleDrawerClose={handleDrawerClose} />
      :
        <MobileDrawer mobileOpen={mobileOpen} navigationList={navigationList} handleLogout={handleLogout} handleNavigation={handleNavigation} handleDrawerClose={handleDrawerClose} />
      }
      <Box component="main" sx={{ flexGrow: 1, p: width > 750 ? 3 : 1, mt: width > 750 ? 0 : 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  )
}

export default MainHeader;