import React from "react";
import { TextField } from "@mui/material";
import TranslationContent from "../../../Translations/TranslationContent";


const ReadTextField = ({ label, value, multiline, minRows }) => {
  return(
    <TextField 
      label={<TranslationContent contentID={label} />} 
      //helperText={value.length > maxStringLength / 2 ? `${value.length}/${maxStringLength}` : ""}
      multiline={multiline ? multiline : false}
      autoComplete="off"  
      size="small" 
      inputProps={{ readOnly: true}} 
      value={value} 
      fullWidth
      minRows={minRows ? minRows : 1}
      variant="standard"
    />
  )
}


export default ReadTextField;