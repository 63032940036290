import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const getSession = async (accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/session`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const getActiveSession = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/session/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const siteServices = { getSession, getActiveSession };
export default siteServices;