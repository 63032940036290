import React from "react";
import { Tokens } from "../../services/Tokens";
import imageService from "../../services/images";
import { logger } from "../../services/logger";
import { Typography, Skeleton, Stack } from "@mui/material";
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { SnackBarContext } from "../../Context/SnackbarProvider";
import TranslationContent from "../../Translations/TranslationContent";


const GuideImage = ({ renderImage, formId, imageId, images, updateImages, openLargeImage }) => {
  const [imageState, setImageState] = React.useState("empty");
  const [imageData, setImageData] = React.useState("");
  const { addAlert } = React.useContext(SnackBarContext);

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(fetchData.current && renderImage && imageId !== "") {
      setImageState("loading");
      fetchData.current = false;
    
    let localImage = images.find(image => image.id === imageId)
    if(localImage) {
      setImageData(localImage.data);
      setImageState("fetched")
    }
    else {
      Tokens().then((tokens) => {
        imageService
        .getFormImage(formId, imageId, tokens.accessToken, tokens.idToken)
        .then(response => {
          logger(response);
          updateImages(response, "add");
          setImageState("fetched")
          setImageData(response.data);
        })
        .catch(error => {
          logger(error);
          setImageState("error")
        })
      })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarSessionExpired", type: "error"});
        setImageState("error")
      })
    
  }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[renderImage])

  switch(imageState) {
    case "empty":
      return null;
    case "loading":
      return (
        <Stack sx={{ width: "100%", textAlign: "center", alignContent: "center", justifyContent: "center", display: "inline-grid"}}>
          <Skeleton variant="retangular" width={125} height={125} />
        </Stack>
      ) 
    case "fetched":
      return (
      <Stack sx={{ width: "100%", textAlign: "center", alignContent: "center", justifyContent: "center", display: "inline-grid"}}>
        <img src={imageData} onClick={() => openLargeImage(imageData)} alt="Inspector's observation's visual content" key={`Observation image - ${imageId}`} height={125} width={125} style={{border: "1px solid", cursor: "zoom-in"}}/>
        <Typography variant="caption"><i><TranslationContent contentID="guideImage" /></i></Typography>
      </Stack>
      )
    case "error":
      return <BrokenImageIcon color="error" sx={{width: 125, height: 125}} />;
    default: 
      return null;
  }
}

export default GuideImage;