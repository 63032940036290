import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, IconButton, Stack, Divider, TextField, Checkbox, FormControlLabel } from "@mui/material";
import TranslationContent from "../../../Translations/TranslationContent";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";


const OtherObservations = ({ form, handleToggleChange, handleTextFieldChange }) => {
  return(
    <>
      <Grid xs={12} mt={3} textAlign={"center"}>
        <Divider textAlign="left" sx={{mb:1}}><Typography color="primary" variant="caption"><b><TranslationContent contentID="otherObservations" /></b></Typography></Divider>
      </Grid>
      <Grid xs={12} md={6}>
        <FormControlLabel control={<Checkbox disabled={form.permissionType === "read"} checked={form.otherObservations.available} onChange={() => handleToggleChange("observationsAvailable")} />} label={<TranslationContent contentID="otherObservationsAvailable" />} />
      </Grid>
      <Grid xs={12} md={6}>
        <FormControlLabel control={<Checkbox disabled={form.permissionType === "read"} checked={form.otherObservations.imagesEnabled} onChange={() => handleToggleChange("imagesEnabled")} />} label={<TranslationContent contentID="allowImages" />} />
      </Grid>
      <Grid xs={12} md={6}>
        <FormControlLabel control={<Checkbox disabled={form.permissionType === "read"} checked={form.otherObservations.restrictMax} onChange={() => handleToggleChange("restrictMax")} />} label={<TranslationContent contentID="setMaxObservations" />} />
      </Grid>
      <Grid xs={12} md={6}>
        <Stack direction="row" spacing={1} ml={-1}>
          <IconButton color="primary" onClick={() => handleToggleChange("decreaseMaxCount")} disabled={form.otherObservations.maxCount === 0 || form.permissionType === "read"}><RemoveCircleIcon /></IconButton>
          <TextField 
            label={<TranslationContent contentID="observationsMax" />} 
            autoComplete="off"
            size="small" 
            fullWidth 
            value={form.otherObservations.maxCount} 
            onChange={(event) => handleTextFieldChange(event, "maxObservationCount")} 
            inputProps={{
              pattern: '[0-9]*', // Use the pattern attribute to enforce numeric input
              inputMode: 'numeric', // Use inputMode to provide a numeric keyboard on mobile devices
              readOnly: form.permissionType === "read",
            }}
          />
          <IconButton disabled={form.permissionType === "read"} color="primary" onClick={() => handleToggleChange("increaseMaxCount")}><AddCircleIcon /></IconButton>
        </Stack>
      </Grid>
    </>
  )
}

export default OtherObservations;