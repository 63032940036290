import React from "react";
import MainLayout from "../../../Layouts/MainLayout";
import { Button, Autocomplete, Paper, Typography, Chip, TextField, Stepper, Step, StepContent, StepButton, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import StartIcon from '@mui/icons-material/Start';
import Error from "../../GeneralComponents/Error";
import Loading from "../../GeneralComponents/Loading";
import TranslationContent from "../../../Translations/TranslationContent";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import { Tokens } from "../../../services/Tokens";
import siteService from "../../../services/sites";
import formService from "../../../services/forms";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { SessionContext, SessionDispatchContext } from "../../../Context/SessionProvider";
import { LanguageContext } from "../../../Translations/LanguageProvider";
import inspectionService from "../../../services/inspections";
import { logger } from "../../../services/logger";


const InspectCreateNew = () => {
  const [step, setStep] = React.useState(0);
  const [site, setSite] = React.useState({});
  const [form, setForm] = React.useState({});
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [measurementNameEN, setMeasurementNameEN] = React.useState("");
  const [measurementNameFI, setMeasurementNameFI] = React.useState("");
  const [sites, setSites] = React.useState(null);
  const [forms, setForms] = React.useState(null);
  const { addAlert } = React.useContext(SnackBarContext);
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const currentLang = React.useContext(LanguageContext);
  const navigate = useNavigate();

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    if(fetchData.current && session.roles.length > 0) {
      fetchData.current = false;
      fetchSitesAndInspections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchSites = () => {
    return new Promise((resolve, reject) => {
      Tokens().then((tokens) => {
        siteService
        .getSites(session.activeRole.organizationId, tokens.accessToken, tokens.idToken)
        .then(response => {
          logger(response);
          resolve(response)
        })
        .catch(error => {
          logger(error);
          setError(true);
          setLoading(false);
          addAlert({message: "snackbarFetchingSitesFailed", type: "error"});
        })
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarSessionExpired", type: "error"});
      })
    })
  }

  const fetchInspectionForms = () => {
    return new Promise((resolve, reject) => {
      Tokens().then((tokens) => {
        formService
        .getForms(session.activeRole.organizationId, tokens.accessToken, tokens.idToken)
        .then(response => {
          logger(response);
          resolve(response)
        })
        .catch(error => {
          logger(error);
          setError(true);
          setLoading(false);
          addAlert({message: "snackbarFetchingInspectionsFailed", type: "error"});
        })
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarSessionExpired", type: "error"});
      })
    })
  }
  
   const fetchSitesAndInspections = async () => {
    const requests = [
      fetchSites(),
      fetchInspectionForms(),
    ]

    const data = await Promise.all(requests);
    if(data) {
      setSites([...data[0]])
      setForms([...data[1]]);
      setLoading(false)
    }
  }

  const submitInspection = () => {
    setLoading(true);
    let requestBody = {
      siteId: site.id,
      formId: form.id,
      nameEN: measurementNameEN,
      nameFI: measurementNameFI,
    }
    Tokens().then((tokens) => {
      inspectionService
      .createInspection(requestBody, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        const updatedSession = {...session};
        updatedSession.unfinishedInspections = updatedSession.unfinishedInspections + 1;
        updateSession({type: "UPDATE_SESSION", value: updatedSession});
        navigate(`/inspect/active/${response}`)
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarInspectionCreationFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const handleSiteSelect = (event, value) => {
    let updatedSite = {...site};
    updatedSite = {...value};
    setSite(updatedSite);
    if(value) {
      setStep(1);
    }
  }

  const handleInspectionSelect = (event, value) => {
    let updatedForm = {...form};
    updatedForm = {...value};
    setForm(updatedForm);
    if(value) {
      setStep(2);
    }
  }

  const retryClick = () => {
    setLoading(true);
    setError(false);
    fetchSitesAndInspections();
  }

  if(error && !loading) return <Error section="newInspection" retryClick={retryClick} />;
  if(loading && !error) return <Loading section="newInspection" />;

  if(!loading && !error) {
    return(
      <MainLayout>
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12} sx={{display: "flex"}}>
              <Stack direction="row">
                <Typography color="primary" variant="body2"><b style={{cursor: "pointer"}} onClick={() => navigate("/inspect/new")} >
                  <TranslationContent contentID="newInspection" /></b><KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
                </Typography>
                <Chip color="primary" size="small" label={<b style={{color: "yellow"}}><TranslationContent contentID="new" /></b>} />
              </Stack>    
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: "7px 5% 25px 5%", width: "100%" }}>
          <Grid container spacing={2} mt={0}>
            <Grid xs={12}>
              <Stepper orientation="vertical" activeStep={step} nonLinear>
                <Step completed={site?.id !== undefined && site.id !== ""}>
                  <StepButton onClick={() => setStep(0)}><TranslationContent contentID="chooseSite" /></StepButton>
                  <StepContent>
                    <Autocomplete
                      filterSelectedOptions
                      value={site}
                      onChange={(event, value) => handleSiteSelect(event, value)}
                      size="small"
                      sx={{textAlign:"center", justifyContent: "center", margin: "auto", alignItems: "center", minWidth: 26, marginTop: 2}}
                      disablePortal
                      id="new-inspection-site-selection"
                      options={sites}
                      getOptionLabel={(option) => typeof option.id !== 'undefined' ? (`${option.streetAddress} - ${option.customerName} (${option.city})`) : ""}
                      freeSolo
                      forcePopupIcon           
                      isOptionEqualToValue={(option, value) => option.id === value.id || value === ""}
                      renderInput={(params) => <TextField {...params} label={<TranslationContent contentID="site" />} />}
                    />
                    {(session.activeRole.role === "admin" || session.activeRole.role === "manager") && 
                      <>
                        <Typography variant="caption"><TranslationContent contentID="siteNotFound" /></Typography><Button size="small" onClick={() => navigate("/site")}><TranslationContent contentID="createSite" /></Button>
                      </>
                    }
                  </StepContent>
                </Step>
                <Step completed={form?.id !== undefined && form.id !== ""}>
                  <StepButton disabled={site.id === undefined || site.id === ""} onClick={() => setStep(1)}><TranslationContent contentID="selectInspection" /></StepButton>
                  <StepContent>
                    <Autocomplete
                      filterSelectedOptions
                      value={form}
                      onChange={(event, value) => handleInspectionSelect(event, value)}
                      size="small"
                      sx={{textAlign:"center", justifyContent: "center", margin: "auto", alignItems: "center", minWidth: 265, marginTop: 2}}
                      id="new-inspection-form-selection"
                      options={forms.filter(form => form.available || form.globallyAvailable)}
                      getOptionLabel={(option) => option.name ? `${option.name[currentLang.lang]} - ${option.owner} (${option.creator.name})` : ""}
                      isOptionEqualToValue={(option, value) => option.id === value.id || value === ""}
                      disabled={step === 0}
                      renderInput={(params) => <TextField {...params} label={<TranslationContent contentID="inspection" />} />}
                    />
                  </StepContent>
                </Step>
                <Step completed={measurementNameEN.length > 5 && measurementNameFI.length > 5}>
                  <StepButton disabled={(site.id === undefined || site.id === "") || (form.id === undefined || form.id === "")} onClick={() => setStep(2)}><TranslationContent contentID="nameInspection" /></StepButton>
                  <StepContent>
                    <TextField size="small" sx={{marginTop: 2}} required disabled={step < 2} label={<TranslationContent contentID="inspectionNameEN" />} value={measurementNameEN} fullWidth onChange={event => setMeasurementNameEN(event.target.value)}/>
                    <TextField size="small" sx={{marginTop: 2}} required disabled={step < 2} label={<TranslationContent contentID="inspectionNameFI" />} value={measurementNameFI} fullWidth onChange={event => setMeasurementNameFI(event.target.value)}/>
                    <Typography variant="caption" sx={{opacity: 0.7}}>* <TranslationContent contentID="atLeastOneNameHelperText" /></Typography>
                    <Button onClick={() => submitInspection()} variant="contained" sx={{marginTop: 2}} disabled={measurementNameEN.length < 5 && measurementNameFI.length < 5} fullWidth><TranslationContent contentID="startInspection" />&nbsp;&nbsp;<StartIcon /></Button>
                  </StepContent>
                </Step>
              </Stepper>
            </Grid>
          </Grid>
        </Paper>
      </MainLayout>
    )
  }
}

export default InspectCreateNew;