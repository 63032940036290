import React from "react";
import { Typography, Zoom, ButtonGroup, Divider, CircularProgress, Button, TextField  } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SaveIcon from "@mui/icons-material/Save";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import SiteInspecitonsTable from "./SiteInspectionsTable";
import { Tokens } from "../../services/Tokens";
import siteService from "../../services/sites";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { SessionContext } from "../../Context/SessionProvider";
import SiteArchiveTable from "./SiteArchiveTable";
import AddInspection from "./AddInspection";
import formService from "../../services/forms";
import organizationService from "../../services/organizations";
import inspectionService from "../../services/inspections";
import { logger } from "../../services/logger";
import SaveFabButton from "../Utils/GlobalComponents/SaveFabButton";
import TranslationContent from "../../Translations/TranslationContent";


const SiteEditor = ({ show, validateSiteInputs, sites, updateSites, updateSelectedSite, selectedSite }) => {
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [showSaveButton, setShowSaveButton] = React.useState(false);
  const [updateUnfinishedInspections, setUpdateUnfinishedInspections] = React.useState(false);
  const [site, setSite] = React.useState(
    {
      brandId: "",
      id: "",
      organizationId: "",
      customerName: "",
      customerEmail: "",
      customerPhone: "",
      streetAddress: "",
      postalCode: "",
      propertyName: "",
      propertyType: "",
      city: ""
    }
  );

  const [inspectors, setInspectors] = React.useState([]);
  const [inspectionForms, setInspectionsForms] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);

  const session = React.useContext(SessionContext);
  const { addAlert } = React.useContext(SnackBarContext);

  React.useEffect(() => {
    const updatedSite = {...site, ...selectedSite};
    setSite(updatedSite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedSite])

  const fetchNewInspectionData = async () => {
    setFetching(true);
    const requests = [
      fetchInspectors(),
      fetchInspectionForms()
    ]
    let data = await Promise.all(requests);
    if(data) {
      let updatedInspectionsForms = [...inspectionForms];
      updatedInspectionsForms = [...data[1]]
      let updatedInspectors = [...inspectors];
      updatedInspectors = [...data[0]]
      setInspectionsForms(updatedInspectionsForms);
      setInspectors(updatedInspectors);
      setFetching(false);
    }
  }

  const fetchInspectors = () => {
    return new Promise((resolve, reject) => {
      Tokens().then((tokens) => {
        organizationService
        .getOrganization(session.activeRole.organizationId, tokens.accessToken, tokens.idToken)
        .then(response => {
          logger(response);
          resolve(response.roles);
          })
        .catch(error => {
          logger(error);
          addAlert({message: "snackbarFetchingInspectorsFailed", type: "error"});
        })
      })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarSessionExpired", type: "error"});
      })
    })
  }

  const fetchInspectionForms = () => {
    return new Promise((resolve, reject) => {
      Tokens().then((tokens) => {
        formService
        .getForms(session.activeRole.organizationId, tokens.accessToken, tokens.idToken)
        .then(response => {
          logger(response);
          resolve(response);
        })
        .catch(error => {
          logger(error);
          addAlert({message: "snackbarFetchingInspectionFormsFailed", type: "error"});
        })
      })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarSessionExpired", type: "error"});
      })
    })
  }

  const addNewTimedInspection = (newInspection) => {
    setFetching(true);
    newInspection.siteId = site.id;
    Tokens().then((tokens) => {
      inspectionService
      .createInspection(newInspection, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        setFetching(false);
        addAlert({message: "snackbarAddInspectionSuccess", type: "success"});
        setUpdateUnfinishedInspections(true);
      })
      .catch(error => {
        logger(error);
        setFetching(false);
        addAlert({message: "snackbarAddInspectionFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setFetching(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })

  }

  const saveSite = () => {
    setLoading(true);
    Tokens().then((tokens) => {
      siteService
      .updateSite(site, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response)
        let updatedSites = sites.map(site => {
          if(site.id === selectedSite.id) {
            site = {...site, ...selectedSite}
            return site;
          }
          return site;
        });
        updateSites(updatedSites);
        setShowSaveButton(false);
        setLoading(false);
        addAlert({message: "snackbarSaveSiteSuccess", type: "success"});
      })
      .catch(error => {
        logger(error);
        setLoading(false);
        addAlert({message: "snackbarSaveSiteFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const deleteSite = () => {
    setDeleting(true);
    setShowDeleteDialog(false);
    Tokens().then((tokens) => {
      siteService
      .deleteSite(site.id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedSites = sites.filter(listSite => listSite.id !== site.id)
        updateSites(updatedSites);
        setShowSaveButton(false);
        addAlert({message: "snackbarDeleteSiteSuccess", type: "success"});
        updateSelectedSite("", false);
        setDeleting(false);
      })
      .catch(error => {
        logger(error);
        setDeleting(false);
        addAlert({message: "snackbarDeleteSiteFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setDeleting(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const handleTextFieldChange = (event, type) => {
    let validOrganizationData  = false;
    
    let updatedSelectedSite = {...site};
    if(type === "propertyName") {
      updatedSelectedSite.propertyName = event.target.value;
    }
    if(type === "propertyType") {
      updatedSelectedSite.propertyType = event.target.value;
    }
    if(type === "customerName") {
      updatedSelectedSite.customerName = event.target.value;
    }
    if(type === "customerEmail") {
      updatedSelectedSite.customerEmail = event.target.value;
    }
    if(type === "customerPhone") {
      updatedSelectedSite.customerPhone = event.target.value;
    }
    if(type === "address") {
      updatedSelectedSite.streetAddress = event.target.value;
    }
    if(type === "postalCode") {
      updatedSelectedSite.postalCode = event.target.value;
    }
    if(type === "city") {
      updatedSelectedSite.city = event.target.value;
    }
    setSite(updatedSelectedSite);
    validOrganizationData = validateSiteInputs(updatedSelectedSite);
    setShowSaveButton(validOrganizationData);
  }


  if(show) {
    return(
      <>
        <ConfirmDialog open={showDeleteDialog} confirmClick={() => deleteSite()} title="deleteSiteTitle" text="deleteSiteDesc" handleClose={() => setShowDeleteDialog(false)} />
        <SaveFabButton show={showSaveButton} loading={loading} onClick={() => saveSite()} disabled={loading || deleting} />
        <ButtonGroup variant="text" sx={{width:"100%"}}>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} onClick={() => updateSelectedSite("", false)} disabled={loading || deleting}><KeyboardReturnIcon /><Typography variant="caption" ><TranslationContent contentID="return" /></Typography></Button>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} color="error" onClick={() => setShowDeleteDialog(true)} disabled={loading || deleting}>{deleting ? <CircularProgress color="error" /> :<><DeleteForeverIcon /><Typography variant="caption" ><TranslationContent contentID="delete" /></Typography></>}</Button>
          <Zoom in={showSaveButton}><Button onClick={() => saveSite()} size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} disabled={loading || deleting}>{loading ? <CircularProgress color="primary"/> : <><SaveIcon /><Typography variant="caption" ><TranslationContent contentID="save" /></Typography></>}</Button></Zoom>
        </ButtonGroup>
        <Grid container spacing={2} mt={0} margin={0}>
          <Grid xs={12}><Divider /></Grid>
          <Grid xs={12} mt={1} >
            <Divider textAlign="left"><Typography color="primary" variant="caption"><b><TranslationContent  contentID="siteInfo" /></b> <i style={{opacity: 0.7, fontSize: "smaller"}}>(*<TranslationContent contentID="required" />)</i></Typography></Divider>
          </Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="propertyName" />} autoComplete="off" required fullWidth value={site.propertyName} onChange={(event) => handleTextFieldChange(event, "propertyName")} disabled={loading || deleting} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="propertyType" />} autoComplete="off" required fullWidth value={site.propertyType} onChange={(event) => handleTextFieldChange(event, "propertyType")} disabled={loading || deleting} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="propertyOwner" />} autoComplete="off" required fullWidth value={site.customerName} onChange={(event) => handleTextFieldChange(event, "customerName")} disabled={loading || deleting} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="propertyEmail" />} autoComplete="off" required fullWidth value={site.customerEmail} onChange={(event) => handleTextFieldChange(event, "customerEmail")} disabled={loading || deleting} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="propertyPhone" />} autoComplete="off" required fullWidth value={site.customerPhone} onChange={(event) => handleTextFieldChange(event, "customerPhone")} disabled={loading || deleting} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="streetAddress" />} autoComplete="off" required fullWidth value={site.streetAddress} onChange={(event) => handleTextFieldChange(event, "address")} disabled={loading || deleting} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="postalCode" />} autoComplete="off" required fullWidth value={site.postalCode} onChange={(event) => handleTextFieldChange(event, "postalCode")} disabled={loading || deleting} /></Grid>
          <Grid xs={12} md={6}><TextField label={<TranslationContent contentID="city" />} autoComplete="off" required fullWidth value={site.city} onChange={(event) => handleTextFieldChange(event, "city")} disabled={loading || deleting} /></Grid>
          <Grid xs={12} mt={1} textAlign={"center"}>
            <Divider textAlign="left" ><Typography color="primary" variant="caption"><b><TranslationContent contentID="unfinishedInspections" /></b></Typography></Divider>
          </Grid>
          <Grid xs={12} textAlign={"center"}>
            <SiteInspecitonsTable siteId={selectedSite.id} toggleUpdate={ () => setUpdateUnfinishedInspections(!updateUnfinishedInspections)} updateUnfinishedInspections={updateUnfinishedInspections}/>
          </Grid>
          <Grid xs={12} textAlign={"center"}>
            <Divider textAlign="left" ><Typography color="primary" variant="caption"><b><TranslationContent contentID="completedInspections" /></b></Typography></Divider>
          </Grid>
          <SiteArchiveTable siteId={selectedSite.id} />
          <Grid xs={12}>
              <Divider />
          </Grid>
          <AddInspection loading={loading} deleting={deleting} fetchNewInspectionData={fetchNewInspectionData} inspectionForms={inspectionForms} inspectors={inspectors} fetching={fetching} addNewTimedInspection={addNewTimedInspection} />
        </Grid>
      </>
    )
  }
}

export default SiteEditor;