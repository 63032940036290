import React from "react";
import { Autocomplete,  IconButton, CircularProgress, TextField, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TranslationContent from "../../../Translations/TranslationContent";

const OrganizationSelector = ({ selectedUser, showRoleSelector, addRole, organizations, fetchOrganizations, deleting }) => {
  const [organizationInput, setOrganizationInput] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const loading = open && organizations.length === 0;

  const handleOrganizationChange = (newValue) => {
    
    let newRole = {
      oid: selectedUser.id,
      userName: selectedUser.name,
      brandId: newValue.brandId,
      organization: {
        name: newValue.name,
        streetAddress: newValue.streetAddress,
        postalCode: newValue.postalCode,
        city: newValue.city,
        phone: newValue.phone,
        email: newValue.email,
      },
      organizationId: newValue.id,
      type: newValue.type,
    }
    
    if(newValue.type === "external") {
      newRole.role = "inspector";
    }
    if(newValue.type === "internal") {
      newRole.role = "admin";
      newRole.internalRights = {
        userManagement: false,
        organizationManagement: false,
        siteManagement: false,
        inspectionFormManagement: false,
        reportManagement: false,
        inspect: false,
      }
    }
    setOpen(false);
    setOrganizationInput("");
    showRoleSelector();
    addRole(newRole);
  }

  React.useEffect(() => {
    if(open && organizations.length === 0) {
      fetchOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])
  
  return(
    <Stack mt={3} direction="row">
      <Autocomplete
        disablePortal
        value={null}
        onChange={(event, newValue) => handleOrganizationChange(newValue)}
        inputValue={organizationInput}
        onInputChange={(event, newInputValue) => setOrganizationInput(newInputValue)}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={organizations}
        filterOptions={(options) => options.filter(option => !selectedUser.roles.some(role => role.organizationId === option.id) && option.name.toLowerCase().includes(organizationInput.toLowerCase()))}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        noOptionsText={<TranslationContent contentID="noOrganizationsAvailable" />}
        disabled={deleting}
        fullWidth
        loading={loading}
        loadingText={<TranslationContent contentID="fetchingOrgs" />}
        renderInput={(params) => 
          <TextField {...params} label={<TranslationContent contentID="organization" />}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        }
      />
      <IconButton onClick={() => showRoleSelector()} disabled={deleting} color="error"><DeleteIcon /></IconButton>
    </Stack>
  )
}

export default OrganizationSelector;