import React from "react";
import { Tokens } from "../../services/Tokens";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import userService from "../../services/users";
import OrganizationCreator from "./OrganizationCreator";
import OrganizationTableContent from "./OrganizationTableContent";
import OrganizationEditor from "./OrganizationEditor";
import { logger } from "../../services/logger";


const OrganizationTable = ({ updateSelectedOrganizationName, updateOrganizations, selectedOrganizationName, creationMode, organizations }) => {
  const [selectedOrganization, setSelectedOrganization] = React.useState({});
  const [allUsers, setAllUsers] = React.useState([]);
  const [fetchingAllUsers, setFetchingAllUsers] = React.useState(false);
  const { addAlert } = React.useContext(SnackBarContext);

  
  const fetchAllUsers = () => {
    setFetchingAllUsers(true)
    Tokens().then((tokens) => {
      userService
      .getUsers(tokens.accessToken, tokens.idToken)
      .then(response => {
        let updatedAllUsers = [...allUsers];
        updatedAllUsers = [...response];
        setAllUsers(updatedAllUsers);
        setFetchingAllUsers(false);
      })
      .catch(error => {
        logger(error);
        setFetchingAllUsers(false);
        addAlert({message: "snackbarFetchingUsersFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setFetchingAllUsers(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const rowClick = (organization, isNew) => {
    if(isNew) {
      updateSelectedOrganizationName("", isNew);
    }
    else {
      updateSelectedOrganizationName(organization.name, isNew);
      let updatedSelectedOrganization = {...selectedOrganization};
      updatedSelectedOrganization = {...organization};
      setSelectedOrganization(updatedSelectedOrganization);
    }
  }

  const validateOrganizationInput = (updatedNewOrganization) => {
    let validStatuses = [false, false, false, false, false, false]
    if(updatedNewOrganization.name.length > 5 && updatedNewOrganization.name.length < 25) {
      validStatuses[0] = true;
    }
    else {
      validStatuses[0] = false;
    }
    if(updatedNewOrganization.streetAddress.length > 5 && updatedNewOrganization.streetAddress.length < 20) {
      validStatuses[1] = true;
    }
    else {
      validStatuses[1] = false;
    }
    if(updatedNewOrganization.postalCode.length === 5) {
      validStatuses[2] = true;
    }
    else {
      validStatuses[2] = false;
    }
    if(updatedNewOrganization.city.length > 1 && updatedNewOrganization.city.length < 20) {
      validStatuses[3] = true;
    }
    else {
      validStatuses[3] = false;
    }
    if(updatedNewOrganization.email.length > 10 && updatedNewOrganization.email.length < 30) {
      validStatuses[4] = true;
    }
    else {
      validStatuses[4] = false;
    }
    if(updatedNewOrganization.phone.length > 6 && updatedNewOrganization.phone.length < 15) {
      validStatuses[5] = true;
    }
    else {
      validStatuses[5] = false;
    }
    logger(validStatuses)
    let valid = validStatuses.every(status => status === true);
    return valid;
  }

  const backButtonClick = () => {
    setSelectedOrganization({});
    updateSelectedOrganizationName("", false);
  }
  
  return(
    <>
      <OrganizationTableContent 
        show={selectedOrganizationName === "" && !creationMode} 
        rowClick={rowClick} 
        organizations={organizations}
      />
      <OrganizationCreator 
        show={creationMode} 
        backButtonClick={backButtonClick} 
        organizations={organizations} 
        allUsers={allUsers}
        fetchAllUsers={fetchAllUsers} 
        fetchingAllUsers={fetchingAllUsers}
        updateOrganizations={updateOrganizations} 
        updateSelectedOrganizationName={updateSelectedOrganizationName} 
        validateOrganizationInput={validateOrganizationInput}
      />
      <OrganizationEditor 
        show={selectedOrganizationName !== "" && !creationMode} 
        backButtonClick={backButtonClick} 
        organizations={organizations} 
        organization={selectedOrganization}
        allUsers={allUsers}
        fetchAllUsers={fetchAllUsers} 
        fetchingAllUsers={fetchingAllUsers}
        updateOrganizations={updateOrganizations} 
        updateSelectedOrganizationName={updateSelectedOrganizationName} 
        validateOrganizationInput={validateOrganizationInput}
      />
    </>
  )
}

export default OrganizationTable;