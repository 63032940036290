import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TranslationContent from "../../../Translations/TranslationContent";


const EmptyListText = ({ show, text }) => {
  if(show) {
    return(
      <Grid xs={12}>
      <Box textAlign={"center"}>
        <Typography sx={{opacity: 0.5}}><TranslationContent contentID={text} /></Typography>
      </Box>
      </Grid>
    )
  }
  else return null;
}

export default EmptyListText;