import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography,  Box, Stack, Card, CardActionArea, CardContent } from "@mui/material";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import LoadingSpinner from "../Loading/LoadingSpinner";
import { SessionContext } from "../../Context/SessionProvider";
import FormSelectDialog from "../Dialogs/FormSelectDialog";
import { Tokens } from "../../services/Tokens";
import formService from "../../services/forms";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { logger } from "../../services/logger";
import TranslationContent from "../../Translations/TranslationContent";


const NewForm = ({ show, forms }) => {
  const [loading, setLoading] = React.useState(false);
  const [showFormSelection, setShowFormSelection] = React.useState(false);
  const session = React.useContext(SessionContext);
  const navigate = useNavigate();
  const { addAlert } = React.useContext(SnackBarContext);

  const submitForm = (formTemplateId) => {
    setLoading(true);
    let requestBody = {
      organizationId: session.activeRole.organizationId,
      brandId: session.activeRole.brandId,
      formTemplateId: formTemplateId
    }
    Tokens().then((tokens) => {
      formService
      .createForm(requestBody, tokens.accessToken, tokens.idToken)
      .then(response => {
        navigate(`/form/${response}`);
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setLoading(false);
        addAlert({message: "snackbarInspectionCreationFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const confirmTemplateSelection = (id) => {
    setShowFormSelection(false);
    submitForm(id);
  }

  if(show && loading) {
    return (
      <Box textAlign={"center"}>
        <LoadingSpinner />
        <Typography sx={{opacity: 0.5, mt: 2}}><TranslationContent contentID="initializingForm" /></Typography>
      </Box>
    )
  }

  if(show && !loading) {
    return(
      <Grid container spacing={2}>
        <FormSelectDialog forms={forms} open={showFormSelection} handleClose={() => setShowFormSelection(false)} confirmTemplateSelection={confirmTemplateSelection} />
        <Grid xs={12} md={6}>
          <Card sx={{height: "20vh"}}>
            <CardActionArea sx={{ padding: 2, width: "100%", height: "100%" }} onClick={() => submitForm("")}>
              <CardContent sx={{display: "flex"}}>
                <Box sx={{width: "80%"}}>
                  <Stack>
                    <Typography variant="h4">
                      <TranslationContent contentID="createNew" />
                    </Typography>
                    <Typography variant="body2" sx={{opacity: 0.6}}>
                      <TranslationContent contentID="startFromEmptyForm" />
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={{width: "20%"}}>
                  <ContentPasteOffIcon sx={{height: "60px", width: "60px"}} color="success" opacity={0.3} mr={0} ml="auto"/>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid xs={12} md={6}>
          <Card sx={{height: "20vh"}}>
            <CardActionArea sx={{padding: 2, width: "100%", height: "100%"}} onClick={() => setShowFormSelection(true)}>
              <CardContent sx={{display: "flex"}}>
                <Box sx={{width: "80%"}}>
                  <Stack>
                    <Typography variant="h4" >
                      <TranslationContent contentID="selectTemplate" />
                    </Typography>
                    <Typography variant="body2" sx={{opacity: 0.6}}>
                      <TranslationContent contentID="selectTemplateDesc" />
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={{width: "20%"}}>
                  <ContentPasteGoIcon sx={{height: "60px", width: "60px"}} color="primary" opacity={0.3} mr={0} ml="auto"/>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    )
  }
  else return null;
}

export default NewForm;