export const translations = {

  // ENGLISH TRANSLATIONS
  en: {
    //Global buttons
    darkModeButton: "Dark",
    lightModeButton: "Light",
    finnish: "suomi",
    english: "English",
    languageSelectLabel: "Language",
    logout: "Logout",
    home: "Home",
    newInspection: "New inspection",
    continueInspection: "Continue inspection",
    inspectionArchive: "Archive",
    account: "Account",
    userManagement: "User management",
    organizationManagement: "Organizations",
    siteManagement: "Sites",
    inspectionTemplate: "Inspection templates",
    reportTemplate: "Report templates",
    close: "Close",
    cancel: "Cancel",
    retry: "Retry",
    internalUser: "Internal user",
    externalUser: "External user",
    createRoleLater: "Create role later",
    save: "Save",
    addRole: "Add role",
    delete: "Delete",
    deleteUser: "Delete user",
    return: "Return",
    internal: "Internal",
    external: "External",
    all: "All",
    clearFilters: "Remove filters",
    apply: "Apply",
    confirm: "Confirm",
    reset: "Reset",
    add: "Add",
    ready: "Done",


    // Global texts and labels
    errorOccured: "An error occurred!",
    somethingWrong: "Something went wrong.",
    applicationAccepted: "Application accepted!",
    postSuccessDesc1: "Before a person can use the application, a role for",
    postSuccessDesc2: "must be created.",
    selectAccessRights: "Set permissions",
    accessRights: "Permissions",
    userManagementCheck: "Administration of user accounts",
    organizationManagementCheck: "Management of organizations",
    siteManagementCheck: "Management of sites",
    inspectionFormManagementCheck: "Management of inspection forms",
    reportManagementCheck: "Management of reports",
    inspectCheck: "Conducting inspections",
    filters: "Filters",
    userType: "User type",
    rowsPerPage: "Rows per page",
    new: "NEW",
    customer: "Customer",
    schedule: "Schedule",
    deleted: "Deleted",
    selectForm: "Select form",
    confirmSelection: "Confirm selection",
    version: "Version",
    environment: "Environment",
    zeroUnfinishedInspections: "No unfinished inspections",
    selectInspection: "Select inspection",
    noInspections: "No inspections",
    edited: "Modified",


    //Login component
    loginHeader: "Login",
    loginCaption: "Please sign in to continue.",
    loginFailed: "Login failed!",
    loginButton: "Sign in",

    //Snackbar content
    snackbarPlaceholderText: "Placeholder for text",
    snackbarSessionExpired: "Session expired! Please login again to continue.",
    snackbarAccountUpdateSuccess: "Account updated!",
    snackbarAccountUpdateFailed: "Updating account failed",
    snackbarAccountAlreadyActive: "Role already active",
    snackbarActiveRoleSwitchSuccess: "Active role changed successfully",
    snackbarActiveRoleSwitchFailed: "Failed to switch active role",
    snackbarOrganizatrionFetchFailed: "Failed to fetch organizations",
    snackbarFetchingUsersFailed: "Failed to fetch users",
    snackbarUserUpdateSuccessful: "User updated",
    snackbarUserUpdateFailed: "Failed to update user",
    snackbarUserDeleteSuccessful: "User deleted",
    snackbarUserDeleteFailed: "Failed to delete user",
    snackbarSubmitNewOrgFailed: "Failed to create organization",
    snackbarSubmitNewOrgSuccess: "Organization created",
    snackbarOrganizationUpdateFailed: "Failed to update organization",
    snackbarOrganizationUpdateSuccess: "Organization updated",
    snackbarOrganizationDeleteSuccess: "Organization deleted",
    snackbarOrganizationDeleteFailed: "Failed to delete organization",
    snackbarFetchingSitesFailed: "Failed to fetch sites",
    snackbarSubmitNewSiteFailed: "Failed to create site",
    snackbarSubmitNewSiteSuccess: "Site created",
    snackbarFetchingInspectorsFailed: "Failed to fetch inspectors",
    snackbarFetchingInspectionFormsFailed: "Failed to fetch inspection forms",
    snackbarSaveSiteSuccess: "Site updated",
    snackbarSaveSiteFailed: "Failed to update site",
    snackbarDeleteSiteSuccess: "Site deleted",
    snackbarDeleteSiteFailed: "Failed to delete site",
    snackbarAddInspectionSuccess: "Inspection added",
    snackbarAddInspectionFailed: "Failed to add inspection",
    snackbarFetchingInspectionsFailed: "Failed to fetch inspections",
    snackbarDownloadFailed: "Download failed",
    snackbarDeleteInspectionFailed: "Failed to delete inspection",
    snackbarDeleteInspectionSuccess: "Inspection deleted",
    snackbarInspectionCreationFailed: "Failed to create inspection",
    snackbarFormSaveSuccess: "Form saved",
    snackbarFormSaveFailed: "Failed to save the form",
    snacbarFormDeleteSuccess: "Form deleted",
    snackbarFormDeleteFailed: "Failed to delete the form",
    snackbarInspectionSaveFailed: "Failed to save inspection",
    snackbarInspectionSaveSuccess: "Inspection saved",
    snackbarInspectionFinishFailed: "Failed to finish inspection",
    snackbarInspectionFinishSuccess: "Inspection completed",
    snackbarFailedToFetchData: "Failed to fetch data",
    snackbarImageDeleteSuccessful: "Image deleted",
    snackbarImageDeleteFailed: "Failed to delete image",
    snackbarImageSaveSuccessful: "Image saved",
    snackbarImageSaveFailed: "Failed to save image",
    snackbarObservationDeleteSuccessful: "Observation deleted",
    snackbarObservationDeleteFailed: "Failed to delete observation",
    snackbarObservationCreateSuccessful: "Observation created",
    snackbarObservationCreateFailed: "Failed to create observation",
    snackbarSectionCreateSuccess: "Section added",
    snackbarSectionCreateFailed: "Failed to add section",
    snackbarSectionDeleteSuccess: "Section deleted",
    snackbarSectionDeleteFailed: "Failed to delete section",

    errorRejectApplication: "Failed to reject application. Please try again later.",
    successRejectApplication: "Application rejected",
    errorAcceptApplication: "Failed to accept application. Please try again later.",
    successAcceptApplication: "Application accepted",

    //NOT FOUND 404
    notFoundCaption1: "OOPS! PAGE NOT FOUND",
    notFoundCaption2: "WE ARE SORRY TO INFORM YOU BUT THE PAGE YOU REQUESTED WAS NOT FOUND",
    notFoundTimer1: "REDIRECTING TO HOME PAGE IN",
    notFoundTimer2: "seconds",
    notFoundButton: "Back to home page",

    //Main header
    inspections: "Inspections",
    management: "Management",

    //Roles
    inspector: "Inspector",
    organizationAdmin: "Organization Admin",
    superAdmin: "Administrator",
    user: "User",
    welcomeback: "Welcome back",
    role: "Role",
    userList: "User list",


    // ACCOUNT
    active: "Active",
    activate: "Activate",
    roles: "Roles",
    preferences: "Preferences",
    personalInfo: "Personal info",
    workNumber: "Work phone number",
    workEmail: "Work email",
    preferenceShowDescription: "Show additional descriptions on the inspection form",

    // DOWNLOAD DOCUMENT
    openedDesc1: "",
    openedDesc2: "has had a",
    openedDesc3: "on ",
    openedDesc4: "The inspection was carried out by",
    openedDesc5: "You can download the report below.",
    downloadReport: "Download report",
    initialLinkTitle: "This link is one-time-only!",
    initialLinkDesc: "Once opened you can not open this report again. If you need access to this information again please download it to your device.",
    openLink: "Open link",
    linkDeprecated: "Link expired",

    // ERROR
    errorTitle: "An error occured!",
    errorDesc: "Fetching data failed",

    //USER MANAGEMENT
    openApplicants: "Open applications",
    noOpenApplicants: "No open applications",
    acceptApplication: "Accept the application",
    rejectApplication: "Reject the application",
    pending: "Pending",
    accepting: "Accepting",
    rejecting: "Rejecting",
    accepted: "Accepted",
    rejected: "Rejected",
    sending: "Sending",
    processedApplication: "Application processed",
    noExtraInfo: "No additional information",
    noPhoneNumber: "No phone number",
    users: "Users",
    applications: "Applications",

    //GLOBALS
    yes: "Yes",
    no: "No",
    manager: "Manager",
    admin: "Admin",
    logOutButton: "Logout",
    uploadNewImage: "Upload new image",
    removeImage: "Remove image",
    send: "Send",

    // TOOLTIPS
    deleteRole: "Delete role",
    deleteReport: "Delete report",
    downloadPDFReport: "Download pdf report",
    createOneTimeDownloadLink: "Create a one-time shareable download link (download does not require login)",
    deleteObservation: "Delete observation",

    // ROLES
    loginSuccessful: "Login successful!",
    loginSuccessDesc1: "Currently",
    loginSuccessDesc2: "account(s) signed in.",
    loginFailedDesc: "No accounts available.",
    sessionExpired: "",

    fetchingRoles: "Fetching role information...",
    selectAccount: "Select account",
    noGroupsAttachedErrorDesc: "There are no roles associated with the user account. To proceed to the application, contact your contact person and ensure that you have been assigned a role. Sometimes it may take a while for the rights to be updated, so we recommend trying again later.",
    chooseRole: "Choose a role",

    hello: "Hello",
    applicationPendingDesc: "Your application has been received but not yet approved. Please check back later.",
    applicationInitialStatusDesc: "Before you can log in with your username, a role must be created for you. Please apply for a role using the form below.",
    sendingApplicationFailed: "Application submission failed!",
    rolesMissingDesc: "There are no roles associated with the user account! Please contact your immediate supervisor to add roles.",
    applicationReceived: "Application received!",
    applicationReceivedDesc: "We have received your application and it has now moved to the processing stage. You will not receive a separate notification when the processing of your application is complete.",
    organization: "Organization",
    phoneNumber: "Phone number",
    additionalInformation: "Additional information",

    // ORGANIZATIONS
    fetchingOrgs: "Fetching organizations...",
    noOrganizationsAvailable: "No organizations available",

    // DIALOGS
    preview: "Preview",
    linkCopiedToClipboard: "Link copied to clipboard",
    creatingLink: "Creating a download link",
    shareLinkCreatonFailed: "Failed to create download link.",
    linkCreatedTitle: "Link created!",
    linkCreatedDesc1: "The link is one-time use and valid for seven (7) days from the moment of creation.",
    linkCreatedDesc2: "Save the link to somewhere safe, because it will disappear when you close this window.",
    shareLink: "Share the link:",
    copyLinkToClipboard: "Copy the link to the clipboard",
    useFormDesc1: "Use the",
    useFormDesc2: "form as a basis for a new inspection form?",
    whatFormRULooking4: "What form are you looking for?",
    selectNewUsers: "Select new user(s)",

    // HOME
    newApplications: "New applications",
    nofiticationBannerText1: "Please review the",
    notificationBannerText2: "new application(s)",
    activeOrganization: "Active organization",
    unfinishedInspections: "Unfinished inspections",
    newInspections: "Scheduled inspections",
    switchButton: "Switch",

    // USERS
    whoAreULooking: "Who are you looking for?",
    name: "Name",
    email: "Email",
    type: "Type",
    userName: "User name",

    // CONFIRM DIALOG
    userDeleteTitle: "Delete user?",
    userDeleteDesc: "User removal is final. Deleted data cannot be recovered.",
    resetTitle: "Reset data?",
    resetDesc: "Data reset is final. Reset data cannot be restored.",
    deleteOrganizationTitle: "Delete organization?",
    deleteOrganizationDesc: "Removal of the organization is final. Deleted data cannot be recovered. The roles and inspections of the organization's associated users are also removed.",
    deleteReportTitle: "Delete report?",
    deleteReportDesc: "Deletion of the report is final. Deleted data cannot be recovered.",
    deleteSiteTitle: "Delete site?",
    deleteSiteDesc: "Site removal is final. Deleted data cannot be recovered. Also the inspections attached to the site are deleted.",
    deleteFormTitle: "Delete form?",
    deleteFormDesc: "Removal of the form is final. Deleted data cannot be recovered.",
    deleteInspectionTitle: "Delete inspection?",
    deleteInspectionDesc: "The removal of the inspection is final. Deleted data cannot be recovered.",
    finishInspectionTitle: "Finish the inspection?",
    finishInspectionDesc: "The action cannot be canceled and no further changes can be made to the inspection after it has been marked complete.",
    observationDeleteTitle: "Delete observation?",
    observationDeleteDesc: "Removal of the observation is final. Deleted data cannot be recovered. Deleting observation will delete all text and image content.",

    // ORGANIZATIONS
    address: "Address",
    streetAddress: "Street address",
    postalCode: "Postal code",
    city: "City",
    organizationInfo: "Organization information",
    addUser: "Add user",
    whatOrgULooking4: "What organization are you looking for?",
    orgHasNoUsers: "There are no users associated with the organization",
    toSaveHaveAtLeastOneManagerDesc: "To save the organization, at least one user has to be included with a manager role",

    // SITES
    siteInfo: "Site information",
    propertyName: "Property name",
    propertyType: "Property type",
    propertyOwner: "Property owner / manager",
    propertyEmail: "Email of the property owner",
    propertyPhone: "Phone number of the property owner",
    completedInspections: "Completed inspections",
    noUnfinishedInspections: "There are no pending inspections at the site",
    created: "Created", 
    daysAgo: "days ago",
    daysLate: "days late",
    timed: "Timed",
    toDays: "days from now",
    lastEdit: "Last edit",
    today: "today",
    late: "LATE",
    notStarted: "NOT STARTED",
    inProgress: "IN PROGRESS",
    scheduleInspection: "Schedule inpsection",
    nameFI: "Name (Finnish)",
    nameEN: "Name (English)",
    empty: "None",
    inspection: "Inspection",
    addInspection: "Add inspection",
    noCompletedInspections: "No inspections have been carried out on the site",
    completed: "Completed",
    done: "DONE",
    whatSiteRULooking4: "What site are you looking for?",
    noSitesFound: "No sites found",

    // ARCHIVE
    noArchivedInspections: "No inspections were found in the archive",

    // INSPECTIONS
    createNew: "Create new",
    chooseTargetAndInspection: "Select site & inspection",
    chooseNew: "Scheduled inspection",
    chooseSite: "Choose site",
    nameInspection: "Name the inspection",
    inspectionNameFI: "Inspection title (Finnish)",
    inspectionNameEN: "Inspection title (English)",
    startInspection: "Start inspection",
    site: "Site",
    siteNotFound: "Site not found?",
    createSite: "Create site",

     // FORM
     initializingForm: "Initializing form...",
     startFromEmptyForm: "Start from scratch",
     selectTemplate: "Select template",
     selectTemplateDesc: "Select template from existing inspection forms",
     owner: "Owner",
    creator: "Creator",
    noInspectionFormsFound: "No inspection form was found",
    noSections: "Please, add sections to see them here",
    otherObservations: "Other observations",
    otherObservationsAvailable: "Other observations -section available",
    allowImages: "Allow taking pictures",
    setMaxObservations: "Set the maximum number of observations",
    observationsMax: "Observations at most (pcs)",
    sectionNameEN: "Section's name (English)",
    sectionNameFI: "Section's name (Finnish)",
    deleteSection: "Delete section",
    editSection: "Edit section",
    hideSection: "Hide section",
    showSection: "Show section",
    addSection: "Add section",
    descEN: "Description (English)",
    descFI: "Description (Finnish)",
    categories: "Categories",
    basicInfo: "Basic info",
    sections: "Sections",
    noName: "Not named",
    formAvailable: "Form available",
    formAvailable2All: "Form available for all organizations",
    readOnly: "Read-only",
    readOnlyDesc: "Please contact your instance administrator to get access to edit the form",
    deleteForm: "Delete form",
    deleteInspection: "Delete inspection",
    category: "Category",
    ifEmptyAskAlwaysDesc: "If empty, the inspection is always asked regardless of the category selections",
    option: "Option",
    selectCategoryToSeeOptions: "Select a category to see options",
    forcedInspection: "Mandatory inspection",
    forcedInspectionDesc: "If set as mandatory, the inspection cannot be marked complete until all the mandatory inspections have been completed.",
    allowInspectorToTakeImages: "Allow the inspector to take pictures",
    guideImage: "Reference image",
    noGuideImage: "Reference image not added",
    answerType: "Response type",
    yesno: "Yes / No",
    numerical: "Numerical",
    smallestAcceptableValue: "Minimum acceptable value",
    greatestAcceptableValue: "Maximum acceptable value",
    inspectionsPerPage: "Inspections per page",
    options: "Options",
    deleteCategory: "Delete category",
    addCategory: "Add category",
    addOption: "Add option",
    categoryEN: "Category (English)",
    categoryFI: "Category (Finnish)",
    optionEN: "Option (English)",
    optionFI: "Option (Finnish)",
    noCategories: "No categories defined",
    noOptions: "No options defined",

    // ACTIVE INSPECTION
    complete: "Complete",
    started: "Started",
    siteAndInspectionInfo: "Site & inspection information",
    inspectionName: "Inspection name",
    selectSection: "Select section",
    completedInspectionsSuffix: "inspections completed", // inspections completed
    observations: "observations",
    comments: "Comments",
    addImage: "Add image",
    addObservation: "Add observation",
    additionalComments: "Section's additional comments",
    someSelectionsUndone: "Some choices not made",
    selectInspectionDesc: "Opening the inspection will mark it as unfinished",


    // BANNERS
    unsavedChangesTitle: "Unsaved changes",
    unsavedChangesDesc: "The changes are only local. Save your changes so they won't be lost.",
    cancelChanges: "Cancel changes",
    required: "required",

     //Helper texts
     atLeastOneNameHelperText: "The name must be given in at least one language and be at least five characters long",
     imageMaxLimitReached: "Image max limit (4) reached",

  },

  // ****************************************************************************************************************************************

  // FINNISH TRANSLATIONS
  fi: {
    //Global buttons
    darkModeButton: "Tumma",
    lightModeButton: "Valoisa",
    finnish: "suomi",
    english: "English",
    languageSelectLabel: "Kieli",
    logout: "Kirjaudu ulos",
    home: "Koti",
    newInspection: "Uusi tarkastus",
    continueInspection: "Jatka tarkastusta",
    inspectionArchive: "Arkisto",
    account: "Käyttäjätili",
    userManagement: "Käyttäjien hallinta",
    organizationManagement: "Organisaatiot",
    siteManagement: "Kohteet",
    inspectionTemplate: "Tarkastuslomakkeet",
    reportTemplate: "Raporttipohjat",
    close: "Sulje",
    cancel: "Peruuta",
    retry: "Yritä uudelleen",
    internalUser: "Sisäinen käyttäjä",
    externalUser: "Ulkoinen käyttäjä",
    createRoleLater: "Luo rooli myöhemmin",
    save: "Tallenna",
    addRole: "Lisää rooli",
    delete: "Poista",
    deleteUser: "Poista käyttäjä",
    return: "Takaisin",
    internal: "Sisäinen",
    external: "Ulkoinen",
    all: "Kaikki",
    clearFilters: "Poista suodattimet",
    apply: "Käytä",
    confirm: "Vahvista",
    reset: "Nollaa",
    add: "Lisää",
    ready: "Valmis",


    // Global texts and labels
    errorOccured: "Tapahtui virhe!",
    somethingWrong: "Jotain meni pieleen.",
    applicationAccepted: "Hakemus hyväksytty!",
    postSuccessDesc1: "Ennen kuin henkilö voi käyttää sovellusta, on henkilölle",
    postSuccessDesc2: "luotava rooli.",
    selectAccessRights: "Aseta käyttöoikeudet",
    accessRights: "Käyttöoikeudet",
    userManagementCheck: "Käyttäjätilien hallinnointi",
    organizationManagementCheck: "Organisaatioiden hallinnointi",
    siteManagementCheck: "Kohteiden hallinnointi",
    inspectionFormManagementCheck: "Tarkastuslomakkeiden hallinnointi",
    reportManagementCheck: "Raporttien hallinnointi",
    inspectCheck: "Tarkastusten tekeminen",
    filters: "Suodattimet",
    userType: "Käyttäjätyyppi",
    rowsPerPage: "Rivejä per sivu",
    new: "UUSI",
    customer: "Asiakas",
    schedule: "Aikataulu",
    deleted: "Poistettu",
    selectForm: "Valitse lomake",
    confirmSelection: "Vahvista valinta",
    version: "Versio",
    environment: "Ympäristö",
    zeroUnfinishedInspections: "Ei keskeneräisiä tarkastuksia",
    selectInspection: "Valitse tarkastus",
    noInspections: "Ei tarkastuksia",
    edited: "Muokattu",


    //Login component
    loginHeader: "Kirjautuminen",
    loginCaption: "Ole hyvä ja kirjaudu sisään jatkaaksesi.",
    loginFailed: "Sisäänkirjautuminen epäonnistui!",
    loginButton: "Kirjaudu",

    //Snackbar content
    snackbarPlaceholderText: "Tekstille varattu paikka",
    snackbarSessionExpired: "Istunto vanhentunut! Kirjaudu uudelleen jatkaaksesi.",
    snackbarAccountUpdateSuccess: "Käyttäjätili päivitetty",
    snackbarAccountUpdateFailed: "Käyttäjätilin päivitys epäonnistui",
    snackbarAccountAlreadyActive: "Rooli on jo aktiivinen",
    snackbarActiveRoleSwitchSuccess: "Aktiivinen rooli vaihdettu onnistuneesti",
    snackbarActiveRoleSwitchFailed: "Aktiivisen roolin vaihtaminen epäonnistui",
    snackbarOrganizatrionFetchFailed: "Organisaatioiden haku epäonnistui",
    snackbarFetchingUsersFailed: "Käyttäjien haku epäonnistui",
    snackbarUserUpdateSuccessful: "Käyttäjä päivitetty",
    snackbarUserUpdateFailed: "Käyttäjän päivittäminen epäonnistui",
    snackbarUserDeleteSuccessful: "Käyttäjä poistettu",
    snackbarUserDeleteFailed: "Käyttäjän poistaminen epäonnistui",
    snackbarSubmitNewOrgFailed: "Organisaation luonti epäonnistui",
    snackbarSubmitNewOrgSuccess: "Organisaatio luotu",
    snackbarOrganizationUpdateFailed: "Organisaation päivittäminen epäonnistui",
    snackbarOrganizationUpdateSuccess: "Organisaatio päivitetty",
    snackbarOrganizationDeleteSuccess: "Organisaatio poistettu",
    snackbarOrganizationDeleteFailed: "Organisaation poistaminen epäonnistui",
    snackbarFetchingSitesFailed: "Kohteiden haku epäonnistui",
    snackbarSubmitNewSiteFailed: "Kohteen luonti epäonnistui",
    snackbarSubmitNewSiteSuccess: "Kohde luotu",
    snackbarFetchingInspectorsFailed: "Tarkastajien haku epäonnistui",
    snackbarFetchingInspectionFormsFailed: "Tarkastuslomakkeiden haku epäonnistui",
    snackbarSaveSiteSuccess: "Kohteen tiedot päivitetty",
    snackbarSaveSiteFailed: "Kohteen tietojen päivittäminen epäonnistui",
    snackbarDeleteSiteSuccess: "Kohde poistettu",
    snackbarDeleteSiteFailed: "Kohteen poistaminen epäonnistui",
    snackbarAddInspectionSuccess: "Tarkastus lisätty",
    snackbarAddInspectionFailed: "Tarkastuksen lisääminen epäonnistui",
    snackbarFetchingInspectionsFailed: "Tarkastusten haku epäonnistui",
    snackbarDownloadFailed: "Lataus epäonnistui",
    snackbarDeleteInspectionFailed: "Tarkastuksen poistaminen epäonnistui",
    snackbarDeleteInspectionSuccess: "Tarkastus poistettu",
    snackbarInspectionCreationFailed: "Tarkastuksen luonti epäonnistui",
    snackbarFormSaveSuccess: "Lomake tallennettu",
    snackbarFormSaveFailed: "Lomakkeen tallennus epäonnistui",
    snacbarFormDeleteSuccess: "Lomake poistettu",
    snackbarFormDeleteFailed: "Lomakkeen poistaminen epäonnistui",
    snackbarInspectionSaveFailed: "Tarkastuksen tallentaminen epäonnistui",
    snackbarInspectionSaveSuccess: "Tarkatus tallennettu",
    snackbarInspectionFinishFailed: "Failed to finish inspection",
    snackbarInspectionFinishSuccess: "Tarkastus kuitattu tehdyksi",
    snackbarFailedToFetchData: "Tietojen haku epäonnistui",
    snackbarImageDeleteSuccessful: "Kuva poistettu",
    snackbarImageDeleteFailed: "Kuvan poistaminen epäonnistui",
    snackbarImageSaveSuccessful: "Kuva tallennettu",
    snackbarImageSaveFailed: "Kuvan tallentaminen epäonnistui",
    snackbarObservationDeleteSuccessful: "Huomio poistettu",
    snackbarObservationDeleteFailed: "Huomion poistaminen epäonnistui",
    snackbarObservationCreateSuccessful: "Huomio luotu",
    snackbarObservationCreateFailed: "Huomion luominen epäonnistui",
    snackbarSectionCreateSuccess: "Osio lisätty",
    snackbarSectionCreateFailed: "Osion lisääminen epäonnistui",
    snackbarSectionDeleteSuccess: "Osio poistettu",
    snackbarSectionDeleteFailed: "Osion poistaminen epäonnistui",


    //NOT FOUND 404
    notFoundCaption1: "OHO! SIVUA EI LÖYTYNYT",
    notFoundCaption2: "OLEMME PAHOILLAMME, MUTTA ETSIMÄÄNNE SIVUA EI LÖYTYNYT",
    notFoundTimer1: "OHJATAAN ETUSIVULLE",
    notFoundTimer2: "sekunnin kuluttua",
    notFoundButton: "Palaa takaisin etusivulle",

    //Main header
    inspections: "Tarkastukset",
    management: "Hallinnoi",

    //Roles
    inspector: "Tarkastaja",
    organizationAdmin: "Pääkäyttäjä",
    superAdmin: "Administraattori",
    user: "Käyttäjä",
    role: "Rooli",
    userList: "Käyttäjälista",

    // ACCOUNT
    active: "Aktiivinen",
    activate: "Aktivoi",
    roles: "Roolit",
    preferences: "Asetukset",
    personalInfo: "Henkilökohtaiset tiedot",
    workNumber: "Työpuhelin",
    workEmail: "Työsähköposti",
    preferenceShowDescription: "Näytä lisäkuvaukset tarkastuslomakkeella",


    // DOWNLOAD DOCUMENT
    openedDesc1: "Kohteeseen",
    openedDesc2: "on tehty",
    openedDesc3: "",
    openedDesc4: "Tarkastuksen on toteuttanut",
    openedDesc5: "Voit ladata raportin alta.",
    downloadReport: "Lataa raportti",
    initialLinkTitle: "Tämä on kertakäyttöinen linkki!",
    initialLinkDesc: "Kun olet avannut linkin, et voi avata tätä raporttia uudelleen. Jos tarvitset pääsyn näihin tietoihin uudelleen, lataa raportti laitteellesi.",
    openLink: "Avaa linkki",
    linkDeprecated: "Linkki vanhentunut",

    // ERROR
    errorTitle: "Tapahtui virhe!",
    errorDesc: "Tietojen haku epäonnistui",

    //USER MANAGEMENT
    openApplicants: "Avoimet hakemukset",
    noOpenApplicants: "Ei avoimia hakemuksia",
    acceptApplication: "Hyväksy hakemus",
    rejectApplication: "Hylkää hakemus",
    pending: "Odottaa",
    accepting: "Hyväksyminen",
    rejecting: "Hylkääminen",
    accepted: "Hyväksytty",
    rejected: "Hylätty",
    sending: "Lähetetään",
    processedApplication: "Hakemus käsitelty",
    noExtraInfo: "Ei lisätietoja",
    noPhoneNumber: "Ei puhelinnumeroa",
    users: "Käyttäjät",
    applications: "Hakemukset",

    //GLOBALS
    yes: "Kyllä",
    no: "Ei",
    manager: "Pääkäyttäjä",
    admin: "Järjestelmänvalvoja",
    logOutButton: "Kirjaudu ulos",
    uploadNewImage: "Lisää kuva",
    removeImage: "Poista kuva",
    send: "Lähetä",

    // TOOLTIPS
    deleteRole: "Poista rooli",
    deleteReport: "Poista raportti",
    downloadPDFReport: "Lataa pdf-raportti",
    createOneTimeDownloadLink: "Luo kertakäyttöinen jaettava latauslinkki (lataus ei vaadi kirjautumista)",
    deleteObservation: "Poista huomio",


    // ROLES
    loginSuccessful: "Kirjautuminen onnistui!",
    loginSuccessDesc1: "Tällä hetkellä",
    loginSuccessDesc2: "käyttäjätunnusta on kirjautunut sisään.",
    loginFailedDesc: "Ei käyttäjätunnuksia saatavilla.",
    welcomeback: "Tervetuloa takaisin",

    fetchingRoles: "Haetaan käyttäjärooleja...",
    selectAccount: "Valitse käyttäjätunnus",
    noGroupsAttachedErrorDesc: "Käyttäjätunnukseen ei ole liitetty yhtään roolia. Jotta voit jatkaa sovellukseen, ole yhteydessä yhteyshenkilöösi ja varmista, että sinulle on annettu rooli. Joskus oikeuksien päivityksessä voi mennä hetki, joten suosittelemme yrittämään myöhemmin uudelleen.",
    chooseRole: "Valitse rooli",

    hello: "Hei",
    applicationPendingDesc: "Hakemuksenne on vastaanotettu, mutta sitä ei ole vielä hyväksytty. Olkaa hyvä ja palatkaa tarkastamaan tilanne myöhemmin uudelleen.",
    applicationInitialStatusDesc: "Ennen kuin käyttäjätunnuksellanne voidaan kirjautua sisään, on teille luotava rooli. Olkaa hyvä, ja hakekaa roolia alla olevan lomakkeen avulla.",
    sendingApplicationFailed: "Hakemuksen lähetys epäonnistui!",
    rolesMissingDesc: "Käyttäjätiliin ei ole liitetty yhtään roolia! Ole hyvä, ja ole yhteydessä lähiesihenkilöösi roolien lisäämistä varten.",
    applicationReceived: "Hakemus vastaanotettu!",
    applicationReceivedDesc: "Olemme vastaanottaneet hakemuksenne ja se on nyt siirtynyt käsittelyvaiheeseen. Ette tule saamaan erillistä ilmoitusta, kun hakemuksenne käsittely on valmis.",
    organization: "Organisaatio",
    phoneNumber: "Puhelinnumero",
    additionalInformation: "Lisätietoja",


    // ORGANIZATIONS
    fetchingOrgs: "Haetaan organisaatioita...",
    noOrganizationsAvailable: "Organisaatioita ei saatavilla",
    
    // DIALOGS
    preview: "Esikatsele",
    linkCopiedToClipboard: "Linkki kopioitu leikepöydälle",
    creatingLink: "Luodaan latauslinkkiä",
    shareLinkCreatonFailed: "Latauslinkin luonti epäonnistui.",
    linkCreatedTitle: "Linkki luotu!",
    linkCreatedDesc1: "Linkki on kertakäyttöinen ja voimassa seitsemän (7) päivää luontihetkestä.",
    linkCreatedDesc2: "Ota linkki talteen heti, sillä se katoaa, kun suljet tämän ikkunan.",
    shareLink: "Jaa linkki:",
    copyLinkToClipboard: "Kopioi linkki leikepöydälle",
    useFormDesc1: "Käytä lomaketta",
    useFormDesc2: "pohjana uudelle tarkastuslomakkeelle?",
    whatFormRULooking4: "Mitä lomaketta etsit?",
    selectNewUsers: "Valitse uudet käyttäjät",


    // HOME
    newApplications: "Uusia hakemuksia",
    nofiticationBannerText1: "Tarkista uudet hakemukset",
    notificationBannerText2: "kpl",
    activeOrganization: "Aktiivinen organisaatio",
    unfinishedInspections: "Keskeneräiset tarkastukset",
    newInspections: "Ajoitettuja tarkastuksia",
    switchButton: "Vaihda",

    // USERS
    whoAreULooking: "Ketä etsit?",
    name: "Nimi",
    email: "Sähköposti",
    type: "Tyyppi",
    userName: "Käyttäjätunnus",

    // CONFIRM DIALOG
    userDeleteTitle: "Poista käyttäjä?",
    userDeleteDesc: "Käyttäjän poistaminen on lopullista. Poistettuja tietoja ei voida palauttaa.",
    resetTitle: "Nollaa tiedot?",
    resetDesc: "Tietojen nollaaminen on lopullista. Nollattuja tietoja ei voida palauttaa.",
    deleteOrganizationTitle: "Poista organisaatio?",
    deleteOrganizationDesc: "Organisaation poistaminen on lopullista. Poistettuja tietoja ei voida palauttaa. Myös organisaation liitettyjen käyttäjien roolit ja tarkastukset poistetaan.",
    deleteReportTitle: "Poista raportti?",
    deleteReportDesc: "Raportin poistaminen on lopullista. Poistettuja tietoja ei voida palauttaa.",
    deleteSiteTitle: "Poista kohde?",
    deleteSiteDesc: "Kohteen poistaminen on lopullista. Poistettu tietoja ei voida palauttaa. Samassa poistetaan kohteeseen liitetyt tarkastukset.",
    deleteFormTitle: "Poista lomake?",
    deleteFormDesc: "Lomakkeen poistaminen on lopullista. Poistettu tietoja ei voida palauttaa.",
    deleteInspectionTitle: "Poista tarkastus?",
    deleteInspectionDesc: "Tarkastuksen poistaminen on lopullista. Poistettuja tietoja ei voida palauttaa.",
    finishInspectionTitle: "Kuittaa tarkastus valmiiksi?",
    finishInspectionDesc: "Toimintoa ei voida peruuttaa eikä tarkastukseen tehdä enää muutoksia sen jälkeen, kun se on merkattu valmiiksi.",
    observationDeleteTitle: "Poista huomio?",
    observationDeleteDesc: "Huomion poistaminen on lopullista. Poistettuja tietoja ei voida palauttaa. Jatkamalla poistat kaiken huomioon liitetyn tekstin ja kuvat.",

    // ORGANIZATIONS
    address: "Osoite",
    streetAddress: "Katuosoite",
    postalCode: "Postinumero",
    city: "Kaupunki",
    organizationInfo: "Organisaation tiedot",
    addUser: "Lisää käyttäjä",
    whatOrgULooking4: "Mitä organisaatiota etsit?",
    orgHasNoUsers: "Organisaation ei ole liitetty yhtään käyttäjää",
    toSaveHaveAtLeastOneManagerDesc: "Tallentaaksesi organisaation, liitä vähintään yksi pääkäyttäjä organisaatioon",

    // SITES
    siteInfo: "Kohteen tiedot",
    propertyName: "Kiinteistön nimi",
    propertyType: "Kiinteistötyyppi",
    propertyOwner: "Kiinteistön omistaja / isännöitsijä",
    propertyEmail: "Kiinteistön omistajan sähköposti",
    propertyPhone: "Kiinteistön omistajan puhelinnumero",
    completedInspections: "Tehdyt tarkastukset",
    noUnfinishedInspections: "Kohteessa ei ole keskenkeräisiä tarkastuksia",
    created: "Luotu",
    daysAgo: "päivää sitten",
    daysLate: "päivää myöhässä",
    timed: "Ajoitettu",
    toDays: "päivän päähän",
    lastEdit: "Viimeisin muokkaus",
    today: "tänään",
    late: "MYÖHÄSSÄ",
    notStarted: "EI ALOITETTU",
    inProgress: "KESKEN",
    scheduleInspection: "Ajoita tarkastus",
    nameFI: "Nimi (suomi)",
    nameEN: "Nimi (englanti)",
    empty: "Tyhjä",
    inspection: "Tarkastus",
    addInspection: "Lisää tarkastus",
    noCompletedInspections: "Kohteeseen ei ole tehty tarkastuksia",
    completed: "Valmistunut",
    done: "VALMIS",
    whatSiteRULooking4: "Mitä kohdetta etsit?",
    noSitesFound: "Kohteita ei löytynyt",

    // ARCHIVE
    noArchivedInspections: "Arkistosta ei löytynyt yhtään tarkastusta",

    // INSPECTIONS
    createNew: "Luo uusi",
    chooseTargetAndInspection: "Valitse kohde & tarkastus",
    chooseNew: "Ajoitettu tarkastus",
    chooseSite: "Valitse kohde",
    nameInspection: "Nimeä tarkastus",
    inspectionNameFI: "Tarkastusnimike (suomi)",
    inspectionNameEN: "Tarkastusnimike (englanti)",
    startInspection: "Aloita tarkastus",
    site: "Kohde",
    siteNotFound: "Kohdetta ei löytynyt?",
    createSite: "Luo kohde",

    // FORM
    initializingForm: "Alustetaan lomaketta...",
    startFromEmptyForm: "Aloita tyhjästä lomakkeesta",
    selectTemplate: "Valitse pohja",
    selectTemplateDesc: "Valitse pohja olemassa olevista lomakkeista",
    owner: "Omistaja",
    creator: "Luoja",
    noInspectionFormsFound: "Yhtään tarkastuslomaketta ei löytynyt",
    noSections: "Ole hyvä ja lisää osioita nähdäksesi ne tässä",
    otherObservations: "Muut huomiot",
    otherObservationsAvailable: "Muut huomiot -osio saatavilla",
    allowImages: "Salli kuvien ottaminen",
    setMaxObservations: "Aseta huomioiden maksimimäärä",
    observationsMax: "Huomioita enintään (kpl)",
    sectionNameEN: "Osion nimi (englanti)",
    sectionNameFI: "Osion nimi (suomi)",
    deleteSection: "Poista osio",
    editSection: "Muokkaa osiota",
    hideSection: "Piilota osio",
    showSection: "Näytä osio",
    addSection: "Lisää osio",
    descEN: "Kuvaus (englanti)",
    descFI: "Kuvaus (suomi)",
    categories: "Kategoriat",
    basicInfo: "Perustiedot",
    sections: "Osiot",
    noName: "Ei nimetty",
    formAvailable: "Lomake saatavilla",
    formAvailable2All: "Lomake kaikkien organisaatioiden saatavilla",
    readOnly: "Vain luku",
    readOnlyDesc: "Ota yhteyttä pääkäyttäjään saadaksesi oikeudet muokata sisältöä",
    deleteForm: "Poista lomake",
    deleteInspection: "Poista tarkastus",
    category: "Kategoria",
    ifEmptyAskAlwaysDesc: "Jos tyhjä, tarkastus kysytään aina riippumatta kategoriavalinnoista",
    option: "Vaihtoehto",
    selectCategoryToSeeOptions: "Valitse kategoria nähdäksesi valinnat",
    forcedInspection: "Pakollinen tarkastus",
    forcedInspectionDesc: "Jos kohta on asetettu pakolliseksi, tarkatusta ei voida merkitä valmiiksi ennen kuin kaikki pakolliset kohdat on tehty.",
    allowInspectorToTakeImages: "Salli tarkastajan ottaa kuvia",
    guideImage: "Ohjekuva",
    noGuideImage: "Ohjekuvaa ei lisätty",
    answerType: "Vastaustyyppi",
    yesno: "Kyllä / ei",
    numerical: "Numeerinen",
    smallestAcceptableValue: "Pienin hyväksyttävä arvo",
    greatestAcceptableValue: "Suurin hyväksyttävä arvo",
    inspectionsPerPage: "Tarkastuksia per sivu",
    options: "Vaihtoehdot",
    deleteCategory: "Poista kategoria",
    addCategory: "Lisää kategoria",
    addOption: "Lisää vaihtoehto",
    categoryEN: "Kategoria (englanti)",
    categoryFI: "Kategoria (suomi)",
    optionEN: "Vaihtoehto (englanti)",
    optionFI: "Vaihtoehto (suomi)",
    noCategories: "Kategorioita ei määritelty",
    noOptions: "Vaihtoehtoja ei määritelty",

    // ACTIVE INSPECTION
    complete: "Valmis",
    started: "Aloitettu",
    siteAndInspectionInfo: "Kohteen & tarkastuksen tiedot",
    inspectionName: "Mittausnimike",
    selectSection: "Valitse osio",
    completedInspectionsSuffix: "tarkastuksista tehty",
    observations: "huomiota",
    comments: "Kommentit",
    addImage: "Lisää kuva",
    addObservation: "Lisää huomio",
    additionalComments: "Osion lisäkommentit",
    someSelectionsUndone: "Osa valinnoista tekemättä",
    selectInspectionDesc: "Tarkastuksen avaaminen merkitsee sen keskeneräiseksi",

    // BANNERS
    unsavedChangesTitle: "Tallentamattomia muutoksia",
    unsavedChangesDesc: "Muutokset ovat vasta paikallisia. Tallenna muutokset, jotta ne eivät katoa.",
    cancelChanges: "Peru muutokset",
    required: "pakollinen",

    //Helper texts
    atLeastOneNameHelperText: "Nimi on annettava vähintään yhdellä kielellä ja oltava vähintään viisi merkkiä pitkä",
    imageMaxLimitReached: "Kuvien maksimimäärä 4",

  }

}