/*
import React from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Typography } from "@mui/material";
import Loading from "../GeneralComponents/Loading";
import Error from "../GeneralComponents/Error";
*/

// Use this if custom reports are added in the future

const Report = () => {
  return null;
  /*
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(true);

  if(error && !loading) {
    return(
      <Error section="reportTemplate" />
    )
  }

  if(loading && !error) {
    return(<Loading section="reportTemplate" />)
  }

  if(!loading && !error) {
    return(
      <MainLayout>
        <Typography><b>REPORT</b></Typography>
      </MainLayout>
    )
  }
  */
}

export default Report;