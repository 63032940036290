import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const updateAccount = async (data, accessToken, idToken) => {
  const request = axios.put(`${baseUrl}/account`, data, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const accountServices = { updateAccount };
export default accountServices;