import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, FormControlLabel, Button, FormGroup, Checkbox, Typography, TextField } from "@mui/material";
import { useViewport } from "../../Context/ViewportProvider";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import LoadingSpinner from "../Loading/LoadingSpinner";
import TranslationContent from "../../Translations/TranslationContent";


const UserSelectionDialog = ({ open, handleClose, allUsers, fetchAllUsers, organizationUsers, fetchingAllUsers, addUsers }) => {
  const radioGroupRef = React.useRef(null);
  const [searchWord, setSearchWord] = React.useState("");
  const [userSelection, setUserSelection] = React.useState([]);
  const { width } = useViewport();

  React.useEffect(() => {
    if(allUsers?.length < 1 && open) {
      fetchAllUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const declineClick = () => {
    let updatedUserSelection = [...userSelection];
    updatedUserSelection = [];
    setUserSelection(updatedUserSelection);
    setSearchWord("");
    handleClose();
  }

  const applyUsersClick = () => {
    addUsers(userSelection);
    setSearchWord("");
    setUserSelection([]);
    handleClose();
  }


  const handleUserSelectionChange = (event) => {
    let updatedUserSelection = [...userSelection];
    if(userSelection.some(user => user.id === event.target.value)) {
      updatedUserSelection = updatedUserSelection.filter(user => user.id !== event.target.value)
    }
    else {
      updatedUserSelection = [...updatedUserSelection, allUsers.find(user => user.id === event.target.value)]
    }
    setUserSelection(updatedUserSelection)
  }

  const filterList = () => {
    let filteredList = []
    allUsers.forEach(user => {
      let existInOrg = !organizationUsers.some(orgUser => orgUser.oid === user.id)
      if(existInOrg) {
        filteredList = [...filteredList, user];
      }
    })
    if(searchWord !== "") {
      filteredList = filteredList.filter(user => user.name.toLowerCase().includes(searchWord.toLowerCase()) || user.azureEmail.toLowerCase().includes(searchWord.toLowerCase()))
    }
    return filteredList;
  }

  return(
    <Dialog
      sx={{ '& .MuiDialog-paper': {  width: width < 600 ? '99%' : "400px", height: "80%" } }}
      maxWidth="xl"
      fullWidth
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogTitle>
        <TranslationContent contentID="selectNewUsers" />
      </DialogTitle>
      <DialogContent dividers >
        {fetchingAllUsers ? 
          <Box sx={{textAlign: "center"}}>
            <LoadingSpinner />
          </Box>
        :
          <FormGroup
            multiple
            ref={radioGroupRef}
            aria-label="users"
            name="users"
            onChange={event => handleUserSelectionChange(event)}
            value={userSelection}
          >
            {filterList().map(user => (
              <FormControlLabel
                sx={{mt: 2}}
                value={user.id}
                key={user.id}
                control={<Checkbox checked={userSelection.some(orgUser => orgUser.id === user.id)}/>}
                label={<><Typography mb={-1}><b>{user.name}</b></Typography><Typography variant="caption">{user.azureEmail}</Typography></>}
              />
            ))}
          </FormGroup>
        }
      </DialogContent>
      {!fetchingAllUsers && 
        <>
          <DialogActions sx={{ display: 'block', width: "100%",}}>
            <Box sx={{ alignItems: 'flex-end', display: "flex", mb: 3 }}>
              <PersonSearchIcon sx={{  mr: 1, my: 0.5 }} />
              <TextField label={<TranslationContent contentID="whoAreULooking" />} variant="standard" value={searchWord} onChange={event => setSearchWord(event.target.value)} fullWidth />
            </Box>
          </DialogActions>
          <DialogActions>
            <Button autoFocus onClick={declineClick}>
              <TranslationContent contentID="cancel" />
            </Button>
            <Button variant="contained" onClick={applyUsersClick}><TranslationContent contentID="confirm" /></Button>
          </DialogActions>
        </>
      }
    </Dialog>
  )
}


export default UserSelectionDialog;