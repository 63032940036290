import React from "react";
import NewForm from "./NewForm";
import InspectionFormTableContent from "./InspectionFormTableContent";


const InspectionFormTable = ({ creationMode, updateSelectedForm, forms }) => {
  return(
    <>
      <InspectionFormTableContent show={!creationMode} forms={forms} updateSelectedForm={updateSelectedForm} />
      <NewForm show={creationMode} forms={forms}/>
    </>
  )
}

export default InspectionFormTable;