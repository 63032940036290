import React from "react";
import { Box, Table, TableBody, TableContainer, TablePagination, TableRow, TableCell, TableHead, TextField, Button } from "@mui/material";
import { useViewport } from "../../Context/ViewportProvider";
import SearchIcon from '@mui/icons-material/Search';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import OrganizationRow from "./OrganizationRow";
import TranslationContent from "../../Translations/TranslationContent";


const OrganizationTableContent = ({ show, rowClick, organizations }) => {
  const { width } = useViewport();
  const [filteredOrganizations, setFilteredOrganizations] = React.useState([]);
  const [searchWord, setSearchWord] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterOrganizations = (event) => {
  
    setSearchWord(event.target.value);
    let updatedFilteredOrganizations = [...organizations];
    if(event.target.value === "") {
      updatedFilteredOrganizations = [...organizations];
    }
    else {
      updatedFilteredOrganizations = updatedFilteredOrganizations.filter(organization => organization.name.toLowerCase().includes(event.target.value.toLowerCase()))
    }
    setFilteredOrganizations(updatedFilteredOrganizations);
}

  if(show) {
    return(
      <>
        <Grid container spacing={2}>
          <Grid xs={12} md={2} sx={{alignSelf: "center"}}>
            <Button variant="contained" fullWidth onClick={() => rowClick("", true)}><DomainAddIcon />&nbsp;&nbsp;<TranslationContent contentID="add" /></Button>
          </Grid>
          <Grid xs={12} md={10}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: 5, marginLeft: width > 750 ? "40%" : 5, marginBottom: "15px", }}>
              <SearchIcon sx={{  mr: 1, my: 0.5 }} color="primary" />
              <TextField label={<TranslationContent contentID="whatOrgULooking4" />} variant="standard" fullWidth value={searchWord} onChange={event => filterOrganizations(event)}  />
            </Box>
          </Grid>
        </Grid>
        <Box>
          <TableContainer sx={{width: "99%", height: "110%", mt: 2}}>
            <Table aria-label="collapsible table" size="small" sx={{width: "99%",}}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell><b><TranslationContent contentID="name" /></b></TableCell>
                  <TableCell align="right"><b><TranslationContent contentID="streetAddress" /></b></TableCell>
                  {width > 600 && 
                    <>
                      <TableCell align="right"><b><TranslationContent contentID="postalCode" /></b></TableCell>
                      <TableCell align="right"><b><TranslationContent contentID="city" /></b></TableCell>
                    </>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {searchWord !== "" ? 
                  filteredOrganizations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(organization => 
                    <OrganizationRow key={organization.id} organization={organization} rowClick={() => rowClick(organization)} />
                  )
                :
                  organizations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(organization => 
                    <OrganizationRow key={organization.id} organization={organization} rowClick={() => rowClick(organization)} />
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={searchWord !== "" ? filteredOrganizations.length : organizations.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={<TranslationContent contentID="rowsPerPage" />}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={width > 600 ? true : false}
            showLastButton={width > 600 ? true : false}
            labelDisplayedRows={
              ({ from, to, count }) => {
                return ` ${from}–${to} / ${count}`
              }
            }
          />
        </Box>
      </>
    )
  }
}

export default OrganizationTableContent;