import React from "react";
import { SessionContext } from "../../Context/SessionProvider";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";
import TranslationContent from "../../Translations/TranslationContent";


const NotificationBanner = () => {
  const session = React.useContext(SessionContext);
  const navigate = useNavigate();

  if(session.activeRole.type === "internal" && session.applications > 0) {
    return(
      <Grid xs={12} mt={1}>
        <Card sx={{display:"inline-flex", width: "100%", borderLeft: "3px solid #009fe3",}}>
          <CardActionArea onClick={() => navigate("/users/registrations")}>
            <CardContent sx={{display: "flex"}}>
              <NewReleasesIcon color="primary" fontSize="small" sx={{alignItems: "center", alignSelf: "center"}} />
              &nbsp;&nbsp;
              <Typography variant="body2">
                <b><TranslationContent contentID="newApplications" /></b>
              </Typography>
              &nbsp;&nbsp;
              <Typography sx={{opacity: 0.7}} variant="body2">
                <TranslationContent contentID="nofiticationBannerText1" /> {session.applications} <TranslationContent contentID="notificationBannerText2" />
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    )
  }
  else return null;
}

export default NotificationBanner;