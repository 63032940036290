import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const saveInspectionImage = async (inspectionId, sectionId, singleInspectionId, imageData, accessToken, idToken) => {
  const request = axios.post(`${baseUrl}/image/${inspectionId}?type=inspection&section=${sectionId}&inspect=${singleInspectionId}`, imageData, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const saveObservationImage = async (inspectionId, observationId, imageData, accessToken, idToken) => {
  const request = axios.post(`${baseUrl}/image/${inspectionId}?type=inspection&subType=observation&inspect=${observationId}`, imageData, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const saveFormImage = async (formId, sectionId, singleInspectionId, imageData, accessToken, idToken) => {
  const request = axios.post(`${baseUrl}/image/${formId}?type=form&section=${sectionId}&inspect=${singleInspectionId}`, imageData, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const getInspectionImage = async (inspectionId, imageId, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/image/${inspectionId}/${imageId}?type=inspection`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const getFormImage = async (formId, imageId, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/image/${formId}/${imageId}?type=form`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const deleteInspectionImage = async (inspectionId, imageId, sectionId, singleInspectionId, accessToken, idToken) => {
  const request = axios.delete(`${baseUrl}/image/${inspectionId}/${imageId}?type=inspection&section=${sectionId}&inspect=${singleInspectionId}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const deleteObservationImage = async (inspectionId, imageId, observationId, accessToken, idToken) => {
  const request = axios.delete(`${baseUrl}/image/${inspectionId}/${imageId}?type=inspection&subType=observation&inspect=${observationId}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const deleteFormImage = async (formId, imageId, sectionId, singleInspectionId,  accessToken, idToken) => {
  const request = axios.delete(`${baseUrl}/image/${formId}/${imageId}?type=form&section=${sectionId}&inspect=${singleInspectionId}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}


const imageServices =
  { 
    saveInspectionImage, 
    saveObservationImage,
    saveFormImage,
    getInspectionImage,
    getFormImage,
    deleteInspectionImage,
    deleteObservationImage,
    deleteFormImage
  };
export  default imageServices;