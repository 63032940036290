import React from "react";
import { IconButton, Stack, Divider, Chip, ListItem, ListItemIcon, ListItemText, Tooltip, CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditNoteIcon from '@mui/icons-material/EditNote';
import TranslationContent from "../../../Translations/TranslationContent";
import WriteTextField from "./WriteTextField";


const Section = ({ section, index, updateSection, overallSections, permissionType, updateSelectedSection, deleting }) => {

  const deleteSection = () => {
    updateSection(section, "delete")
  }

  const increaseSectionIndex = () => {
    updateSection(section, "increaseSectionIndex", index)
  }

  const decreaseSectionIndex = () => {
    updateSection(section, "decreaseSectionIndex", index)
  }

  const handleTextFieldChange = (event, type) => {
    let updatedSection = {...section};
    if(type === "sectionNameEN") {
      updatedSection.name.en = event.target.value.length < 51 ? event.target.value : updatedSection.name.en;
    }
    if(type === "sectionNameFI") {
      updatedSection.name.fi = event.target.value.length < 51 ? event.target.value : updatedSection.name.fi;
    }
    updateSection(updatedSection, "update");
  }

 
  return(
    <div>
      <ListItem sx={{pl: 0}} 
        secondaryAction={
          <Stack>
            <Tooltip title={<TranslationContent contentID="deleteSection" />} arrow>{deleting === section.id ? <CircularProgress color="error" sx={{maxWidth: 25, maxHeight: 25, alignSelf: "center"}}/> : <IconButton color="error" disabled={permissionType === "read"} onClick={() => deleteSection()}><DeleteIcon /></IconButton>}</Tooltip>
            <Tooltip title={<TranslationContent contentID="editSection" />} arrow><IconButton color="primary" disabled={permissionType === "read"} onClick={() => updateSelectedSection(section)}><EditNoteIcon /></IconButton></Tooltip>
          </Stack>
        }
      >
        <ListItemIcon>
          <Stack>
            <IconButton disabled={index === 0 || permissionType === "read"} color="primary" onClick={() => decreaseSectionIndex()}><KeyboardArrowUpIcon /></IconButton>
            <Chip label={index+1} />
            <IconButton disabled={index === overallSections - 1 || permissionType === "read"} color="primary" onClick={() => increaseSectionIndex()}><KeyboardArrowDownIcon /></IconButton>
          </Stack>
        </ListItemIcon>
        <ListItemText 
          disableTypography
          primary={
            <Stack spacing={2} sx={{width: "95%",}}>
              <WriteTextField label="sectionNameEN" maxStringLength={50} multiline={true} readOnly={permissionType === "read"} value={section.name?.en} onChange={event => handleTextFieldChange(event, "sectionNameEN")}/>
              <WriteTextField label="sectionNameFI" maxStringLength={50} multiline={true} readOnly={permissionType === "read"} value={section.name?.fi} onChange={event => handleTextFieldChange(event, "sectionNameFI")}/>
            </Stack>
          }
        />
      </ListItem>
      <Divider sx={{mt: 1}} />
    </div>
  )
}

export default Section;