import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from "@mui/material";
import TranslationContent from "../../Translations/TranslationContent";


const ImagePreviewDialog = ({ open, close, img, }) => {
  return(
    <Dialog open={open} onClose={() => close()} fullWidth maxWidth="xl">
      <DialogTitle><TranslationContent contentID="preview" /></DialogTitle>
      <Divider />
      <DialogContent sx={{textAlign:"center"}}><img src={img} alt="Current profile avatar" style={{maxWidth:"99%", maxHeight: "70vh"}} /></DialogContent>
      <Divider />
      <DialogActions><Button onClick={() => close()}><TranslationContent contentID="close" /></Button></DialogActions>
    </Dialog>
  )
}

export default ImagePreviewDialog;