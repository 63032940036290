import React from "react";
import { List, ListSubheader, Typography, Button, Stack, ListItem, IconButton, ListItemText, TextField, CircularProgress, Divider, Box, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuid } from "uuid";
import TranslationContent from "../../../Translations/TranslationContent";
import ImagePreviewDialog from "../../Dialogs/ImagePreviewDialog";
import ImageResizer from 'react-image-file-resizer';
import imageService from "../../../services/images";
import { Tokens } from "../../../services/Tokens";
import { logger } from "../../../services/logger";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import ObservationImage from "../../Images/ObservationImage";
import inspectionService from "../../../services/inspections";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";


const Observations = ({ inspection, updateInspection, images, updateImages }) => {
  const [adding, setAdding] = React.useState(false);
  const [deleting, setDeleting] = React.useState("");
  const [observationToDelete, setObservationToDelete] = React.useState("");
  const [deleteObservationDialogOpen, setDeleteObservationDialogOpen] = React.useState(false);
  const [imageTransactionActive, setImageTransactionActive] = React.useState("");
  const [showLargeImage, setShowLargeImage] = React.useState(false);
  const [selectedLargeImage, setSelectedLargeImage] = React.useState("");
  const { addAlert } = React.useContext(SnackBarContext);

  const newObservations = () => {
    setAdding(true);
    let updatedInspection = {...inspection};
    updatedInspection.otherObservations = {...inspection.otherObservations};
    let newObservation = {
      id: uuid(),
      comment: "",
      images: [],
    }
    updatedInspection.otherObservations.observations = [...updatedInspection.otherObservations.observations, newObservation];
    Tokens().then((tokens) => {
      inspectionService
      .updateInspection(updatedInspection, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        addAlert({message: "snackbarObservationCreateSuccessful", type: "success"});
        setAdding(false);
        updateInspection(updatedInspection, "imageUpdate");
      })
      .catch(error => {
        logger(error);
        setAdding(false);
        addAlert({message: "snackbarObservationCreateFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setAdding(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const openLargeImage = (imageData) => {
    setSelectedLargeImage(imageData);
    setShowLargeImage(true);
  }

  const closeLargeImage = () => {
    setShowLargeImage(false);
    setSelectedLargeImage("");
  }

  const handleCommentChange = (event, id) => {
    let updatedInspection = {...inspection};

    updatedInspection.otherObservations.observations = updatedInspection.otherObservations.observations.map(observation => {
      if(observation.id === id) {
        observation.comment = event.target.value;
        return observation
      }
      else return observation;
    });
    updateInspection(updatedInspection);
  }

  const handleImageChange = (event, id) => {
    setImageTransactionActive(id);

    let updatedInspection = {...inspection};

    let files = [...event.target.files]

    if (files.length > 0 && files.length < 5) {
      
      
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        // Resize and compress the image before setting it in context
        ImageResizer.imageFileResizer(
          file,
          300, // New width (in pixels)
          300, // New height (in pixels)
          'PNG', // Output format (JPEG or PNG)
          90, // Quality (1 to 100)
          0, // Rotation (0, 90, 180, or 270)
          (resizedImage) => {
            // save image to database inspectionId, observationId, imageData, accessToken, idToken
            Tokens().then((tokens) => {
              imageService
              .saveObservationImage(inspection.id, id, resizedImage, tokens.accessToken, tokens.idToken)
              .then(response => {
                logger(response);
                updateImages({data: resizedImage, id: response}, "add")
                updatedInspection.otherObservations.observations = updatedInspection.otherObservations.observations.map(observation => {
                  if(observation.id === id && observation.images.length < 5) {
                    observation.images = [...observation.images, response];
                    return observation;
                  }
                  else return observation;
                });
                addAlert({message: "snackbarImageSaveSuccessful", type: "success"});
                setImageTransactionActive("");
              })
              .catch(error => {
                logger(error);
                addAlert({message: "snackbarImageSaveFailed", type: "error"});
              })
            })
            .catch(error => {
              logger(error);
              addAlert({message: "snackbarImageSaveFailed", type: "error"});
            })
          },
          'base64', // Output type (base64 or blob)
          300, // Max file size (in kilobytes)
          350, // Max width (in pixels)
          350 // Max height (in pixels)
        );
      };
    }
    updateInspection(updatedInspection, "imageUpdate");
  }

  const deleteObservation = async () => {
    setDeleting(observationToDelete);
    setDeleteObservationDialogOpen(false);
    // delete all images attached to the observation. When done, PUT request for removed observation
    let updatedInspection = {...inspection};
    let foundObservation = updatedInspection.otherObservations.observations.find(observation => observation.id === observationToDelete);
    let imageRemoval = [];
    await Promise.all(
      imageRemoval = foundObservation.images.map(async (image) => {
        Tokens().then((tokens) => {
          imageService
          .deleteObservationImage(inspection.id, image, observationToDelete, tokens.accessToken, tokens.idToken)
          .then(response => {
            logger(response);
            return true;
          })
          .catch(error => {
            logger(error);
            return false;
          })
        })
        .catch(error => {
          logger(error);
          return false;
        })
    }))
    
  

    if(foundObservation.images.length === 0 || imageRemoval.every(removal => removal)) {
      updatedInspection.otherObservations.observations = updatedInspection.otherObservations.observations.filter(observation => observation.id !== observationToDelete);
      Tokens().then((tokens) => {
        inspectionService
        .updateInspection(updatedInspection, tokens.accessToken, tokens.idToken)
        .then(response => {
          logger(response);
          addAlert({message: "snackbarObservationDeleteSuccessful", type: "success"});
          updateInspection(updatedInspection, "imageUpdate");
          setObservationToDelete("");
          setDeleting("");
        })
        .catch(error => {
          logger(error);
          setDeleting("");
          addAlert({message: "snackbarObservationDeleteFailed", type: "error"});
        })
      })
      .catch(error => {
        logger(error);
        setDeleting("");
        addAlert({message: "snackbarSessionExpired", type: "error"});
      })
    }
  }

  const showDeleteConfirmation = (id) => {
    setObservationToDelete(id);
    setDeleteObservationDialogOpen(true);
  }
  
  return(
    <List 
      dense 
      sx={{ textAlign: "center" }} 
      subheader={
      <ListSubheader sx={{background: "none", fontSize: "larger"}}>
        <TranslationContent contentID="otherObservations" />
        {inspection.otherObservations.restrictMax ? 
        <Typography>{inspection.otherObservations.observations.length} / {inspection.otherObservations.maxCount}</Typography>
        :
        <Typography>{inspection.otherObservations.observations.length} <TranslationContent contentID="observations" /></Typography>
      }
      </ListSubheader>}
    >
      <ImagePreviewDialog open={showLargeImage} close={() => closeLargeImage()} img={selectedLargeImage} />
      <ConfirmDialog title="observationDeleteTitle" text="observationDeleteDesc" open={deleteObservationDialogOpen} handleClose={() => setDeleteObservationDialogOpen(false)} confirmClick={() => deleteObservation()} />
      
    
     {inspection.otherObservations.observations.map((observation, index) => 
     <div key={observation.id}>
      <ListItem 
        secondaryAction={
          <>{deleting === observation.id ? 
          <CircularProgress color="error" sx={{maxWidth: 25, maxHeight: 25}}/>
          :
          <Tooltip title={<TranslationContent contentID="deleteObservation" />} arrow>
            <IconButton color="error" sx={{marginRight: "-12px"}} onClick={() => showDeleteConfirmation(observation.id)} disabled={imageTransactionActive !== ""}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
        </>
        }
      >
        <ListItemText 
          primary={<TextField disabled={imageTransactionActive !== ""} size="small" label={<TranslationContent contentID="comments" />} fullWidth multiline onChange={(event) => handleCommentChange(event, observation.id)} value={observation.comment} />}
          />
      </ListItem>
      {observation.images.length > 3 && 
      <Typography mt={2} mb={2} sx={{opacity: 0.5}}><b><TranslationContent contentID="imageMaxLimitReached" /></b></Typography>}
      {imageTransactionActive === observation.id && 
            <CircularProgress />}
      {(inspection.otherObservations.imagesEnabled && observation.images.length < 4 && imageTransactionActive === "") &&
        <Grid xs={12} md={6} m="auto" >
          
            <>
            <input accept="image/*" type="file" id={observation.id} style={{display: "none"}} onChange={event => handleImageChange(event, observation.id)} multiple/>
            <label htmlFor={observation.id}>
            <Button sx={{margin:"auto", textAlign: "center"}} component="span"><Stack><AddAPhotoIcon sx={{alignSelf: "center"}} /><TranslationContent contentID="addImage" /></Stack></Button>
            </label>
            </>
          
        </Grid>
      }
      {observation.images.length > 0 && 
      <Grid container sx={{textAlign: "center", justifyContent: "center"}} spacing={2}>
          {observation.images.map((image) => (
          <Grid xs={12} sm={4} md={3} xl={2} m="auto" key={image}>
            <ObservationImage imageId={image} images={images} updateImages={updateImages} openLargeImage={openLargeImage} inspection={inspection} updateInspection={updateInspection} observationId={observation.id} />
          </Grid>
          ))}
          </Grid>
      }
      <Divider />
      </div>
     )}
     {(inspection.otherObservations.maxCount >= inspection.otherObservations.observations.length && inspection.otherObservations.restrictMax) && 
      <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            variant="contained"
            sx={{width: 200, marginTop: "25px", marginBottom: "10px"}} 
            size="small"
            disabled={adding || imageTransactionActive !== ""}
            onClick={() => newObservations()}
          >
            <TranslationContent contentID="addObservation" />
          </Button>
          {adding && (
            <CircularProgress
              size={24}
              sx={{
                //color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-5px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      }
    </List>
  )
}

export default Observations;