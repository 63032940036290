import React from "react";
import MainLayout from "../../../Layouts/MainLayout";
import { Typography, Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Loading from "../../GeneralComponents/Loading";
import Error from "../../GeneralComponents/Error";
import ArchiveTable from "./ArchiveTable";
import { useViewport } from "../../../Context/ViewportProvider";
import { Tokens } from "../../../services/Tokens";
import archiveService from "../../../services/archives";
import { SessionContext } from "../../../Context/SessionProvider";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { useNavigate } from "react-router-dom";
import { logger } from "../../../services/logger";
import TranslationContent from "../../../Translations/TranslationContent";


const InspectArchive = () => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const { width } = useViewport();
  const [inspections, setInspections] = React.useState([]);
  const session = React.useContext(SessionContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const navigate = useNavigate();

  
  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.roles.length === 0) {
      navigate("/roles");
    }
    if(fetchData.current && session.roles.length > 0) {
      fetchData.current = false;
      fetchInspections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchInspections = () => {
    Tokens().then((tokens) => {
      archiveService
      .getArchivedInspections(session.activeRole.organizationId, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedInspections = [...inspections];
        updatedInspections = [...response];
        setInspections(updatedInspections);
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarFetchingInspectionsFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
      navigate("");
    })
  }

  const retryClick = () => {
    setLoading(true);
    setError(false);
    fetchInspections();
  }
  
  if(loading && !error) return <Loading section="inspectionArchive" />;
  if(!loading && error) return <Error section="inspectionArchive" retryClick={retryClick} />;

  if(!error && !loading && inspections.length === 0) {
    return(
      <MainLayout>
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Typography color="primary" variant="body2"><b><TranslationContent contentID="inspectionArchive" /></b></Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{padding: "7px", width: "100%",}}>
          <Grid container spacing={2} mt={0}>
            <Grid xs={12} sx={{textAlign: "center", justifyContent: "center", padding: "50px"}}>
              <Typography><i><TranslationContent contentID="noArchivedInspections" /></i></Typography>
            </Grid>
          </Grid>
        </Paper>
      </MainLayout>
    )
  }

  if(!error && !loading && inspections.length > 0) {
    return(
      <MainLayout>
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Typography color="primary" variant="body2"><b><TranslationContent contentID="inspectionArchive" /></b></Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper sx={{padding: "7px", width: "100%",}}>
            <Grid container spacing={2} mt={0}>
              <Grid xs={12} sx={{textAlign: "center", justifyContent: "center", padding: width > 600 ? "50px" : "0px"}}>
                <ArchiveTable inspections={inspections} />
              </Grid>
            </Grid>
          </Paper>
      </MainLayout>
    )
  }
}

export default InspectArchive;