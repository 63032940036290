import React from "react";
import { Typography, Button, Divider, Paper, TablePagination, ButtonGroup, Zoom, CircularProgress, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TranslationContent from "../../../Translations/TranslationContent";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SaveIcon from "@mui/icons-material/Save";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { v4 as uuid } from "uuid";
import EmptyListText from "./EmptyListText";
import SectionCategory from "./SectionCategory";
import SectionInspection from "./SectionInspection";
import MainLayout from "../../../Layouts/MainLayout";
import { LanguageContext } from "../../../Translations/LanguageProvider";
import LoadingSpinner from "../../Loading/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { useViewport } from "../../../Context/ViewportProvider";
import SaveFabButton from "../../Utils/GlobalComponents/SaveFabButton";
import ImageResizer from 'react-image-file-resizer';
import { logger } from "../../../services/logger";
import { Tokens } from "../../../services/Tokens";
import imageService from "../../../services/images";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import formService from "../../../services/forms";


const SectionContent = ({ selectedSection, updateSection, updateSectionChanges, form, permissionType, saving, images, updateImages }) => {
  const currentLang = React.useContext(LanguageContext);
  const [loading, setLoading] = React.useState(true);
  const [section, setSection] = React.useState({});
  const [deleting, setDeleting] = React.useState("");
  const [showSaveButton, setShowSaveButton] = React.useState(false);
  const [imageTransactionActive, setImageTransactionActive] = React.useState(false);
  const { width } = useViewport();
  const { addAlert } = React.useContext(SnackBarContext);

  const navigate = useNavigate();

  React.useEffect(() => {
    setSection({...section, ...selectedSection});
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addCategory = () => {
    let updatedSection = {...section};
    let newCategory = {
      id: uuid(),
      selectedOption: "",
      name: {
        fi: "",
        en: "",
      },
      options: [],
    }
    updatedSection.categories = [...updatedSection.categories, newCategory];
    setSection(updatedSection);
    setShowSaveButton(true);
  }

  const deleteCategory = (id) => {
    let updatedSection = {...section};
    updatedSection.categories = updatedSection.categories.filter(category => category.id !== id);
    setSection(updatedSection);
    setShowSaveButton(true);
  }

  const handleCategoryTextFieldChange = (event, newCategory, type) => {
    let updatedSection = {...section};
    updatedSection.categories = updatedSection.categories.map(category => {
      if(category.id === newCategory.id) {
        if(type === "categoryNameEN") {
          category.name.en = event.target.value.length < 51 ? event.target.value : category.name.en;
        }
        if(type === "categoryNameFI") {
          category.name.fi = event.target.value.length < 51 ? event.target.value : category.name.fi;
        }
        return category;
      }
      else return category;
    })
    setSection(updatedSection);
    setShowSaveButton(true);
  }

  const addOption = (updatedCategory) => {
    let updatedSection = {...section};
    updatedSection.categories = updatedSection.categories.map(category => {
      if(category.id === updatedCategory.id) {
        let newOption = {
          id: uuid(),
          name: {
            fi: "",
            en: "",
          },
        }
        category.options = [...category.options, newOption];
        return category;
      }
      else return category;
    })
    setSection(updatedSection);
    setShowSaveButton(true);
  }

  const deleteOption = (categoryId, optionId) => {
    let updatedSection = {...section};
    updatedSection.categories = updatedSection.categories.map(category => {
      if(category.id === categoryId) {
        category.options = category.options.filter(option => option.id !== optionId);
        return category;
      }
      else return category;
    })
    setSection(updatedSection);
    setShowSaveButton(true);
  }

  const handleOptionTextFieldChange = (event, categoryId, optionId, type) => {
    let updatedSection = {...section};
    updatedSection.categories = updatedSection.categories.map(category => {
      if(category.id === categoryId) {
        category.options = category.options.map(option => {
          if(option.id === optionId) {
            if(type === "optionNameEN") {
              option.name.en = event.target.value.length < 51 ? event.target.value : option.name.en;
              return option;
            }
            if(type === "optionNameFI") {
              option.name.fi = event.target.value.length < 51 ? event.target.value : option.name.fi;
              return option;
            }
          }
          return option;
        });
        return category;
      }
    return category;
    })
    setSection(updatedSection);
    setShowSaveButton(true);
  }

  const addInspection = () => {
    let updatedSection = {...section};
    let newInspection = {
      id: uuid(),
      name: {
        fi: "",
        en: "",
      },
      desc: {
        fi: "",
        en: "",
      },
      forceComplete: false,
      enableImage: true,
      isBoolean: true,
      linkedCategoryId: "",
      linkedOptionId: "",
      booleanValue: "",
      isNumber: false,
      numberValue: 0,
      maxNumberValue: 1,
      minNumberValue: 0,
      guideImage: "",
      inspectionImages: [],
      comment: "",
    }
    
    let inspectionCount = updatedSection.inspections.length + 1;
    setSection(prevSection => ({
      ...prevSection,
      inspections: [...prevSection.inspections, newInspection]
    }));
    setShowSaveButton(true);
    setPage(Math.ceil(inspectionCount / rowsPerPage) - 1);
  }

  const deleteInspection = async (inspectionId) => {
    setDeleting(inspectionId);
    const inspection = section.inspections.find(inspection => inspection.id === inspectionId);
    if(inspection.guideImage !== "") {
      Tokens().then((tokens) => {
        imageService
        .deleteFormImage(form.id, inspection.guideImage, section.id, inspectionId, tokens.accessToken, tokens.idToken)
        .then(response => {
          logger(response);
          //updateSectionChanges(updatedSection, "imageUpdate");
          let updatedSection = section.inspections.filter(inspection => inspection.id !== inspectionId);
          updateSectionChanges(updatedSection, "imageUpdate");
          updateImages(inspection.guideImage, "remove");
        })
        .catch(error => {
          logger(error);
          addAlert({message: "snackbarImageDeleteFailed", type: "error"});
          setDeleting("");
        })
      })
      .catch(error => {
        logger(error);
        addAlert({message: "snackbarSessionExpired", type: "error"});
        setDeleting("");
      })
    }

    let updatedForm = {...form};
    updatedForm.sections = updatedForm.sections.map(oldSection => {
      if(oldSection.id === section.id) {
        oldSection.inspections = oldSection.inspections.filter(inspection => inspection.id !== inspectionId)
        return oldSection;
      }
      else return oldSection;
    })

    await Tokens().then((tokens) => {
      formService
      .updateForm(updatedForm, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedSection = {...section};
        updatedSection.inspections = updatedSection.inspections.filter(inspection => inspection.id !== inspectionId);
        setSection(updatedSection);
        setPage(Math.ceil(updatedSection.inspections.length / rowsPerPage) - 1);
        addAlert({message: "snackbarDeleteInspectionSuccess", type: "success"});
        setDeleting("");
      })
      .catch(error => {
        logger(error);
        setDeleting("");
        addAlert({message: "snackbarDeleteInspectionFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setDeleting("");
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const increaseInspectionIndex = (index) => {
    let updatedSection = {...section};
    if(section.inspections.length > 1 && index + 1 < section.inspections.length) {
      let itemToMove = section.inspections[index];
      section.inspections.splice(index, 1);
      section.inspections.splice(index + 1, 0, itemToMove);
    }
    setSection(updatedSection);
    setShowSaveButton(true);
  }

  const decreaseInspectionIndex = (index) => {
    let updatedSection = {...section};
    if(section.inspections.length > 1 && index > 0) {
      let itemToMove = section.inspections[index];
      section.inspections.splice(index, 1);
      section.inspections.splice(index - 1, 0, itemToMove);
    }
    setSection(updatedSection);
    setShowSaveButton(true);
  }

  const handleInspectionTextFieldChange = (event, inspectionId, type) => {
    let updatedSection = {...section};
    updatedSection.inspections = updatedSection.inspections.map(inspection => {
      if(inspection.id === inspectionId) {
        if(type === "nameEN") {
          inspection.name.en = event.target.value.length < 151 ? event.target.value : inspection.name.en;
        }
        if(type === "nameFI") {
          inspection.name.fi = event.target.value.length < 151 ? event.target.value : inspection.name.fi;
        }
        if(type === "descEN") {
          inspection.desc.en = event.target.value.length < 501 ? event.target.value : inspection.desc.en;
        }
        if(type === "descFI") {
          inspection.desc.fi = event.target.value.length < 501 ? event.target.value : inspection.desc.fi;
        }
        if(type === "linkedCategory") {
          inspection.linkedCategoryId = event.target.value;
          if(event.target.value === "") {
            inspection.linkedOptionId = "";
          }
        }
        if(type === "linkedOption") {
          inspection.linkedOptionId = event.target.value;
        }
        if(type === "minNumberValue") {
          let value = handleDecimalsOnValue(event.target.value);
          value = value.replaceAll(",", ".")
          inspection.minNumberValue = value;
        }
        if(type === "maxNumberValue") {
          let value = handleDecimalsOnValue(event.target.value);
          value = value.replaceAll(",", ".")
          inspection.maxNumberValue = value;
        }
        return inspection;
      }
      else return inspection;
    })
    setSection(updatedSection);
    setShowSaveButton(true);
  }

  function handleDecimalsOnValue(value) {
    const regex = /([0-9]*[.|,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
  }

  const handleInspectionToggle = (inspectionId, type) => {
    let updatedSection = {...section};
    updatedSection.inspections = updatedSection.inspections.map(inspection => {
      if(inspection.id === inspectionId) {
        if(type === "forceComplete") {
          inspection.forceComplete = !inspection.forceComplete;
        }
        if(type === "enableImage") {
          inspection.enableImage = !inspection.enableImage;
        }
        if(type === "answerType") {
          inspection.isNumber = !inspection.isNumber;
          inspection.isBoolean = !inspection.isBoolean;
        }
        return inspection;
      }
      return inspection;
    })
    setSection(updatedSection);
    setShowSaveButton(true);
  }

  const handleInspectionImageChange = (event, inspectionId) => {
    setImageTransactionActive(inspectionId);
    const file = event.target.files[0];
    let updatedSection = {...section};
    // Resize and compress the image before setting it in context
    ImageResizer.imageFileResizer(
      file,
      300, // New width (in pixels)
      300, // New height (in pixels)
      'PNG', // Output format (JPEG or PNG)
      90, // Quality (1 to 100)
      0, // Rotation (0, 90, 180, or 270)
      (resizedImage) => {
        Tokens().then((tokens) => {
          imageService
          .saveFormImage(form.id, updatedSection.id, inspectionId, resizedImage, tokens.accessToken, tokens.idToken)
          .then(response => {
            logger(response);
            updateImages({data: resizedImage, id: response}, "add")
            updatedSection.inspections = updatedSection.inspections.map(inspection => {
              if(inspection.id === inspectionId) {
                inspection.guideImage = response;
                return inspection;
              }
              return inspection;
            })
            updateSectionChanges(updatedSection, "imageUpdate");
            addAlert({message: "snackbarImageSaveSuccessful", type: "success"});
            setImageTransactionActive("");
          })
          .catch(error => {
            logger(error);
            addAlert({message: "snackbarImageSaveFailed", type: "error"});
            setImageTransactionActive("");
          })
        })
        .catch(error => {
          logger(error);
          addAlert({message: "snackbarImageSaveFailed", type: "error"});
          setImageTransactionActive("");
        })
      },
      'base64', // Output type (base64 or blob)
      100, // Max file size (in kilobytes)
      350, // Max width (in pixels)
      350 // Max height (in pixels)
    );
  }


  const returnClick = () => {
    if(showSaveButton) {
      updateSectionChanges(section, "update");
    }
    updateSection();
  }

  const saveClick = async () => {
   let finished = await updateSectionChanges(section, "updateAndSave");
   if(finished) {
    setShowSaveButton(false);
   }
  }

  if(loading) {
    return(
      <MainLayout>
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Stack direction="row">
                <Typography color="primary" variant="body2">
                  <b style={{cursor: "pointer"}} onClick={() => navigate("/form")} >
                    <TranslationContent contentID="inspectionTemplate" />
                  </b>
                </Typography>
                <KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} color="primary" />
                <Typography color="primary" variant="body2" onClick={() => updateSection()} sx={{cursor: "pointer"}}>
                  {form.name[currentLang.lang]}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={2} mt={0}>
          <Grid xs={12} sx={{textAlign: "center", justifyContent: "center", padding: "50px"}}>
            <LoadingSpinner />
          </Grid>
        </Grid>
      </MainLayout>
    )
  }
  else {
    return(
      <MainLayout>
        <SaveFabButton show={showSaveButton} loading={saving} onClick={() => saveClick()} disabled={saving} />
        <Paper sx={{padding: "7px", marginBottom: "15px"}} elevation={5} mb={2}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Stack direction="row">
                <Typography color="primary" variant="body2" sx={{textOverflow: "fade", maxWidth: "30%", alignSelf: "center" }}>
                  <b style={{cursor: "pointer"}} onClick={() => navigate("/form")} >
                    <TranslationContent contentID="inspectionTemplate" />
                  </b>
                </Typography>
                <KeyboardArrowRightIcon sx={{verticalAlign:"middle", alignSelf: "center" }} color="primary" />
                <Typography color="primary" variant="body2" onClick={() => updateSection()} sx={{cursor: "pointer", textOverflow: "fade", maxWidth: "30%", alignSelf: "center" }}>
                  {form.name[currentLang.lang]}
                </Typography>
                <KeyboardArrowRightIcon sx={{verticalAlign:"middle", alignSelf: "center" }} color="primary" />
                {section.name[currentLang.lang] === "" ? 
                  <Typography variant="body2" sx={{textOverflow: "fade", maxWidth: "30%", alignSelf: "center" }}>
                    <i style={{opacity: 0.5}}><TranslationContent contentID="noName" /></i>
                  </Typography>
                :
                  <Typography color="primary" variant="body2" sx={{textOverflow: "fade", maxWidth: "30%", alignSelf: "center" }}>
                    <i>{section.name[currentLang.lang]}</i>
                  </Typography>
                }
              </Stack>
            </Grid>
          </Grid>
        </Paper>
        <ButtonGroup variant="text" color="primary" sx={{width:"100%"}}>
          <Button size="small" sx={{width: "33%", display: "grid", justifyItems: "center"}} onClick={() => returnClick()} disabled={loading}><KeyboardReturnIcon /><Typography variant="caption" ><TranslationContent contentID="return" /></Typography></Button>
          <Zoom in={showSaveButton}><Button sx={{width: "33%", display: "grid", justifyItems: "center"}} disabled  /></Zoom>
          <Zoom in={showSaveButton}><Button size="small" onClick={() => saveClick()} sx={{width: "33%", display: "grid", justifyItems: "center"}} disabled={loading || saving || form.permissionType === "read"}>{saving ? <CircularProgress color="primary"/> : <><SaveIcon /><Typography variant="caption" ><TranslationContent contentID="save" /></Typography></>}</Button></Zoom>
        </ButtonGroup>
        <div key={section.id} style={{textAlign: "center"}}>
          <Divider textAlign="left" sx={{mb:1}}><Typography color="primary" variant="caption"><b><TranslationContent contentID="categories" /></b></Typography></Divider>
          <EmptyListText show={section.categories.length < 1} text="noCategories" />
          {section.categories.map(category => 
            <SectionCategory 
              key={category.id}
              category={category} 
              permissionType={permissionType} 
              deleteOption={deleteOption} 
              deleteCategory={deleteCategory} 
              handleCategoryTextFieldChange={handleCategoryTextFieldChange} 
              handleOptionTextFieldChange={handleOptionTextFieldChange} 
              addOption={addOption} 
            />
          )}
          <Button variant="contained" disabled={permissionType === "read"} sx={{ mt: 5, mb: 4 }} onClick={() => addCategory()} ><AddIcon />&nbsp;&nbsp;<TranslationContent contentID="addCategory" /></Button>
          <Divider textAlign="left" sx={{mb:1}}><Typography color="primary" variant="caption"><b><TranslationContent contentID="inspections" /></b></Typography></Divider>
          <EmptyListText show={section.inspections.length < 1} text="noInspections" />
          {section.inspections.length > 0 &&
            section.inspections.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((inspection, index) =>
              <SectionInspection 
                key={inspection.id + index}
                inspection={inspection} 
                inspections={section.inspections}
                section={section}
                index={index}
                permissionType={permissionType} 
                decreaseInspectionIndex={decreaseInspectionIndex}
                increaseInspectionIndex={increaseInspectionIndex}
                deleteInspection={deleteInspection}
                deleting={deleting}
                handleInspectionImageChange={handleInspectionImageChange}
                handleInspectionTextFieldChange={handleInspectionTextFieldChange}
                handleInspectionToggle={handleInspectionToggle}
                imageTransactionActive={imageTransactionActive}
                images={images}
                updateImages={updateImages}
                form={form}
                updateSectionChanges={updateSectionChanges}
              />
            )
          }
          <Divider sx={{mt: 3}} />
          <TablePagination
            rowsPerPageOptions={[1, 2, 3, 4, 5]}
            component="div"
            count={section.inspections.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={<TranslationContent contentID="inspectionsPerPage" />}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={width > 600 ? true : false}
            showLastButton={width > 600 ? true : false}
            labelDisplayedRows={
              ({ from, to, count }) => {
                return ` ${from}–${to} / ${count}`
              }
            }
          />
          <Button sx={{ mb: 3, mt: 5, alignSelf: "center" }} variant="contained" disabled={permissionType === "read"} onClick={() => addInspection()}>
            <AddIcon />&nbsp;&nbsp;<TranslationContent contentID="addInspection" />
          </Button>
        </div>
      </MainLayout>
    )
  }
}

export default SectionContent;